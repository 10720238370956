import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { NatDTO, NATSaveRequestDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'nat-settings',
    templateUrl: './nat-configuration.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class NatConfiguration implements OnInit {
    @Input("NatRule") Rule: NatDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("PublicIps") PublicIps: Array<string>;
    @Input("PrivateIps") PrivateIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Output() NewBindings = new EventEmitter();
    constants: Constants = new Constants();
    loading: boolean = false;
    globalization: any = Globalization;
    token: string;
    language: string;
    natRuleForm : FormGroup;
    saveRequest : NATSaveRequestDTO = new NATSaveRequestDTO();

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder : FormBuilder, public auth: AuthService ) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.natRuleForm = this.formBuilder.group({
            'protocolo' : ['',[Validators.required]],
            'description' : ['',[Validators.required, Validators.maxLength(50)]],
            'ipSource' : ['',[Validators.required, Validators.maxLength(15)]],
            'ipDestination' : ['',[Validators.required, Validators.maxLength(15)]],
            'originalPort' : ['',[Validators.required, Validators.maxLength(15)]],
            'portDestination' : ['',[Validators.required, Validators.maxLength(15)]],
        });

    }

    ngOnInit() {
        this.Rule = new NatDTO();
    }

    saveRule() {
        this.loading = true;
        this.natRuleForm.disable();
        this.saveRequest.natRules.natRule = [];
        this.saveRequest.natRules.natRule.push(this.Rule);
        this.saveRequest.DatacenterID = this.DatacenterID;
        this.wlservice.SaveNat(this.saveRequest).subscribe(
            () => {
                this.natRuleForm.enable();
                this.snackbar.open('Regla de Firewall guardada satisfactoriamente.', null, {
                    duration: 2000,
                });
                this.loading = false;
                this.sidenav.close();
                this.NewBindings.emit(this.Rule);
            },
            error => {
                this.natRuleForm.enable();
                this.snackbar.open('Error guardando regla NAT ' + error.error, null, {
                    duration: 2000,
                });
            }
        )

    }
}
