import { Component, Input, Output, OnInit, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { IPSetDTO, IPSetSaveRequestDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';



@Component({
  selector: 'ipset-settings',
  templateUrl: './ipset-configuration.component.html',
  styleUrls: ['../networkSettings.scss']
})

export class IpsetConfigurationComponent implements OnInit, OnChanges {
    @Input("IPSet") IPSet: IPSetDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("appGr") appGr: Array<any>;
    @Input("sidenav") sidenav: MatSidenav;
    @Output() NewBindings = new EventEmitter();
    loading: boolean = false;
    private validForm: boolean = false;
    saveRequest: IPSetSaveRequestDTO = new IPSetSaveRequestDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    ipsetForm: FormGroup;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.ipsetForm = this.formBuilder.group({
            'Value' : ['', [Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]],

        });
    }

    resetAll() {
        this.loading = false;
        this.saveRequest = new IPSetSaveRequestDTO();
    }

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if(changes["IsNew"]){
            if(changes["IsNew"].currentValue){
                this.resetAll();
            }
        }
    }

    saveRule() {
        this.loading = true;
        this.ipsetForm.disable();

        if(this.IPSet.ObjectId != null)
        {
          this.IPSet.Revision = this.IPSet.Revision + 1;
        } else{
          this.IPSet.Name = this.IPSet.Value;
        }
        this.saveRequest.IPSet = this.IPSet;
        this.saveRequest.DatacenterID = this.DatacenterID;
        this.wlservice.SaveIPSet(this.saveRequest).subscribe(
            response => {
                this.ipsetForm.enable();
                this.loading = false;
                this.snackbar.open('IPSet Guardado satisfactoriamente', null, {
                    duration: 3000,
                });

                this.sidenav.close();
                this.NewBindings.emit(this.IPSet);
            },
            error => {
                this.ipsetForm.enable();
                this.loading = false;
                this.snackbar.open('Error guardando IPSet ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
