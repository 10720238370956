import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-useStatistics',
    templateUrl: './useStatistics.component.html',
    styleUrls: ['./useStatistics.component.css']
})
export class UseStatisticsComponent implements OnInit {

    ngOnInit(){

    }
}