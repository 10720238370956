import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  @Input() user: any; 
  @Input() menu: any;
  menus = [];
  constructor(private sidebarservice : SidebarService) {
    this.menus = this.sidebarservice.getMenuList();
  }

  ngOnInit() {
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle (level :number, currentMenu) {
    if (currentMenu.type === 'dropdown') {
        console.log("==MENU==",this.menus)
        this.menus.forEach(element => {
          if(level ===1){
            if (element === currentMenu) {
              currentMenu.active = !currentMenu.active;
            } else {
              element.active = false;
            }
          }else if(level ===2){
              
            if( element.submenus  && element.submenus.length > 0){
              element.submenus.forEach(data => {
                if (data === currentMenu) {
                  currentMenu.active = !currentMenu.active;
                } else {
                  data.active = false;
                }
              });
            }
          }
        });
      
    }
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }


}
