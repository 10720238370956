import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FirewallIPDto } from '../DTO/firewall-ip-dto';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { NewPfSenseIpComponent } from '../pfsense-ip/forms/new-pf-sense-ip/new-pf-sense-ip.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-firewall-aliases-all',
  templateUrl: './firewall-aliases-all.component.html',
  styleUrls: ['./firewall-aliases-all.component.scss']
})
export class FirewallAliasesAllComponent implements AfterViewInit{
  @Input() item:FirewallIPDto[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'description', 'address'];
  dataSource: MatTableDataSource<FirewallIPDto>;
  constructor(public dialog: MatDialog) { 
  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data =  this.item;
    }
  }

  newRule() {
    const dialogRef = this.dialog.open(NewPfSenseIpComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

      }

    });

  }

}
