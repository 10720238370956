<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid" *ngIf="product">
    <div class="sidenav-title">
        <h5 *ngIf="product.ID == null">{{'VIRTUAL_SERVERS.ADD_VIRTUAL_SERVER' | translate}}</h5>
        <h5 *ngIf="product.ID != null">{{'VIRTUAL_SERVERS.EDIT_VIRTUAL_SERVER' | translate}}</h5>
    </div>
    <form [formGroup]="virtualServerForm" novalidate (ngSubmit)="SaveProduct()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'VIRTUAL_SERVERS.PLAN_DATA' | translate}}</h6>
            </div>
            <mat-form-field class="input-container resetTextSize" class="col-12" floatLabel="always">
                <input matInput required class="grey-text-input txtSmall" placeholder="{{'VIRTUAL_SERVERS.PLAN_NAME' | translate}}"
                    value="" name="nombredelPlan" required minlength="4" id="nombredelPlan" formControlName="nombredelPlan" [(ngModel)]="product.Name"
                />
                <mat-icon matSuffix *ngIf="virtualServerForm.get('nombredelPlan').valid && virtualServerForm.get('nombredelPlan').touched"
                    class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!virtualServerForm.get('nombredelPlan').valid && virtualServerForm.get('nombredelPlan').touched"
                    class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="virtualServerForm.get('nombredelPlan').hasError('minlength') && virtualServerForm.get('nombredelPlan').touched">El nombre debe contar con al menos 4 caracteres</div>
                        <div *ngIf="virtualServerForm.get('nombredelPlan').hasError('required') && virtualServerForm.get('nombredelPlan').touched">El nombre del plan es un campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end" class="grey-text-input">{{product.Name ? product.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <mat-form-field class="input-container resetTextSize container-fluid" floatLabel="always">
                <textarea class="grey-text-input txtSmall txtArea configInput" required name="descripciondelPlan" id="descripciondelPlan"
                    formControlName="descripciondelPlan" minlength="20" [(ngModel)]="product.Description" matInput maxlength="130"
                    placeholder="{{'VIRTUAL_SERVERS.PLAN_DESCRIPTION' | translate}}">
                            </textarea>
                <mat-icon matSuffix *ngIf="virtualServerForm.get('descripciondelPlan').valid && virtualServerForm.get('descripciondelPlan').touched"
                    class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!virtualServerForm.get('descripciondelPlan').valid && virtualServerForm.get('descripciondelPlan').touched"
                    class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="virtualServerForm.get('descripciondelPlan').hasError('minlength') && virtualServerForm.get('descripciondelPlan').touched">El tamaño mínimo es 20 caracteres.</div>
                        <div *ngIf="virtualServerForm.get('descripciondelPlan').hasError('required') && virtualServerForm.get('descripciondelPlan').touched">La descripción es un campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{product.Description ? product.Description.length : 0}} / 130</mat-hint>
            </mat-form-field>
            <div class="sidenav-subtitle col-12">
                <h6>Configuración del plan</h6>
            </div>
            <mat-form-field class="col-6 full-width" floatLabel="always">
                <mat-select class="margin-subtitle-10" required (selectionChange)="refreshProductPrice()" [(ngModel)]="product.Configuration.BundleMachine.Cores.Value"
                    name="selectedCore" placeholder="Cores" matTooltip="{{'VIRTUAL_SERVERS.SELECT_QUANTITY_OF_CORES' | translate}}" matTooltipPosition="above" formControlName="cantidadCores" floatPlaceholder="always">
                    <mat-option *ngFor="let c of constants.CoreList" [value]="c.value">{{c.value}}</mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field class="col-6 full-width" floatLabel="always">
                <mat-select  class="margin-subtitle-10" required (selectionChange)="refreshProductPrice()" [(ngModel)]="product.Configuration.BundleMachine.Ram.Value"
                    name="selectedRam" placeholder="RAM" matTooltip="{{'VIRTUAL_SERVERS.SELECT_QUANTITY_OF_RAM' | translate}}" matTooltipPosition="above" formControlName="cantidadRam" floatPlaceholder="always">
                    <mat-option
                        *ngFor="let r of constants.RamList" [value]="r.value">{{r.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="margin-subtitle-20" required (selectionChange)="changeProductType($event)" [(ngModel)]="product.Image" name="ProductImage"
                    placeholder="{{'VIRTUAL_SERVERS.PRODUCT_TYPE' | translate}}" formControlName="tipodeProductos">
                    <mat-option *ngFor="let c of constants.ProductImages" [value]="c.Value">{{c.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6">
                <p class="total-cost-text"><b>{{'VIRTUAL_SERVERS.TOTAL_COST' | translate}}:</b> <span>{{ product.Configuration.BundleMachine.Cost.DiscountMRC
                    | currency:'USD':true:'1.2-2'}}</span>
                </p>
            </div>
            <div class="container-fluid col-6 no-right-padding">
                <button class="pull-right" type="submit" color="primary" [disabled]="!virtualServerForm.valid || loading" mat-raised-button>{{'VIRTUAL_SERVERS.SAVE' | translate}}</button>
            </div>
        </div>
    </form>
</div>
