import { Component, Input, Output, OnInit, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from '../../../services/wlservice.service';
import { ServiceDTO, ProductDTO, BundleDTO, ServiceAdditionalInfo } from '../../../models/dtos.component';
import { Constants } from '../../../models/constants.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { Globalization } from '../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomFormValidator } from '../../../tools/CustomValidator';
import { TermsComponent } from '../terms/terms.component';
@Component({
    selector: 'service-settings',
    templateUrl: './service-configuration.component.html',
    styleUrls: ['./service-configuration.component.scss']
})

export class ServiceSettings implements OnInit, OnChanges {
    @Input() Service: ServiceDTO;
    @Input() sidenav: MatSidenav;
    @Output() onSave = new EventEmitter<ServiceDTO>();
    @ViewChild('stepper') stepper : MatStepper;
    public bundle: BundleDTO = new BundleDTO();
    constants: Constants = new Constants();
    serviceAdditionalInfo: ServiceAdditionalInfo = new ServiceAdditionalInfo();
    progress: number = 0;
    TemplateList: Array<any> = [];
    OsType: number = null;
    loading: boolean = false;
    loadingTemplates: boolean = false;
    confirmedTerms: boolean = false;
    domainProducts: Array<any> = [];
    confirmForm: FormGroup;
    bundleSelectFG: FormGroup;
    currentBundle: ProductDTO;
    constructor(private service: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, private dialog: MatDialog) {
        this.confirmForm = this.formBuilder.group({
            'datacenter': ['', [Validators.required]],
            'osType': ['', [Validators.required]],
            'os': ['', [Validators.required]],
            'iops': ['', [Validators.required]],
            'availability': ['', [Validators.required]],
            'paymentConst': ['', [Validators.required]],
        });
        this.bundleSelectFG = this.formBuilder.group({
            'bundle': [{ value: null, disable: this.loading }, [Validators.required]]
        });
    }

    changeStep(index : number){
        if(this.stepper.selectedIndex != index)
            this.stepper.selectedIndex = index;
    }

    resetComponent() {
        this.bundle = new BundleDTO();
        this.constants = new Constants();
        this.progress = 0;
        this.TemplateList = [];
        this.OsType = null;
        this.loading = false;
        this.loadingTemplates = false;
        this.confirmedTerms = false;
    }

    ngOnChanges(changes) {
        if (changes['Service'].currentValue) {
            this.serviceAdditionalInfo.CustomerID = changes['Service'].currentValue.CustomerID;
            this.bundle.BundleMachine = changes['Service'].currentValue.Configuration;
            this.progress = 0;
        }
    }

    ngOnInit() {
        this.Service = new ServiceDTO();
        this.getDomainProducts();
        this.sidenav.closedStart.subscribe(() => {
            this.bundleSelectFG.reset();
            this.confirmForm.reset();
            this.changeStep(0);
        })
    }

    getDomainProducts() {
        this.loading = true;
        this.service.GetProducts().subscribe(
            (response:any) => {
                this.loading = false;
                this.domainProducts = response;
            },
            error => {

            }
        )
    }

    selectPlan() {
        this.bundle.BundleMachine = this.currentBundle.Configuration.BundleMachine;
        this.serviceAdditionalInfo.BundleID = this.currentBundle.ID;
        if (this.bundle.BundleMachine.Duration == 0) {
            this.bundle.BundleMachine.Duration = null;
        }
        this.refreshPrice();
        this.progress = 1;
    }

    getOsList() {
        if (this.bundle.BundleMachine.Vcenter != null && this.OsType != null) {
            this.loadingTemplates = true;
            this.service.GetOsList(this.bundle.BundleMachine.Vcenter, this.OsType).subscribe(
                (response:any) => {
                    this.loadingTemplates = false;
                    this.TemplateList = response;
                },
                error => {
                    this.loadingTemplates = false;
                    this.TemplateList = [];
                }
            );
        }
    }

    viewTerms() {
        
    }

    selectTemplate() {
        this.refreshPrice();
    }

    refreshPrice() {
        this.loading = true;
        this.service.GetProductPrice(this.bundle).subscribe(
            (response:any) => {
                this.loading = false;
                this.bundle = response;
                if (this.bundle.BundleMachine.Duration == 0) {
                    this.bundle.BundleMachine.Duration = null;
                }
            },
            error => {
                this.loading = false;
            }
        );
    }

    processConfig() {
        this.progress = 2;
    }

    selecttab() {
        this.confirmedTerms = false;
    }

    SaveService() {
        this.confirmForm.disable();
        this.loading = true;
        this.service.SaveService(this.bundle, this.serviceAdditionalInfo).subscribe(
            (response:any) => {
                this.confirmForm.enable();
                this.loading = false;
                this.onSave.emit(response);
                this.bundleSelectFG.reset();
                this.confirmForm.reset();
                this.resetComponent();
                this.sidenav.close();
                this.changeStep(0);
                this.snackbar.open('Servicio guardado satisfactoriamente', null, {
                    duration: 3000,
                });
            },
            error => {
                this.confirmForm.enable();
                this.loading = false;
                this.snackbar.open('Error guardando servicio. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
    ShowTerms() {
        this.dialog.open(TermsComponent, {
            width: '60%'
        });
    }

    GetConstantValue(Id: string, type: string) {
        if (Id == null || Id === 'null' || Id === '0') {
            return '';
        } else if (type === 'dc') {
            return this.constants.DataCenter.filter(dc => dc.ID === Id)[0].Name;
        } else if (type === 'pc') {
            return this.constants.PaymentConditions.filter(dc => dc.ID.toString() === Id)[0].Name;
        } else if (type === 'av') {
            return this.constants.Availability.filter(dc => dc.ID.toString() === Id)[0].Name;
        } else {
            console.log(Id, type);
        }
    }
    ReplaceName(name) {
        let response = name.replace(/_/g, ' ');
        let capitalizeName = "";
        response.split(' ').forEach(element => {
            if (capitalizeName.length != 0) {
                capitalizeName += " ";
            }
            capitalizeName += element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
        });
        return capitalizeName;
    }
}
