import { Component, ViewChild, OnInit} from '@angular/core';
import { Globalization } from '../../../globalization/globalization';
import { WlService } from '../../../services/wlservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  inputs:['activeColor','baseColor','overlayColor']
})

export class ImageUploadComponent  implements OnInit {

  @ViewChild("fileInput") fileInput;
    globalization: any = Globalization;
    dragging: boolean = false;
    loaded: boolean = false;
    customerImage: string = '';
    editImage: boolean = false
    imageLoaded: boolean = false;
    imageSrc: string = '';
    activeColor: string = 'green';
    baseColor: string = '#ccc';
    borderColor :string;
    iconColor : string;
    HeaderConfiguration : any;
    overlayColor: string = 'rgba(255,255,255,0.5)';
    token: string;
    language: string;

    constructor(private  service: WlService , public snackbar: MatSnackBar, public auth : AuthService) {}

    public ngOnInit(){
        this.inicializate();
        this.getDomainData();
    }

    inicializate(){
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    public getDomainData(){
        this.service.GetDomainInformation().subscribe(
            (response:any)=>{
                this.HeaderConfiguration = JSON.parse(response.HeaderConfiguration);
                if(this.HeaderConfiguration.Logo.length > 0){
                  this.customerImage =  environment.serviceUrl+"CustomerImages/"+this.HeaderConfiguration.Logo;
                  this.imageSrc = this.customerImage;
                }
            },    
            error=>{
                this.snackbar.open('Error consultando domainInfo ' + error.error, null, {
                    duration: 2000,
                });
            }
          
        )
    }


   
    saveFile(): void {
      let fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];
            this.service.uploadFile(fileToUpload, "Domain/UploadLogo").subscribe(
                response => {
                  this.snackbar.open('Logo actualizado correctamente', null, {
                      duration: 2000,
                  });
                  this.getDomainData();
                  this.editImage = false;
                },    
                error=>{
                    this.snackbar.open('Error actualizando el logo' + error.error, null, {
                        duration: 2000,
                    });
                }
            );
        }
    }

    cancelUpload(): void{
      this.imageSrc = (this.customerImage) ?  this.customerImage : this.imageSrc ='';
      this.editImage = false;
    }

    handleDragEnter() {
        this.dragging = true;
    }
    
    handleDragLeave() {
        this.dragging = false;
    }
    
    handleDrop(e) {
        e.preventDefault();
        this.dragging = false;
        this.handleInputChange(e);
    }
    
    handleImageLoad() {
      this.imageLoaded = true 
      this.iconColor = this.overlayColor;
    }

    handleInputChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();

        if (!file.type.match(pattern)) {
            this.snackbar.open('Formato Invalido', null, {
              duration: 2000,
            });
            return;
        }
        this.editImage = true;
        this.loaded = false;
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
    }
    
    handleReaderLoaded(e) {
        var reader = e.target;
        this.imageSrc = reader.result;
        this.loaded = true;
    }

    setActive() {
        this.borderColor = this.activeColor;
        if (this.imageSrc.length === 0) {
            this.iconColor = this.activeColor;
        }
    }
    
    setInactive() {
        this.borderColor = this.baseColor;
        if (this.imageSrc.length === 0) {
            this.iconColor = this.baseColor;
        }
    }
}