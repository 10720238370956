import { Component } from '@angular/core';
import {WlService} from './services/wlservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [WlService]
})

export class AppComponent {
  spaceScreens: Array<any>;
}
