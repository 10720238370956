<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5>Agregar contacto de soporte</h5>
    </div>
    <form [formGroup]="supportForm" (ngSubmit)="saveContact()">
        <div class="row container-fluid sidenav-form-content">
            <mat-form-field class="full-width">
                <input class="" name="name" id="name" formControlName="name" [(ngModel)]="supportContact.Name" required matInput type="text"
                    placeholder="Nombre del contacto" maxlength="50">
                <mat-icon matSuffix *ngIf="supportForm.get('name').untouched" class="form-wait">live_help</mat-icon>
                <mat-icon matSuffix *ngIf="supportForm.get('name').valid && supportForm.get('name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!supportForm.get('name').valid && supportForm.get('name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="supportForm.get('name').hasError('required') && supportForm.get('name').touched">Campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint class="" align="end">{{supportContact.Name ? supportContact.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input class="" name="phone" id="phone" formControlName="phone" [(ngModel)]="supportContact.Telephone" required name="Telephone"
                    matInput type="text" placeholder="Teléfono" maxlength="50">
                <mat-icon matSuffix *ngIf="supportForm.get('name').untouched" class="form-wait">live_help</mat-icon>
                <mat-icon matSuffix *ngIf="supportForm.get('phone').valid && supportForm.get('phone').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!supportForm.get('phone').valid && supportForm.get('phone').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="supportForm.get('phone').hasError('required') && supportForm.get('phone').touched">Campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint class="" align="end">{{supportContact.Telephone ? supportContact.Telephone.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input type="email" class="" required name="email" id="email" formControlName="email" [(ngModel)]="supportContact.Email"
                    matInput placeholder="Correo" maxlength="50" />
                <mat-icon matSuffix *ngIf="supportForm.get('name').untouched" class="form-wait">live_help</mat-icon>
                <mat-icon matSuffix *ngIf="supportForm.get('email').valid && supportForm.get('email').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!supportForm.get('email').valid && supportForm.get('email').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="supportForm.get('email').hasError('required') && supportForm.get('email').touched">Campo requerido.</div>
                        <div *ngIf="supportForm.get('email').hasError('EmailValidator') && supportForm.get('email').touched">El formato no corresponde.</div>
                    </div>
                </mat-hint>
                <mat-hint class="" align="end">{{supportContact.Email ? supportContact.Email.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width">
                <input class="" name="country" id="country" formControlName="country" [(ngModel)]="supportContact.Country" required name="Country"
                    matInput type="text" placeholder="Pais" maxlength="50">
                <mat-hint class="" align="end">{{supportContact.Country ? supportContact.Country.length : 0}} / 50</mat-hint>
                <mat-icon matSuffix *ngIf="supportForm.get('name').untouched" class="form-wait">live_help</mat-icon>
                <mat-icon matSuffix *ngIf="supportForm.get('country').valid && supportForm.get('country').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!supportForm.get('country').valid && supportForm.get('country').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="supportForm.get('phone').hasError('required') && supportForm.get('phone').touched">Campo requerido.</div>
                    </div>
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons no-default-padding">
            <div class="col-12 no-default-padding">
                <button class="pull-right" type="submit" color="primary" [disabled]="!supportForm.valid" mat-raised-button>Guardar</button>
            </div>
        </div>
    </form>
</div>