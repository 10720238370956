<div class="card" style="width: 600px">
    <div class="card-block container-fluid margin-bottom-10">
          <div class="card-title modal-title">
            <h6 class="pull-left">{{data.Title}}</h6>
            <div class="pull-right close-button" (click)="cancelClick()">
              <mat-icon>close</mat-icon>
            </div>
          </div>
            <form [formGroup]="SaveQuoteForm" class="example-form" (ngSubmit)="SaveBundleQuote()">
                <div class="full-width margin-top-10" style="display: inline-block">
                    <mat-form-field class="example-full-width">
                        <input matInput placeholder="Cliente" arial-label="Cliente" [matAutocomplete]="auto" formControlName="Customer" [(ngModel)]="customerName">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="GetCustomerData" isOpen="false" autoActiveFirstOption="false">
                                <mat-option *ngFor="let customer of filteredCustomer | async" [value]="customer">
                                <div class="customer-autocomplete-line">
                                    <span>{{customer.Name | titlecase}}</span> |
                                    <small>Email: {{customer.Email}}</small>
                                </div>
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon matSuffix *ngIf="SaveQuoteForm.get('Customer').valid && SaveQuoteForm.get('Customer').touched" class="form-accepted">done</mat-icon>
                            <mat-icon matSuffix *ngIf="!SaveQuoteForm.get('Customer').valid && SaveQuoteForm.get('Customer').touched" class="form-rejected">error</mat-icon>
                    </mat-form-field>
                    <div class="row">
                        <div class="col-6">
                            <mat-form-field class="example-full-width">
                                    <input matInput placeholder="Titulo de la cotización" arial-label="Titulo" formControlName="Name">
                            </mat-form-field>
                        </div>
                        <div class="col-6">
                            <mat-form-field class="example-full-width description">
                                    <textarea matInput placeholder=" Observaciones e informacion extra de contácto" formControlName="Description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="full-width margin-top-20">
                    <button type = "submit" color="primary" mat-button class="pull-right" [disabled] ="!SaveQuoteForm.valid">Guardar</button>
                    <button type = "button" color="warn" mat-button (click)="cancelClick()" class="pull-right" *ngIf="!loading">Cancelar</button>
                </div>
            </form>
     </div>
</div>
