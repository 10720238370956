import { Component, OnInit, Inject, Output, AfterViewInit, ChangeDetectorRef, EventEmitter  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WlService } from '../../../../services/wlservice.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { Validators, FormGroup, FormBuilder} from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { UserQuoteDTO } from '../../../../models/dtos.component';

@Component({
    selector: 'app-calc-confirm',
    templateUrl: './saveBundleQuoteModal.component.html',
    styleUrls: [ '../../../../tools/modals/modals.scss', './saveBundleQuoteModal.component.scss']
  })

export class SaveBundleQuoteModalComponent implements OnInit, AfterViewInit {
    loading = false;
    SaveQuoteForm : FormGroup;
    public selectedCustomer : object;
    filteredCustomer: Observable<UserQuoteDTO[]>;
    customerName = '';
    public users: any = [];
    constructor(
        public dialogRef: MatDialogRef<SaveBundleQuoteModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: any,
        private service: WlService,
        public snackbar: MatSnackBar,
        public auth: AuthService,
        private formBuilder : FormBuilder,
        private changeDetector: ChangeDetectorRef

    ){
      this.customerName = data.customerName;
      this.SaveQuoteForm = this.formBuilder.group({
        Customer : [{ value: '', disabled: false }, [Validators.required]],
        Description: [],
        Name : [{ value: '', disabled: false }, [Validators.required]]
        });
    }

    onNoClick() : void {
      this.dialogRef.close();
    }
    ngOnInit() {
      this.GetCustomer();
    }


    closeDialog() : void{
      this.dialogRef.close();
    }

    cancelClick() {
        if(!this.data.Cancel)
            this.dialogRef.close();
        else
            this.data.Cancel();
    }

    okClick() : void{
      this.dialogRef.close();
      this.data.Ok(this.data);
    }

    ngAfterViewInit() {
        this.filteredCustomer = this.SaveQuoteForm.valueChanges.pipe(startWith(''),map(s => s ? this._filteredCustomers(s) : this.users.slice()))
        this.changeDetector.detectChanges();
    }

    GetCustomer() {
        this.users = [];
        this.selectedCustomer = null;
        this.service.GetUserAccounts()
                .subscribe((response:any) => {
                    this.users = response.filter(s => s.CustomerID != null);
                }, error => {
            });
      }

    GetCustomerData(customer?: UserQuoteDTO) {
        return customer ? customer.Name : undefined;
    }

   private _filteredCustomers(value:any) :UserQuoteDTO[]{
    if(typeof value.Customer !== "string")
        return;
    const filterValue = value.Customer.toLowerCase();
    return this.users.filter(s => s.Name.toLowerCase().indexOf(filterValue) != -1 || s.Email.toLowerCase().indexOf(filterValue) != -1);
    }

    SaveBundleQuote() {
      let quoteDate =  {
          Customer :  this.GetSelectedCustomer(),
          Name : this.SaveQuoteForm.value.Name,
          Description : this.SaveQuoteForm.value.Description
      };
      this.dialogRef.close(quoteDate);
    }

    GetSelectedCustomer() : any {
        if (this.SaveQuoteForm.valid){
            return this.SaveQuoteForm.value.Customer;
        }
        else{
            this.snackbar.open('Cliente Invalido ', null, {
                duration: 2000,
            });
        }
    }

}

