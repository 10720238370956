import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FirewallDTO, FirewallSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'firewall-component',
  templateUrl: './firewall-component.component.html',
  styleUrls: ['./firewall-component.component.scss']
})
export class FirewallComponent implements OnInit, OnChanges {
    @Input("FirewallRules") FirewallRules: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Name', 'Action', 'SourceIp', 'DestinationIp', 'Port', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      this.dataSource.data = this.FirewallRules;
      
      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      this.dataSource.data = this.FirewallRules;
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemoveFirewallModal(rule: FirewallDTO) {
      this.dialog.open(ConfirmationDialog, {
          data: {
              Title: "Eliminar Regla de Firewall",
              Message: "Esta seguro?",
              Ok: () => this.RemoveFirewallRule(rule),
          }
      });
    }

    RemoveFirewallRule(rule: FirewallDTO) {
        let req = new FirewallSaveRequestDTO()
        req.DatacenterID = this.DatacenterID;
        req.firewallRules.Rule.push(rule);
        this.wlservice.RemoveFirewallRule(req).subscribe(
            response => {
                this.OnDelete.emit(rule);
                this.snackbar.open('Regla eliminada satisfactoriamente. ', null, {
                    duration: 3000,
                });
            },
            error => {
                this.snackbar.open('Error eliminando regla ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditFirewallRule(rule: FirewallDTO) {
      this.OnEdit.emit(rule);
    }
}