export const environment = {
  production: true,
  serviceUrl: "https://cloudapi.ifxcorp.com/",
  console: "https://cloudadmin.ifxcorp.com:4000/index.html",
  OVMConsole: "https://cloudconsole.ifxcorp.com:7002",
  OVMConsoleCl: "https://cloudconsole.ifxcorp.com:7003",
  CSJ_URL: "https://dashboardcsjapi.api.ifxcorp.com",
  CSJC: "a5626a8de7bff8b35e887df47860b27a4cc78c03e6602f5fb5f8dd38205e5e97f15164186528d398cc40e33b683eac112a9caf71f30da48f0483604112eee4a4aaec8b59286ab413061f436e908ff530536b36b4f2b36f47b9247bd8173eeb447ece975e8cba2d7eebe4727644",
  urlApiLogs: "https://ifx-core-system-prd.us-e1.cloudhub.io/api/logs",
  //KEY_RSA_PUB_DEVC: "-----BEGIN PUBLIC KEY-----MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHYfi+b2Zil7WVFXtSqQ2jFsVAHNl3spvs9Zy8V/flcivcOm4CCQm+Ds56gHMOgTgvZLwp+ZuR0uSAbpwpqQzDn0kFPPWVqdC4SCAT6z7C1fgyamdjxv/23NlUGRgfeXuuTSut6ODGzk5TUpYwJqq6C3nXPTan6aScuOnTfgHk1vAgMBAAE=-----END PUBLIC KEY-----"
  KEY_RSA_PUB_DEVC: `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZCa09hc+moDezpQJj7m0x99if
  ZUncXPpOsT9VmqxRIk++lYRjJBFG2vZFpiwSB2M9qvLtOEbAfrVI5Uq8fesv0niq
  M+Y78zHEt3LRPt7ULzMcKeRkG3P6lBp6aaxyl7a53HaW3PLbOf084WGze1ymKPWk
  JLdpVPjp0p4zLgGC/QIDAQAB
  -----END PUBLIC KEY-----`
};
