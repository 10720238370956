export const Globalization = {
        general : {

            required : {
                'en-us': 'Required', 'es-co': 'Campo requerido.',
            }
        },
        login:
        {
            message: {
                // 'en-us':'Enter your user name and password.',
                // 'es-co':'Ingrese su usuario y contraseña',
                'en-us': 'Hi! We wish you to have a very nice day.',
                'es-co': 'Hola!, Esperamos que tengas un muy buen día',
            },
            user: {
                'en-us': 'type your user name',
                'es-co': 'Usuario',
            },
            password: {
                'en-us': 'Place your Password',
                'es-co': 'Contraseña',
            },
            loginButton: {
                'en-us': 'Login',
                'es-co': 'Ingresar',
            },
            forgotPassword: {
                'en-us': 'Forgot your password?',
                'es-co': '¿Olvidaste tu contraseña?',
            }
        },

        menu:
        {
            home: {
                'en-us': 'Home', 'es-co': 'Inicio',
            },
            dataCenter: {
                'en-us': 'Datacenter', 'es-co': 'Centro de datos',
            },
            plans: {
                'en-us': 'Services', 'es-co': 'Servicios',
            },
            Administration: {
                'en-us': 'Administration', 'es-co': 'Administración',
            },
            Configuration: {
                'en-us': 'Settings', 'es-co': 'Configuración',
            },
            Information: {
                'en-us': 'Information', 'es-co': 'Información',
            },
            logout: {
                'en-us': 'Logout', 'es-co': 'Cerrar sesión',
            },
            virtualServers: {
                'en-us': 'Virtual servers', 'es-co': 'Servidores virtuales',
            },
            calculator: {
                'en-us': 'Calculator', 'es-co': 'Cotizador',
            },
            Prospects: {
                'en-us': 'Prospects', 'es-co': 'Prospectos',
            },
            eventsLog: {
                'en-us': 'Event viewer', 'es-co': 'Log de eventos',
            },
            useStatistics: {
                'en-us': 'Statistics', 'es-co': 'Estadisticas de uso',
            },
            whiteLabelConfiguration: {
                'en-us': 'Look and Feel', 'es-co': 'Mi portal',
            },
            CancelledServices: {
                'en-us': 'Cancelled services', 'es-co': 'Servicios cancelados',
            },
            userAccounts: {
                'en-us': 'User accounts', 'es-co': 'Cuentas de usuario',
            },
            billing: {
                'en-us': 'Billing', 'es-co': 'Facturación',
            },
            Marketplace: {
                'en-us': 'Marketplace', 'es-co': 'Tienda',
            },
            accountConfiguration: {
                'en-us': 'My account', 'es-co': 'Mi cuenta',
            },
            activeDirectory: {
                'en-us': 'Active Directory', 'es-co': 'Directorio activo',
            },
            role: {
                'en-us': 'Roles', 'es-co': 'Roles',
            },
            emailSetup: {
                'en-us': 'Email branding', 'es-co': 'Correos',
            },
            helpDesk: {
                'en-us': 'Helpdesk', 'es-co': 'Material de ayuda',
            },
            Faq: {
                'en-us': 'FAQ', 'es-co': 'FAQ',
            },
            pendingMachines: {
                'en-us': 'Machines in progress', 'es-co': 'Máquinas pendientes',
            },
            supportContacts: {
                'en-us': 'Support Contacts', 'es-co': 'Contactos de Soporte',
            },
            desktop: {
                'en-us': 'Home', 'es-co': 'Inicio',
            }
        },
        desktop:
        {
            whitelabelinfo:
            {
                cloud: {
                    'en-us': 'Cloud: ', 'es-co': 'Nube: '
                },
                telephone: {
                    'en-us': 'Telephone: ', 'es-co': 'Teléfono: '
                },
                status: {
                    'en-us': 'Status: ', 'es-co': 'Estado: '
                },
                cost: {
                    'en-us': 'Cost: ', 'es-co': 'Costo:'
                },
                currentlyyouhave: {
                    'en-us': 'Currently you have: ', 'es-co': 'Cuentas con: '
                },
                users: {
                    'en-us': 'Users', 'es-co': 'Usuarios'
                },
                services: {
                    'en-us': 'Service(s)', 'es-co': 'Servicio(s)'
                },
                aditionalservices: {
                    'en-us': 'Aditional Service(s)', 'es-co': 'Servicio(s) Adicional(es)'
                },
            },

            favorites:
            {
                title: {
                    'en-us': 'Favorites', 'es-co': 'Favoritos'
                },
                user: {
                    'en-us': 'User', 'es-co': 'Usuario'
                },
                machine: {
                    'en-us': 'Machine', 'es-co': 'Máquina'
                },
                firewall: {
                    'en-us': 'Firewall', 'es-co': 'Cortafuegos'
                },
            },

            accountsumary:
            {
                title: {
                    'en-us': 'ACCOUNT SUMMARY', 'es-co': 'RESUMEN DE CUENTA'
                },
                acounttodate: {
                    'en-us': 'Account status to date', 'es-co': 'Estado de cuenta'
                },
                currentbalance: {
                    'en-us': 'Current Balance', 'es-co': 'Balance Actual'
                },
                activemachines: {
                    'en-us': 'Active Machines', 'es-co': 'Máquina Activas'
                },
                activemachinesnumber: {
                    'en-us': 'Active Machines', 'es-co': 'Total Activas'
                },
                pendingcancelations: {
                    'en-us': 'Pending', 'es-co': 'Cancelaciones'
                },
                balancetodate: {
                    'en-us': 'Cancelations', 'es-co': 'Pendientes'
                },
                activecustomers: {
                    'en-us': 'Active Customers', 'es-co': 'Clientes Activos'
                },
                activecustnumber: {
                    'en-us': 'Current', 'es-co': 'Total Activos'
                },
            },

            changelog:
            {
                title: {
                    'en-us': 'CHANGE LOG', 'es-co': 'REGISTRO DE CAMBIOS'
                }
            }

        },

        datacenter:
        {
            serviceid: {
                'en-us': 'Service Id: ', 'es-co': 'Id del Servicio: '
            },
            status: {
                'en-us': 'Status: ', 'es-co': 'Estado: '
            },
            edit: {
                'en-us': 'Edit ', 'es-co': 'Editar '
            },
            menu: {
                adminnet: {
                    'en-us': 'Admin Networks', 'es-co': 'Administrar Redes '
                }
            },
            TURN_OFF: {
                'en-us': 'Shutdown', 'es-co': 'Apagar'
            },
            TURN_ON: {
                'en-us': 'Power On', 'es-co': 'Encender'
            },
            LAY_OFF: {
                'en-us': 'Suspend', 'es-co': 'Suspender'
            },
            RESTART: {
                'en-us': 'Restart', 'es-co': 'Reiniciar '
            },
            RESUME: {
                'en-us': 'Restart', 'es-co': 'Reiniciar '
            },
            TURN_OFF_GUEST: {
                'en-us': 'Power On Guest OS', 'es-co': 'Encender Sistema Operativo Invitado'
            },
            LAY_OFF_GUEST: {
                'en-us': 'Suspend Guest OS', 'es-co': 'Suspender Sistema Operativo Invitado'
            },
            RESTART_GUEST: {
                'en-us': 'Restart Guest OS', 'es-co': 'Reiniciar Sistema Operativo Invitado'
            },
        },

        dcSidenav:
        {
            actions: {
                'en-us': 'Actions', 'es-co': 'Acciones'
            },
            cores: {
                'en-us': 'Configured Cores: ', 'es-co': 'CPU'
            },
            ram: {
                'en-us': 'Configured Ram: ', 'es-co': 'Ram (GB)'
            },
            machinestatus: {
                'en-us': 'Machine status: ', 'es-co': 'Estado'
            },
            password: {
                'en-us': 'Password: ', 'es-co': 'Contraseña'
            },
            privateip: {
                'en-us': 'Private Ip: ', 'es-co': 'Ip Privada: '
            },
            rdpaccess: {
                'en-us': 'RDP/SSH access: ', 'es-co': 'Acceso RDP/SSH: '
            },
            macaddress: {
                'en-us': 'Mac Address: ', 'es-co': 'Dirección Mac: '
            },
            viewconfig: {
                'en-us': 'Set up', 'es-co': 'Configurar'
            },
            diskSize: {
                'en-us': 'Disk size', 'es-co': 'Disco'
            },
            datacenter: {
                'en-us': 'Datacenter', 'es-co': 'Centro de datos'
            }
        },

        networkSettings: {
            gettingInfo: {
                'en-us': 'Obtaining information from the net', 'es-co': 'Obteniendo información de red'
            },
            services: {
                'en-us': 'Services', 'es-co': 'Servicios'
            },
            publicips: {
                'en-us': 'Public Ips', 'es-co': 'Ips Públicas'
            },

            sideNavNat:
            {
                protocol: {
                    'en-us': 'Protocol', 'es-co': 'Protocolo'
                },
                description: {
                    'en-us': 'Description', 'es-co': 'Descripción'
                },
                ipSource: {
                    'en-us': 'Ip Source', 'es-co': 'Ip Origen'
                },
                sourcePort: {
                    'en-us': 'Source Port', 'es-co': 'Puerto Origen'
                },
                ipDestination: {
                    'en-us': 'Destination Ip', 'es-co': 'Ip Destino'
                }
            },

            sideNavDHCP:
            {
                serviceId: {
                    'en-us': 'Service Id', 'es-co': 'Id del Servicio'
                },
                defaultGateway: {
                    'en-us': 'Default Gateway', 'es-co': 'Puerta Predeterminada'
                },
                ipAddress: {
                    'en-us': 'Ip Address', 'es-co': 'Driección Ip'
                },
                dnsPrimary: {
                    'en-us': 'Primary DNS', 'es-co': 'DNS Primario'
                },
                dnsSecondary: {
                    'en-us': 'Secondary DNS', 'es-co': 'DNS Secundario'
                },
                netMask: {
                    'en-us': 'Net Mask', 'es-co': 'Mascara de Red'
                },
            },
            sideNavRouting:
            {
                network: {
                    'en-us': 'Network', 'es-co': 'Red'
                },
                nextHop: {
                    'en-us': 'Next Hop', 'es-co': 'Enrutamiento'
                },
                vnic: {
                    'en-us': 'Interface', 'es-co': 'Interfaz'
                },
                description: {
                    'en-us': 'Description', 'es-co': 'Descripción'
                }
            },
            sideNavVPN:
            {
                algorithm: {
                    'en-us': 'Encryption Algorithm', 'es-co': 'Algoritmo de Encripción'
                },
                diffieHellman: {
                    'en-us': 'Team Diffie-Hellman', 'es-co': 'Grupo Diffie-Hellman'
                },
                enablePFS: {
                    'en-us': 'Enable PFS', 'es-co': 'Habilitar PFS'
                },
                status: {
                    'en-us': 'Status', 'es-co': 'Estado'
                },
                localIp: {
                    'en-us': 'Local Ip', 'es-co': 'Ip Local'
                },
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                password: {
                    'en-us': 'Password', 'es-co': 'Contraseña'
                },
                localSubnets: {
                    'en-us': 'Local Subnets', 'es-co': 'Subnets Locales'
                },
                ipDestination: {
                    'en-us': 'Ip Destionation', 'es-co': 'Ip Destino'
                },
            },

            sideNavIPSet:
            {
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                value: {
                    'en-us': 'IP Addresses', 'es-co': 'Direcciones IP'
                }
            },
            sideNavAppProfile:
            {
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                template: {
                    'en-us': 'Type', 'es-co': 'Tipo'
                },
                method: {
                    'en-us': 'Persistence', 'es-co': 'Persistencia'
                },
                cookieName: {
                    'en-us': 'Cookie name', 'es-co': 'Nombre de cookie'
                },
                cookieMode: {
                    'en-us': 'Mode', 'es-co': 'Modo'
                },
                expire: {
                    'en-us': 'Expires (in seconds)', 'es-co': 'Expiración (en segundos)'
                }
            },
            sideNavVirtualServer:
            {
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                applicationProfileId: {
                    'en-us': 'Application Profile', 'es-co': 'Application Profile'
                },
                ipAddress: {
                    'en-us': 'IP Address', 'es-co': 'Dirección IP'
                },
                protocol: {
                    'en-us': 'Protocol', 'es-co': 'Protocolo'
                },
                port: {
                    'en-us': 'Port', 'es-co': 'Puerto'
                },
                defaultPool: {
                    'en-us': 'Default Pool', 'es-co': 'Default Pool'
                },
                connectionLimit: {
                    'en-us': 'Connection Limit', 'es-co': 'Límite de conexión'
                },
                connectionRateLimit: {
                    'en-us': 'Connection Rate Limit', 'es-co': 'Límite de velocidad de conexión'
                }
            },
            sideNavPool:
            {
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                algorithm: {
                    'en-us': 'Algorithm', 'es-co': 'Algoritmo'
                },
                monitorId: {
                    'en-us': 'Monitor', 'es-co': 'Monitor'
                },
                ipAddress: {
                    'en-us': 'IP Address', 'es-co': 'Dirección IP'
                },
                port: {
                    'en-us': 'Port', 'es-co': 'Puerto'
                },
                monitorPort: {
                    'en-us': 'Monitor Port', 'es-co': 'Puerto de monitoreo'
                },
                weight: {
                    'en-us': 'Weight', 'es-co': 'Peso'
                },
                members: {
                    'en-us': 'Members', 'es-co': 'Miembros'
                },
                minConn: {
                    'en-us': 'Min. Connections', 'es-co': 'Conexiones mín.'
                },
                maxConn: {
                    'en-us': 'Max. Connections', 'es-co': 'Conexiones máx.'
                }
            },
            sideNavFW:
            {
                name: {
                    'en-us': 'Name', 'es-co': 'Nombre'
                },
                rule: {
                    'en-us': 'Rule Type', 'es-co': 'Tipo de Regla'
                },
                sourceIp: {
                    'en-us': 'Source Ip', 'es-co': 'Ip Origen'
                },
                status: {
                    'en-us': 'Action', 'es-co': 'Acción'
                },
                ipDestination: {
                    'en-us': 'Ip Destination', 'es-co': 'Ip Destino'
                },
                portConfig: {
                    'en-us': 'Port Configuration', 'es-co': 'Configuración de Puertos'
                },
                portSet: {
                    'en-us': 'Set Ports?', 'es-co': '¿Especificar Puertos?'
                },
                protocol: {
                    'en-us': 'Protocol', 'es-co': 'Protocolo'
                },
                port: {
                    'en-us': 'Port', 'es-co': 'Puerto'
                },
            }

        },

        grid: {
            gridFirstPart: {
                'en-us': 'Showing records of', 'es-co': 'Mostrando registros de'
            },
            gridSecondPart: {
                'en-us': 'Records', 'es-co': 'registros'
            },
        },

        virtualServer: {
            disabled: {
                'en-us': 'Disable', 'es-co': 'INHABILITAR'
            },
            enabled: {
                'en-us': 'Enable', 'es-co': 'HABILITAR'
            },
            delete: {
                'en-us': 'Delete', 'es-co': 'BORRAR'
            },

            sideNavVS: {
                planName: {
                    'en-us': 'Plan Name', 'es-co': 'Nombre del plan'
                },
                planDescription: {
                    'en-us': 'Plan Description', 'es-co': 'Descripción del plan'
                },
                machineConfig: {
                    'en-us': 'Machine Configuration', 'es-co': 'Configuración de la Máquina'
                },
                productType: {
                    'en-us': 'Product Type', 'es-co': 'Tipo de Producto'
                },
                totalCost: {
                    'en-us': 'Total Cost: ', 'es-co': 'Costo inicial: '
                },
                suggestedCost: {
                    'en-us': 'Suggested Cost: ', 'es-co': 'Costo Sugerido: '
                },
                save: {
                    'en-us': 'Save', 'es-co': 'Guardar'
                },
                restore: {
                    'en-us': 'Restore', 'es-co': 'Restaurar'
                }
            }
        },

        whiteLabelConfiguration : {
            logo: {
                uploadFile : {
                    'en-us': 'Upload Logo', 'es-co': 'Subir Logo'
                },
                description : {
                    'en-us': 'Add the image or the logo of your company', 'es-co': 'Agrega la imagen o el logo de tu empresa,'
                },
                sizeImage : {
                    'en-us': 'The recommended size is 500px X 190px', 'es-co': 'El tamaño recomendado es de 500px X 190px'
                },
                accept : {
                    'en-us': 'ACCEPT', 'es-co': 'ACEPTAR'
                },
                cancel: {
                    'en-us': 'CANCEL', 'es-co': 'CANCELAR'
                }
            },
            sslCertificate: {
                uploadFile : {
                    'en-us': 'Upload Certificate', 'es-co': 'Cargar Certificado'
                },
                password: {
                    // tslint:disable-next-line:max-line-length
                    'en-us': 'Please enter a certificate and then validate it using the password provided by your provider', 'es-co': 'Por favor ingresa un certificado y luego validalo usando la contraseña proporcionada por tu proveedor'
                },
                accept : {
                    'en-us': 'ACCEPT', 'es-co': 'ACEPTAR'
                },

                cancel : {
                    'en-us': 'CANCEL', 'es-co': 'CANCELAR'
                }
            }
        },

        helpMaterial: {
            mainInfo: {
                'en-us': 'Upload or review help material', 'es-co': 'Revise o adjunte material de ayuda'
            },
            placeholder: {
                'en-us': 'Start your search...', 'es-co': 'Inicie su busqueda...'
            },
            addHelpMaterial : {
                'en-us': 'Add Material', 'es-co': 'Agregar Material de Ayuda'
            },
            save : {
                'en-us': 'SAVE', 'es-co': 'GUARDAR'
            },
            restore : {
                'en-us': 'RESTORE', 'es-co': 'RESTAURAR'
            },
            recentMaterial : {
                'en-us': 'Recent Material', 'es-co': 'Material Reciente'
            },
            uploadFile : {
                'en-us': 'Upload File', 'es-co': 'Subir Archivo'
            },
            sideNav:
            {
                title: {
                    'en-us': 'Title', 'es-co': 'Titulo'
                },
                description: {
                    'en-us': 'Description', 'es-co': 'Descripción'
                },
                selectTopic: {
                    'en-us': 'Select Topic', 'es-co': 'Selecione Tema'
                },

                topics:
                {
                    whiteLabel: {
                        'en-us': 'WhiteLabel', 'es-co': 'Marca Blanca'
                    },
                    agent: {
                        'en-us': 'Agent', 'es-co': 'Agente'
                    },
                    reseller: {
                        'en-us': 'Reseller', 'es-co': 'Revendedor'
                    },
                    systemUser: {
                        'en-us': 'System User', 'es-co': 'Usuario del sistema'
                    },
                    customer: {
                        'en-us': 'Customer', 'es-co': 'Cliente'
                    },
                }

            }

        },

        calculator: {

            serverName : {
                'en-us': 'Server Name', 'es-co': 'Nombre de la maquina'
            },
            hypervisor : {
                'en-us': 'Hypervisor', 'es-co': 'Hypervisor'
            },
            serverProfile : {
                'en-us' : 'Server Profile' , 'es-co': 'Perfil del servidor'
            },
            detail: {
                'en-us': 'Detail', 'es-co': 'Detalle'
            },
            quantity : {
                'en-us': 'Quantity', 'es-co': 'Cantidad'
            },
            propertity : {
                'en-us': 'propertity', 'es-co': 'Propiedad'
            },
            gb : {
                'en-us': '# GB', 'es-co': '# GB'
            },
            speed : {
                'en-us': 'Speed', 'es-co': 'Velocidad'
            },

            hardDisk : {
                'en-us': 'Hard Disk', 'es-co': 'Disco Duro'
            },

            MRC : {
                'en-us': 'MRC', 'es-co': 'MRC'
            },

            selectNetwork : {
                'en-us': 'Select', 'es-co': 'Seleccione'
            },

            AmountOfIps : {
                'en-us': 'Amount Of IPs', 'es-co': 'Cantidad de IPs'
            },
            serverTitle: {
                'en-us': 'Server Name', 'es-co': 'Nombre del servidor'
            },

            hardDiskconfig : {
                'en-us': 'hard Disk Configuration', 'es-co': ' Configuración discos duros'
            },

            stepperNext : {
                'en-us': 'Next', 'es-co': 'Siguiente'
            },

            stepperPrevious : {
                'en-us': 'Back', 'es-co': 'Anterior'
            },

            connectivity : {
                'en-us': 'Connectivity', 'es-co': 'Conectividad'
            },
            ROI : {
                'en-us': 'New ROI', 'es-co': 'Nuevo ROI'
            },

            financialAnalitycs : {
                'en-us': 'Financial Analitycs', 'es-co': 'Análisis Financiero'
            },

            margen: {
                'en-us': 'Margen', 'es-co': 'Margen'
            },

            addDisk : {
                'en-us': 'Add Disk', 'es-co': 'Agregar Disco'
            },

            infrastructure: {
                title: {
                    'en-us': 'Infrastructure', 'es-co': 'Infraestructura'
                },

                Ram : {
                    'en-us': 'Ram', 'es-co': 'Memoria RAM'
                },
                Network : {
                    'en-us': 'Network', 'es-co': 'Internet'
                },
                Availability : {
                    'en-us': 'Availability', 'es-co': 'Disponibilidad'
                },

                table: {
                    propertity : {
                        label : {
                            'en-us': 'Property', 'es-co': 'Propiedad'
                        },
                        prosecution: {
                            'en-us': 'prosecution', 'es-co': 'Procesamiento',
                        },
                        RAM: {
                            'en-us': 'RAM', 'es-co': 'Memoria RAM',
                        },
                        networks : {
                            'en-us': 'Networks', 'es-co': 'Redes',
                        },
                        availability: {
                            'en-us': 'Availability', 'es-co': 'Disponibilidad',
                        }
                    },
                    detail : {
                        label : {
                            'en-us': 'Detail', 'es-co': 'Detalle'
                        },
                        cores : {
                            'en-us': '# Cores', 'es-co': '# Cores'
                        },
                        gb : {
                            'en-us': '# GB', 'es-co': '# GB'
                        },
                        internet : {
                            'en-us': 'Internet', 'es-co': 'Internet'
                        },
                        level : {
                            'en-us': 'Level(%)', 'es-co': 'Nivel(%)'
                        },
                        selectCore : {
                            'en-us': 'Select Cores', 'es-co': 'Seleccione Cores'
                        },
                        selectRAM : {
                            'en-us': 'Select RAM', 'es-co': 'Seleccione RAM'
                        },
                        selectNetwork : {
                            'en-us': 'Select', 'es-co': 'Seleccione'
                        },

                        selectAvailability : {
                            'en-us': 'Select Availability', 'es-co': 'Seleccione Disponibilidad'
                        },
                        item:  {
                            'en-us': 'Item', 'es-co': 'Item'
                        },
                        MRC : {
                            MRC: 'Item', 'es-co': 'MRC'
                        }
                    }
                }
            },

            software : {
                title: {
                    'en-us': 'Software licensing', 'es-co': 'Licenciamiento de Software'
                },

                item : {
                    'en-us': 'Item', 'es-co': 'Item'
                },

                selectOS: {
                    'en-us': 'Select OS', 'es-co': 'Seleccione sistema operativo'
                },

                selectDB: {
                    'en-us': 'Select database', 'es-co': 'Seleccione base de datos'
                },
                selectApplications: {
                    'en-us': 'Select Applications', 'es-co': 'Seleccione aplicaciones'
                },
                selectUsers: {
                    'en-us': 'Select Applications', 'es-co': 'Seleccione aplicaciones'
                },
                Os : {
                    'en-us': 'OS', 'es-co': 'Sistema Operativo'
                },
                database : {
                    'en-us': 'Database', 'es-co': 'Base de datos'
                },

                MachineLicenses : {
                    'en-us': 'MachineLicenses', 'es-co': 'Licencias'
                },
                table: {
                    item1: {
                        OS : {
                            'en-us': 'OS', 'es-co': 'Sistema Operativo'
                        },
                        database : {
                            'en-us': 'Database', 'es-co': 'Base de datos'
                        },
                        applications : {
                            'en-us': 'Applications', 'es-co': 'Aplicaciones'
                        }
                    },

                    itemTwo: {
                        version : {
                            'en-us': 'Version', 'es-co': 'Versión'
                        },
                        Application : {
                            'en-us': 'Application', 'es-co': 'Aplicación'
                        },
                        Users : {
                            'en-us': 'Users', 'es-co': 'Usuarios'
                        },

                    },
                    detail : {
                        label : {
                            'en-us': 'Detail', 'es-co': 'Detalle'
                        }
                    },
                    MrcSoftware: {
                        'en-us': 'MRC Software', 'es-co': 'MRC Software'
                    },
                    MRC: {
                        'en-us': 'MRC', 'es-co': 'MRC'
                    }
                }
            },

            licensing : {
                title: {
                    'en-us': 'Licenses', 'es-co': 'Licencias'
                },
                quantity : {
                    'en-us': 'Quantity', 'es-co': 'Cantidad'
                },
                select : {
                    'en-us': 'Select', 'es-co': 'Seleccione Cantidad'
                },
                selectLicense : {
                    'en-us': 'Select', 'es-co': 'Seleccione Aplicación'
                }
            },
            disk : {
                title : {
                    'en-us': 'Hard Disk', 'es-co': 'Discos Duros'
                },
                gb : {
                    'en-us': 'GB', 'es-co': 'GB'
                },
                selectDriveSpeed  : {
                    'en-us': 'Select', 'es-co': 'Seleccione velocidad'
                },
                driveSpeed : {
                    'en-us': 'Drive\'s speed', 'es-co': 'Velocidad de Disco'
                },
                selectBackup : {
                    'en-us': 'Select', 'es-co': 'Seleccione Almacenamiento'
                },
                backup : {
                    'en-us': 'Backup and retention', 'es-co': 'Backup y retención'
                },

                database: {
                    'en-us': 'Database', 'es-co': 'Base de datos'
                },
                MRC : {
                    'en-us': 'MRC additional disks', 'es-co': 'MRC discos adicionales'
                },

                Month : {
                    'en-us': 'Month', 'es-co': 'Mes'
                }
            },

            priceSumary : {
                title: {
                    'en-us': 'Price Sumary', 'es-co': 'Resumen cotización servidor Actual'
                }
            },

            currentSummary : {
                title : {
                    'en-us': 'Current server summary', 'es-co': 'Resumen servidor actual'
                },
                totalVirtualServer : {
                    'en-us': 'Virtual Server', 'es-co': 'Servidor virtual'
                },
                totalSoftware : {
                    'en-us': 'Software', 'es-co': 'Software'
                },
                aditionalservices : {
                    'en-us': 'Additional services', 'es-co': 'Servicio adicionales'
                },
                total  : {
                    'en-us': 'Total :', 'es-co' : 'Total'
                },

                AddToQuote : {
                    'en-us': 'Add to Quote', 'es-co': 'Agregar a la solución'
                },

                SuggestedRetail : {
                    'en-us': 'Suggested retail price', 'es-co': 'Precio público sugerido'
                },

                totalSurvey : {
                    totalSolution : {
                        'en-us': 'Total Solution', 'es-co': 'Total Solución'
                    },
                    SuggestedPrice : {
                        'en-us': 'Suggested price', 'es-co': 'Precio Sugerido'
                    }
                },
                currentTotalServer : {
                    'en-us': 'Current Server Total', 'es-co': 'Total servidor actual'
                }
            },
        },
        buttons : {
            cancel : {
                'en-us': 'Cancel', 'es-co': 'Cancelar'
            },

            accept : {
                'en-us': 'Accept', 'es-co': 'Aceptar'
            }
        },

        role: {
            title: {
                'en-us': 'Enter the profile you want to edit', 'es-co': 'Ingrese el perfil que desea editar'
            },
            placeholder: {
                'en-us': 'Enter the profile you want to edit', 'es-co': 'Ingrese el perfil que desea editar'
            },
            components: {
                'data-center': { 'es-co': 'Centro de Datos' },
                'use-statistic': { 'es-co': 'Estadísticas de Uso' },
                'registry-load': { 'es-co': 'Log de eventos' },
                'account-setup': { 'es-co': 'Configuración de Cuenta' },
                'user-accounts': { 'es-co': 'Cuentas de Usuario' },
                'HelpMaterial': { 'es-co': 'Material de Ayuda' },
                'active-directory': { 'es-co': 'Directorio Activo' },
                'whitelabel-configuration': { 'es-co': 'Configuracion de Sitio' },
                'virtual-server': { 'es-co': 'Configuracion de Planes' },
                'billing-load': { 'es-co': 'Facturacion' },
                'documents-load': { 'es-co': 'Documentos' },
                'role-configuration': { 'es-co': 'Configuracion de roles' },
                'calculator-load': { 'es-co': 'Cotizador' },
                'email-setup': { 'es-co': 'Configuración de Correos' },
                'common': { 'es-co': 'Common' },
                'CancelledServices': { 'es-co': 'Servicios Cancelados' },
                'faq': { 'es-co': 'FAQ' },
                'prospects': { 'es-co': 'Prospectos' },
                'pendingMachine': { 'es-co': 'Maquinas en Aprovisionamiento' },
                'AddCustomer': { 'es-co': 'Agregar Cliente' },
                'supportContacts': {'es-co':'Contactos de soporte'},
            },
        }
    }
