import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomFormValidator } from '../../CustomValidator';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './modal.component.html',
    styleUrls:['../modals.scss']
})
export class sendMailDialog {
    loading:boolean = false;
    SendMailForm : FormGroup;
    user:string;

    constructor(public dialogRef: MatDialogRef<sendMailDialog>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder : FormBuilder) {
        this.SendMailForm = this.formBuilder.group({
            'userName' : [{value:'',disabled: this.loading},[Validators.required]],
            'emailInput' : [{value:'',disabled: this.loading},[Validators.required,Validators.minLength(10),CustomFormValidator.EmailValidator]],
            'Capex' :  []
        });
    }
    sendMail(){
        this.SendMailForm.disable();
        this.loading = true;
        this.dialogRef.close(this.data);
    }

    cancelClick(){
        if(!this.data.Cancel)
            this.dialogRef.close();
        else
            this.data.Cancel();
    }
}
