import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WlService } from '../../services/wlservice.service';
import { UserInfoDTO } from '../../models/dtos.component';
import { BroadcastEmitter } from '../../services/BroadcastEmitter';
import { Constants } from '../../models/constants.component';
import { Globalization } from '../../globalization/globalization';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {CustomFormValidator} from '../../tools/CustomValidator';

@Component({
    selector: 'app-accountConfiguration',
    templateUrl: './accountConfiguration.component.html',
    styleUrls: ['./accountConfiguration.component.scss']
})
export class AccountConfigurationComponent implements OnInit {
    user: UserInfoDTO = new UserInfoDTO();
    constants : Constants = new Constants();
    @ViewChild("sidenav") sidenav: MatSidenav;
    public loading: boolean = true;
    public OldPassword: string = "";
    public NewPassword: string = "";
    public RepeatPassword: string = "";
    initials: string = "";
    accountForm: FormGroup;
    passwordForm: FormGroup;
    constructor(public dialog: MatDialog, private service: WlService, public snackbar: MatSnackBar, public navbarBroadcast: BroadcastEmitter, private formBuilder: FormBuilder) {
        this.accountForm = this.formBuilder.group({
            'name' : [{value:'',disabled:this.loading},[Validators.required]],
            'username' : ['',[Validators.required]],
            'email' : ['',[Validators.required,Validators.minLength(10),CustomFormValidator.EmailValidator]],
            'language' : ['',[Validators.required]],
        });
        this.passwordForm = this.formBuilder.group({
            'currentPassword' : ['',[Validators.required]],
            'newPassword' : ['', [Validators.required]],
            'repeatPassword' : ['',[Validators.required]],
        });
    }

    ngOnInit() {
        this.navbarBroadcast.SendMessage({ text: "Configuración de cuenta" });
        this.getCustomerInfo();
    }

    getCustomerInfo() {
        this.service.GetUserInformation().subscribe(
            (response:any) => {
                this.loading = false;
                this.user = response;
                this.initials = this.user.Name.split(' ').map(function (e, i) { if (i < 2) { return e.substring(0, 1) } else { return '' } }).join('')
            },
            error => {
                this.loading = false;
            }
        )
    }

    SaveUserInfo() {
        this.accountForm.disable();
        this.passwordForm.disable();
        this.loading = true;
        this.service.SaveUserInfo(this.user).subscribe(
            (response:any) => {
                this.accountForm.enable();
                this.passwordForm.enable();
                this.loading = false;
                this.user = response;
                this.sidenav.close();
                this.snackbar.open('Usuario modificado satisfactoriamente', null, {
                    duration: 2000,
                });
            },
            error => {
                this.accountForm.enable();
                this.passwordForm.enable();
                this.loading = false;
                this.snackbar.open('Errro modificando usuario' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }

    ChangePassword() {
        if (this.NewPassword != this.RepeatPassword)
            this.snackbar.open('Las contraseñas no coinciden', null, {
                duration: 2000,
            });
        else if (this.OldPassword == this.NewPassword)
            this.snackbar.open('Las contraseña nueva no puede ser la misma', null, {
                duration: 2000,
            });
        else {
            this.accountForm.disable();
            this.passwordForm.disable();
            this.loading = true;
            this.service.ChangePassword(this.OldPassword, this.NewPassword).subscribe(
                response => {
                    this.accountForm.enable();
                    this.passwordForm.enable();
                    this.loading = false;
                    this.sidenav.close();
                    this.snackbar.open('Contraseña cambiada satisfactoriamente', null, {
                        duration: 3000,
                    });
                },
                error => {

                    this.accountForm.enable();
                    this.passwordForm.enable();
                    this.loading = false;
                    this.snackbar.open('Error cambiando contraseña: ' + error.error, null, {
                        duration: 4000,
                    });
                }
            )
        }
    }
}


@Component({
    selector: 'password-edit-dialog',
    templateUrl: 'password-edit.html',
    styleUrls: ['../../tools/modals/modals.scss']
})
export class PasswordEditDialog {
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PasswordEditDialog>, public snackbar: MatSnackBar, private service: WlService) { }
    public OldPassword: string = "";
    public NewPassword: string = "";
    public RepeatPassword: string = "";
    public loading: boolean = false;
    ChangePassword() {
        if (this.NewPassword != this.RepeatPassword)
            this.snackbar.open('Las contraseñas no coinciden', null, {
                duration: 2000,
            });
        else if (this.OldPassword == this.NewPassword)
            this.snackbar.open('Las contraseña nueva no puede ser la misma', null, {
                duration: 2000,
            });
        else {
            this.loading = true;
            this.service.ChangePassword(this.OldPassword, this.NewPassword).subscribe(
                response => {
                    this.loading = false;
                    this.dialogRef.close();
                    this.snackbar.open('Contraseña cambiada satisfactoriamente', null, {
                        duration: 3000,
                    });
                },
                error => {
                    console.log("error!! 1", error);
                    this.loading = false;
                    this.snackbar.open('Error cambiando contraseña: ' + error.error, null, {
                        duration: 4000,
                    });
                }
            )
        }
    }
}
