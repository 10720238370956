import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-server-protection',
  templateUrl: './server-protection.component.html',
  styleUrls: ['./server-protection.component.scss']
})
export class ServerProtectionComponent {
    loading:boolean = false;
    optionSelected : string;
    options: string[] = ['Cliente tiene solución AV', 'Cliente acepta riesgo infección', 'Solución solicitada no disponible'];
    constructor(public dialogRef: MatDialogRef<ServerProtectionComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {}
    okClick(){
      if(this.optionSelected!=null){
        this.data.Ok()
        this.dialogRef.close();
        ;
      }

    }
    cancelClick(){
      this.data.Cancel();
      this.dialogRef.close();
    }
}

