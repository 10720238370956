
<mat-toolbar color="primary">
    Crear nueva regla
    <div class="close-modal full-width">
      <button mat-icon-button (click)="Close()" class="pull-right">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

    <div class="modal-form-container" >
      <div class="container-fluid rule-container mt-5">
       
        <form class="add-row" #addRule="ngForm" (ngSubmit)="AddRule()">
          <mat-dialog-content class="mat-typography">
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;" >Edit Advanced Outbound NAT Entry</legend>
              <div class="row">
                <div class="col-xl-6 col-lg-6 select-container"> 
                  <mat-checkbox class="example-margin" [(ngModel)]="disabled"></mat-checkbox>
                  <label class="example-margin">Disable this rule:</label>
                </div>
                <div class="col-xl-6 col-lg-6 select-container"> 
                  <mat-checkbox class="example-margin" [(ngModel)]="disabled">Do not NAT</mat-checkbox>  
                  <label class="example-margin">Enabling this option will disable NAT for traffic matching this rule and stop processing Outbound NAT rules</label>
                </div>
              </div>
    
              <div class="row">
                <div class="col-xl-6 col-lg-6 select-container"> 
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <mat-select name="Type" required class="full-width" [(ngModel)]="newRule.interface" placeholder="Interface">
                      <mat-option value="wan">
                        WAN
                      </mat-option>
                      <mat-option value="lan">
                        LAN
                      </mat-option>
                      <mat-option value="mgmt">
                        MGMT
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 select-container"> 
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <mat-select name="Type" required class="full-width" [(ngModel)]="newRule.interface" placeholder="Address Family">
                        <mat-option value="IPV4">
                          IPv4
                        </mat-option>
                        <mat-option value="IPV6">
                          IPv6
                        </mat-option>
                        <mat-option value="IPV4&IPV6">
                          IPv4 + IPv6
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 select-container"> 
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <mat-select name="Type" required class="full-width" [(ngModel)]="newRule.interface" placeholder="Protocol">
                      <mat-option value="any">
                        any
                      </mat-option>
                      <mat-option value="tcp">
                        TCP
                      </mat-option>
                      <mat-option value="mgmt">
                        MGMT
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              
              </div>
  
              <div class="row">
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="Type" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Source Type">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="SourceNetwork" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Source network">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="SourceNetwork" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Source Port or Range">
                    </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="Type" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Destination Type">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="SourceNetwork" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Destination network">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="SourceNetwork" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Destination Port or Range">
                    </mat-form-field>
                </div>
              </div>
            </fieldset>
    
            <fieldset class="properties-border">
                <legend class="properties-border" style="width: auto; font-size: 1rem;">Translation</legend>
                <div class="row">
                    <div class="col-xl-5 col-lg-5">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width" [(ngModel)]="newRule.interface" placeholder="Address">
                              <mat-option value="any">
                                Interface Address
                              </mat-option>    
                            </mat-select>
                        </mat-form-field>
                      <label>Connections matching this rule will be mapped to the specified Address.
                        The Address can be an Interface, a Host-type Alias, or a Virtual IP address.</label>  
                    </div>
    
                    <div class="col-xl-3 col-lg-3">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <input name="SourceNetwork" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Port or Range">
                        </mat-form-field>
                        <label>Enter the external source Port or Range used for remapping the original source port on connections matching the rule.
                        </label>  
                    </div>
                    <div class="col-xl-2 col-lg-2">
                      <mat-checkbox class="example-margin" [(ngModel)]="disabled"></mat-checkbox>
                      <label class="example-margin">Static Port</label>
                  </div>
                </div>
            </fieldset>
          
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">MISC</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="Des" required class="full-width" [(ngModel)]="newRule.interface" matInput placeholder="Description">
                    </mat-form-field>
                  </div> 
                </div>
            </fieldset>
          </mat-dialog-content>  
      
          <div class="action-buttons">
            <button mat-button color="primary"> <span class="material-icons">save</span> Guardar</button>
          </div>
        </form>

      </div>
    </div>
 
 