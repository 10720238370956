<mat-sidenav-container>
  <mat-sidenav #sidenav position="end" class="detail-sidenav">
    <machineOperations [machine]="selectedMachine"></machineOperations>
  </mat-sidenav>

  <mat-progress-bar
    *ngIf="loading"
    mode="indeterminate"
    class="loading-sidenav"
  ></mat-progress-bar>
  <div class="container-fluid body-container">
    <div class="">
      <div class="empty-cloud-container" *ngIf="loading">
        <mat-spinner color="accent" class="cloud-spinner"></mat-spinner>
        <span class="cloud-text">{{ "DATACENTER.LOADING" | translate }}</span>
      </div>
    </div>
    <!--Carga las VM's si el cliente no tiene familia-->

    <div
      class="not-machine-error"
      *ngIf="machines && machines.length === 0 && !loading"
    >
      <div *ngIf="machinesChild?.length === 0 && loading === false">
        <span class="not-machine-text"
          >No cuenta con máquinas registradas en esta nube
        </span>
      </div>
    </div>
    <div *ngIf="machines && machines.length !== 0 && !loading">
      <mat-tab-group
        class="mat-tab-group"
        (selectedTabChange)="onChangeTypeMachine($event.index)"
      >
        <ng-container *ngFor="let machine of machines">
          <mat-tab class="tab-content" *ngIf="validShow(machine)">
            <ng-template mat-tab-label *ngIf="machine.category == 'CS'">
              {{ "DATACENTER." + machine.category | translate }}
            </ng-template>
            <ng-template mat-tab-label *ngIf="machine.category == 'RP'">
              <span (click)="selectedPool = null">
                {{ "DATACENTER." + machine.category | translate }}
              </span>
            </ng-template>
            <div *ngIf="machine.category == 'CS'">
              <div
                *ngIf="!machine.machinesList || machine.machinesList == null"
              >
                No cuenta con máquinas
              </div>
              <mat-table
                [dataSource]="machine.machinesList"
                *ngIf="!machine.machinesList || machine.machinesList != null"
              >
                <ng-container matColumnDef="Estado">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      {{ "DATACENTER.STATUS" | translate }}
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <mat-icon
                        style="cursor: help"
                        [ngClass]="GetColorState(machine.State)"
                        matTooltip="{{
                          'STATUS_MACHINES.' + machine.State | translate
                        }}"
                        matTooltipPosition="right"
                      >
                        fiber_manual_record
                      </mat-icon>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SID">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">SID</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.ServiceID }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SistemaOperativo">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      {{ "DATACENTER.VMNAME" | translate }}
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <span matTooltip="{{ GetAlias(machine) }}">{{
                        GetAlias(machine)
                      }}</span>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="RAM">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">RAM</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.Ram.Value }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Cores">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">Cores</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.Cores.Value }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="HDD">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">HDD</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.MachineStorage[0].size }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Options">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      <mat-icon>apps</mat-icon>
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <div
                        class=""
                        *ngIf="
                          machine.State != 'PENDING' && machine.State != 'BUSY'
                        "
                      >
                        <button
                          [matMenuTriggerFor]="menu"
                          class="mat-icon-button"
                          mat-icon-button
                        >
                          <div class="full-width ellipsis-on-row">
                            <mat-icon>more_horiz</mat-icon>
                          </div>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [matMenuTriggerFor]="actions">
                            Acciones
                          </button>
                          <mat-menu #actions="matMenu">
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State == 'POWER_ON' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="PowerOffVm(machine)"
                            >
                              <mat-icon>power_settings_new</mat-icon>
                              <span
                                >{{ "DATACENTER.TURN_OFF" | translate }}
                              </span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State == 'POWER_OFF' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="PowerOnVM(machine)"
                            >
                              <mat-icon>power_settings_new</mat-icon>
                              <span>{{
                                "DATACENTER.TURN_ON" | translate
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State != 'SUSPEND' &&
                                machine.STATE != 'POWER_OFF' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="SuspendVM(machine)"
                            >
                              <mat-icon>pause</mat-icon>
                              <span>{{
                                "DATACENTER.LAY_OFF" | translate
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="machine.State == 'SUSPEND'"
                              (click)="ResumeVm(machine)"
                            >
                              <mat-icon>play_arrow</mat-icon>
                              <span>{{ "DATACENTER.RESUME" | translate }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="machine.State == 'POWER_ON'"
                              (click)="RestartVm(machine)"
                            >
                              <mat-icon>repeat</mat-icon>
                              <span>{{
                                "DATACENTER.RESTART" | translate
                              }}</span>
                            </button>
                            <mat-divider></mat-divider>
                          </mat-menu>
                          <button
                            mat-menu-item
                            (click)="ShowMachineConsole(machine)"
                            [disabled]="machine.State != 'POWER_ON'"
                          >
                            <span>{{
                              "DATACENTER.SHOWCONSOLE" | translate
                            }}</span>
                          </button>
                          <button
                            mat-menu-item
                            (click)="ShowMachineActions(machine)"
                          >
                            <span>{{
                              "DATACENTER.EDITCONFIG" | translate
                            }}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let machine; columns: displayedColumns"
                ></mat-row>
              </mat-table>
            </div>
            <div *ngIf="machine.category == 'RP'">
              <div
                *ngIf="!machinesResourcePool || machinesResourcePool == null"
              >
                No cuenta con máquinas
              </div>
              <div
                *ngIf="inPool && !selectedPool"
                class="row mx-0 justify-con tent-md-center my-5"
                style="gap: 2rem;
                display: flex;
                justify-content: center;"
              >
                <mat-card
                  class="alternate-theme col-lg-3 col-md-3 mat-card-style"
                  *ngFor="let rpMachine of machinesResourcePool | keyvalue"
                  (click)="selectPool(rpMachine.key)"
                >
                  <mat-card-title
                    class="card-title-component mat-card-title mat-card-title"
                  >
                    SID Pool: {{ rpMachine.key }}
                  </mat-card-title>
                  <mat-card-subtitle
                    class="mat-card-subtitle"
                    style="text-align: center"
                  >
                    VMS: ({{ machinesResourcePool[rpMachine.key].length }})
                  </mat-card-subtitle>
                  <mat-card-content class="d-flex justify-content-center">
                    <section class="grafico-barras" *ngIf="chartData">
                      <div
                        class="summary-meters-block"
                        *ngIf="poolChardKeys[rpMachine.key]"
                      >
                        <ul class="summary-items-list">
                          <li class="resource-meter">
                            <span class="title">CPU</span>
                            <span class="resource-meter-top-right-info">
                              Libre:
                              {{
                                poolChardKeys[rpMachine.key].CPU[0].series[1]
                                  .value
                              }}
                              Cores
                            </span>
                            <div
                              class="progress"
                              matTooltip="CPU Utilizada : {{
                                poolChardKeys[rpMachine.key].CPU[0].series[2]
                                  .value | number : '1.2-2'
                              }} %"
                            >
                              <mat-progress-bar
                                class="example-margin"
                                [color]="
                                  poolChardKeys[rpMachine.key].CPU[0].series[2]
                                    .value < 100
                                    ? color
                                    : color2
                                "
                                [mode]="mode"
                                [value]="
                                  poolChardKeys[rpMachine.key].CPU[0].series[2]
                                    .value
                                "
                                [bufferValue]="bufferValue"
                              >
                              </mat-progress-bar>
                            </div>
                            <span class="resource-meter-bottom-left-info">
                              Utilizado:
                              {{
                                poolChardKeys[rpMachine.key].CPU[0].series[0]
                                  .value
                              }}
                              Cores
                            </span>
                            <span class="resource-meter-bottom-right-info">
                              Capacidad:
                              {{
                                poolChardKeys[rpMachine.key].CPU[0].series[0]
                                  .value +
                                  poolChardKeys[rpMachine.key].CPU[0].series[1]
                                    .value
                              }}
                              Cores
                            </span>
                          </li>
                          <li class="resource-meter">
                            <span class="title">MEMORIA</span>
                            <span class="resource-meter-top-right-info">
                              Libre:
                              {{
                                poolChardKeys[rpMachine.key].RAM[0].series[1]
                                  .value
                              }}
                              GB
                            </span>
                            <div
                              class="progress"
                              matTooltip="Memoria utilizada : {{
                                poolChardKeys[rpMachine.key].RAM[0].series[2]
                                  .value | number : '1.2-2'
                              }} %"
                            >
                              <mat-progress-bar
                                class="example-margin"
                                [color]="
                                  poolChardKeys[rpMachine.key].RAM[0].series[2]
                                    .value < 100
                                    ? color
                                    : color2
                                "
                                [mode]="mode"
                                [value]="
                                  poolChardKeys[rpMachine.key].RAM[0].series[2]
                                    .value
                                "
                                [bufferValue]="bufferValue"
                              >
                              </mat-progress-bar>
                            </div>
                            <span class="resource-meter-bottom-left-info">
                              Utilizado:
                              {{
                                poolChardKeys[rpMachine.key].RAM[0].series[0]
                                  .value
                              }}
                              GB
                            </span>
                            <span class="resource-meter-bottom-right-info">
                              Capacidad:
                              {{
                                poolChardKeys[rpMachine.key].RAM[0].series[0]
                                  .value +
                                  poolChardKeys[rpMachine.key].RAM[0].series[1]
                                    .value
                              }}
                              GB
                            </span>
                          </li>
                          <li class="resource-meter">
                            <span class="title">ALMACENAMIENTO</span>s
                            <span class="resource-meter-top-right-info">
                              Libre:
                              {{
                                poolChardKeys[rpMachine.key].HD[0].series[1]
                                  .value
                              }}
                              GB
                            </span>
                            <div
                              class="progress"
                              matTooltip="Almancenamiento utilizado : {{
                                poolChardKeys[rpMachine.key].HD[0].series[2]
                                  .value | number : '1.2-2'
                              }} %"
                            >
                              <mat-progress-bar
                                class="example-margin"
                                [color]="
                                  poolChardKeys[rpMachine.key].HD[0].series[2]
                                    .value < 100
                                    ? color
                                    : color2
                                "
                                [mode]="mode"
                                [value]="
                                  poolChardKeys[rpMachine.key].HD[0].series[2]
                                    .value
                                "
                                [bufferValue]="bufferValue"
                              >
                              </mat-progress-bar>
                            </div>
                            <span class="resource-meter-bottom-left-info">
                              Utilizado:
                              {{
                                poolChardKeys[rpMachine.key].HD[0].series[0]
                                  .value
                              }}
                              GB
                            </span>
                            <span class="resource-meter-bottom-right-info">
                              Capacidad:
                              {{
                                poolChardKeys[rpMachine.key].HD[0].series[0]
                                  .value +
                                  poolChardKeys[rpMachine.key].HD[0].series[1]
                                    .value
                              }}
                              GB
                            </span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </mat-card-content>
                </mat-card>
              </div>
              <div
                class="container-fluid machine-container p-0"
                *ngIf="!inPool || selectedPool"
              >
                <button
                  class="btn btn-outline-secondary rounded py-1 mt-3"
                  style="font-size: 13px"
                  (click)="selectedPool = null"
                >
                  Volver
                </button>
                <div
                  class="pid-style-text text-center full-width"
                  floatLabel="never"
                >
                  SID Pool: <strong>{{ selectedPool }}</strong>
                </div>
                <div class="container-fluid machine-container p-0">
                  <mat-table
                    [dataSource]="this.machinesResourcePool[selectedPool]"
                  >
                    <ng-container matColumnDef="Estado">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">
                          {{ "DATACENTER.STATUS" | translate }}
                        </div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width ellipsis-on-row">
                          <mat-icon
                            style="cursor: help"
                            [ngClass]="GetColorState(rpItem.State)"
                            matTooltip="{{
                              'STATUS_MACHINES.' + rpItem.State | translate
                            }}"
                            matTooltipPosition="right"
                          >
                            fiber_manual_record
                          </mat-icon>
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="SID">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">SID</div>
                      </mat-header-cell>
                      <mat-cell
                        *matCellDef="let rpItem"
                        class="row detail-row"
                        class="header-align-right"
                      >
                        <div class="full-width">{{ rpItem.ServiceID }}</div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="SistemaOperativo">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">
                          {{ "DATACENTER.VMNAME" | translate }}
                        </div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width">
                          <span matTooltip="{{ GetAlias(rpItem) }}">{{
                            GetAlias(rpItem)
                          }}</span>
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="RAM">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">RAM</div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width ellipsis-on-row">
                          {{ rpItem.Configuration.Ram.Value }}
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Cores">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">Cores</div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width ellipsis-on-row">
                          {{ rpItem.Configuration.Cores.Value }}
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="HDD">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">HDD</div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width ellipsis-on-row">
                          {{ rpItem.Configuration.MachineStorage[0].size }}
                        </div>
                      </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Options">
                      <mat-header-cell
                        *matHeaderCellDef
                        class="row header-table"
                      >
                        <div class="full-width ellipsis-on-row">
                          <mat-icon>apps</mat-icon>
                        </div>
                      </mat-header-cell>
                      <mat-cell *matCellDef="let rpItem" class="row detail-row">
                        <div class="full-width ellipsis-on-row">
                          <div
                            class=""
                            *ngIf="
                              rpItem.State != 'PENDING' &&
                              rpItem.State != 'BUSY'
                            "
                          >
                            <button
                              [matMenuTriggerFor]="menu"
                              class="mat-icon-button"
                              mat-icon-button
                            >
                              <div class="full-width ellipsis-on-row">
                                <mat-icon>more_horiz</mat-icon>
                              </div>
                            </button>
                            <mat-menu #menu="matMenu">
                              <button
                                mat-menu-item
                                [matMenuTriggerFor]="actions"
                              >
                                Acciones
                              </button>
                              <mat-menu #actions="matMenu">
                                <button
                                  mat-menu-item
                                  *ngIf="
                                    rpItem.State == 'POWER_ON' &&
                                    rpItem.ProvisioningState != 12
                                  "
                                  (click)="PowerOffVm(rpItem)"
                                >
                                  <mat-icon>power_settings_new</mat-icon>
                                  <span
                                    >{{ "DATACENTER.TURN_OFF" | translate }}
                                  </span>
                                </button>
                                <button
                                  mat-menu-item
                                  *ngIf="
                                    rpItem.State == 'POWER_OFF' &&
                                    rpItem.ProvisioningState != 12
                                  "
                                  (click)="PowerOnVM(rpItem)"
                                >
                                  <mat-icon>power_settings_new</mat-icon>
                                  <span>{{
                                    "DATACENTER.TURN_ON" | translate
                                  }}</span>
                                </button>
                                <button
                                  mat-menu-item
                                  *ngIf="
                                    rpItem.State != 'SUSPEND' &&
                                    rpItem.STATE != 'POWER_OFF' &&
                                    rpItem.ProvisioningState != 12
                                  "
                                  (click)="SuspendVM(rpItem)"
                                >
                                  <mat-icon>pause</mat-icon>
                                  <span>{{
                                    "DATACENTER.LAY_OFF" | translate
                                  }}</span>
                                </button>
                                <button
                                  mat-menu-item
                                  *ngIf="rpItem.State == 'SUSPEND'"
                                  (click)="ResumeVm(rpItem)"
                                >
                                  <mat-icon>play_arrow</mat-icon>
                                  <span>{{
                                    "DATACENTER.RESUME" | translate
                                  }}</span>
                                </button>
                                <button
                                  mat-menu-item
                                  *ngIf="rpItem.State == 'POWER_ON'"
                                  (click)="RestartVm(rpItem)"
                                >
                                  <mat-icon>repeat</mat-icon>
                                  <span>{{
                                    "DATACENTER.RESTART" | translate
                                  }}</span>
                                </button>
                                <mat-divider></mat-divider>
                              </mat-menu>
                              <button
                                mat-menu-item
                                (click)="ShowMachineConsole(rpItem)"
                                [disabled]="rpItem.State != 'POWER_ON'"
                              >
                                <span>{{
                                  "DATACENTER.SHOWCONSOLE" | translate
                                }}</span>
                              </button>
                              <button
                                mat-menu-item
                                (click)="ShowMachineActions(rpItem)"
                              >
                                <span>{{
                                  "DATACENTER.EDITCONFIG" | translate
                                }}</span>
                              </button>
                            </mat-menu>
                          </div>
                        </div>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row
                      *matHeaderRowDef="displayedColumns; sticky: true"
                    ></mat-header-row>
                    <mat-row
                      *matRowDef="let rpItem; columns: displayedColumns"
                    ></mat-row>
                  </mat-table>
                </div>
              </div>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>

    <!--Carga las VM's si el cliente tiene familia-->
    <ng-container *ngIf="(machinesChild | json) === '{}'">
      <span class="not-machine-text">No cuenta con máquinas registradas </span>
    </ng-container>
    <div *ngIf="machinesChild">
      <mat-tab-group>
        <div *ngFor="let machines of machinesChild | keyvalue">
          <ng-container *ngIf="machinesChild[machines.key]?.length === 0">
            <span class="not-machine-text"
              >No cuenta con máquinas registradas
            </span>
          </ng-container>
          <mat-tab *ngIf="machinesChild[machines.key]">
            <ng-template mat-tab-label>
              <span
                *ngIf="machines.key !== 'CS'"
                (click)="getChartInfo(machines.key, selectedCustomer?.ID)"
              >
                <div><b>SID Pool: </b>{{ machines.key }}</div>
              </span>
              <span *ngIf="machines.key === 'CS'"> Máquinas </span>
            </ng-template>

            <ng-container *ngIf="machinesChild[machines.key]?.length === 0">
              <span class="not-machine-text"
                >No cuenta con máquinas registradas
              </span>
            </ng-container>
            <ng-container *ngIf="machinesChild[machines.key]?.length !== 0">
              <mat-table [dataSource]="machinesChild[machines.key]">
                <ng-container matColumnDef="Estado">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      {{ "DATACENTER.STATUS" | translate }}
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <mat-icon
                        style="cursor: help"
                        [ngClass]="GetColorState(machine.State)"
                        matTooltip="{{
                          'STATUS_MACHINES.' + machine.State | translate
                        }}"
                        matTooltipPosition="right"
                      >
                        fiber_manual_record
                      </mat-icon>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SID">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">SID</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.ServiceID }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="SistemaOperativo">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      {{ "DATACENTER.VMNAME" | translate }}
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <span matTooltip="{{ GetAlias(machine) }}">{{
                        GetAlias(machine)
                      }}</span>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="RAM">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">RAM</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.Ram.Value }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Cores">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">Cores</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.Cores.Value }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="HDD">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">HDD</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      {{ machine.Configuration.MachineStorage[0].size }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Options">
                  <mat-header-cell *matHeaderCellDef class="row header-table">
                    <div class="full-width ellipsis-on-row">
                      <mat-icon>apps</mat-icon>
                    </div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let machine" class="row detail-row">
                    <div class="full-width ellipsis-on-row">
                      <div
                        class=""
                        *ngIf="
                          machine.State != 'PENDING' && machine.State != 'BUSY'
                        "
                      >
                        <button
                          [matMenuTriggerFor]="menu"
                          class="mat-icon-button"
                          mat-icon-button
                        >
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [matMenuTriggerFor]="actions">
                            Acciones
                          </button>
                          <mat-menu #actions="matMenu">
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State == 'POWER_ON' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="PowerOffVm(machine)"
                            >
                              <mat-icon>power_settings_new</mat-icon>
                              <span
                                >{{ "DATACENTER.TURN_OFF" | translate }}
                              </span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State == 'POWER_OFF' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="PowerOnVM(machine)"
                            >
                              <mat-icon>power_settings_new</mat-icon>
                              <span>{{
                                "DATACENTER.TURN_ON" | translate
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="
                                machine.State != 'SUSPEND' &&
                                machine.STATE != 'POWER_OFF' &&
                                machine.ProvisioningState != 12
                              "
                              (click)="SuspendVM(machine)"
                            >
                              <mat-icon>pause</mat-icon>
                              <span>{{
                                "DATACENTER.LAY_OFF" | translate
                              }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="machine.State == 'SUSPEND'"
                              (click)="ResumeVm(machine)"
                            >
                              <mat-icon>play_arrow</mat-icon>
                              <span>{{ "DATACENTER.RESUME" | translate }}</span>
                            </button>
                            <button
                              mat-menu-item
                              *ngIf="machine.State == 'POWER_ON'"
                              (click)="RestartVm(machine)"
                            >
                              <mat-icon>repeat</mat-icon>
                              <span>{{
                                "DATACENTER.RESTART" | translate
                              }}</span>
                            </button>
                            <mat-divider></mat-divider>
                          </mat-menu>
                          <button
                            mat-menu-item
                            (click)="ShowMachineConsole(machine)"
                            [disabled]="machine.State != 'POWER_ON'"
                          >
                            <span>{{
                              "DATACENTER.SHOWCONSOLE" | translate
                            }}</span>
                          </button>
                          <button
                            mat-menu-item
                            (click)="ShowMachineActions(machine)"
                          >
                            <span>{{
                              "DATACENTER.EDITCONFIG" | translate
                            }}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="displayedColumns; sticky: true"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let machine; columns: displayedColumns"
                ></mat-row>
              </mat-table>
            </ng-container>
          </mat-tab>
        </div>
      </mat-tab-group>
    </div>
  </div>
</mat-sidenav-container>
