import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DnatRuleDto } from '../../DTO/dnat-rules-dto';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-new-firewall-port-rule',
  templateUrl: './new-firewall-port-rule.component.html',
  styleUrls: ['./new-firewall-port-rule.component.scss']
})
export class NewFirewallPortRuleComponent implements OnInit {

  newRule : DnatRuleDto;
  disabled: boolean = false;
  constructor(public dialogRef: MatDialogRef<NewFirewallPortRuleComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder : FormBuilder) {
    this.newRule = new DnatRuleDto();
   }

  ngOnInit(): void {
  }
  
  Close(): void {
    this.dialogRef.close(null);
  }

  AddRule(){
    /*this.service.AddFirewallRule(this.newRule).subscribe(
      response=>{
        this.rules.add(response);
      },
      error=>{}
    ) */
  }

  CloseRuleFormView(){

  }
}