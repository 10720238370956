import { Pipe, PipeTransform } from '@angular/core';
import {ipObject} from '../../DTO/firewall-ip-dto';

@Pipe({
  name: 'filterType'
})
export class FilterTypePipe implements PipeTransform {

  transform(item: any[], ...args: string[]): any[] {
     
    if(item.length > 0){
          
     return item.filter(item => (item.value.type) ? item.value.type.indexOf(args[0]) !== -1 : null);
    }

   
    
  }

}
