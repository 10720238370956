<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<mat-horizontal-stepper #stepper [linear]="true">
    <mat-step [stepControl]="bundleSelectFG">
        <ng-template matStepLabel>
            <mat-icon class="mat-24 wl-stepper-header">
                desktop_mac
            </mat-icon>
        </ng-template>
        <div class="service-title">
            <div class="row">
                <div class="col-12">
                    <h6>Seleccione un plan</h6>
                </div>
            </div>
        </div>
        <form [formGroup]="bundleSelectFG" (ngSubmit)="selectPlan()">
            <div class="service-container">
                <mat-radio-group class="row" [(ngModel)]="currentBundle" formControlName="bundle">
                    <mat-radio-button *ngFor="let product of domainProducts" class="col-12" [value]="product">
                        <div class="row">
                            <div class="col-12 no-default-padding">
                                <div class="card service-plan-card full-width margin-bottom-20">
                                    <div class="card-header">
                                        {{product.Name}}
                                    </div>
                                    <div class="card-block">
                                        <p class="card-text">
                                            {{product.Description}}
                                        </p>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="service-value">{{product.Configuration.BundleMachine.Cores.Value}}</div>
                                                <div class="service-text">CPU</div>
                                            </div>
                                            <div class="col-4">
                                                <div class="service-value">{{product.Configuration.BundleMachine.Ram.Value}}</div>
                                                <div class="service-text">RAM</div>
                                            </div>
                                            <div class="col-4">
                                                <div class="service-value service-cost">{{product.Configuration.Cost.DiscountMRC | currency:'USD':true:'2.0-1' }}</div>
                                                <div class="service-text">Costo</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="service-button-container">
                <div class="row">
                    <div class="col-6 text-left">
                        <div class="bundle-total-price" *ngIf="bundle.Cost.DiscountMRC != 0">
                            <span class="total-bundle-text">Total</span><span class="service-cost total-bundle-cost">{{bundle.Cost.DiscountMRC | currency:'USD':true:'2.0-2'}}</span>
                        </div>
                        <div class="bundle-total-price" *ngIf="currentBundle != null">
                            <span class="total-bundle-text">Total</span><span class="service-cost total-bundle-cost">{{currentBundle.Configuration.Cost.DiscountMRC | currency:'USD':true:'2.0-2'}}</span>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <button color="primary" mat-raised-button matStepperNext type="submit" [disabled]="!bundleSelectFG.valid">
                        Continuar    
                    </button>
                    </div>
                </div>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="confirmForm">
        <ng-template matStepLabel>
            <mat-icon class="mat-24 wl-stepper-header">
                settings_applications
            </mat-icon>
        </ng-template>
        <form [formGroup]="confirmForm" novalidate (ngSubmit)="processConfig()">
            <div class="service-title">
                <div class="row">
                    <div class="col-12">
                        <h6>Configuración</h6>
                    </div>
                </div>
            </div>
            <div class="service-container">
                <div class="row">
                    <div class="col-12">
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select name="Vcenter" class="full-width" (selectionChange)="getOsList()" name="datacenter" id="datacenter" formControlName="datacenter"
                                [(ngModel)]="bundle.BundleMachine.Vcenter" placeholder="DataCenter">
                                <mat-option *ngFor="let dc of constants.DataCenter" [value]="dc.ID">
                                    {{ dc.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('datacenter').hasError('required') && confirmForm.get('datacenter').touched">Campo requerido.</mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select (selectionChange)="getOsList()" name="osType" id="osType" formControlName="osType" [(ngModel)]="OsType" required placeholder="Tipo de Sistema Operativo">
                                <mat-option *ngFor="let dc of constants.ProvisioningOS" [value]="dc.ID">
                                    {{ dc.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('osType').hasError('required') && confirmForm.get('osType').touched">Campo requerido.</mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select (selectionChange)="refreshPrice()" class="full-width" [disabled]="TemplateList == []" name="os" id="os" formControlName="os"
                                [(ngModel)]="bundle.BundleMachine.OperativeSystem.Os" required placeholder="Sistema Operativo"
                                floatPlaceholder="always">
                                <mat-option *ngFor="let dc of TemplateList" [value]="dc.TemplateName">
                                    {{ dc.OsName }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('os').hasError('required') && confirmForm.get('os').touched">Campo requerido.</mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select (selectionChange)="refreshPrice()" class="full-width" name="availability" id="availability" formControlName="availability"
                                [(ngModel)]="bundle.BundleMachine.Availability.availabilityType" required placeholder="Disponibilidad"
                                floatPlaceholder="always">
                                <mat-option *ngFor="let dc of constants.Availability" [value]="dc.ID">
                                    {{ dc.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('availability').hasError('required') && confirmForm.get('availability').touched">Campo requerido.</mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select (selectionChange)="refreshPrice()" class="full-width" name="iops" id="iops" formControlName="iops"
                                [(ngModel)]="bundle.BundleMachine.MachineStorage[0].diskType" required placeholder="IOPS"
                                floatPlaceholder="always">
                                <mat-option *ngFor="let dc of constants.IOPS" [value]="dc.ID">
                                    {{ dc.ID }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('iops').hasError('required') && confirmForm.get('iops').touched">Campo requerido.</mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="always" class="full-width margin-top-10">
                            <mat-select (selectionChange)="refreshPrice()" class="full-width" name="paymentConst" id="paymentConst" formControlName="paymentConst"
                                [(ngModel)]="bundle.BundleMachine.Duration" required placeholder="Condiciones de pago" floatPlaceholder="always">
                                <mat-option *ngFor="let dc of constants.PaymentConditions" [value]="dc.ID">
                                    {{ dc.Name }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="confirmForm.get('paymentConst').hasError('required') && confirmForm.get('paymentConst').touched">Campo requerido.</mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div class="service-button-container">
                <div class="row">
                    <div class="col-6 text-left">
                        <div class="bundle-total-price">
                            <span class="total-bundle-text">Total</span><span class="service-cost total-bundle-cost">{{bundle.Cost.DiscountMRC | currency:'USD':true:'2.0-2'}}</span>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <button color="primary" mat-raised-button matStepperNext [disabled]="!confirmForm.valid">
                            Siguiente
                        </button>
                    </div>

                </div>
            </div>
        </form>

    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <mat-icon class="mat-24 wl-stepper-header">
                check_circle
            </mat-icon>
        </ng-template>
        <div class="service-title">
            <div class="row">
                <div class="col-12">
                    Confirmación
                </div>
            </div>
        </div>
        <div class="service-container">
            <div class="container-fluid">
                <div class="col-12 no-default-padding">
                    <div class="wl-service-confirmation-textbox col-12">
                        <label>Cores</label><span>{{""+bundle.BundleMachine.Cores.Value}}</span>
                        <label>Ram</label><span>{{""+bundle.BundleMachine.Ram.Value}}</span>
                        <label>Sistema Operativo</label><span>{{ReplaceName(""+bundle.BundleMachine.OperativeSystem.Os)}}</span>
                        <label>Centro de datos</label><span>{{GetConstantValue(""+bundle.BundleMachine.Vcenter,"dc")}}</span>
                        <label>Disponibilidad</label><span>{{GetConstantValue(""+bundle.BundleMachine.Availability.availabilityType,"av")}}</span>
                    </div>
                    <div class="wl-service-confirmation-textbox col-12">
                        <label>Condiciones de pago</label><span>{{GetConstantValue(""+bundle.BundleMachine.Duration,"pc")}}</span>
                        <label>Costo</label><span>{{bundle.Cost.DiscountMRC | currency:'USD':true:'2.0-2'}}</span>
                    </div>

                    <span color="primary" (click)="ShowTerms()">
                                Acepto los terminos y condiciones
                            </span>
                </div>
            </div>

        </div>
        <div class="service-button-container">
            <div class="row">
                <div class="col-6 text-left">
                    <div class="bundle-total-price">
                        <span class="total-bundle-text">Total</span><span class="service-cost total-bundle-cost">{{bundle.Cost.DiscountMRC | currency:'USD':true:'2.0-2'}}</span>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <button color="primary" [disabled]="loading" (click)="SaveService()" mat-raised-button>Guardar</button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>