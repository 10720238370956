import { MatSnackBar } from '@angular/material/snack-bar';
import { CsjService } from './../../../services/csj/csj.service';
import { Component, OnInit, AfterViewInit, ViewChild, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import domtoimage from "dom-to-image";
import { ExcelService } from 'src/app/services/excel/excel.service';

@Component({
  selector: 'app-csj-general-graphics',
  templateUrl: './csj-general-graphics.component.html',
  styleUrls: ['./csj-general-graphics.component.scss']
})
export class CsjGeneralGraphicsComponent {

  public dataSource = new MatTableDataSource();
  data: any[];
  multi: any[];

  //Graphics options
  view: any[] = [550, 280];
  colorScheme = {
    domain: [
      "#5AA454",
      "#E44D25",
      "#CFC0BB",
      "#7aa3e5",
      "#a8385d",
      "#aae3f6",
      "#17A5DE",
      "#e5e",
      "#FE7878",
    ],
  };

  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = "Días";
  yAxisLabel: string = "Efectividad";
  timeline: boolean = true;
  autoScale: boolean = true;
  unit: string = "Unidad";
  legendPosition: string = "below";

  maxDate: Date;

  // Table
  displayedColumns = [
    "Unit",
    "T_FILESORIGEN",
    "T_FILESDESTINO",
    "FILES_SIORIGEN_SIDESTINO",
    "FILES_SIORIGEN_NODESTINO",
    "FILES_NOORIGEN_SIDESTINO",
    "S_FILES_SIORIGEN_SIDESTINO",
    "S_FILES_SIORIGEN_NODESTINO",
    "S_FILES_NOORIGEN_SIDESTINO",
  ];
  resultsLength = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public csjService: CsjService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private excelService: ExcelService
  ) {
    this.getReport(csjService);
    this.getMissingFilesToCopy(csjService);
    this.getTimeUseOfDisk(csjService);
  }

  getValue(array: any, item: string, search: string) {
    const ob: any = array.find((e: any) => e.name == search);
    return ob && ob[item] ? ob[item] : 0;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getReport(csjService: CsjService) {
    csjService.getReport().subscribe(
      (res: any) => {
        this.dataSource.data = res.report;
      },
      (err) => console.log(err)
    );
  }

  getMissingFilesToCopy(csjService: CsjService) {
    csjService.getMissingFilesToCopy().subscribe(
      (res: any) => {
        this.data = res.files;
        this.forEachArrays(this.data);
      },
      (err) => console.log(err)
    );
  }

  getTimeUseOfDisk(csjService: CsjService) {
    csjService.getTimeUseOfDisk().subscribe(
      (res: any) => {
        this.multi = res.files;
        this.forEachArrays(this.multi);
      },
      (err) => console.log(err)
    );
  }

  exportToExcel(data: any) {
    const result = [];
    data.forEach((disk: any) => {
      const newDisk = {
        disco: disk.unit,
        TotalArchivosOrigen: this.getValue(
          disk.columns,
          "counting",
          "Total Archivos Origen Unidad"
        ),
        TotalArchivosDestino: this.getValue(
          disk.columns,
          "counting",
          "Total Archivos Destino Unidad"
        ),
        ArchivosSiOrigenSiDestino: this.getValue(
          disk.columns,
          "counting",
          "[SIORIGEN_SIDESTINO]"
        ),
        ArchivosSiOrigenNoDestino: this.getValue(
          disk.columns,
          "counting",
          "[SIORIGEN_NODESTINO]"
        ),
        ArchivosNoOrigenSiDestino: this.getValue(
          disk.columns,
          "counting",
          "[NOORIGEN_SIDESTINO]"
        ),
        TamArchivosSiOrigenSiDestino: this.getValue(
          disk.columns,
          "size",
          "[SIORIGEN_SIDESTINO]"
        ),
        TamArchivosSiOrigenNoDestino: this.getValue(
          disk.columns,
          "size",
          "[SIORIGEN_NODESTINO]"
        ),
        TamArchivosNoOrigenSiDestino: this.getValue(
          disk.columns,
          "size",
          "[NOORIGEN_SIDESTINO]"
        ),
      };
      result.push(newDisk);
    });
    this.excelService.exportAsJsonExcelFile(
      result,
      "Información de los discos"
    );
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  downloadToJpeg(id: string) {
    // domtoimage
    //   .toJpeg(document.getElementById(id), { bgcolor: "#FFF" })
    //   .then(function (dataUrl) {
    //     var link = document.createElement("a");
    //     link.download = id + ".jpeg";
    //     link.href = dataUrl;
    //     link.click();
    //   });
  }

  refresh() {
    this.dataSource.data = [];
    this.multi = [];
    this.data = [];
    this.getReport(this.csjService);
    this.getMissingFilesToCopy(this.csjService);
    this.getTimeUseOfDisk(this.csjService);
  }

  forEachArrays(array) {
    array.forEach((disk: any) => {
      if (disk.name) {
        disk.series.forEach((serie: any) => {
          if (!this.maxDate) this.maxDate = serie.name;
          else {
            if (this.maxDate < serie.name) this.maxDate = serie.name;
          }
        });
      }
      if (disk.name) {
        disk.series.forEach((serie: any) => {
          if (!this.maxDate) this.maxDate = serie.name;
          else {
            if (this.maxDate < serie.name) this.maxDate = serie.name;
          }
        });
      }
    });
  }
}
