import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { WlService } from '../../../services/wlservice.service';
import { ProductDTO, BundleDTO } from '../../../models/dtos.component';
import { Constants } from '../../../models/constants.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
// tslint:disable-next-line: component-selector
    selector: 'plan-edition',
    templateUrl: 'plan-edition.component.html',
    styleUrls: ['../virtualServers.component.scss']
})

export class planEdition implements OnInit, OnChanges {
    @Input() product: ProductDTO;
    @Input() sidenav: MatSidenav;
    @Output() savedProduct = new EventEmitter<ProductDTO>();
    constants: Constants = new Constants();
    public loading = false;
    token: string;
    language: string;
    virtualServerForm: FormGroup;


    constructor(private service: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder,public auth: AuthService) {
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.virtualServerForm = this.formBuilder.group({
            'nombredelPlan': [{ value: '', disabled: this.loading }, [Validators.required, Validators.minLength(4)]],
            'descripciondelPlan': [{ value: '', disabled: this.loading }, [Validators.required, Validators.maxLength(130)]],
            'cantidadCores': [{ value: '', disabled: this.loading }, [Validators.required]],
            'cantidadRam': [{ value: '', disabled: this.loading }, [Validators.required]],
            'tipodeProductos': [{ value: '', disabled: this.loading }, [Validators.required]],
        });
    }
    ngOnInit() {
      this.product = new ProductDTO();
      this.refreshProductPrice();
  }

    ngOnChanges(changes) {
        if (changes['product'].currentValue) {
            if (!changes['product'].currentValue.ID) {
                this.virtualServerForm.reset({
                    cantidadCores: 2,
                    cantidadRam: 2,
                    tipodeProductos: '/assets/img/gralServer.png',
                });
              }
            this.refreshProductPrice();
        }
    }

    public SaveProduct() {
        this.virtualServerForm.disable();
        this.loading = true;
        this.service.SaveProduct(this.product).subscribe(
            (response:any) => {
                this.virtualServerForm.enable();
                this.loading = false;
                this.savedProduct.emit(response);
                this.sidenav.close();
            },
            error => {
                this.virtualServerForm.enable();
                this.loading = false;
                this.snackbar.open('Error guardando producto ' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }

    public closeDialog() {
        this.sidenav.close();
    }

    public changeProductType(value) {
        switch (value.value) {
            case '/assets/img/gralServer.png':
                this.product.VirtualizationProviderID = 1;
                this.product.Configuration.BundleMachine.Hypervisor.plataform = 1;
                break;
            case '/assets/img/sapLogoBg.png':
                this.product.VirtualizationProviderID = 2;
                this.product.Configuration.BundleMachine.Hypervisor.plataform = 2;
                break;
            default:
                break;
        }
        console.log(value);
    }

    public clearSelection() {
        this.product.Configuration = new BundleDTO();
        this.product.Name = '';
        this.product.Description = '';
    }

    public refreshProductPrice() {
        this.loading = true;
        this.service.GetProductPrice(this.product.Configuration).subscribe(
            (response:any) => {
                this.loading = false;
                this.product.Configuration = response;
            },
            error => {
                this.loading = false;
                this.snackbar.open('Error consultando precio ' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }
}
