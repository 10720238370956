<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="AppProfile.ApplicationProfileId == null || AppProfile.ApplicationProfileId == ''">{{'APPLICATION_PROFILE.ADD_APPLICATION_PROFILE' | translate}}</h5>
        <h5 *ngIf="AppProfile.ApplicationProfileId != null && AppProfile.ApplicationProfileId != ''">{{'APPLICATION_PROFILE.EDIT_APPLICATION_PROFILE' | translate}}</h5>
    </div>
    <form [formGroup]="appProfileForm" (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'APPLICATION_PROFILE.CONFIGURATION_OF_THE_APPLICATION_PROFILE' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input name="AppProfName" id="AppProfName" formControlName="Name" [(ngModel)]="AppProfile.Name" required matInput placeholder="{{'APPLICATION_PROFILE.NAME' | translate}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="appProfileForm.get('Name').valid && appProfileForm.get('Name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!appProfileForm.get('Name').valid && appProfileForm.get('Name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="appProfileForm.get('Name').hasError('required') && appProfileForm.get('Name').touched">{{'APPLICATION_PROFILE.REQUIRED_FIELD' | translate}}</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{AppProfile.Name ? AppProfile.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{'APPLICATION_PROFILE.TYPE' | translate}}"
                    name="Template" id="Template" formControlName="Template" [(ngModel)]="AppProfile.Template"
                    floatPlaceholder="always">
                    <mat-option [value]="'TCP'">TCP</mat-option>
                    <mat-option [value]="'HTTP'">HTTP</mat-option>
                    <mat-option [value]="'HTTPS'">HTTPS</mat-option>
                    <mat-option [value]="'UDP'">UDP</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-12 full-width" floatLabel="always">
              <mat-select class="col-12 full-width margin-top-20" placeholder="{{'APPLICATION_PROFILE.PERSISTENCE' | translate}}"
                  name="Method" id="Method" formControlName="Method" [(ngModel)]="AppProfile.Persistence.Method"
                  floatPlaceholder="always">
                  <mat-option [value]="'none'">None</mat-option>
                  <mat-option [hidden]="AppProfile.Template != 'HTTP' && AppProfile.Template != 'HTTPS'" [value]="'cookie'">Cookie</mat-option>
                  <mat-option [value]="'sourceip'">{{'APPLICATION_PROFILE.IP_SOURCE' | translate}}</mat-option>
                  <mat-option [hidden]="AppProfile.Template != 'TCP'" [value]="'msrdp'">MSRDP</mat-option>
              </mat-select>
          </mat-form-field>

          <mat-form-field [hidden]="AppProfile.Persistence.Method != 'cookie'" class="col-12 full-width" floatLabel="always">
                <input name="CookieName" id="CookieName" formControlName="CookieName" [(ngModel)]="AppProfile.Persistence.CookieName" required matInput placeholder="{{'APPLICATION_PROFILE.COOKIE_NAME' | translate}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="AppProfile.Persistence.Method == 'cookie' && appProfileForm.get('CookieName').valid" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="AppProfile.Persistence.Method == 'cookie' && !appProfileForm.get('CookieName').valid" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="AppProfile.Persistence.Method == 'cookie' && appProfileForm.get('CookieName').hasError('required')">{{'APPLICATION_PROFILE.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{AppProfile.Persistence.CookieName ? AppProfile.Persistence.CookieName.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field [hidden]="AppProfile.Persistence.Method != 'cookie'" class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{'APPLICATION_PROFILE.MODE' | translate}}"
                    name="CookieMode" id="CookieMode" formControlName="CookieMode" [(ngModel)]="AppProfile.Persistence.CookieMode"
                    floatPlaceholder="always">
                    <mat-option [value]="'insert'">Insert</mat-option>
                    <mat-option [value]="'prefix'">Prefix</mat-option>
                    <mat-option [value]="'app'">App Session</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field [hidden]="AppProfile.Persistence.Method == 'none' || AppProfile.Persistence.Method == ''" class="col-12 full-width" floatLabel="always">
            <input name="Expire" id="Expire" formControlName="Expire" [(ngModel)]="AppProfile.Persistence.Expire" required matInput placeholder="{{'APPLICATION_PROFILE.EXPIRES_IN_SECONDS' | translate}}"
                maxlength="50">
            <mat-icon matSuffix *ngIf="appProfileForm.get('Expire').valid && appProfileForm.get('Expire').touched" class="form-accepted">done</mat-icon>
            <mat-icon matSuffix *ngIf="!appProfileForm.get('Expire').valid && appProfileForm.get('Expire').touched" class="form-rejected">error</mat-icon>
            <mat-hint align="start">
                <div class="error-messages">
                    <div *ngIf="appProfileForm.get('Expire').hasError('required') && appProfileForm.get('Expire').touched">{{'APPLICATION_PROFILE.REQUIRED_FIELD' | translate}}.</div>
                </div>
            </mat-hint>
            <mat-hint align="end">{{AppProfile.Persistence.Expire ? AppProfile.Persistence.Expire.length : 0}} / 50</mat-hint>
        </mat-form-field>

        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'APPLICATION_PROFILE.CANCEL' | translate}}</button>
            </div>

            <div class="col-6 full-width">
                <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!appProfileForm.valid">{{'APPLICATION_PROFILE.SAVE' | translate}}</button>
            </div>
        </div>
    </form>
</div>