<mat-progress-bar *ngIf="Loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div>
    <div>
        <button class="pull-right wl-grid-refresh-button" matTooltip="Refrescar" (click)="ReloadNetwork()" mat-icon-button>
            <mat-icon class="mat-24">autorenew</mat-icon>
        </button>
    </div>

    <mat-form-field class="full-width">
        <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" #input>
    </mat-form-field>
</div>
<mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef>Acción</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Action}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="SourceIp">
        <mat-header-cell *matHeaderCellDef>IP Origen</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.SourceIp}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="DestinationIp">
        <mat-header-cell *matHeaderCellDef>IP Destino</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.DestinationIp}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Port">
        <mat-header-cell *matHeaderCellDef>Puerto</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Port}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Options">
        <mat-header-cell *matHeaderCellDef>Opciones</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button [matMenuTriggerFor]="menu" class="mat-icon-button" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="EditFirewallRule(row)">
                    <span>Editar</span>
                </button>
                <button mat-menu-item (click)="RemoveFirewallModal(row)">
                    <span>Eliminar</span>
                </button>
            </mat-menu>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <div>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </div>
</mat-table>
<mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"></mat-paginator>