
 <!-- <section>
    <div class="add-button-ip pull-right">
      <button mat-raised-button color="primary">Agregar</button>
    </div>
  </section> -->
  <mat-divider></mat-divider>
  
  <!--<mat-form-field>
    <mat-label>Filter</mat-label>
   <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>-->
  
  <table mat-table [dataSource]="dataSource" style="width:100%" *ngIf="item">
  
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  
      <!-- Name Column -->
      <ng-container matColumnDef="Interface">
        <th mat-header-cell *matHeaderCellDef> Interface </th>
        <td mat-cell *matCellDef="let element"> {{element.Interface}} </td>
      </ng-container>
    
      <!-- Symbol Column -->
      <ng-container matColumnDef="External IP">
        <th mat-header-cell *matHeaderCellDef> External IP </th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Internal IP">
        <th mat-header-cell *matHeaderCellDef> Internal IP </th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>

       <!-- Symbol Column -->
       <ng-container matColumnDef="Destination IP">
        <th mat-header-cell *matHeaderCellDef> Internal IP </th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>
     
      <!-- Symbol Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef> Description</th>
        <td mat-cell *matCellDef="let element">  </td>
      </ng-container>
  
    
<!--    <ng-c>ontainer matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> acciones</th>
            <td mat-cell *matCellDef="let element ; let i = index">
             
                <button id="btnMoreAccions+ 'i'"  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item  id="detail">
                      <mat-icon>remove_red_eye</mat-icon>
                      <span>Ver detalle</span>
                    </button>   
                </mat-menu>
  
                <button mat-icon-button matTooltip="Eliminar" id="removeNetworklLink">
                  <mat-icon>delete</mat-icon>
                </button>
            </td>
      </ng-container>-->
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
