<div style="width:440px !important;" class="card">
    <div class="card-block container-fluid margin-bottom-10 margin-top-10">
        <div class="card-title modal-title" style="margin-top:0!important;">
            <h6 class="pull-left">Cambio de Nombre</h6>
            <div class="pull-right close-button" (click)="CloseDialog()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <form [formGroup]="ServiceNameFormFb"  novalidate (ngSubmit)="ChangeName()">
            <div class="full-width margin-top-20 margin-bottom-20" style="display: inline-block">Ingrese un nuevo nombre para la máquina seleccionada:</div>
            <mat-form-field class="full-width">
                <input style="color:#613B3B !important" type="text" class="wtxt" [(ngModel)]="ServiceName" name="ServiceNameControl" matInput
                    placeholder="Nombre" maxlength="40" formControlName ="ServiceName" >
                    <mat-icon matSuffix *ngIf="ServiceNameFormFb.get('ServiceName').valid && ServiceNameFormFb.get('ServiceName').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!ServiceNameFormFb.get('ServiceName').valid && ServiceNameFormFb.get('ServiceName').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="ServiceNameFormFb.get('ServiceName').hasError('required') && ServiceNameFormFb.get('ServiceName').touched">Campo requerido</mat-error>
                <mat-hint align="end">{{ServiceName.length}} / 40</mat-hint>
            </mat-form-field><br><br>
            <div class="text-right margin-top-10">
                <button mat-raised-button color="primary" type="submit" [disabled]="!ServiceNameFormFb.valid" >Guardar</button>
            </div>
        </form>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>