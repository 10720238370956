import { Component, OnInit } from '@angular/core';
import { LoginDTO } from '../models/dtos.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WlService } from '../services/wlservice.service';
import { Globalization } from '../globalization/globalization';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CustomFormValidator } from '../tools/CustomValidator';
import { Constants } from '../models/constants.component';
import { AuthService } from '../services/auth/auth.service';
import { Outh2ModalComponent } from './outh2-modal/outh2-modal.component';
import { LogsService } from '../services/logs/logs-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  loginDTO: LoginDTO = new LoginDTO();
  isLoading: boolean = false;
  glob: any = Globalization;
  domainInformation: any;
  domainLanguage: string = "es-co";
  HeaderConfiguration: any;
  domainLogo: string;
  constants = new Constants();
  hide: boolean = true;
  loginForm: FormGroup;
  mostrar: boolean = true;
  currentYear: number = (new Date()).getFullYear();
  public outhValue: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private service: WlService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    public auth: AuthService,
    private logsServices: LogsService
  ) {
    this.loginForm = this.formBuilder.group({
      'username': ['', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]],
      'password': ['', [Validators.required]]
    });
  }
  login(event: any) {
    this.isLoading = true;
    this.service.login(this.loginDTO).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.auth.setToken(response.token);
        if (response.auth) {
          this.outhValue = atob(response.auth);
          this.outh2Modal();
        } else {
          this.logsServices.postAppLog('Inicio de Sesión', 'Uso', true, 'Login correctamente', null)
          this.router.navigateByUrl('/home');
        }
      },
      error => {
        this.isLoading = false;
        if (error.status == 400)
          this.snackbar.open('El usuario no se encuentra registrado en el dominio', null, {
            duration: 3000,
          });
        else if (error.status == 401)
          this.snackbar.open('El usuario o contraseña es incorrecto, por favor intente de nuevo', null, {
            duration: 3000,
          });
        else
          console.log(error);
        this.logsServices.postAppLog('Inicio de Sesión', 'Uso', false, 'No fue posible el login', null)
      }
    )
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let token = params['t'];
      if (token) {
        this.auth.setToken(token);
        this.router.navigateByUrl('/home');
      }
    });
    this.service.GetDomainInformation().subscribe(
      respose => {
        this.domainInformation = respose;
        this.HeaderConfiguration = JSON.parse(this.domainInformation.HeaderConfiguration);
        this.domainLanguage = this.domainInformation.DefaultLanguage;
        this.domainLogo = environment.serviceUrl + "CustomerImages/" + this.HeaderConfiguration.Logo;
        localStorage.setItem("language", this.domainLanguage);
      },
      error => {
        console.log("Invalid WL Domain", error);
      }
    )
  }
  openDialog() {
    this.dialog.open(LoginModalComponent, { hasBackdrop: true, disableClose: true, width: "600px" });
  }

  outh2Modal() {
    this.dialog.open(Outh2ModalComponent, {
      width: '40%',
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result === this.outhValue) {
        this.logsServices.postAppLog('Inicio de Sesión', 'Uso', true, 'Login correctamente', null)
        this.router.navigateByUrl('/home');
      } else {
        this.snackbar.open('El código ingresado no es válido', null, {
          duration: 3000
        });
        this.isLoading = false;
        this.loginForm.enable();
      }
    });
  }
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginModalComponent {
  rememberEmail: string;
  loading: boolean = false;
  rememberForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<LoginModalComponent>, private service: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder) {
    this.rememberForm = this.formBuilder.group({
      'rememberEmail': [{ value: '', disable: this.loading }, [Validators.required, Validators.minLength(10), CustomFormValidator.EmailValidator]]
    });

  }
  rememberPassword() {
    if (this.rememberEmail && this.rememberEmail != "") {
      this.rememberForm.disable();
      this.loading = true;
      this.service.rememberPassword(this.rememberEmail).subscribe(
        response => {
          this.rememberForm.enable();
          this.loading = false;
          this.snackbar.open('Contraseña reiniciada satisfactoriamente, por favor revise su correo', null, {
            duration: 2000,
          });
          this.dialogRef.close();
        },
        error => {

          this.rememberForm.enable();
          this.loading = false;
          this.snackbar.open(error.error, null, {

            duration: 2000,
          });
        }
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
