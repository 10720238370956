export class  FirewallRulesDto {
    public id: string;
    public tracker: string;
    public type : string;
    public interface: string;
    public ipprotocol: string;
    public tag: string;
    public tagged: string;
    public max: string; 
    public maxSrcNodes:string;
    public maxSrcConn: string;
    public maxSrcStates : string;
    public statetimeout : string;
    public statetype :string;
    public os : string;
    public protocol :string;
    public icmptype : string;
    public source :SourceFirewallRulesDto;
    public destination : DestinationFirewallRulesDto;
    public descr : string;
    public updated :UpdatedFirewallRulesDto;
    public created : UpdatedFirewallRulesDto;

}



export class SourceFirewallRulesDto {
    public address?: string;
    public any?: string;
    public network? :string;
    public port ?: string;
}

export class DestinationFirewallRulesDto{
    public address?: string;
    public any?: string;
    public network? :string;
    public port ?: string;
}


export class UpdatedFirewallRulesDto
{
    public time: string;
    public username: string; 
}