<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="sidenav-title container-fluid">
    <h5 *ngIf="user.ID == null">Agregar usuario</h5>
    <h5 *ngIf="user.ID != null">Editar usuario</h5>
</div>
<div class="">
    <form [formGroup]="addClientForm" novalidate (ngSubmit)="saveUser()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>Datos del usuario</h6>
            </div>
            <div *ngIf="customerType == 'SYSTEM_USER'">
                <mat-form-field class="full-width col-12" floatLabel="always">
                    <mat-select class="" [required]="customerType == 'SYSTEM_USER'" placeholder="Perfil de usuario" name="userProfile"
                        id="userProfile" formControlName="userProfile" [(ngModel)]="user.RoleID" >
                        <mat-option *ngFor="let profile of profiles" [value]="profile.ID">
                            {{profile.Name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix *ngIf="addClientForm.get('userProfile').valid && addClientForm.get('userProfile').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!addClientForm.get('userProfile').valid && addClientForm.get('userProfile').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="addClientForm.get('userProfile').hasError('required') && addClientForm.get('userProfile').touched">Campo requerido</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field floatLabel="always" class="full-width col-12">
                <mat-select selected="customerType" class="" required placeholder="Tipo de usuario" name="userType" required
                    id="userType" formControlName="userType" [(ngModel)]="user.CustomerTypeID">
                    <mat-option selected [value]="customerType">{{customerType}}</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="addClientForm.get('userType').valid && addClientForm.get('userType').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('userType').valid && addClientForm.get('userType').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('userType').hasError('required') && addClientForm.get('userType').touched">Campo requerido.</mat-error>
            </mat-form-field>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="login" class="" id="login" formControlName="login" [(ngModel)]="user.Login" required matInput
                    placeholder="Login / Alias" maxlength="50">
                <mat-icon matSuffix *ngIf="addClientForm.get('login').valid && addClientForm.get('login').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('login').valid && addClientForm.get('login').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('login').hasError('required') && addClientForm.get('login').touched">Campo requerido.</mat-error>
                <mat-hint class="" align="end">{{ user.Login ? user.Login.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="sidenav-subtitle col-12">
                <h6>Datos de contacto</h6>
            </div>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="name" class="" id="name" formControlName="name" [(ngModel)]="user.Name" name="Name" matInput
                    type="text" placeholder="Nombre" maxlength="100">
                <mat-icon matSuffix *ngIf="addClientForm.get('name').valid && addClientForm.get('name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('name').valid && addClientForm.get('name').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('name').hasError('required') && addClientForm.get('name').touched">Campo requerido.</mat-error>
                <mat-hint class="" align="end">{{user.Name ? user.Name.length : 0}} / 100</mat-hint>
            </mat-form-field>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="phone" class="" id="phone" formControlName="phone" [(ngModel)]="user.Telephone" name="Telephone"
                    required matInput placeholder="Télefono" value="111-222-3333" maxlength="20">
                <mat-icon matSuffix *ngIf="addClientForm.get('phone').valid && addClientForm.get('phone').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('phone').valid && addClientForm.get('phone').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('phone').hasError('required') && addClientForm.get('phone').touched">Campo requerido.</mat-error>
                <mat-error *ngIf="addClientForm.get('phone').hasError('NumberValidator') && addClientForm.get('phone').touched">El número no cuenta con un formato válido</mat-error>
                <mat-hint class="" align="end">{{user.Telephone ? user.Telephone.length : 0}} / 20</mat-hint>
            </mat-form-field>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="id" class="" id="id" formControlName="id" [(ngModel)]="user.Identification" name="Identification"
                    required matInput placeholder="Identificacion" maxlength="30">
                <mat-icon matSuffix *ngIf="addClientForm.get('id').valid && addClientForm.get('id').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('id').valid && addClientForm.get('id').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('id').hasError('required') && addClientForm.get('id').touched">Campo requerido.</mat-error>
                <mat-hint class="" align="end">{{user.Identification ? user.Identification.length : 0}} / 30</mat-hint>
            </mat-form-field>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="address" class="" id="address" formControlName="address" [(ngModel)]="user.Address" name="Address"
                    required matInput placeholder="Dirección" maxlength="150">
                <mat-icon matSuffix *ngIf="addClientForm.get('address').valid && addClientForm.get('address').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('address').valid && addClientForm.get('address').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="addClientForm.get('address').hasError('required') && addClientForm.get('address').touched">Campo requerido.</mat-error>
                <mat-hint class="" align="end">{{user.Address ? user.Address.length : 0}} / 150</mat-hint>
            </mat-form-field>
            <mat-form-field class="margin-subtitle-10 full-width col-12" floatLabel="always">
                <input name="emailInput" class="" id="emailInput" formControlName="emailInput" [(ngModel)]="user.Email" required
                    matInput placeholder="Correo" maxlength="50">
                <mat-icon matSuffix *ngIf="addClientForm.get('emailInput').valid && addClientForm.get('emailInput').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!addClientForm.get('emailInput').valid && addClientForm.get('emailInput').touched" class="form-rejected">error</mat-icon>
                <mat-hint class="" align="end">{{user.Email ? user.Email.length : 0}} / 50</mat-hint>
                <mat-error *ngIf="addClientForm.get('emailInput').hasError('required') && addClientForm.get('emailInput').touched">Campo requerido.</mat-error>
                <mat-error *ngIf="addClientForm.get('emailInput').hasError('minlength') && addClientForm.get('emailInput').touched">El tamaño minimo del correo son 10 caracteres.</mat-error>
                <mat-error *ngIf="addClientForm.get('emailInput').hasError('emailValidator') && addClientForm.get('emailInput').touched">El correo electrónico no cuenta con el formato adecuado.</mat-error>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-12 no-right-padding">
                <button class="pull-right" type="submit" [disabled]="!addClientForm.valid" mat-raised-button color="primary">Guardar</button>
            </div>
        </div>
    </form>
</div>
