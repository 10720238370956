import { AliasFirewallRulesDto} from './alias-firewall-rules-dto';
import { FirewallRulesDto} from './firewall-rules-dto';
import { SnatRulesDto } from './snat-rules-dto';
import { DnatRuleDto } from './dnat-rules-dto';
import { DhcpServerDto} from './dhcp-server-dto';


export class RulesResponseDetailsDto
    {
        public aliasFirewallRules: Array<AliasFirewallRulesDto>;
        public firewallRules: Array<FirewallRulesDto>;
        public snatRules:   Array<SnatRulesDto>;
        public dnatRule : Array<DnatRuleDto>;
        public dhcpServer : Array<DhcpServerDto>;
    }