<mat-sidenav-container class="full-container" style="z-index:6">
    <app-header></app-header>
    <mat-sidenav #sidenav class="wl-sidenav">
        <div class="sidenav-container">
            <div class="blockMenuLogo">
                <img class="imgLogoMenu" src="./assets/img/LogoIFXwhite.png" alt="" width="50px; heigth:50px">
                <div class="txtPos wtxt">
                    <i class="material-icons">person</i> {{userName}}
                </div>
            </div>
            <mat-spinner class="menu-loading-spinner" *ngIf="menuLoading"></mat-spinner>
            <mat-list *ngIf="!menuLoading" class="menu-options-container">
                <mat-list-item *ngFor="let item of (menu) ? menu.Children : []" [ngClass]="{'selectable-menu-option': item.Children.length == 0}" (click)="menuClick(item)">
                    <mat-icon color="accent" mat-list-icon>{{item.Icon}}</mat-icon>
                    <h4 mat-line>{{'MENU.'+item.leaf | translate}}</h4>
                    <button *ngIf="item.Children.length > 0" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu [overlapTrigger]="true" style="display: none" #menu="matMenu">
                      <button class="mat-menu-style" (click)="menuClick(submenu)" *ngFor="let submenu of item.Children" mat-menu-item  matTooltip="{{submenu.leaf}}">
                          <mat-icon [ngStyle]="{'color': (submenu.ID === user.ID) ? '#3577ac' : '#ffab40'}" mat-list-icon>{{submenu.Icon}}</mat-icon>
                          <span *ngIf="submenu.ID">{{submenu.leaf}}</span>
                          <span *ngIf="!submenu.ID">{{'MENU.'+setCountryLeafName(submenu.leaf) | translate}}</span>
                      </button>
                    </mat-menu>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="cursor-pointer" (click)="logout();sidenav.close()">
                    <mat-icon color="accent" mat-list-icon>close</mat-icon>
                    <h4 mat-line>{{'MENU.logout' | translate}}</h4>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-sidenav>
    <mat-toolbar>
        <div class="header-toolbar">
            <button class="menu-toolbar-button" mat-icon-button (click)="sidenav.open()">
                <mat-icon>menu</mat-icon>
            </button>
            <span class="wl-toolbar-title"> {{headerText | uppercase}}</span>
          </div>
        <span class="example-spacer"></span>
        <button mat-button [matMenuTriggerFor]="lang">
          <mat-icon class="example-icon">language</mat-icon>
          {{languageSelect | translate }}
        </button>
        <mat-menu #lang="matMenu">
          <button mat-menu-item (click)="changeLang('es')" [disabled]="languageSelect !== 'HOME.ENGLISH'">
            <span>{{'HOME.SPANISH' | translate}}</span>
          </button>
          <button mat-menu-item (click)="changeLang('en')" [disabled]="languageSelect !== 'HOME.SPANISH'">
            <span>{{'HOME.ENGLISH' | translate}}</span>
          </button>
        </mat-menu>
        <div [hidden]="!displayMenu">
            <button mat-button [matMenuTriggerFor]="submenu">
                <mat-icon>view_module</mat-icon>
            </button>
            <mat-menu #submenu="matMenu">
                <button mat-menu-item *ngFor="let leaf of actions" (click)="leaf.action()">
                    <mat-icon>{{leaf.icon}}</mat-icon>
                    <span>{{leaf.name | translate}}</span>
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>

    <div class="main-container">
        <router-outlet name="main"></router-outlet>
    </div>
</mat-sidenav-container>

<!--The content below is only a placeholder and can be replaced.-->
<!--<div class="wrapper default-theme" [ngClass]="getClasses()">
  <app-navbar></app-navbar>
  <main>
    <app-sidebar [user]="user" [menu]="menu"></app-sidebar>
      <div class="pages container-fluid pt-4 pb-4 pl-4 pr-4 ">
        <router-outlet name="main"></router-outlet>
        <hr>
        <app-footer></app-footer>
      </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
</div>-->
