

import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/throw'
import 'rxjs/add/operator/catch';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const IdToken = this.auth.getToken();
        if (IdToken) {
            const authReq = req.clone(
                {
                    headers: req.headers.set('Authorization', 'Basic ' + IdToken)
                }
            );
            return next.handle(authReq)
        } else {
            return next.handle(req)
                .catch((error, caught) => {
                    console.log("Error Occurred");
                    console.log(error);
                    return Observable.throw(error);
                }) as any;
        }

    }
}
