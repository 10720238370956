import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { GridComponent } from "../../tools/grid/grid.component";
import { WlService } from '../../services/wlservice.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportContact } from '../../models/dtos.component';
import { BroadcastEmitter } from '../../services/BroadcastEmitter';
import { ConfirmationDialog } from '../../tools/modals/confirmation/modal.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-supportNumbers',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportNumbers implements OnInit {
    @ViewChild('sideNav') sideNav: MatSidenav;
    @ViewChild('supportNumbersGrid') supportNumbersGrid: GridComponent;
    selectedContact: SupportContact = new SupportContact();
    public SupportContacts: Array<SupportContact> = [];

    public grid = {
        columns: [
            { name: "Telephone", title: "Número" },
            { name: "Email", title: "Email" },
            { name: "Country", title: "Pais" },
            { name: "Favourite", title: "Favorito" }
        ],
        data: [],
        toolbar: [
            { "type": "button", "permission": "AddCustomer", "click": () => { this.addNumber() }, "text": "Agregar cliente", "align": "left" }
        ],
        filter: "Email",
        pageSize: 10,
        // rowClick: (u) => { this.userSettings(u) },
        refresh: (u) => { this.getNumbers() },
        actions: [
            {
                text: "Favorito",
                action: (u) => { this.EditFav(u) },
                hide: (u) => { return u.Favourite }
            }, {
                text: "Quitar Favorito",
                action: (u) => { this.EditFav(u) },
                hide: (u) => { return !u.Favourite }
            },
            {
                text: "Eliminar",
                action: (u) => { this.RemoveFav(u) }
            }
        ]
    }

    constructor(public service: WlService, public snackbar: MatSnackBar, public navbarBroadcast: BroadcastEmitter) {

    }

    ngOnInit() {
        this.navbarBroadcast.SendMessage({
            text: "Contactos de soporte",
            actions: [{ icon: 'person_add', name: 'Agregar número', action: () => { this.addNumber() } }], display: true
        });
        this.getNumbers();
    }

    savedContact(supportUser: SupportContact) {
        this.supportNumbersGrid.pushRow(supportUser);
    }

    RemoveFav(supportUser: SupportContact) {
        this.supportNumbersGrid.loading = true;
        this.service.RemoveContact(supportUser).subscribe(
            response => {
                this.getNumbers();
            },
            error => {
                this.snackbar.open('Error Eliminando Favorito. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    EditFav(supportUser: SupportContact) {
        this.supportNumbersGrid.loading = true;
        this.service.EditFavoriteContact(supportUser).subscribe(
            response => {
                this.snackbar.open('Cambios guardados satisfactoriamente. ', null, {
                    duration: 3000,
                });
                this.getNumbers();
            },
            error => {
                this.supportNumbersGrid.loading = false;
                this.snackbar.open('Error Editando Favorito. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    getNumbers() {
        this.supportNumbersGrid.loading = true;
        this.service.GetSupportContacts().subscribe(
            result => {
                this.supportNumbersGrid.setData(result);
            },
            error => {
                this.supportNumbersGrid.loading = false;
                this.snackbar.open('Error consultando numeros de soporte. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    addNumber() {
        this.selectedContact = new SupportContact();
        this.sideNav.open();
    }

}