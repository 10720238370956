export class CustomFormValidator{
    static AlphaNumericValidator(control){
        var regexp = new RegExp(/^[a-zA-Z0-9]+$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'alphaNumericValidator': true};
        }
    }

    static PasswordStrengthValidator(control){
        var regexp = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'passwordValidator': true};
        }
    }
    static EmailValidator(control){
        var regexp = new RegExp(/^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'emailValidator': true};
        }
    }
    static NumberValidator(control){
        var regexp = new RegExp(/^\d{1,10}$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'NumberValidator': true};
        }
    }

    static DecimalNumberValidator(control){
        var regexp = new RegExp(/^(\d*\.)?\d+$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'DecimalNumberValidator': true};
        }
    }
    static InputValidator(control){
        var regexp = new RegExp(/^\d{1,5}$/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'InputValidator': true};
        }
    }

    static PortValidator(control){
        var regexp = new RegExp(/(^(\d{1,5}\-\d{1,5})$)|(^\d{1,5}$)/);
        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'PortValidator': true};
        }
    }

    static ipAddressValidator(control){
        var regexp = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);

        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'ipAddressValidator': true};
        }

    }

    static maskValidator(control){
        var regexp = new RegExp(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1}(\/([0-9]|[1-2][0-9]|3[0-2]))$/);

        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'maskValidator': true};
        }

    }

    static networkGroupValidator(control){
        var regexp = new RegExp(/^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9]{1,2}){0,1}(\/([0-9]|[1-2][0-9]|3[0-2]))\,?)*)-?)*$/);

        if(regexp.test(control.value)){
            return null;
        }
        else{
            return {'networkGroupValidator': true};
        }

    }
}