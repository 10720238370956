<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="IPSet.ObjectId == null || IPSet.ObjectId == ''">Agregar IPSet</h5>
        <h5 *ngIf="IPSet.ObjectId != null && IPSet.ObjectId != ''">Editar IPSet</h5>
    </div>
    <form [formGroup]="ipsetForm" (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>Configuración del IPSet</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input name="IPSetValue" id="IPSetValue" formControlName="Value" [(ngModel)]="IPSet.Value" required matInput placeholder="{{globalization.networkSettings.sideNavIPSet.value[language]}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="ipsetForm.get('Value').valid && ipsetForm.get('Value').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!ipsetForm.get('Value').valid && ipsetForm.get('Value').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="ipsetForm.get('Value').hasError('required') && ipsetForm.get('Value').touched">Campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{IPSet.Value ? IPSet.Value.length : 0}} / 50</mat-hint>
            </mat-form-field>
        </div>

        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">Cancelar</button>
            </div>

            <div class="col-6 full-width">
                <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!ipsetForm.valid">Guardar</button>
            </div>
        </div>
    </form>
</div>