<mat-sidenav-container class="main-container">
    <mat-sidenav 
        (closed)="finishSaving()" 
        #sideNav 
        [disableClose]="false" 
        position="end" 
        class="">
            <user-edition 
            [customerType]="'CUSTOMER'" 
            (savedUser)="savedUser($event)" 
            [sidenav]="sideNav" 
            [user]=selectedUser></user-edition>
    </mat-sidenav>
    <div class="container-fluid user-accounts-grid">
        <simple-grid #usersGrid [settings]=grid></simple-grid>
    </div>
</mat-sidenav-container>