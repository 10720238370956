import { environment } from "./../../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CsjService {
  private urlCSJ = environment.CSJ_URL;
  private baseUrl = "/api/v1/";
  private headers: HttpHeaders = new HttpHeaders({
    TypeCustom: "Bearer",
  });
  private CSJTOKEN_NAME: string = "csjtoken";

  constructor(private http: HttpClient) {}

  public getToken(): string {
    return localStorage.getItem(this.CSJTOKEN_NAME);
  }

  login(): Observable<any> {
    return this.http.post(`${this.urlCSJ}${this.baseUrl}auth/token`, {
      csjC: environment.CSJC,
    });
  }

  getMissingFilesToCopy(): Observable<any> {
    return this.http.get(
      `${this.urlCSJ}${this.baseUrl}diskFiles/getMissingFilesToCopy`,
      {
        headers: this.headers,
      }
    );
  }

  getTimeUseOfDisk(): Observable<any> {
    return this.http.get(
      `${this.urlCSJ}${this.baseUrl}diskFiles/getTimeUseOfDisk`,
      {
        headers: this.headers,
      }
    );
  }

  getDiskDetails(): Observable<any> {
    return this.http.get(
      `${this.urlCSJ}${this.baseUrl}diskFiles/getDiskDetails`,
      {
        headers: this.headers,
      }
    );
  }

  getInfoDisk(): Observable<any> {
    return this.http.get(
      `${this.urlCSJ}${this.baseUrl}openFaas/getListDiskInfo`,
      {
        headers: this.headers,
      }
    );
  }

  getReport(): Observable<any> {
    return this.http.get(`${this.urlCSJ}${this.baseUrl}diskFiles/getReport`, {
      headers: this.headers,
    });
  }

  getYesOriginNoDestination(): Observable<any> {
    return this.http.get(
      `${this.urlCSJ}${this.baseUrl}diskFiles/getYesOriginNoDestination`,
      {
        headers: this.headers,
      }
    );
  }
}
