import { Component, OnInit, NgModule, ViewChild} from '@angular/core';
import { Globalization } from '../../globalization/globalization';
import { HelpdeskDTO } from '../../models/dtos.component';
import { WlService } from '../../services/wlservice.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms'
import { NgForm } from '@angular/forms';
import { BrowserModule} from '@angular/platform-browser'
import { environment } from '../../../environments/environment';
import { NgClass, NgStyle} from '@angular/common';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-helpDesk',
    templateUrl: './helpDesk.component.html',
    styleUrls: ['./helpDesk.component.scss'],
})

export class HelpDeskComponent implements OnInit  {
  @ViewChild("fileInput") fileInput;
  searchDocument : any;
  globalization: any = Globalization;
  term : any;
  maxSizeFile = 5261359;
  public loading = false;
  p: any;
  token: string;
  total : any;
  language: string;
  selectedCustomers = [];
  customersType=[];
  HelpDeskMaterial = [];
  userFilter: any = { name: '' };
  document: HelpdeskDTO;
  validationMessage: string;
  documentLoading : boolean = true;

  public uploader:FileUploader = new FileUploader({});
  public hasBaseDropZoneOver:boolean = false;
  public hasAnotherDropZoneOver:boolean = false;

  public fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e:any):void {
    this.hasAnotherDropZoneOver = e;
  }

  constructor(private  service: WlService , public snackbar: MatSnackBar, public auth :AuthService){
    this.document = new HelpdeskDTO();
    this.validationMessage = "";
  }
  ngOnInit(){
    this.inicializate();
    this.getHelpDeskMaterial();
  }
  getHelpDeskMaterial(){
    this.service.getHelpDeskMaterial().subscribe(
      (response:any)=>{
        this.HelpDeskMaterial = response;
        this.documentLoading = false;
      },
      error=>{
        this.snackbar.open('Error consultando Material de ayuda' + error.error, null, {
            duration: 2000,
        });
      }
    )
  }
  restoreData (document: NgForm){
    document.reset();
    this.clearForm();
  }

  saveHelpDeskMaterial(document: NgForm){
    if(this.validationform(document))
        if(this.validationsize()) this.sendData(document);
        else
          this.snackbar.open('El tamaño del archivo excede el limite', null, {
            duration: 2000,
          });
    else
       this.snackbar.open('Por favor complete todos los campos obligatorios', null, {
        duration: 2000,
      });
  }
  validationform(document):boolean{
    return (document.valid
    && this.uploader.queue.length > 0
    && this.selectedCustomers.length > 0) ? true : false;
  }
  sendData(document): void{
    let params = {
      ExtensionFile : this.getExtensionFile(this.uploader.queue[0]._file.name),
      Type :"HelpDeskMaterial",
      Users: this.createCleanArray(this.selectedCustomers),
      Date : new Date(),
      Title : document.value.Title,
      Description : document.value.Description
    }
    this.service.uploadFile(this.uploader.queue[0]._file,"Domain/UploadhelpMaterial", params).subscribe(
      response=>{
          this.snackbar.open('Documento cargado correctamente', null, {
            duration: 2000,
          });
          document.onReset();
          this.clearForm();
          this.getHelpDeskMaterial();
      },
      error=>{
          this.snackbar.open('Error consultando Material de ayuda ' + error.error, null, {
              duration: 2000,
          });
      }
    )
  }
  changeCustomer(obj){
    const updateItem = this.selectedCustomers.find(this.findIndexToUpdate, obj.Identifier);
    const index = this.selectedCustomers.indexOf(updateItem);
    if (index > -1) {
      this.selectedCustomers.splice(index, 1);
    } else {
      this.selectedCustomers.push(obj);
    }
  }
  getExtensionFile(fileName){
    const extesion = fileName.split('.');
    return extesion[1];
  }
  findIndexToUpdate(obj) {
      return obj.Identifier === this;
  }
  inicializate(){
    this.token = this.auth.getToken();
    this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    this.customersType = [
      {Identifier: 'WHITE_LABEL', Label: 'HELPDESK.TOPICS_WHITELABEL'},
      {Identifier: 'SYSTEM_USER', Label: 'HELPDESK.TOPICS_SYSTEMUSER'},
      {Identifier: 'CUSTOMER_USER', Label: 'HELPDESK.TOPICS_CUSTOMER'}
    ];
  }
  createCleanArray(selectedCustomers):string[]{
    let customers = [];
    selectedCustomers.forEach(element => {
      customers.push(element.Identifier);
    })
    return customers;
  }

  clearForm(): void{
    for (let option of this.customersType) {
      option.checked = false;
    }
    this.selectedCustomers = [];
    if( this.uploader.queue[0]){
      this.uploader.queue[0].remove();
    }
  }
  downloadDocument(fileName :string) : void {
    let url = environment.serviceUrl+"SupportMaterial/"+fileName;
    window.open(url);
  }
  validationsize(): boolean{
    return (this.uploader.queue[0].file.size > this.maxSizeFile) ? false : true;
  }

}









