import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-activeDirectory',
    templateUrl: './activeDirectory.component.html',
    styleUrls: ['./activeDirectory.component.scss']
})
export class ActiveDirectoryComponent implements OnInit {

    ngOnInit(){

    }
}