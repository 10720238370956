import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from '../../../services/wlservice.service';
import { UserDTO,ServiceDTO } from '../../../models/dtos.component';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Constants } from '../../../models/constants.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { TermsComponent } from '../terms/terms.component';

@Component({
    selector: 'demo-services',
    templateUrl: './demo-services.component.html',
    styleUrls: ['../service-configuration/service-configuration.component.scss']
})

export class DemoServices implements OnInit {
    @Input() sidenav: MatSidenav;
    @Input() selectedUser: UserDTO;
    @Output() onSave = new EventEmitter<ServiceDTO>();
    @ViewChild('stepper') stepper : MatStepper;
    DatacenterID: string;
    loading: boolean = false;
    Os: number = null;
    constants: Constants = new Constants();
    confirmedTerms:boolean = false;
    bundleSelectFG:FormGroup;
    constructor(private service: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder,private dialog : MatDialog) {
        this.bundleSelectFG = this.formBuilder.group({
            'bundle': [{ value: null, disable: this.loading }, [Validators.required]],
            'datacenter': [{ value: null, disable: this.loading }, [Validators.required]]
        });
    }

    ShowTerms() {
        this.dialog.open(TermsComponent, {
            width: "60%"
        });
    }

    changeStep(index : number){
        this.stepper.selectedIndex = index;
    }

    savePlan() {
        this.loading = true;
        this.service.SaveTestService(this.DatacenterID, this.Os , this.selectedUser.ID).subscribe(
            (response:any) => {
                this.snackbar.open('Servicio creado satisfactoriamente.', null, {
                    duration: 3000,
                });
                this.changeStep(0);
                this.bundleSelectFG.reset();
                this.onSave.emit(response);
                this.loading = false;
                this.DatacenterID = null;
                this.Os = null;
                this.confirmedTerms = false;
                this.sidenav.close();
            },
            error => {
                this.loading = false;
                this.snackbar.open('Error creando servicio.' + error.error, null, {
                    duration: 4000,
                });
            }
        )

    }

    ngOnInit() {
        this.sidenav.closedStart.subscribe(() => {
            this.changeStep(0);
        })
    }
}