
import { JwtHelperService  } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import { AuthService } from './auth.service';

@Injectable()
export class SessionCheckService {
    constructor( private jwtHelper: JwtHelperService , public auth: AuthService ){};

    public validate( token:string ): Observable< any > {
        return Observable.interval(1000).map( (x) => this.jwtHelper.isTokenExpired(token) );
    }
}