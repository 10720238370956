import { Component, Input, Output,OnChanges, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from '../../../../services/wlservice.service';
import { AdditionalServiceDTO, ServiceDTO } from '../../../../models/dtos.component';
import { Constants } from '../../../../models/constants.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';

@Component({
    selector: 'disk-configuration',
    templateUrl: './disk-configuration.component.html',
    styleUrls: ['../../dataCenter.component.scss'],
})

export class DiskConfiguration implements OnInit,OnChanges {
    @Input() AdditionalService: AdditionalServiceDTO;
    @Input() sidenav:MatSidenav;
    @Input() Machine: ServiceDTO;
    @Output() savedAdditionalService = new EventEmitter<AdditionalServiceDTO>();
    backup : number = 1;
    constants : Constants = new Constants();
    public loading:boolean = false;
    diskConfigurationFb : FormGroup;
    constructor(private service: WlService, public snackbar: MatSnackBar, public formBuilder : FormBuilder) {
        this.diskConfigurationFb = this.formBuilder.group({
            'iops' : [{value: '', disabled:this.loading},[Validators.required]],
            'size' : [{value: '', disabled:this.loading},[Validators.required]],
            'backup' : [{value: '', disabled:this.loading},[Validators.required]],
        });
    }

    ngOnChanges(changes) {
        if (changes.AdditionalService && changes.AdditionalService.currentValue) {
            console.log("changes", changes);
            this.getDiskPrice();
        }
    }

    ngOnInit() {
        this.AdditionalService = new AdditionalServiceDTO();
    }

    saveDisk(){
        this.loading = true;
        this.service.AddAdditionalService(this.AdditionalService).subscribe(
            (response:any)=>{
                this.loading = false;
                this.snackbar.open("Cambios guardados satisfactoriamente", null, {
                    duration: 3000
                });
                this.sidenav.close();
                this.savedAdditionalService.emit(response);
            },
            error=>{
                this.loading = false;
                this.snackbar.open("Error guardando disco" + error.error, null, {
                    duration: 4000
                });
            }
        )
    }

    getDiskPrice(){
        this.loading=true;
        this.service.GetStorageCost(this.AdditionalService.Configuration).subscribe(
            (response: any)=>{
                this.loading=false;
                this.AdditionalService.Configuration = response;
            },
            error=>{
                this.loading=false;
            }
        )
    }
}

