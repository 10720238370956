<mat-progress-bar *ngIf="Loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div>
    <div>
        <button class="pull-right wl-grid-refresh-button" matTooltip="Refrescar" (click)="ReloadNetwork()" mat-icon-button>
            <mat-icon class="mat-24">autorenew</mat-icon>
        </button>
    </div>
    <mat-form-field class="full-width">
        <input matInput placeholder="Search" (keyup)="applyFilter($event.target.value)" #input>
    </mat-form-field>
    <div class="row full-width justify-content-center" *ngIf="changeOrder">
        <div>
            <button class="btn btn-success changeButtons" (click)="SaveNatRules()" [disabled]="Loading">Guardar cambios</button>
        </div>
        <div>
            <button class="btn btn-danger changeButtons" (click)="ReloadNetwork()" [disabled]="Loading">Descartar cambios</button>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource">
    <ng-container matColumnDef="Index">
        <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{dataSource.data.indexOf(row) + 1}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Description">
        <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Description}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Protocol">
        <mat-header-cell *matHeaderCellDef>Protocolo</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.Protocol}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="OriginalAddress">
        <mat-header-cell *matHeaderCellDef>IP Origen</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.OriginalAddress}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="OriginalPort">
        <mat-header-cell *matHeaderCellDef>Puerto Origen</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.OriginalPort}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="TranslatedAddress">
        <mat-header-cell *matHeaderCellDef>IP Destino</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.TranslatedAddress}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="TranslatedPort">
        <mat-header-cell *matHeaderCellDef>Puerto Destino</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.TranslatedPort}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Options">
        <mat-header-cell *matHeaderCellDef>Opciones</mat-header-cell>
        <mat-cell *matCellDef="let row">
            <button [matMenuTriggerFor]="menu" class="mat-icon-button" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="EditNatRule(row)">
                    <span>Editar</span>
                </button>
                <button mat-menu-item (click)="RemoveNatRuleModal(row)">
                    <span>Eliminar</span>
                </button>
            </mat-menu>

            <button mat-icon-button class="pull-right refresh-customer-button" (click)="RuleUp(dataSource.data.indexOf(row))" [disabled]="dataSource.data.indexOf(row) == 0" matTooltip="Subir regla" matTooltipPosition="below">
                <mat-icon>keyboard_arrow_up</mat-icon>
            </button>
            <button mat-icon-button class="pull-right refresh-customer-button" (click)="RuleDown(dataSource.data.indexOf(row))" [disabled]="dataSource.data.indexOf(row) == (dataSource.data.length - 1)" matTooltip="Bajar regla" matTooltipPosition="below">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </button>

        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <div>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </div>
</mat-table>
<mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"></mat-paginator>