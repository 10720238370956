<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="VirtualServer.ApplicationProfileId == null || VirtualServer.ApplicationProfileId == ''">{{'VIRTUAL_SERVER.ADD_APPLICATION_PROFILE' | translate}}</h5>
        <h5 *ngIf="VirtualServer.ApplicationProfileId != null && VirtualServer.ApplicationProfileId != ''">{{'VIRTUAL_SERVER.EDIT_APPLICATION_PROFILE' | translate}}</h5>
    </div>
    <form [formGroup]="virtualServerForm" (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'VIRTUAL_SERVER.CONFIGURATION_OF_THE_APPLICATION_PROFILE' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input name="VirtualServerName" id="VirtualServerName" formControlName="Name" [(ngModel)]="VirtualServer.Name" required matInput placeholder="{{'VIRTUAL_SERVER.NAME' | translate}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="virtualServerForm.get('Name').valid && virtualServerForm.get('Name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!virtualServerForm.get('Name').valid && virtualServerForm.get('Name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="virtualServerForm.get('Name').hasError('required') && virtualServerForm.get('Name').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{VirtualServer.Name ? VirtualServer.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VIRTUAL_SERVER.APPLICATION_PROFILE' | translate}}"
                  name="ApplicationProfileId" required id="ApplicationProfileId" formControlName="ApplicationProfileId" [(ngModel)]="VirtualServer.ApplicationProfileId" floatPlaceholder="always">
                  <mat-option *ngFor="let appProfile of AppProfiles" [value]="appProfile.ApplicationProfileId">{{appProfile.Name}}</mat-option>
              </mat-select>
              <div class="error-messages col-12">
                  <div *ngIf="virtualServerForm.get('ApplicationProfileId').hasError('required') && virtualServerForm.get('ApplicationProfileId').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
              </div>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VIRTUAL_SERVER.IP_ADDRESS' | translate}}"
                  name="IpAddress" required id="IpAddress" formControlName="IpAddress" [(ngModel)]="VirtualServer.IpAddress" floatPlaceholder="always">
                  <mat-option *ngFor="let publicip of PublicIps" [value]="publicip">{{publicip}}</mat-option>
              </mat-select>
              <div class="error-messages col-12">
                  <div *ngIf="virtualServerForm.get('IpAddress').hasError('required') && virtualServerForm.get('IpAddress').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
              </div>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <mat-select class="col-12 full-width margin-top-20" placeholder="{{'VIRTUAL_SERVER.PROTOCOL' | translate}}"
                  name="VirtualServerProtocol" id="VirtualServerProtocol" formControlName="Protocol" [(ngModel)]="VirtualServer.Protocol"
                  floatPlaceholder="always">
                  <mat-option [value]="'tcp'">TCP</mat-option>
                  <mat-option [value]="'http'">HTTP</mat-option>
                  <mat-option [value]="'https'">HTTPS</mat-option>
                  <mat-option [value]="'udp'">UDP</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <input name="VirtualServerPort" id="VirtualServerPort" formControlName="Port" [(ngModel)]="VirtualServer.Port" required matInput placeholder="{{'VIRTUAL_SERVER.PORT' | translate}}"
                  maxlength="50">
              <mat-icon matSuffix *ngIf="virtualServerForm.get('Port').valid && virtualServerForm.get('Port').touched" class="form-accepted">done</mat-icon>
              <mat-icon matSuffix *ngIf="!virtualServerForm.get('Port').valid && virtualServerForm.get('Port').touched" class="form-rejected">error</mat-icon>
              <mat-hint align="start">
                  <div class="error-messages">
                      <div *ngIf="virtualServerForm.get('Port').hasError('required') && virtualServerForm.get('Port').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
                  </div>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VIRTUAL_SERVER.DEFAULT_PORT' | translate}}"
                  name="DefaultPoolId" required id="DefaultPoolId" formControlName="DefaultPoolId" [(ngModel)]="VirtualServer.DefaultPoolId" floatPlaceholder="always">
                  <mat-option *ngFor="let pool of Pools" [value]="pool.PoolId">{{pool.Name}}</mat-option>
              </mat-select>
              <div class="error-messages col-12">
                  <div *ngIf="virtualServerForm.get('DefaultPoolId').hasError('required') && virtualServerForm.get('DefaultPoolId').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
              </div>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <input name="ConnectionLimit" id="ConnectionLimit" formControlName="ConnectionLimit" [(ngModel)]="VirtualServer.ConnectionLimit" required matInput placeholder="{{'VIRTUAL_SERVER.CONNECTION_LIMIT' | translate}}"
                  maxlength="50">
              <mat-icon matSuffix *ngIf="virtualServerForm.get('ConnectionLimit').valid && virtualServerForm.get('ConnectionLimit').touched" class="form-accepted">done</mat-icon>
              <mat-icon matSuffix *ngIf="!virtualServerForm.get('ConnectionLimit').valid && virtualServerForm.get('ConnectionLimit').touched" class="form-rejected">error</mat-icon>
              <mat-hint align="start">
                  <div class="error-messages">
                      <div *ngIf="virtualServerForm.get('ConnectionLimit').hasError('required') && virtualServerForm.get('ConnectionLimit').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
                  </div>
              </mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
              <input name="ConnectionRateLimit" id="ConnectionRateLimit" formControlName="ConnectionRateLimit" [(ngModel)]="VirtualServer.ConnectionRateLimit" required matInput placeholder="{{'VIRTUAL_SERVER.CONNECTIONRATELIMIT' | translate}}"
                  maxlength="50">
              <mat-icon matSuffix *ngIf="virtualServerForm.get('ConnectionRateLimit').valid && virtualServerForm.get('ConnectionRateLimit').touched" class="form-accepted">done</mat-icon>
              <mat-icon matSuffix *ngIf="!virtualServerForm.get('ConnectionRateLimit').valid && virtualServerForm.get('ConnectionRateLimit').touched" class="form-rejected">error</mat-icon>
              <mat-hint align="start">
                  <div class="error-messages">
                      <div *ngIf="virtualServerForm.get('ConnectionRateLimit').hasError('required') && virtualServerForm.get('ConnectionRateLimit').touched">{{'VIRTUAL_SERVER.REQUIRED_FIELD' | translate}}.</div>
                  </div>
              </mat-hint>
            </mat-form-field>

            <div class="row container-fluid sidenav-form-actionbuttons">
              <div class="col-6 full-width">
                  <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'VIRTUAL_SERVER.CANCEL' | translate}}</button>
              </div>

              <div class="col-6 full-width">
                  <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!virtualServerForm.valid">{{'VIRTUAL_SERVER.SAVE' | translate}}</button>
              </div>
            </div>
        </div>
    </form>
</div>
