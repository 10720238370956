import { Component, Input, Output, OnInit, OnChanges, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from '../../../../services/wlservice.service';
import { AppProfileDTO, AppProfileSaveRequestDTO, PersistenceAppProfileDTO } from '../../../../models/dtos.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';



@Component({
  selector: 'applicationProfile-settings',
  templateUrl: './application-profile.component.html',
  styleUrls: ['../networkSettings.scss']
})

export class ApplicationProfileComponent implements OnInit, OnChanges {
    @Input("ApplicationProfile") AppProfile: AppProfileDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("IpSets") ipSets: Array<any>;
    @Input("appGr") appGr: Array<any>;
    @Input("PrivateIps") PrivateIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Input("IsNew") IsNew : boolean = true;
    @Output() NewBindings = new EventEmitter();
    const: Constants = new Constants();
    loading: boolean = false;
    private validForm: boolean = false;
    saveRequest: AppProfileSaveRequestDTO = new AppProfileSaveRequestDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    appProfileForm: FormGroup;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.appProfileForm = this.formBuilder.group({
            'Name' : ['',[Validators.required]],
            'Template' : ['',[Validators.required]],
            'Method' : ['',[Validators.required]],
            'CookieName' : [{value: '', disabled: false},[]],
            'CookieMode' : [{value: '', disabled: false},[]],
            'Expire' : [{value: '', disabled: false},[]],

        });

        this.appProfileForm.valueChanges.subscribe(values => {
            if(values['Method'] === 'cookie'){
                this.appProfileForm.controls['CookieName'].setValidators([Validators.required]);
                this.appProfileForm.controls['CookieName'].updateValueAndValidity({emitEvent: false});
                this.appProfileForm.controls['CookieMode'].setValidators([Validators.required]);
                this.appProfileForm.controls['CookieMode'].updateValueAndValidity({emitEvent: false});
            }
            else{
                if(this.AppProfile.ApplicationProfileId == null)
                {
                    this.appProfileForm.controls['CookieName'].setValue('',{emitEvent: false});
                    this.appProfileForm.controls['CookieMode'].setValue('',{emitEvent: false});
                }

                this.appProfileForm.controls['CookieName'].setValidators([]);
                this.appProfileForm.controls['CookieName'].updateValueAndValidity({emitEvent: false});
                this.appProfileForm.controls['CookieMode'].setValidators([]);
                this.appProfileForm.controls['CookieMode'].updateValueAndValidity({emitEvent: false});
            }

            if(values['Method'] === 'none' || values['Method'] === '')
            {
                if(this.AppProfile.ApplicationProfileId == null)
                    this.appProfileForm.controls['Expire'].setValue('',{emitEvent: false});

                this.appProfileForm.controls['Expire'].setValidators([]);
                this.appProfileForm.controls['Expire'].updateValueAndValidity({emitEvent: false});
            }
            else{
                this.appProfileForm.controls['Expire'].setValidators([Validators.required]);
                this.appProfileForm.controls['Expire'].updateValueAndValidity({emitEvent: false});
            }
        });
    }

    resetAll() {
        this.loading = false;
        this.saveRequest = new AppProfileSaveRequestDTO();
    }

    ngOnInit() {
        this.AppProfile = new AppProfileDTO();
        this.AppProfile.Persistence = new PersistenceAppProfileDTO();
    }

    ngOnChanges(changes) {
        if(changes["IsNew"]){
            if(changes["IsNew"].currentValue){
                this.resetAll();
                this.IsNew = false;
            }
        }
    }

    saveRule() {
        this.loading = true;
        this.appProfileForm.disable();

        this.saveRequest.ApplicationProfile = this.AppProfile;
        this.saveRequest.DatacenterID = this.DatacenterID;
        this.wlservice.SaveAppProfile(this.saveRequest).subscribe(
            response => {
                this.appProfileForm.enable();
                this.loading = false;
                this.snackbar.open('App profile creada satisfactoriamente', null, {
                    duration: 3000,
                });

                this.sidenav.close();
            },
            error => {
                this.appProfileForm.enable();
                this.loading = false;
                this.snackbar.open('Error agregando app profile ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
