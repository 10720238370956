import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DnatRuleDto } from '../DTO/dnat-rules-dto';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFirewallPortRuleComponent } from '../firewall-nat-port-forward/new-firewall-port-rule/new-firewall-port-rule.component'

@Component({
  selector: 'app-firewall-nat-port-forward',
  templateUrl: './firewall-nat-port-forward.component.html',
  styleUrls: ['./firewall-nat-port-forward.component.scss']
})
export class FirewallNatPortForwardComponent implements AfterViewInit {
  @Input() item:DnatRuleDto[] = []; // decorate the property with @Input()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['Interface', 'Protocol', 'Source Address', 'Source Ports', 'Dest. Address','Dest. Ports', 'NAT IP', 'NAT Ports', 'Description'];
  dataSource: MatTableDataSource<DnatRuleDto>;
  
 
  constructor(public dialog: MatDialog) {

  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data =  this.item;
    }
  }

  newRule() {
    const dialogRef = this.dialog.open(NewFirewallPortRuleComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
   
    });

  }


}
