<div class="card">
  <div class="card-header card-header-color">
    <h4 class="card-title">Cambio de contraseña</h4>
  </div>
  <div class="card-block">
         <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <form #newPassValidation="ngForm" (ngSubmit)="ChangePassword()">
            <mat-form-field class="configInput">
                <input [(ngModel)]="OldPassword" name="OldPassword" required type="password" matInput placeholder="Contraseña actual">
            </mat-form-field><br>
            <mat-form-field class="configInput">
                <input [(ngModel)]="NewPassword" name="NewPassword" required type="password" matInput placeholder="Nueva contraseña">
            </mat-form-field><br>
            <mat-form-field class="configInput">
                <input [(ngModel)]="RepeatPassword" name="RepeatPassword" required type="password" matInput placeholder="Repetir Nueva contraseña">
            </mat-form-field><br>
            <mat-card-actions>
                <button class="btnPosFixCC" mat-button type="submit"  [disabled]="!newPassValidation.form.valid">GUARDAR</button>
            </mat-card-actions>
         </form>
  </div>
</div>

