<div class="row container-fluid blundle-quote-grid">
  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <mat-card style="position: fixed;">
      <mat-card-header class="card-header-component">
        <mat-card-title class="card-title-component">
          {{'MYQUOTES.MY_QUOTES'|translate}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="">
        <div class="san-list san-card-list">
          <mat-list class="customer-list">
            <mat-list-item  *ngFor="let item of items; let i=index" [ngClass]="{'active': item.isActive}" (click)="toggleItem(item)" class="minos-menu-item">
                <mat-icon mat-list-icon> {{item.icon}} </mat-icon>
                <span mat-line class="minos-menu-text">
                  {{item.name}}
                </span>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
    <div class="example-container">
      <div class="example-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'MYQUOTES.SEARCH_QUOTE'|translate}}...">
        </mat-form-field>
        <div class="pull-right">
          <button mat-menu-item  (click)="caculatorRoute()">
              <mat-icon>reply</mat-icon>
              {{'MYQUOTES.RETURN_CALCULATOR'|translate}}
            </button>
        </div>
      </div>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
      <mat-table *ngIf ="dataSource.data" #table [dataSource]="dataSource" class="wl-grid container-fluid">
        <!-- Codigo Column -->
        <ng-container matColumnDef="Cod">
          <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.CODE'|translate}}  </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.ID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="BusinessOpportunityID">
          <mat-header-cell *matHeaderCellDef> ID Oportunidad  </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.BusinessOpportunityID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Owner">
          <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.QUOTED_FOR'|translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.OwnerName}} </mat-cell>
        </ng-container>

        <!-- Cliente Column -->
        <ng-container matColumnDef="Client">
          <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.TITLE'|translate}}   </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="Description">
          <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.DESCRIPTION'|translate}}  </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Description}} </mat-cell>
        </ng-container>

        <!-- Cliente Titulo -->
        <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.CLIENT'|translate}}  </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.CustomerName}} </mat-cell>
          </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="Date">
          <mat-header-cell *matHeaderCellDef> {{'MYQUOTES.DATE'|translate}} </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.Date | date: 'medium' }} </mat-cell>
        </ng-container>

        <!-- Acciones Column -->
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <mat-header-cell *matHeaderCellDef> <mat-icon>apps</mat-icon> </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index;">
              <button [matMenuTriggerFor]="menuData" class="machine-actions-menu-{{i}} mat-icon-button" mat-icon-button>
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menuData="matMenu">
                <button mat-menu-item (click)="EditQuote(element.ID)">
                    <mat-icon>gradient</mat-icon>
                    <span> {{'MYQUOTES.LOAD_IN_THE_CALCULATOR'|translate}}</span>
                </button>
                <button mat-menu-item (click)="DeleteQuote(i)" *ngIf="element.Owner">
                    <mat-icon>delete_forever</mat-icon>
                    <span> {{'MYQUOTES.REMOVE'|translate}}</span>
                </button>
              </mat-menu>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  </div>

























