import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SupportContact, VpnDTO, ProfileDTO, Storage, PermissionDTO, LoginDTO, FirewallSaveRequestDTO,
  BundleDTO, ProductDTO, UserDTO, UserInfoDTO, ServiceAdditionalInfo, AdditionalServiceDTO,
  DHCPSaveRequestDTO, AppProfileSaveRequestDTO, PriceItemDTO, VirtualServerSaveRequestDTO,
  PoolSaveRequestDTO, IPSetSaveRequestDTO, VirtualMachineType } from '../models/dtos.component';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import { CloudServerCalculatorOut } from '../components/calculator-cloud-server/dto/cloud-server-dto';
import { v4 as uuid } from 'uuid';
import {BundleQuoteDTO} from '../components/calculator-cloud-server/dto/cloud-server-dto'
import { PfenseRequestDto } from '../components/dataCenter/pf-sense-network-settings/DTO/pfsenseRequest-dto';
import { RequestAliasFirewallDto} from '../components/dataCenter/pf-sense-network-settings/DTO/firewall-ip-dto';


@Injectable()
export class WlService {
    private serviceURL = environment.serviceUrl;
    public ModuleName = '';
    public Permissions: Array<string> = [];
    constructor(private http: HttpClient, private router: Router, public auth: AuthService) {
        this.ModuleName = '';
    }

    public GetGraphPool(customerId: string, serviceId: string) {
        return this.processData('chart/GetGraphPool', { CustomerId: customerId, ServiceId: serviceId });
    }

    public getQuestions() {
        return this.getData('Faq/GetFaq', '');
    }

    public GetProfiles() {
        return this.getData('Profile/GetProfileList', '');
    }

    public GetProducts() {
        return this.getData('Product/GetProducts', '');
    }

    public GetProductPrice(product: BundleDTO) {
        return this.processData('Calculator/GetSolutionPrice', product);
    }

    public GetBundleQuotesByUser(isWhiteLabel) {
        return this.processData('Calculator/GetBundleQuotesByUser', isWhiteLabel);
        // isWhiteLabel valida que la peticios es desde white label
    }

    public CalculatorSendEmail(product: any) {
        return this.processData('Product/CalculatorSendEmail', product);
    }

    public SaveProduct(product: ProductDTO) {
        return this.processData('Product/SaveProduct', product);
    }

    public RemoveProduct(product: ProductDTO) {
        return this.processData('Product/RemoveProduct', { ProductID: product.ID });
    }

    public changeProductState(product: ProductDTO) {
        return this.processData('Product/changeProductState', product);
    }

    public GetDomainInformation() {
        return this.getData('Domain/GetDomainInformation', '', null);
    }

    public GetUserInformation(CustomerID: string = null) {
        return this.processData('Customer/GetUserInformation', { CustomerID });
    }

    public GetUser(CustomerID: string) {
        return this.processData('Customer/GetCustomer', {userid: CustomerID});
    }
    public GenerateCredential(data: any) {
        return this.processData('Customer/GenerateCredential', data);
      }

    public getMenu() {
        return this.getData('Menu/GetMenu', '');
    }

    public getUsersAndDatacenters() {
      return this.getData('Menu/GetUsersAndDatacenters', '');
    }

    public login(loginDTO: LoginDTO) {
        return this.processData('Authentication/Login', loginDTO, null);
    }

    public rememberPassword(email: string) {
        return this.processData('Authentication/RememberPassword', { Email: email }, null);
    }

    public getCustomerAccounts(CustomerID: string = '') {
        return this.getData('Customer/getCustomerAccounts', CustomerID);
    }

    public getUserAccounts(CustomerID: string = '') {
        return this.getData('Customer/GetUserAccounts', CustomerID);
    }

    public getSystemUserAccounts(CustomerID: string = null) {
        return this.processData('Customer/GetSystemUserAccounts', { CustomerID });
    }

    public getAllUsers() {
        return this.getData('Customer/GetParentUsers', '');
    }

    public getChangeLog() {
        return this.getData('Domain/GetChangeLog', '', null);
    }

    public getHelpDeskMaterial() {
        return this.getData('Domain/GethelpMaterial', '');
    }

    public GetService(ServiceID: string) {
        return this.processData('VirtualMachine/GetService', { ServiceID });
    }

    public GetDataCenterMachines(DataCenterID: string = null, CustomerID: string = null) {
        return this.processData('VirtualMachine/GetServices', { User: CustomerID, VcenterID: DataCenterID });
    }

    public GetCustomerCloud(CustomerID: string) {
      return this.processData('Customer/GetCustomerClouds', { CustomerID });
    }

    public GetCustomerMachines(CustomerID: string) {
      return this.processData('VirtualMachine/GetServices', { User: CustomerID });
    }

    public GetAdditionalServices(MachineID: string) {
        return this.processData('VirtualMachine/GetAdditionalServices', { ServiceParentID: MachineID });
    }

    public GetAdditionalServicesByCustomer(CustomerID: string) {
        return this.processData('VirtualMachine/GetAdditionalServicesByCustomer', { CustomerID });
    }

    public PowerOnVm(ServiceID: string) {
        return this.processData('VirtualMachine/PowerOnVm', { ServiceID });
    }

    public PowerOffVm(ServiceID: string) {
        return this.processData('VirtualMachine/PowerOffVm', { ServiceID });
    }

    public SuspendVm(ServiceID: string) {
        return this.processData('VirtualMachine/SuspendVm', { ServiceID });
    }

    public ResumeVm(ServiceID: string) {
        return this.processData('VirtualMachine/ResumeVm', { ServiceID });
    }

    public RestartVm(ServiceID: string) {
        return this.processData('VirtualMachine/RestartVm', { ServiceID });
    }

    public GuestPowerOffVmAPI(ServiceID: string) {
        return this.processData('VirtualMachine/GuestPowerOffVmAPI', { ServiceID });
    }

    public GuestSuspendVmAPI(ServiceID: string) {
        return this.processData('VirtualMachine/GuestSuspendVmAPI', { ServiceID });
    }

    public GuestResetVMAPI(ServiceID: string) {
        return this.processData('VirtualMachine/GuestResetVMAPI', { ServiceID });
    }

    public SaveUser(user: UserDTO, domain: any = null) {
        return this.processData('Customer/SaveUser', { user, domain });
    }

    public RemoveUser(CustomerID: string) {
        return this.processData('Customer/DeleteUser', { CustomerID });
    }

    public ChangePassword(OldPassword: string, NewPassword: string) {
        return this.processData('Authentication/ChangePassword', { OldPassword, NewPassword });
    }

    public SaveUserInfo(userInfo: UserInfoDTO) {
        return this.processData('Customer/ChangeUserInformation', userInfo);
    }

    public GetOsList(VcenterID: string = null, OsType: number = null) {
        return this.processData('VirtualMachine/GetOsList', { VcenterID, OsType });
    }

    public SaveService(Bundle: BundleDTO, additionalServiceConfiguration: ServiceAdditionalInfo) {
        return this.processData('VirtualMachine/AddService', { serviceDefinition: Bundle,
          serviceAdditionalConfiguration: additionalServiceConfiguration });
    }

    public SaveTestService(DataCenterID: string, Os: number, CustomerID: string) {
        return this.processData('VirtualMachine/AddTestService', { DataCenterID, Os, CustomerID });
    }

    public GetNetworkInformation(cloudid: string) {
        return this.processData('network/getnetworkconfiguration', { CloudID: cloudid });
    }

    public GetEdge(datacenterid: string) {
        return this.processData('network/getEdgeByDatacenter', { DatacenterID: datacenterid });
    }

    public AddAdditionalService(additionalServiceDTO: AdditionalServiceDTO) {
        return this.processData('VirtualMachine/AddAdditionalService', additionalServiceDTO);
    }

    public AddDHCPRule(dto: DHCPSaveRequestDTO) {
        return this.processData('Network/SaveStaticBinding', dto);
    }

    public SaveNat(dto: any) {
        return this.processData('Network/SaveNatRule', dto);
    }

    public SaveAllNat(dto: any) {
        return this.processData('Network/SetNatRules', dto);
    }

    public RemoveDHCPRule(dto: DHCPSaveRequestDTO) {
        return this.processData('Network/DeleteStaticBinding', dto);
    }

    public RemoveNatRule(dto: any) {
        return this.processData('Network/DeleteNatRule', dto);
    }

    public SaveVpn(dto: any) {
        return this.processData('Network/SaveIpSec', dto);
    }

    public GetIpSecStats(datacenterid: string) {
        return this.processData('Network/GetIpSecStats', {DatacenterID: datacenterid});
    }

    public SaveRouting(dto: any) {
        return this.processData('Network/SaveRouting', dto);
    }

    public RemoveVpn(dto: VpnDTO, DataCenterID: string) {
        return this.processData('Network/DeleteIpSec', { Request: dto, DataCenterID });
    }

    public SaveAppProfile(dto: AppProfileSaveRequestDTO) {
        return this.processData('Network/AddAppProfile', dto);
    }

    public SaveIPSet(dto: IPSetSaveRequestDTO) {
        return this.processData('Network/SaveIPSet', dto);
    }

    public RemoveIPSet(dto: IPSetSaveRequestDTO) {
        return this.processData('Network/RemoveIPSet', dto);
    }

    public SaveVirtualServer(dto: VirtualServerSaveRequestDTO) {
        return this.processData('Network/AddVirtualServer', dto);
    }

    public RemoveAppProfile(dto: AppProfileSaveRequestDTO) {
        return this.processData('Network/RemoveAppProfile', dto);
    }

    public SavePool(dto: PoolSaveRequestDTO) {
        return this.processData('Network/AddPool', dto);
    }

    public RemovePool(dto: PoolSaveRequestDTO) {
        return this.processData('Network/RemovePool', dto);
    }

    public RemoveVirtualServer(dto: VirtualServerSaveRequestDTO) {
        return this.processData('Network/RemoveVirtualServer', dto);
    }

    public SaveFirewallRule(dto: FirewallSaveRequestDTO) {
        return this.processData('Network/SaveFirewallRule', dto);
    }

    public RemoveFirewallRule(dto: FirewallSaveRequestDTO) {
        return this.processData('Network/DeleteFirewallRule', dto);
    }

    public GetInterfacesStats(cloudid: string , interval: string) {

        return this.processData('Network/GetInterfacesStats', { CloudID: cloudid, interval });
    }

    public GetMachineToken(ServiceID: string) {
        return this.processData('VirtualMachine/GetMachineToken', { ServiceID });
    }

    public GetMachinePassword(ServiceID: string) {
        return this.processData('VirtualMachine/GetMachinePassword', { ServiceID });
    }

    public GetStorageCost(storage: Storage) {
        return this.processData('Calculator/GetStoragePrice', storage);
    }

    public GetPendingMachines() {
        return this.getData('VirtualMachine/GetPendingMachines', '');
    }

    public ResendUserMail(CustomerID: string, Email: string = '') {
        return this.processData('Customer/ResendUserMail', { CustomerID, Email });
    }

    public GetAvailablePermissions() {
        return this.getData('Profile/GetAvailablePermissions', '');
    }

    public GetProfilePermissions(ProfileID: string) {
        return this.processData('Profile/GetProfilePermissions', { ProfileID });
    }

    public SaveProfilePermissions(Permissions: Array<PermissionDTO>, ProfileID: string) {
        return this.processData('Profile/SaveProfilePermissions', { ProfileID, Permissions });
    }

    public SaveProfile(Profile: ProfileDTO) {
        return this.processData('Profile/SaveProfile', Profile);
    }

    public GetSupportContacts() {
        return this.getData('Domain/GetSupportContacts', '');
    }

    public EditFavoriteContact(supportContact: SupportContact) {
        return this.processData('Domain/EditFavoriteContact', supportContact);
    }

    public RemoveContact(supportContact: SupportContact) {
        return this.processData('Domain/RemoveContact', supportContact);
    }

    public SaveContact(supportContact: SupportContact) {
        return this.processData('Domain/SaveSupportContact', supportContact);
    }

    public GetFavouriteSupportContact() {
        return this.getData('Domain/GetFavouriteSupportContact', '');
    }

    public GetRelationAplicationsOS(PriceTypeID: number, Os: string) {
        return this.processData('Product/RelationProductMachineLicense', { PriceTypeID, Os });
    }

    public IncreasePoints(QuestionID: string) {
        return this.processData('Faq/IncreasePoints', { QuestionID });
    }

    public ChangeServiceName(ServiceID: string, Id: string, ServiceName: string = '') {
        return this.processData('VirtualMachine/RenameMachine', { ServiceID, ServiceName, Id });
    }

    public SaveBundleQuote(bundleQuoteDTO: BundleQuoteDTO) {
        return this.processData('Calculator/SaveBundleQuote', bundleQuoteDTO);
    }
    public DeleteBundleQuote(bundleQuoteDTO: BundleQuoteDTO) {

        return this.processData ('Calculator/DeleteBundleQuote', bundleQuoteDTO);
    }

    public GetUserAccounts() {
        return this.getData('customer', 'getdomaincustomers');
    }

    public SearchService(ServiceID: string, CustomerID: string) {
        return this.processData('VirtualMachine/GetErpServiceDetails', { ServiceID, CustomerID });
    }

    public TrackingRoute( CustomerID: string, Route: string ) {
        return this.processData('Customer/UpdateCustomerTrackingVisitsRoute', { CustomerID , Route});
    }

    public GetTrackinguUsers(Route: string ) {
        return this.processData('Customer/GetCustomerTrackingVisits', {Route});
    }

    public VerifyVirtualNetwork(DatacenterID: string) {
        return this.processData('Network/VerifyVirtualNetwork', {DatacenterId: DatacenterID});
    }

    public RefreshToken() {
        this.getData('Authentication/RefreshToken', this.ModuleName, false).subscribe(
            (response: any) => {
                this.auth.setToken(response.token);
                if (JSON.parse(atob(response.token.split('.')[1])).Permissions) {
                    this.Permissions = JSON.parse(JSON.parse(atob(response.token.split('.')[1])).Permissions);
                } else {
                    this.Permissions = [];
                }
            }, (error) => {
                if (error.status === 401) {
                    this.router.navigateByUrl('/');
                }
            }
        );
    }

    public getPriceList() {
        return this.getData('Calculator/GetPriceList', '');
    }

    public updatePriceList(item: PriceItemDTO) {
        return this.processData('Calculator/UpdatePriceList', item);
    }

    public GenerateToken(CustomerID) {
        return this.processData('Authentication/ImpersonateUser', { CustomerID });
    }




    public uploadFile(fileToUpload: any, url: string = '', params: any = '') {
        const Input = new FormData();
        Input.append('uploadFile', fileToUpload, fileToUpload.name);
        if (params) {
            if (params.Type === 'CertificateSSL') {
                Input.append('password', params.password);
            } else if (params.Type === 'HelpDeskMaterial') {
                Input.append('ExtensionFile', params.ExtensionFile);
                Input.append('Title', params.Title);
                Input.append('Description', params.Description);
                Input.append('Users', JSON.stringify(params.Users));
            }
        }
        return this.http.post(this.serviceURL + url, Input)
            .map((res: Response) => res)
            .catch((error: any) =>
               this.HandleError(error)
        );
    }


    public CloudServerCalculatorOut(cloudServerCalcObj: CloudServerCalculatorOut): any {
      return this.processData('CalculatorCloudServer/Calculate', cloudServerCalcObj);
    }


    public GetGenericListOptionsByListID(listName: string) : any {
      return this.processData('Calculator/GetGenericListOptionsByListID', { 'Name': listName });
    }

    public generateUUID(): string {
      return uuid();
    }

    public sendCalculatorCloudServerSendEmail(BundleQuote: any) {
      return this.processData('Product/CalculatorCloudServerSendEmail', BundleQuote);
    }

    public GetMenu(): any {
      return this.getData('menu', 'GetMinosMenu');
    }

    public getBundleQuoteByID(idBundleQuote: number) {
      return this.processData('Calculator/GetBundleQuoteByID', idBundleQuote);
    }

    public DeleteBundleQuoteByID(idBundleQuote) {
      // tslint:disable-next-line:radix
      const identifier = parseInt(idBundleQuote);
      return this.processData('Calculator/DeleteBundleQuoteById', identifier);
    }

    public SendBundleQuote(BundleQuoteDTO: any) {
      return this.processData('Product/CalculatorPreSaleSendEmail', BundleQuoteDTO);
    }

    public getNeworkPfsenseRules(pfsenseRequest : PfenseRequestDto){
        return this.processData('VirtualMachine/GetNetworkRules', pfsenseRequest);
    }
    public GetNetworkType(DataCenterID: string = null, CustomerID: string = null) {
      return this.processData('VirtualMachine/GetNetworkType', { VcenterID: DataCenterID });
    }

    public PfCreateAliasFirewall ( pfsenseRequest : RequestAliasFirewallDto ){
      return this.processData ('VirtualMachine/PfCreateAliasFirewall', pfsenseRequest);
    }



    private getData(url: string, params: string,  refreshToken: boolean = true ) {
        if (refreshToken) {
            this.RefreshToken();
        }
        return this.http.get(this.serviceURL + url + '/' + params)
            .map((res: Response) => res)
            .catch((error: any) =>
                this.HandleError(error)
        );
    }

    private processData(url: string, body: any, refreshToken: boolean = true) : any {
        if (refreshToken) {
             this.RefreshToken();
         }
        return this.http.post(this.serviceURL + url, body)
             .map((res: Response) => res)
             .catch((error: any) =>
                this.HandleError(error)
         );
     }

    private HandleError(error: Response | any) {
        try {
            // tslint:disable-next-line: deprecation
            return Observable.throw(error);
        } catch (ex) {
            // tslint:disable-next-line: deprecation
            return Observable.throw(error);
        }
    }

   


}
