import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { VpnDTO,  VPNSaveRequestDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';


@Component({
    selector: 'vpn-settings',
    templateUrl: './vpn-configuration.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class VpnConfiguration implements OnInit, OnChanges {
    @Input("VpnRule") Rule: VpnDTO;
    @Input("VpnRuleId") RuleID: string;
    @Input("DatacenterID") DatacenterID: string;
    @Input("PublicIps") PublicIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Output() NewBindings = new EventEmitter();
    constants: Constants = new Constants();
    localSubnets: string = "";
    peerSubnets: string = "";
    loading: boolean = false;
    globalization: any = Globalization;
    token: string;
    language: string;
    vpnForm : any;
    saveVPNRequest : VPNSaveRequestDTO = new VPNSaveRequestDTO();
    network: any = null;
    checked = false;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder:FormBuilder, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.vpnForm = this.formBuilder.group({
            'algorithm' : ['',[Validators.required]],
            'group' : ['',[Validators.required]],
            'habilitarPFS' : ['',[Validators.required]],
            'status' : ['',[Validators.required]],
            'localIp' : ['',[Validators.required]],
            'name' : ['',[Validators.required]],
            'editPassword': ['',[]],
            'password' : ['',[Validators.required, Validators.maxLength(64)]],
            'localSubnet' : ['',[Validators.required, Validators.maxLength(600), Validators.pattern(/^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9]{1,2}){0,1}(\/([0-9]|[1-2][0-9]|3[0-2]))\,?)*)-?)*$/)]],
            'peerIp' : ['',[Validators.required, Validators.maxLength(600)]],
            'destinationIp' : ['',[Validators.required, Validators.maxLength(600), Validators.pattern(/^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/[0-9]{1,2}){0,1}(\/([0-9]|[1-2][0-9]|3[0-2]))\,?)*)-?)*$/)]],
        });

    }

    ngOnInit() {
        this.Rule = new VpnDTO();
    }

    ngOnChanges(changes) {
        if (changes['Rule']) {
            this.localSubnets = changes['Rule'].currentValue.LocalSubnets.Subnet ? changes['Rule'].currentValue.LocalSubnets.Subnet.join(",") : "";
            this.peerSubnets = changes['Rule'].currentValue.PeerSubnets.Subnet ? changes['Rule'].currentValue.PeerSubnets.Subnet.join(",") : "";
        }
    }

    saveRule() {
        this.loading = true;
        this.vpnForm.disable();
        this.Rule.LocalSubnets.Subnet = this.localSubnets.split(',');
        this.Rule.PeerSubnets.Subnet = this.peerSubnets.split(',');
        this.Rule.LocalId = this.Rule.LocalIp;
        this.Rule.PeerId = this.Rule.PeerIp;
        this.network = null;
        if (!this.checked) {
            this.Rule.Psk = null;
        }
        console.log(this.RuleID);
        this.wlservice.GetNetworkInformation(this.DatacenterID).subscribe(
        response => {
            this.network = response;
            if(this.RuleID != null && !this.Rule.IsNew)
            {
                this.network.IpSec.Sites.Site.splice(this.RuleID,1);
            }
            this.network.IpSec.Sites.Site.push(this.Rule);
            this.network.IpSec.Enabled = 'true';
            this.saveVPNRequest.DatacenterID = this.DatacenterID;
            this.saveVPNRequest.IpSec = this.network.IpSec;
            this.wlservice.SaveVpn(this.saveVPNRequest).subscribe(
                (response) => {
                    this.vpnForm.enable();
                    this.snackbar.open('Regla de vpn guardada satisfactoriamente.', null, {
                        duration: 2000,
                    });
                    this.loading = false;
                    this.sidenav.close();
                    this.NewBindings.emit(response);
                },
                error => {
                    this.loading = false;
                    this.vpnForm.enable();
                    this.snackbar.open('Error guardando regla VPN ' + error.error, null, {
                        duration: 2000,
                    });
                }
            )
        },
        error => {
          console.error(error);
        });
    }
}
