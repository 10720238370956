import { Component, OnInit, AfterViewInit, ViewChild, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'src/app/services/excel/excel.service';
// import domtoimage from "dom-to-image";

@Component({
  selector: 'app-csj-modal',
  templateUrl: './csj-modal.component.html',
  styleUrls: ['./csj-modal.component.scss']
})
export class CsjModalComponent implements AfterViewInit
{
  private static instance: CsjModalComponent = null;
  name: string = "";
  dataGraph = [];

  selectedIndex: number = 0;

  view: any[] = [700, 380];
  xAxisLabel: string = "Días";
  yAxisLabel: string = "Numero de archivos faltantes";
  timeline: boolean = true;
  autoScale: boolean = true;
  unit: string = "Unidad";
  legendPosition: string = "below";
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  colorScheme = {
    domain: ["#F44336"],
  };

  showTab: boolean = false;
  dataSource = new MatTableDataSource();
  resultsLength = 10;
  displayedColumns = ["Disco", "Path", "Size"];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CsjModalComponent>,
    private excelService: ExcelService
  ) {
    this.name = data.name;
    this.dataGraph = [data.graphic];
    this.showTab = data.showTab;

    if (!this.showTab) {
      this.yAxisLabel = "Porcentaje de uso";
      this.xAxisLabel = "Fecha";
    }

    if (data.table.name) {
      this.dataSource.data = data.table.series;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  // Modal Dialog Action
  okClick() {
    this.dialog.close();
  }

  cancelClick() {
    this.dialog.close();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  exportToExcel() {
    this.excelService.exportAsJsonExcelFile(
      this.dataSource.data,
      "Archivos faltantes"
    );
  }

  downloadToJpeg(id:string) {
    // domtoimage.toJpeg(document.getElementById(id), { bgcolor: '#FFF' })
    // .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = id + '.jpeg';
    //     link.href = dataUrl;
    //     link.click();
    // });
  }

}

