<mat-sidenav-container class="main-container">
    <mat-sidenav (closed)="resetSidenav()" #sideNav position="end">
        <service-settings #SerSet [hidden]="sidenavMode != 'service'" (onSave)="savedService($event)" [sidenav]="sideNav" [Service]="selectedService"></service-settings>
        <user-edition [hidden]="sidenavMode != 'user'" [customerType]="'SYSTEM_USER'" (savedUser)="savedUser($event)" [sidenav]="sideNav"
            [user]="selectedUser"></user-edition>
        <demo-services [selectedUser]="user" (onSave)="savedService($event)" [hidden]="sidenavMode != 'demoservice'" [sidenav]="sideNav"></demo-services>
    </mat-sidenav>
    <div class="row margin-top-10">
        <div class="col-lg-3">
            <div class="row margin-bottom-20 wl-info-container">
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-xs-12 margin-bottom-10 wl-info-box-container">
                    <div class="row wl-info-box wl-account-status ">
                        <div class="col-4 no-default-padding wl-left-icon">
                            <mat-icon class="wl-info-icon">account_balance_wallet</mat-icon>
                        </div>
                        <div class="col-8 no-default-padding wl-left-icon-text">
                            <div class="wl-account-status-title">Estado de cuenta</div>
                            <div class="wl-account-status-value">{{user.Cost | currency:'USD':true:'2.0-2'}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-xs-12  margin-bottom-10 wl-info-box-container">
                    <div class="row wl-info-box wl-active-machines">
                        <div class="col-4 no-default-padding wl-left-icon">
                            <mat-icon class="wl-info-icon">developer_board</mat-icon>
                        </div>
                        <div class="col-8 no-default-padding wl-left-icon-text">
                            <div class="wl-account-status-title">Máquinas activas</div>
                            <div class="wl-account-status-value">{{user.ServiceCount}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-xs-12 margin-bottom-10 wl-info-box-container">
                    <div class="row wl-info-box wl-pending-cancelations">
                        <div class="col-4 no-default-padding wl-left-icon">
                            <mat-icon class="wl-info-icon">sentiment_neutral</mat-icon>
                        </div>
                        <div class="col-8 no-default-padding wl-left-icon-text">
                            <div class="wl-account-status-title">Cancelaciones pendientes</div>
                            <div class="wl-account-status-value">{{user.pendingCancellations}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-xs-12 margin-bottom-10 wl-info-box-container">
                    <div class="row   wl-info-box wl-active-customers">
                        <div class="col-4 no-default-padding wl-left-icon">
                            <mat-icon class="wl-info-icon">sentiment_very_satisfied</mat-icon>
                        </div>
                        <div class="col-8 no-default-padding wl-left-icon-text">
                            <div class="wl-account-status-title">Usuarios activos</div>
                            <div class="wl-account-status-value">{{user.ActiveCustomers}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9">
            <mat-tab-group>
                <mat-tab label="Usuarios">
                    <div class="container-fluid margin-top-10">
                        <simple-grid #UsersGrid [settings]=usersGrid></simple-grid>
                    </div>
                </mat-tab>
                <mat-tab label="Servicios">
                    <div class="container-fluid margin-top-10">
                        <mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
                        <div>
                            <div>
                                <button class="pull-right wl-grid-refresh-button" matTooltip="Refrescar" (click)="getServices()" mat-icon-button>
                                    <mat-icon class="mat-24">autorenew</mat-icon>
                                </button>
<!--                                 <button class="pull-right wl-grid-refresh-button" matTooltip="Agregar servicio" (click)="AddService()" mat-icon-button>
                                    <mat-icon class="mat-24">add_circle</mat-icon>
                                </button> -->
                                <button class="pull-right wl-grid-refresh-button" matTooltip="Servicio demo" *ngIf="userRole == 'WHITE_LABEL'" (click)="AddFreeService()" mat-icon-button>
                                    <mat-icon class="mat-24">add_circle_outline</mat-icon>
                                </button>
                            </div>
                            <mat-form-field >
                                <mat-select placeholder="Search" class="full-width" (keyup)="applyFilter($event.target.value)" #input>
                                  <mat-option value="option">Option</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <mat-table #table [dataSource]="dataSource">
                            <ng-container matColumnDef="State">
                                <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <mat-icon style="cursor: help" [ngClass]="GetColorState(row.State)" matTooltipPosition="right">
                                        fiber_manual_record
                                    </mat-icon>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="ServiceName">
                                <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <div matTooltip="{{row.ServiceName}}">
                                        {{row.ServiceName}}
                                    </div>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="ServiceID">
                                <mat-header-cell *matHeaderCellDef>Service ID</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.ServiceID}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Cores">
                                <mat-header-cell *matHeaderCellDef>Cores</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.Configuration.Cores.Value}} </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="RAM">
                                <mat-header-cell *matHeaderCellDef>RAM</mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.Configuration.Ram.Value}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Options">
                                <mat-header-cell *matHeaderCellDef>Opciones</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                    <button [matMenuTriggerFor]="menu" class="mat-icon-button" mat-icon-button>
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="this.router.navigateByUrl('/home/(main:MachineSettings/' + row.ServiceID + ')', { skipLocationChange: true })" *ngIf="row.State != 'PENDING'">
                                            <span>Ver configuración </span>

                                        </button>
                                    </mat-menu>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></mat-header-row>
                            <div>
                                <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
                            </div>
                        </mat-table>
                        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"></mat-paginator>
                    </div>
                </mat-tab>
                <mat-tab label="Servicios Adicionales">
                    <div class="container-fluid margin-top-10">
                        <simple-grid #AdditionalServicesGrid [settings]=additionalServicesGrid></simple-grid>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</mat-sidenav-container>
