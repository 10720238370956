import { Component } from '@angular/core';

export class FaqQuestion {
  public ID: string;
  public Answer: string;
  public Color: string;
  public CustomerName: string;
  public Icon: string;
  public Language: string;
  public Module: string;
  public Points: number;
  public Question: string;
  public show: boolean;
  constructor() {
    this.ID = null;
    this.Answer = '';
    this.Color = '';
    this.CustomerName = null;
    this.Icon = '';
    this.Language = 'es-co';
    this.Module = null;
    this.Points = 0;
    this.Question = '';
    this.show = false;
  }
}

export class SupportContact {
  public ID: string;
  public Name: string;
  public Telephone: string;
  public Email: string;
  public Country: string;
  constructor() {
    this.ID = null;
    this.Name = '';
    this.Telephone = '';
    this.Email = '';
    this.Country = '';
  }
}

export class ProfileDTO {
  public ID: string;
  public Name: string;
  constructor() {
    this.ID = null;
    this.Name = '';
  }
}

export class PermissionDTO {
  public ID: string;
  public Template: string;
  public Children: Array<PermissionDTO>;
  public isChecked: boolean;
  constructor() {
    this.ID = null;
    this.Template = null;
    this.Children = [];
    this.isChecked = false;
  }
}

export class ServiceAdditionalInfo {
  BundleID: string;
  CustomerID: string;
  constructor() {
    this.BundleID = null;
    this.CustomerID = null;
  }
}
export class RoutingSaveRequestDTO {
  StaticRouting: any;
  DatacenterID: string;
  constructor() {
    this.StaticRouting = null;
  }
}

export class RoutingDTO {
  Description: string;
  Network: string;
  NextHop: string;
  Vnic: string;
  Mtu: string;
  AdminDistance: string;
  Type: string;
  constructor() {
    this.Description = null;
    this.Network = null;
    this.NextHop = null;
    this.Vnic = null;
    this.Mtu = null;
    this.AdminDistance = null;
    this.Type = 'user';
  }
}
export class VPNSaveRequestDTO {
  IpSec: any;
  DatacenterID: string;
  constructor() {
    this.IpSec = null;
  }
}
export class VpnDTO {
  Enabled: boolean;
  Name: string;
  LocalId: string;
  LocalIp: string;
  PeerId: string;
  PeerIp: string;
  EncryptionAlgorithm: string;
  EnablePfs: boolean;
  DhGroup: string;
  Psk: string;
  AuthenticationMode: string;
  LocalSubnets: SubnetDTO;
  IsNew: boolean;
  PeerSubnets: SubnetDTO;
  constructor() {
    this.Enabled = null;
    this.Name = null;
    this.LocalId = null;
    this.IsNew = false;
    this.LocalIp = null;
    this.PeerId = null;
    this.PeerIp = null;
    this.EncryptionAlgorithm = null;
    this.EnablePfs = null;
    this.DhGroup = null;
    this.Psk = null;
    this.AuthenticationMode = null;
    this.LocalSubnets = new SubnetDTO();
    this.PeerSubnets = new SubnetDTO();
  }
}

export class SubnetDTO {
  Subnet: Array<string>;
  constructor() {
    this.Subnet = [];
  }
}
export class FirewallApplication {
  protocol: string;
  port: string;
  constructor() {
    this.protocol = null;
    this.port = null;
  }
}
export class DHCPSaveRequestDTO {
  staticBinding: DHCPDTO;
  DatacenterID: string;
  constructor() {
    this.staticBinding = new DHCPDTO();
  }
}
export class AllNATSaveRequestDTO {
  nat: NATSaveRequestDTO;
  DatacenterID: string;
  constructor() {
    this.nat = new NATSaveRequestDTO();
  }
}
export class NATSaveRequestDTO {
  natRules: NATRequestDTO;
  DatacenterID: string;
  constructor() {
    this.natRules = new NATRequestDTO();
  }
}
export class NATRequestDTO {
  natRule: Array<NatDTO>;
  constructor() {
    this.natRule = [];
  }
}

export class VirtualServerSaveRequestDTO {
  VirtualServer: VirtualServerDTO;
  DatacenterID: string;
  constructor() {
    this.DatacenterID = '';
    this.VirtualServer = new VirtualServerDTO();
  }
}

export class PoolSaveRequestDTO {
  Pool: PoolDTO;
  DatacenterID: string;
  constructor() {
    this.DatacenterID = '';
    this.Pool = new PoolDTO();
  }
}

export class AppProfileSaveRequestDTO {
  ApplicationProfile: AppProfileDTO;
  DatacenterID: string;
  constructor() {
    this.DatacenterID = '';
    this.ApplicationProfile = new AppProfileDTO();
  }
}

export class FirewallSaveRequestDTO {
  firewallRules: FirewallRequestDTO;
  DatacenterID: string;
  constructor() {
    this.DatacenterID = '';
    this.firewallRules = new FirewallRequestDTO();
  }
}
export class FirewallRequestDTO {
  Rule: Array<FirewallDTO>;
  constructor() {
    this.Rule = [];
  }
}

export class VirtualServerDTO {
  AccelerationEnabled: boolean;
  ApplicationProfileId: string;
  ApplicationProfileName: string;
  ConnectionLimit: number;
  ConnectionRateLimit: number;
  DefaultPoolId: string;
  DefaultPoolName: string;
  EnableServiceInsertion: boolean;
  Enabled: boolean;
  IpAddress: string;
  Name: string;
  Port: string;
  Protocol: string;
  VirtualServerId: string;
  constructor() {
    this.AccelerationEnabled = null;
    this.ApplicationProfileId = null;
    this.ApplicationProfileName = null;
    this.ConnectionLimit = 0;
    this.ConnectionRateLimit = 0;
    this.DefaultPoolId = null;
    this.DefaultPoolName = null;
    this.EnableServiceInsertion = null;
    this.Enabled = null;
    this.IpAddress = '';
    this.Name = '';
    this.Port = '';
    this.Protocol = '';
    this.VirtualServerId = '';
  }
}

export class AppProfileDTO {
  ApplicationProfileId: string;
  InsertXForwardedFor: boolean;
  Name: string;
  Persistence: PersistenceAppProfileDTO;
  ServersSslEnabled: boolean;
  SslPassthrough: boolean;
  Template: string;
  constructor() {
    this.ApplicationProfileId = null;
    this.InsertXForwardedFor = null;
    this.Name = '';
    this.Persistence = new PersistenceAppProfileDTO();
    this.ServersSslEnabled = null;
    this.SslPassthrough = null;
    this.Template = '';
  }
}

export class PersistenceAppProfileDTO {
  CookieMode: string;
  CookieName: string;
  Expire: string;
  Method: string;
  constructor() {
    this.CookieMode = '';
    this.CookieName = '';
    this.Expire = '';
    this.Method = '';
  }
}

export class MonitorDTO {
  Interval: number;
  MaxRetries: number;
  MonitorId: string;
  Name: string;
  Timeout: number;
  Type: string;
  constructor() {
    this.Interval = null;
    this.MaxRetries = null;
    this.MonitorId = null;
    this.Name = '';
    this.Timeout = null;
    this.Type = '';
  }
}

export class PoolDTO {
  Algorithm: string;
  Member: Array<PoolServerDTO>;
  MonitorId: string;
  MonitorName: string;
  Name: string;
  PoolId: string;
  Transparent: boolean;
  constructor() {
    this.Algorithm = '';
    this.Member = [];
    this.MonitorId = null;
    this.MonitorName = null;
    this.Name = '';
    this.PoolId = null;
    this.Transparent = null;
  }
}

export class PoolServerDTO {
  MemberId: string;
  IpAddress: string;
  Weight: number;
  MonitorPort: number;
  Port: number;
  MaxConn: number;
  MinConn: number;
  Condition: string;
  Name: string;
  constructor() {
    this.MemberId = '';
    this.IpAddress = '';
    this.Weight = 1;
    this.MonitorPort = null;
    this.Port = null;
    this.MaxConn = 0;
    this.MinConn = 0;
    this.Condition = 'enabled';
    this.Name = '';
  }
}

export class IPSetDTO {
  ObjectId: string;
  Name: string;
  Description: string;
  Value: string;
  Revision: number;
  constructor() {
    this.ObjectId = null;
    this.Name = '';
    this.Description = '';
    this.Value = '';
    this.Revision = null;
  }
}

export class IPSetSaveRequestDTO {
  IPSet: IPSetDTO;
  DatacenterID: string;
  constructor() {
    this.DatacenterID = '';
    this.IPSet = new IPSetDTO();
  }
}

export class FirewallDTO {
  id: number;
  Name: string;
  ruleType: string;
  Enabled: boolean;
  loggingEnabled: boolean;
  description: string;
  Action: string;
  Source: FirewallSourceDTO;
  Destination: FirewallSourceDTO;
  Application: Array<FirewallApplication>;
  Port: string;
  SourceIP: string;
  constructor() {
    this.id = null;
    this.Port = '';
    this.Name = null;
    this.ruleType = null;
    this.Enabled = null;
    this.loggingEnabled = true;
    this.description = null;
    this.Action = 'accept';
    this.Source = new FirewallSourceDTO();
    this.Destination = new FirewallSourceDTO();
    this.Application = [];
    this.SourceIP = '';
  }
}

export class FirewallSourceDTO {
  exclude: string;
  groupingObjectId: Array<string>;
  IpAddress: Array<string>;
  vnicGroupID: string;
  constructor() {
    this.exclude = null;
    this.groupingObjectId = [];
    this.IpAddress = [];
    this.vnicGroupID = null;
  }
}

export class NatDTO {
  Action: string;
  RuleId: number;
  RuleTag: string;
  OriginalAddress: string;
  TranslatedAddress: string;
  Description: string;
  Protocol: string;
  OriginalPort: string;
  TranslatedPort: string;
  DnatMatchSourceAddress: string;
  DnatMatchSourcePort: string;
  constructor() {
    this.Action = 'dnat';
    this.RuleId = null;
    this.RuleTag = '0';
    this.OriginalAddress = null;
    this.TranslatedAddress = null;
    this.Description = null;
    this.Protocol = null;
    this.OriginalPort = null;
    this.TranslatedPort = null;
    this.DnatMatchSourceAddress = null;
    this.DnatMatchSourcePort = null;
  }
}

export class DHCPDTO {
  MacAddress: string;
  BindingId: string;
  DefaultGateway: string;
  Hostname: string;
  IpAddress: string;
  PrimaryNameServer: string;
  SecondaryNameServer: string;
  SubnetMask: string;
  ServiceID: string;
  constructor() {
    this.BindingId = null;
    this.DefaultGateway = null;
    this.Hostname = null;
    this.IpAddress = null;
    this.PrimaryNameServer = '200.62.2.180';
    this.SecondaryNameServer = '200.62.2.181';
    this.SubnetMask = '255.255.255.0';
  }
}

export class UserInfoDTO {
  public ID: string;
  public Name: string;
  public Email: string;
  public UserName: string;
  public LastUpdate: Date;
  public Language: string;
  public Cost: number;
  public ServiceCount: number;
  public pendingCancellations: number;
  public ActiveCustomers: number;
  public Initials:string
  constructor() {
    this.ID = null;
    this.Language = 'es-co';
    this.Name = '';
    this.Email = '';
    this.UserName = '';
    this.LastUpdate = new Date();
    this.Initials = '';
  }
}

export class UserDTO {
  public ID: string;
  public DomainName: string;
  public CustomerTypeID: string;
  public Name: string;
  public LastName: string;
  public Identification: string;
  public Active: boolean;
  public Login: string;
  public Address: string;
  public Telephone: string;
  public Email: string;
  public ParentID: string;
  public RoleID: string;
  public RoleInt: string;
  public Language: string;
  public IsDemo: boolean;
  public CreatedDate: Date;
  public UpdateDate: Date;
  public CustomerID: number;
  public ServiceID: number;
  public TotalCost: number;
  public Calculator: boolean;
  constructor() {
    this.ID = null;
    this.CustomerTypeID = null;
    this.DomainName = null;
    this.Name = null;
    this.LastName = '';
    this.Identification = null;
    this.Active = true;
    this.Login = null;
    this.Address = null;
    this.Telephone = null;
    this.Email = null;
    this.ParentID = null;
    this.RoleID = null;
    this.Language = null;
    this.IsDemo = false;
    this.CreatedDate = new Date();
    this.UpdateDate = new Date();
    this.CustomerID = null;
    this.ServiceID = null;
    this.TotalCost = null;
    this.Calculator = false;
  }
}


export interface UserQuoteDTO {
  ID?: string;
  DomainName: string;
  CustomerTypeID: string;
  Name: string;
  LastName: string;
  Identification: string;
  Active: boolean;
  Login: string;
  Address: string;
  Telephone: string;
  Email: string;
  ParentID: string;
  RoleID: string;
  RoleInt: string;
  Language: string;
  IsDemo: boolean;
  CreatedDate: Date;
  UpdateDate: Date;
  CustomerID: number;
  ServiceID: number;
  TotalCost: number;
  Calculator: boolean;
}

export class LoginDTO {
  public UserName: string;
  public Password: string;
  constructor() {
    this.UserName = '';
    this.Password = '';
  }
}

export class MenuDTO {
  public Id: number;
  public Children: Array<MenuDTO>;
  public leaf: string;
  public Icon: string;
  constructor() {
    this.Id = 0;
    this.Children = [];
    this.leaf = '';
  }
}

export class InterfaceDTO {
  public Label: string;
  public Name: string;
  public AddressGroups: Array<AddressGroupDTO>;
  public Mtu: string;
  public Type: string;
  public PortGroupId: string;
  public PortGroupName: string;
  public Index: string;
  public IsConnected: string;
}

export class AddressGroupDTO {
  public primaryAddress: string;
  public subnetMask: string;
  public subnetPrefixLength: string;
}

export class ServiceDTO {
  public ID: string;
  public ServiceType: string;
  public ServiceName: string;
  public Configuration: Machine;
  public State: string;
  public ServiceID: number;
  public VcenterID: string;
  public Duration: number;
  public CustomerID: string;
  public BundleID: string;
  public Progress: number;
  public ErrorMessage: string;
  public vCenterVersion: string;
  constructor() {
    this.ID = null;
    this.ServiceType = null;
    this.ServiceName = '';
    this.Configuration = new Machine();
    this.State = null;
    this.ServiceID = null;
    this.VcenterID = null;
    this.Duration = null;
    this.CustomerID = null;
    this.BundleID = null;
    this.Progress = 0.0;
    this.ErrorMessage = null;
    this.vCenterVersion = null;
  }
}

export class ProductDTO {
  public ID: string;
  public ProductType: string;
  public Name: string;
  public State: boolean;
  public Configuration: BundleDTO;
  public Updated: Date;
  public IsSaving = false;
  public shown = false;
  public Description: string;
  public Image: string;
  public VirtualizationProviderID: number;
  constructor() {
    this.ID = null;
    this.ProductType = null;
    this.Name = null;
    this.State = true;
    this.Configuration = new BundleDTO();
    this.Updated = null;
    this.Description = null;
    this.Image = '/assets/img/gralServer.png';
    this.VirtualizationProviderID = 1;
  }
  public clearProduct() {
    this.Name = '';
    this.Description = '';
    this.Configuration = new BundleDTO();
  }
}

export class BundleDTO {
  ID: number;
  Title: string;
  BundleMachine: Machine;
  BundleServices: Array<any>;
  BundleLicenses: Array<any>;
  Cost: any;

  constructor() {
    this.ID = null;
    this.BundleMachine = new Machine();
    this.BundleServices = null;
    this.BundleLicenses = null;
    this.Cost = new BundleCost();
  }
}

export class BundleQuoteDTO {
  ID: number;
  Name: string;
  CustomerID: string;
  CustomerName: string;
  CloudConfiguration: Array<BundleDTO>;
  ConfigurationString: string;
  Status: boolean;
  Date: Date;
  Description: string;
  PlanDiscountCount: number;
  constructor() {
    this.ID = null;
    this.CustomerID = null;
    this.CloudConfiguration = [];
    this.Name = '';
    this.CustomerName = '';
    this.Status = true;
    this.Date = new Date();
    this.Description = '';
    this.ConfigurationString = '';
    this.PlanDiscountCount = 0;
  }
}


export class BundleCost {
  public _CAPEX: number;
  public _OPEX: number;
  public CAPEX: number;
  public OPEX: number;
  public MRC: number;
  public DiscountMRC: number;
  constructor() {
    this._CAPEX = 0;
    this._OPEX = 0;
    this.CAPEX = 0;
    this.OPEX = 0;
    this.MRC = 0;
    this.DiscountMRC = 0;
  }
}

export class Machine {
  public ID: string;
  public Duration: number;
  public Cores: Cores;
  public Ram: Ram;
  public Hypervisor: Hypervisor;
  public Availability: Ha;
  public Network: Networking;
  public MachineStorage: [Storage];
  public OperativeSystem: OperativeSystem;
  public DataBase: any;
  public MachineName: string;
  public RemoteAccess: any;
  public PrivateIp: string;
  public MacAddress: string;
  public MachineLicenses: Array<MachineLicense>;
  public MachineServices: Array<MachineService>;
  public Monitoring: BundleCost;
  public ServerProtection: ServerProtection;
  public Cost: BundleCost;
  public Vcenter: string;
  public ServiceID: string;
  constructor() {
    this.ID = null;
    this.Duration = null;
    this.Cores = new Cores();
    this.Ram = new Ram();
    this.Hypervisor = new Hypervisor();
    this.Availability = new Ha();
    this.Network = new Networking();
    this.MachineStorage = [new Storage()];
    this.OperativeSystem = new OperativeSystem();
    this.DataBase = new DataBase();
    this.MachineName = '';
    this.RemoteAccess = '';
    this.MacAddress = null;
    this.MachineLicenses = [new MachineLicense()];
    this.MachineServices = [new MachineService()];
    this.Monitoring = new BundleCost();
    this.ServerProtection = new ServerProtection();
    this.Cost = new BundleCost();
    this.Vcenter = null;
    this.ServiceID = null;
  }
}

class ServerProtection {
  public Active: boolean;
  public Cost: BundleCost;
  constructor() {
    this.Active = true;
    this.Cost = new BundleCost();
  }

}

class MachineService {
  public LicenseName: string;
  public LicenseType: string;
  public Quantity: number;
  public Cost: BundleCost;
  constructor() {
    this.LicenseName = '';
    this.LicenseType = '2';
    this.Cost = new BundleCost();
  }
}

export class MachineLicense {
  public Identifier: string;
  public Quantity: number;
  public LicenseType: number;
  public Cost: BundleCost;
  constructor() {
    this.Identifier = 'WINRDP';
    this.Quantity = 0;
    this.LicenseType = 0;
    this.Cost = new BundleCost();
  }
}

class DataBase {
  public Db: number;
  public Name: string;
  public Cost: BundleCost;
  constructor() {
    this.Cost = new BundleCost();
    this.Name = '';
  }
}

class Networking {
  public localNetworkType: number;
  public Internet: number;
  public Cost: BundleCost;
  constructor() {
    this.localNetworkType = 0;
    this.Internet = 5;
    this.Cost = new BundleCost();
  }
}

class Ha {
  public availabilityType: number;
  public replicationServer: number;
  public Cost: BundleCost;
  constructor() {
    this.availabilityType = 0;
    this.replicationServer = null;
    this.Cost = new BundleCost();
  }
}

class Hypervisor {
  public plataform: number;
  public Cost: BundleCost;
  constructor() {
    this.plataform = 1;
    this.Cost = new BundleCost();
  }
}

class Cores {
  public Value: number;
  public Cost: BundleCost;
  constructor() {
    this.Value = 2;
    this.Cost = new BundleCost();
  }
}

class Ram {
  public Value: number;
  public Cost: BundleCost;
  constructor() {
    this.Value = 2;
    this.Cost = new BundleCost();
  }
}

export class AdditionalServiceDTO {
  public ID: string;
  public ServiceParentID: string;
  public Configuration: Storage;
  public ServiceType: string;
  public CreationDate: Date;
  public ExpirationDate: Date;
  public ServiceID: number;
  public CancelState: string;
  public State: boolean;
  constructor() {
    this.ID = null;
    this.ServiceParentID = null;
    this.Configuration = new Storage();
    this.ServiceType = 'DISK';
    this.CreationDate = new Date();
    this.ExpirationDate = null;
    this.ServiceID = null;
    this.CancelState = null;
    this.State = true;
  }
}

class Backup {
  public Month: number;
  public Cost: BundleCost;
  constructor() {
    this.Month = 1;
    this.Cost = new BundleCost();
  }
}
export class Storage {
  public diskType: number;
  public size: number;
  public Backup: Backup;
  public database: boolean;
  public Cost: BundleCost;
  public SqlLicense: boolean;
  constructor() {
    this.diskType = 100;
    this.size = 100;
    this.database = false;
    this.Backup = new Backup();
    this.Cost = new BundleCost();
  }
}


class OperativeSystem {
  public Os: string;
  public Cost: BundleCost;
  constructor() {
    this.Cost = new BundleCost();
    this.Os = '';
  }
}

export class HelpdeskDTO {
  public Title: string;
  public Description: string;
  public File: string;
  public Users: string[];
  constructor() {
    this.Title = '';
    this.Description = '';
    this.File = '';
    this.Users = [];
  }
}
export class OptionsCalculator {
  ID: number;
  NAME: string;
  constructor() {
    this.ID = 0;
    this.NAME = 'seleccione una opción';
  }
}


export class serverCalculatorList {

  Name: string;
  AdditionalService: any;
  AditionalIPs: any;
  Disk: [Storage];
}

export class PriceItemDTO {
  public IDPL: string;
  public item: PriceItemDetail;

  constructor() {
    this.IDPL = '';
    this.item = new PriceItemDetail();
  }
}

export class VirtualMachineType {
  category: string;
  virtualMachineTypeName: string;
  machineList: [ServiceDTO];
  constructor() {
    this.category = '';
    this.virtualMachineTypeName = '';
    this.machineList = [new ServiceDTO()];
  }
}


export class PriceItemDetail {
  Name: string;
  Identifier: string;
  MRC: number;
  CAPEXReuse: number;
  CAPEXReal: number;
  OPEXReal: number;
  OPEXReuse: number;
  PriceTypeID: number;
  IDPT: number;
  Relation: string;
  MaxCores: number;
  SAL: boolean;
  Active: boolean;

  constructor() {
    this.Name = '';
    this.Identifier = '';
    this.MRC = null;
    this.CAPEXReal = null;
    this.OPEXReal = null;
  }
}

export class CustomerMachinesService {
  public category: string;
  public virtualMachineTypeName: string;
  public machinesList: Array<ServiceDTO>;
  constructor() {
    this.category = null;
    this.virtualMachineTypeName = null;
    this.machinesList = new Array<ServiceDTO>();
  }
}

