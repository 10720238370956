import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { productFamily } from './productFamily';
import { CredentialDTO } from '../../models/credential';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WlService } from 'src/app/services/wlservice.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
  public credentialForm: FormGroup;
  public credential: CredentialDTO;
  public products = productFamily;
  public passwordVisible: boolean;
  public isLoading: boolean;
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  constructor(private snackbar: MatSnackBar,
    private service: WlService,
    private auth: AuthService,
    public router: Router,
    public _fbuilder: FormBuilder
  ) {}

  ngOnInit() {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['']);
      return false;
    }
    this.passwordVisible = false;
    this.credential = new CredentialDTO();
    this.isLoading = false;
    this.credentialForm = this._fbuilder.group({
      'usermail': ['', [Validators.email, Validators.required]],
      'product': ['', [Validators.required]],
      'password': ['', [Validators.required]],
    });
  }
  SendCredential() {
    this.isLoading = true;
    this.service.GenerateCredential(this.credential).subscribe(response => {
      this.isLoading = false;
      this.credential = response;
      this.stepper.next();
      this.ShowSnackbar("Credencial generada satisfactoriamente");
    }, error => {
      this.isLoading = false;
    });

  }
  ShowSnackbar(message: string) {
    this.snackbar.open(message, null, {
      duration: 3000
    });
  }

}
