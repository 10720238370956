export  class DhcpServerDto
{
    public mac : string;
    public cid : string;  
    public ipaddr : string;  
    public hostname : string;
    public descr: string;  
    public arp_table_static_entry: string;
    public filename : string
    public rootpath : string
    public defaultleasetime: string
    public maxleasetime :string
    public gateway :string
    public domain : string
    public domainsearchlist : string 
    public ddnsdomain :string
    public ddnsdomainprimary : string
    public ddnsdomainkeyname : string
    public ddnsdomainkey : string
    public tftp : string
    public ldap : string
    public dnsserver : Array<string> ;
}