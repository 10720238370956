import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class PfSenseMenuService {

  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'FIREWALL',
      icon: 'fa fa-server',
      active: false,
      type: 'dropdown',
      link: 'aliases',
      submenus: [
        {
          title: 'ALIAS',
          active: false,
          icon : 'fa fa-globe',
          type: 'simple',
          link: 'aliases'
        },
        {
          title: 'NAT',
          icon : 'fa fa fa-globe',
          active: false,
          type: 'simple',
          link: 'nat'
        },
        {
          title: 'FW',
          icon : 'fa fa fa-globe',
          active: false,
          type: 'simple',
          link: 'rules',
        }
      ]
    },
    {
      title: 'SERVICES',
      icon: 'fa fa-cog',
      active: false,
      type: 'dropdown',
      link: 'dhcp',
      submenus: [
        {
          title: 'DHCP SERVICES',
          icon :'fa fa fa-globe',
          component : "Administration",
          link: 'dhcp'
        }
      ]
    },
    {
      title: 'Extra',
      type: 'header'
    }
  ];
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
