<mat-card class="event-card-container mt-4">
  <div class="custom-card-decoration-switch" style="justify-content: center">
    <p class="text-light mt-3">Gráficos generales en línea de tiempo</p>
  </div>
  <div class="text-center">
    <small><strong>Última actualización:</strong> {{ maxDate }}</small>
  </div>
  <div class="update-dashboard-container p-0" style="right: 0; top: 0">
    <button
      mat-icon-button
      class="pull-right"
      (click)="refresh()"
      matTooltip="{{ 'CSJ.REFRESH' | translate }}"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="row justify-content-center" style="gap: 2rem">
    <div class="col-11 mt-4 col-lg-auto ngx-charts-line-h shadow">
      <button
        class="box-md-20 pull-right wl-grid-refresh-button btnExport shadow"
        matTooltip="Exportar Graficas"
        (click)="downloadToJpeg('missingFiles')"
        mat-icon-button
      >
        <span class="material-icons md-24 al-top">get_app</span>
      </button>
      <div id="missingFiles" class="px-1 py-3">
        <h5 class="my-3 text-center">Gráfico de archivos faltantes por día</h5>
        <ngx-charts-line-chart
          [view]="view"
          [scheme]="colorScheme"
          [legend]="true"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [xAxisLabel]="''"
          [yAxisLabel]="'Número de archivos'"
          [autoScale]="autoScale"
          [results]="data"
          [legendTitle]="'Discos'"
          [legendPosition]="'below'"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
    <div class="col-11 mt-4 col-lg-auto ngx-charts-line-h shadow">
      <button
        class="box-md-20 pull-right wl-grid-refresh-button btnExport shadow"
        matTooltip="Exportar Graficas"
        (click)="downloadToJpeg('percentOfDisk')"
        mat-icon-button
      >
        <span class="material-icons md-24 al-top">get_app</span>
      </button>
      <div id="percentOfDisk" class="px-1 py-3">
        <h5 class="my-3 text-center">
          Gráfico porcentaje de uso de los discos
        </h5>
        <ngx-charts-line-chart
          [view]="view"
          [scheme]="colorScheme"
          [legend]="true"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [xAxisLabel]="''"
          [yAxisLabel]="'Porcentaje de uso'"
          [autoScale]="autoScale"
          [results]="multi"
          [legendTitle]="'Discos'"
          [yScaleMin]="0"
          [yScaleMax]="100"
          [legendPosition]="'below'"
        >
        </ngx-charts-line-chart>
      </div>
    </div>
    <div class="col-12 px-0 shadow">
      <h5 class="mt-3 mb-0 text-center">Información de los discos</h5>
      <div class="padding-card">
        <div class="px-3">
          <button
            mat-icon-button
            class="pull-right add-customer-cloud btnExport shadow"
            (click)="exportToExcel(dataSource.data)"
            matTooltip="{{ 'CSJ.TO_EXPORT' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>save</mat-icon>
          </button>
          <mat-form-field style="width: 100%">
            <input
              matInput
              (keyup)="applyFilter($event.target.value)"
              placeholder="{{ 'CSJ.SEARCH' | translate }}..."
            />
          </mat-form-field>
          <div class="row">
            <div class="col-12">
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="Unit">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell">
                    <div class="full-width ellipsis-on-row">Disco</div>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{ row.unit }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="T_FILESORIGEN">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Total <br />
                      Archivos Origen
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(
                          row.columns,
                          "counting",
                          "Total Archivos Origen Unidad"
                        )
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="T_FILESDESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Total <br />
                      Archivos Destino
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(
                          row.columns,
                          "counting",
                          "Total Archivos Destino Unidad"
                        )
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FILES_SIORIGEN_SIDESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Archivos [SIORIGEN_SIDESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(
                          row.columns,
                          "counting",
                          "[SIORIGEN_SIDESTINO]"
                        )
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FILES_SIORIGEN_NODESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Archivos [SIORIGEN_NODESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(
                          row.columns,
                          "counting",
                          "[SIORIGEN_NODESTINO]"
                        )
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="FILES_NOORIGEN_SIDESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Archivos [NOORIGEN_SIDESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(
                          row.columns,
                          "counting",
                          "[NOORIGEN_SIDESTINO]"
                        )
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="S_FILES_SIORIGEN_SIDESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Tamaño Archivos (GB) [SIORIGEN_SIDESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(row.columns, "size", "[SIORIGEN_SIDESTINO]")
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="S_FILES_SIORIGEN_NODESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Tamaño Archivos (GB) [SIORIGEN_NODESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(row.columns, "size", "[SIORIGEN_NODESTINO]")
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="S_FILES_NOORIGEN_SIDESTINO">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Tamaño Archivos (GB) [NOORIGEN_SIDESTINO]
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{
                        getValue(row.columns, "size", "[NOORIGEN_SIDESTINO]")
                      }}
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                  class="minos-header-row"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <mat-paginator
                [length]="resultsLength"
                [pageSizeOptions]="[8, 16]"
                [showFirstLastButtons]="true"
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
