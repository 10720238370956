import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { WlService } from '../services/wlservice.service';
import { MenuDTO, UserInfoDTO } from '../models/dtos.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BroadcastEmitter } from '../services/BroadcastEmitter';
import { AuthService } from '../services/auth/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public menu = new MenuDTO();
  private token: string;
  public userName = '';
  public menuLoading = true;
  public headerText = '';
  private subscription: Subscription;
  public actions = [];
  private language: string;
  public languageSelect = '';
  public displayMenu = false;
  user: UserInfoDTO = new UserInfoDTO();
  tokenPayload: any;
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  constructor(private service: WlService,
    private router: Router,
    private broadcast: BroadcastEmitter,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    public translate: TranslateService,
    private appService: AppService) {


    translate.addLangs(['en', 'es']);
    this.translate.setDefaultLang('es');
    this.subscription = this.broadcast.GetMessage()
      .subscribe(message => {
        setTimeout(() => {
          if (message.text) {
            this.headerText = message.text;
          }
          if (message.actions) {
            this.actions = message.actions;
          }
          if (message.display) {
            this.displayMenu = message.display;
          } else {
            this.displayMenu = false;
          }
        }, 0);
      });
    this.tokenPayload = this.auth.tokenPayload(this.auth.getToken());
  }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  ngOnInit() {
    this.token = this.auth.getToken();
    this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    if (this.language.indexOf('en') !== -1) {
      this.translate.use('en');
      this.languageSelect = 'HOME.ENGLISH';
    } else {
      this.languageSelect = 'HOME.SPANISH';
      this.translate.use('es');
    }
    if (this.token == null) {
      this.router.navigateByUrl('/');
    } else {
      this.getMenu();
      this.getUser();
      try {
        this.userName = JSON.parse(atob(this.token.split('.')[1])).UserName;
      } catch (ex) { this.userName = null; }
    }
  }
  @HostListener('window:beforeunload')
  doSomething() {
    this.auth.setToken(this.token);
  }

  getUser() {
    this.service.GetUserInformation()
      .subscribe((response: any) => {
        this.user = response;
        this.user.Initials = this.user.Name.split(' ').map(function (e, i) { if (i < 2) { return e.substring(0, 1).toUpperCase() } else { return '' } }).join('')
      }, (error) => {
        this.translate.get('HOME.USER_NOT_FOUND').subscribe((response) => {
          this.snackBar.open(response + ': ' + error, null, {
            duration: 5000,
          });
        });
      });
  }
  changeLang(lang) {
    this.translate.use(lang);
    if (lang === 'es') {
      this.user.Language = 'es-co';
      this.languageSelect = 'HOME.SPANISH';
    } else {
      this.user.Language = 'en-us';
      this.languageSelect = 'HOME.ENGLISH';
    }
    this.service.SaveUserInfo(this.user)
      .subscribe(() => {
        this.translate.get('HOME.LANGUAGE_SUCCESSFULLY_MODIFIED').subscribe((response) => {
          this.snackBar.open(response, null, {
            duration: 2000,
          });
        });
      }, (error) => {
        this.translate.get('HOME.ERROR_UPDATING_USER_LANGUAGE').subscribe((response) => {
          this.snackBar.open(response + error, null, {
            duration: 2000,
          });
        });
      });
  }

  logout() {
    this.auth.clearStorage();
    this.router.navigateByUrl('/');
  }
  goHome() {
    this.headerText = '';
    this.actions = [];
    this.router.navigateByUrl('/home/(main:desktop)', { skipLocationChange: true });
  }

  getMenu() {
    this.service.getMenu()
      .subscribe((response: any) => {
        this.menu = response;
        console.log("menu1", this.menu);
        this.service.getUsersAndDatacenters()
          .subscribe((dataUsers: any) => {
            if (dataUsers && dataUsers.length > 0) {
              this.menu.Children.forEach((data) => {
                if (data.leaf === 'dataCenter') {
                  data.Children = dataUsers;
                }

                console.log("menu" , this.menu);
                return data;
              });
            }
            this.menuLoading = false;
            const start = this.GetFirstChild(this.menu);
            this.router.navigateByUrl('/home/(main:' + start + ')', { skipLocationChange: true });
          }, (error) => {
            this.menuLoading = false;
            if (error.status === 401) {
              this.router.navigateByUrl('/');
            }
          });
      }, (error) => {
        this.menuLoading = false;
        if (error.status === 401) {
          this.router.navigateByUrl('/');
        }
      }
      );
  }
  GetFirstChild(data: any): string {
    try {
      if (data.Children.length === 0 || data.leaf === 'desktop') {
        return data.leaf;
      } else {
        return this.GetFirstChild(data.Children[0]);
      }
    } catch (error) {
      console.error(error);
      return data.leaf;
    }
  }
  menuClick(component) {
    if (component.Children && component.Children.length !== 0) {
      return;
    }
    this.headerText = '';
    this.actions = [];
    if (!component.ParentCustomerID) {
      this.auth.setToken(this.token);
    }
    if (component.ParentCustomerID) {
      this.service.GenerateToken(component.ID).subscribe(
        (response: any) => {
          this.auth.setToken(response.token);
          this.router.navigateByUrl('/home/(main:childrenUsers/' + encodeURIComponent(component.ID)
            + '/' + true + ')', { skipLocationChange: true });
        }
      );
    } else if (component.Id) {
      this.router.navigateByUrl('/home/(main:dataCenter/' + encodeURIComponent(component.Id) + '/' + false + ')',
        { skipLocationChange: true });
    } else {
      this.router.navigateByUrl('/home/(main:' + component.leaf + ')', { skipLocationChange: true });
    }
    this.sidenav.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setCountryLeafName(countryName: string) {
    switch (countryName) {
      case 'Colombia TMK':
        return 'Colombia';
      case 'Colombia67':
        return 'Colombia';
      case 'Colombia vcdtc':
        return 'Colombia';
      default:
        return countryName;
    }
  }
}
