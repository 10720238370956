
import {Storage, BundleCost}  from './dto/machine-dto';
import {
  TotalDatacenterCost, CloudServerCalculatorIn, ResumenMRC, ResumenCapex, ResumenOpex,
  GenericListOption, CloudServerCalculatorOut, MachineLicense, ServerProtection,IselectProperty, CloudserverEmailDTO, BundleDTO, IcurrencyOptions,InternalRole, BundleQuoteDTO, BundleCloudQuoteDto
} from './dto/cloud-server-dto';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WlService } from '../../services/wlservice.service';
import { ConfirmationDialog } from '../../tools/modals/confirmation/modal.component';
import { sendMailDialog } from '../../tools/modals/sendMail/modal.component';
import { CloudServerEditValuesComponent } from './modals/edit-values/cloud-server-edit-values.component';
import { ServerProtectionComponent } from './modals/server-protection/server-protection.component';
import { SaveBundleQuoteModalComponent } from './modals/save-bundle-quote/saveBundleQuoteModal.component';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-calculator-cloud-server',
  templateUrl: './calculator-cloud-server.component.html',
  styleUrls: ['./calculator-cloud-server.component.scss']
})

export class CalculatorCloudServerComponent implements OnInit {

  @Output() addCloudServerInResumen = new EventEmitter<any>();
  @Output() addOrRemoveResumen = new EventEmitter();
  @Output() deleteDataCompleteResumen = new EventEmitter<any>();

  public roleActions: any[] = [];
  public internalRole: Array<InternalRole> = [];
  public cloudServerCalcInObj: CloudServerCalculatorIn;
  public cloudServerCalcOutObj: CloudServerCalculatorOut = new CloudServerCalculatorOut();
  public bundleQuoteDto = new BundleCloudQuoteDto();
  public BundleQuote = new BundleQuoteDTO();
  public resumenMRC: ResumenMRC = new ResumenMRC();
  public resumenOpex: ResumenOpex = new ResumenOpex();
  public resumenCapex: ResumenCapex = new ResumenCapex();
  public step = 5;
  public calculatorFormGroup: FormGroup;
  public diskSlider = 100;
  public loading: boolean = false;
  public isChasisChecked: boolean = false;
  public isSophosChecked: boolean = true;
  public isDcAlternoChecked: boolean = false;
  public count = 1;
  public bundleDtoList = new CloudserverEmailDTO();
  public dataSendMail: object = {};
  sizeMessage = true;
  color = '#2581b1';
  texColor = '#fff';
  colortitle = '#1b5979';
  color2 = '#e9e7e7';
  texColor2 = '#000';
  public currencyOptions: IcurrencyOptions = {
    align: 'left',
    allowNegative: false,
    allowZero: true,
    decimal: ',',
    precision: 0,
    prefix: '$ ',
    suffix: ' USD',
    thousands: '.'
  };
  public bdList: GenericListOption[] = [];
  public ramList: GenericListOption[] = [];
  public rdpList: GenericListOption[] = [];
  public coreList: GenericListOption[] = [];
  public chasisList: GenericListOption[] = [];
  public tipoUsoList: GenericListOption[] = [];
  public internetList: GenericListOption[] = [];
  public gbRecursosList: GenericListOption[] = [];
  public osRecursosList: GenericListOption[] = [];
  public replicationList: GenericListOption[] = [];
  public iopsRecursosList: GenericListOption[] = [];
  public periodoContratoList: GenericListOption[] = [];
  public serverProtectionList: GenericListOption[] = [];
  public resumenCalculatorList: CloudServerCalculatorIn[] = [];
  public dataCenterTypeList: GenericListOption[] = [];
  public PriceTYPEList = [];
  public idBundleQuote: number = null;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: WlService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.cloudServerCalcInObj = new CloudServerCalculatorIn();
  }

  ngOnInit(): void {
    this.GetPermission();
    this.getGenericOptionsList();

    this.idBundleQuote = this.activatedRoute.snapshot.paramMap.get('idBundleQuote') ?
        parseInt(this.activatedRoute.snapshot.paramMap.get('idBundleQuote'), 10) : null;
      if (this.idBundleQuote !== null) {
        this.getBundleQuoteByID(this.idBundleQuote);
      }
    this.loadExcelInformation();
    this.modalVDI();
    this.calculatorFormGroup = this.formBuilder.group({
      Uso: ['', Validators.required],
      Cores: ['', Validators.required],
      Ram: ['', Validators.required],
      Gb: ['', Validators.required],
      Iops: ['', Validators.required],
      Internet: ['', Validators.required],
      validateChasis: ['', Validators.required],  // Es el parametro AutorizaPresidencia Si o no
      OS: ['', Validators.required],
      BaseDatos: ['', Validators.required],
      Rdp: ['', Validators.required],
      Sophos: ['', Validators.required],
      DcAlterno: ['', Validators.required],
      Agente: ['', Validators.required],
      PeriodoContrato: ['', Validators.required],
      Datacenter: ['', Validators.required],
      CAPEXType:  [ '', Validators.required],
      OPEXType:   [ '', Validators.required]
    });
  }
   GetPermission() {
    this.service.GetMenu().subscribe(
      (response) => {
        this.internalRole = response;
        this.roleActions = response.filter(d => d.label === 'Midas');
        console.log("roleActions", this.roleActions);
      }, (error) => {
        console.error(error);
      }
    );
  }
  GetActions(identifier: number): boolean {
    if (this.roleActions.length > 0) {
      return this.roleActions.filter(item => item.actions.some(s => s.ID === identifier)).length > 0 ? true : false;
    }
  }

  onChanges(editMRC: boolean  =false): void {
    this.loadExcelInformation(editMRC);
  }

  DcAlterno(){
    if(!this.isDcAlternoChecked){
      this.cloudServerCalcOutObj.Agente = 'N/A';
    }
    this.onChanges();
  }


  compareObjectSelect(data?: IselectProperty) {
    return data ? data.Name : undefined;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.ID === o2.ID;
  }

  loadExcelInformation(editMRC: boolean = false ): void {
    this.loading = true;
    this.setSlideToogleValue();
    this.checkSettings();
    this.cloudServerCalcOutObj.editMRC  = (editMRC) ? true : false;
    this.service.CloudServerCalculatorOut(this.cloudServerCalcOutObj).subscribe(
      (response: CloudServerCalculatorIn) => {
        console.log('responseCloud', response );
        this.cloudServerCalcInObj = response;
        this.cloudServerCalcInObj.resumenCapex = response.resumenCapex;
        this.cloudServerCalcInObj.resumenOpex = response.resumenOpex;
        this.cloudServerCalcInObj.resumenMrc = response.resumenMrc;
        this.cloudServerCalcInObj.Cost  = this.TypeCost(response);
        if (editMRC) {
          this.cloudServerCalcInObj.Cost.MRC =  response.resumenMrc.MrcManual;
        } else {
          this.cloudServerCalcInObj.Cost.MRC = response.resumenMrc.MrcSugerido;
        }

        if (!this.cloudServerCalcInObj.configValidation) {
          this.modalLicenceseDB();
        }

        this.cloudServerCalcInObj.Cost.DiscountMRC = response.resumenMrc.MrcSugerido;
        this.cloudServerCalcInObj.currency =  'USD';
        this.cloudServerCalcInObj.objCloudServerOut =  response.objCloudServerOut;
        this.loading = false;
      });
  }

  TypeCost(response: CloudServerCalculatorIn): BundleCost {
    const cost = new BundleCost();

    if (response.objCloudServerOut.OPEXType === 'Real') {
      cost.OPEX = response.resumenOpex.Total;

    } else {
      cost._OPEX = response.resumenOpex.Total;
    }

    if (response.objCloudServerOut.CAPEXType === 'Real') {
      cost.CAPEX = response.resumenCapex.Total;

    } else {
      cost._CAPEX = response.resumenCapex.Total;
    }
    return cost;
  }

  getGenericOptionsList(): void {
    this.service.GetGenericListOptionsByListID('tipoUsoListCloudServer').subscribe(
      (response: GenericListOption[]) => {
        this.tipoUsoList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('coresRecursosCloudServer').subscribe(
      (response: GenericListOption[]) => {
        this.coreList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('ramRecursosCloudServer').subscribe(
      (response: GenericListOption[]) => {
        this.ramList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('internetRecursosCloud').subscribe(
      (response: GenericListOption[]) => {
        this.internetList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('gbRecursosCloudServer').subscribe(
      (response: GenericListOption[]) => {
        this.gbRecursosList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('iopsRecursosCloudServer').subscribe(
      (response: GenericListOption[]) => {
        this.iopsRecursosList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('becauseChasisRecurosCloud').subscribe(
      (response:GenericListOption[]) => {
        this.chasisList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('osLicenciamientoCloudServer').subscribe(
      (response : GenericListOption[]) => {
        this.osRecursosList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('bdLicenciamientoCloudServer').subscribe(
      (response : GenericListOption[]) => {
        this.bdList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('rdpLicenciamientoCloudServer').subscribe(
      (response : GenericListOption[]) => {
        this.rdpList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('serverProtectionLicenciamientoCloudServer').subscribe(
      (response : GenericListOption[]) => {
        this.serverProtectionList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('replicationContiniudadCloudServer').subscribe(
      (response : GenericListOption[]) => {
        this.replicationList = response;
      }
    );
    this.service.GetGenericListOptionsByListID('periodo_contratado_cloud_server').subscribe(
      (response : GenericListOption[]) => {
        this.periodoContratoList = response;
      }
    );

    this.service.GetGenericListOptionsByListID('datacenter_type').subscribe(
      (response : GenericListOption[]) => {
        this.dataCenterTypeList = response;
      }
    );

    this.service.GetGenericListOptionsByListID('PriceTYPE').subscribe(
      (response : GenericListOption[]) => {
        this.PriceTYPEList = response;
      }
    );
  }

  totaldata(): BundleCost {
    const totalCost = new BundleCost();
    this.resumenCalculatorList.forEach(element => {
      if (element.addResumen) {
        totalCost.MRC += element.Cost.MRC;
        totalCost.CAPEX += element.Cost.CAPEX;
        totalCost.OPEX += element.Cost.OPEX;
        totalCost._OPEX += element.Cost._OPEX;
        totalCost._CAPEX += element.Cost._CAPEX;
      }
    });
    return totalCost;
  }


  addCloudServerCalculatorOut(): void {
    if (this.resumenCalculatorList.length === 0) {
      this.count = 1;
    }
    this.cloudServerCalcInObj.UUID = this.service.generateUUID();
    this.cloudServerCalcInObj.Name = 'Servidor' + (this.count++);
    this.cloudServerCalcInObj.addResumen = true;
    this.cloudServerCalcInObj.Cores = this.cloudServerCalcOutObj.Cores;
    this.cloudServerCalcInObj.Ram = this.cloudServerCalcOutObj.Ram;
    this.cloudServerCalcInObj.Gb = this.cloudServerCalcOutObj.Gb;
    this.cloudServerCalcInObj.objCloudServerOut.BaseDatos = this.cloudServerCalcOutObj.BaseDatos;
    this.cloudServerCalcInObj.objCloudServerOut.CAPEXType = this.cloudServerCalcOutObj.CAPEXType;
    this.cloudServerCalcInObj.objCloudServerOut.OPEXType = this.cloudServerCalcOutObj.OPEXType;
    const cloudServeDataClone = JSON.parse(JSON.stringify(this.cloudServerCalcInObj));
    const cloudServeDataClone2 = JSON.parse(JSON.stringify(this.cloudServerCalcInObj));
    this.addCloudServerInResumen.emit({ type: 5, data: cloudServeDataClone }); // Agregar clodu servers  al Resumen General
    this.resumenCalculatorList.push({ ...cloudServeDataClone2 });
  }

  TotalResources(): TotalDatacenterCost {
    const resources =  new TotalDatacenterCost();
    this.resumenCalculatorList.forEach(element => {
      if (element.addResumen) {
        if (element.objCloudServerOut.DcAlterno === 'NO') {
            resources.principal.Cost.MRC += element.Cost.MRC;
            resources.principal.Cost.CAPEX += element.Cost.CAPEX;
            resources.principal.Cost.OPEX += element.Cost.OPEX;
            resources.principal.Cost._OPEX += element.Cost._OPEX;
            resources.principal.Cost._CAPEX += element.Cost._CAPEX;
            if (element.objCloudServerOut.BaseDatos !== 'N/A') {
              resources.principal.RAM += element.objCloudServerOut.Ram
              resources.principal.Cores += element.objCloudServerOut.Cores
              resources.principal.Storage += element.objCloudServerOut.Gb
            } else {
              resources.principal.RAMDB += element.objCloudServerOut.Ram
              resources.principal.CoresDB += element.objCloudServerOut.Cores
              resources.principal.StorageDB += element.objCloudServerOut.Gb
            }

        } else {

            resources.alternate.Cost.MRC += element.Cost.MRC;
            resources.alternate.Cost.CAPEX += element.Cost.CAPEX;
            resources.alternate.Cost.OPEX += element.Cost.OPEX;
            resources.alternate.Cost._OPEX += element.Cost._OPEX;
            resources.alternate.Cost._CAPEX += element.Cost._CAPEX;

          if (element.objCloudServerOut.BaseDatos !== 'N/A') {
            resources.alternate.RAM += element.objCloudServerOut.Ram
            resources.alternate.Cores += element.objCloudServerOut.Cores
            resources.alternate.Storage += element.objCloudServerOut.Gb
          } else {
            resources.alternate.RAMDB += element.objCloudServerOut.Ram
            resources.alternate.CoresDB += element.objCloudServerOut.Cores
            resources.alternate.StorageDB += element.objCloudServerOut.Gb
          }
        }
      }
    });

    return resources;
  }

  deleteServerBySummary(index: any): void {
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: 'Eliminar Servidor',
        Message: '¿Está seguro que eliminar este servidor de la cotización?',
        Ok: () => {
          this.resumenCalculatorList.splice(index, 1);
          this.deleteDataCompleteResumen.emit({ data: { ...this.resumenCalculatorList[index] }, type: 5 }); // Metodo para borrar Equipo
        }
      }
    });
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep(): void {
    this.step--;
  }

  snackBarMessage(message: string): void {
    this.snackbar.open(message, null, {
      duration: 2000,
    });
  }

  checkSettings() {
    if (this.cloudServerCalcOutObj.OS !== 'Windows Server') {
      this.cloudServerCalcOutObj.Rdp = 0;
    }
    if (this.cloudServerCalcOutObj.Uso !== 'Database') {
      this.cloudServerCalcOutObj.BaseDatos = 'N/A';
    }
  }

  setSlideToogleValue() {
    if (this.isChasisChecked === false) {
      this.cloudServerCalcOutObj.AutorizaPresidencia = 'NO';
    } else {
      this.cloudServerCalcOutObj.AutorizaPresidencia = 'SI';
    }

    if (this.isDcAlternoChecked === false) {
      this.cloudServerCalcOutObj.DcAlterno = 'NO';
    } else {
      this.cloudServerCalcOutObj.DcAlterno = 'SI';
    }
  }

  serverProtection() {
    if (this.isSophosChecked === false) {
      const d = this.dialog.open(ServerProtectionComponent, {
        hasBackdrop: true,
        disableClose: false,
        data: {
          Title: 'Server Protection',
          Ok: () => {
            this.isSophosChecked = false;
            this.cloudServerCalcOutObj.Sophos = 'NO';
            this.loadExcelInformation();
          },
          Cancel: () => {
            this.isSophosChecked = true;
            this.cloudServerCalcOutObj.Sophos = 'SI'
            this.loadExcelInformation();
          }
        }
      });
      d.afterClosed()
      .subscribe(result => {});

    } else {
      this.isSophosChecked = true;
      this.cloudServerCalcOutObj.Sophos = 'SI';
      this.loadExcelInformation();
    }
  }

  addOrRemoveResumenMethod(data: any) {
    data.addResumen = !data.addResumen;
    this.addOrRemoveResumen.emit({ type: 5, UUID: data.UUID, status: data.addResumen });
  }

  removeCheckInResumen(UUID: string) {
    this.resumenCalculatorList = this.resumenCalculatorList.map((dato) => {
      if (dato.UUID === UUID) { dato.addResumen = false; }
      return dato;
    });
  }

  sendQuote(): void {
    const d = this.dialog.open(sendMailDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: 'Enviar Cotización'
      }
    });

    d.afterClosed().subscribe(result => {
      if (result) {
        this.cloudMachineList();
        this.dataSendMail = {
          Configuration: this.bundleQuoteDto,
          Email: result.email,
          Name: result.name,
          Detail: result.detail
        }

        this.service.sendCalculatorCloudServerSendEmail(this.dataSendMail).subscribe(
          response => {
            this.loading = false;
            this.snackbar.open('Correo Enviado Correctamente', null, {
              duration: 2000,
            });
          },
          error => {
            this.loading = false;
            this.snackbar.open('Ocurrió un error por favor intentelo nuevamente'+ error, null, {
              duration: 2000,
            });
          }
        );

      }
    });
  }

  cloudMachineList() {
    this.bundleQuoteDto = new BundleCloudQuoteDto();
    const totalData = this.totaldata();
    const storage = new Storage();
    const _machineType = new MachineLicense();
      this.resumenCalculatorList.forEach((element) => {
        const bundleDto = new BundleDTO();
        bundleDto.BundleMachine.MachineLicenses = [];
        _machineType.Identifier = element.objCloudServerOut.Uso;
        _machineType.Quantity = 1;
        bundleDto.BundleMachine.MachineLicenses.push(_machineType);
        bundleDto.ID = element.UUID
        bundleDto.BundleMachine.Cores.Value = element.objCloudServerOut.Cores;
        storage.diskType = element.objCloudServerOut.Iops;
        storage.size = element.objCloudServerOut.Gb;
        bundleDto.BundleMachine.MachineStorage = [storage];
        bundleDto.BundleMachine.Duration = parseInt(element.objCloudServerOut.PeriodoContrato, 10);
        bundleDto.BundleMachine.Cores.Value = element.objCloudServerOut.Cores;
        bundleDto.BundleMachine.Ram.Value = element.objCloudServerOut.Ram;
        bundleDto.BundleMachine.Network.Internet = element.objCloudServerOut.Internet;
        bundleDto.BundleMachine.OperativeSystem.Os = element.objCloudServerOut.OS;
        bundleDto.BundleMachine.DataBase = element.objCloudServerOut.BaseDatos;
        bundleDto.BundleMachine.MachineName = element.Name;
        bundleDto.BundleMachine.Cost.CAPEX = element.Cost.CAPEX;
        bundleDto.BundleMachine.Cost.OPEX = element.Cost.OPEX;
        bundleDto.BundleMachine.Cost._CAPEX = element.Cost._CAPEX;
        bundleDto.BundleMachine.Cost._OPEX = element.Cost._OPEX;
        bundleDto.BundleMachine.Cost.MRC = element.Cost.MRC;
        bundleDto.BundleMachine.Cost.DiscountMRC = element.Cost.MRC;
        if (element.objCloudServerOut.Rdp > 0) {
          const _machineLicenseRdp = new MachineLicense();
          _machineLicenseRdp.Identifier = 'RDP';
          _machineLicenseRdp.Quantity = element.objCloudServerOut.Rdp;
          bundleDto.BundleMachine.MachineLicenses.push(_machineLicenseRdp);
        }
        if (element.objCloudServerOut.BaseDatos !== 'N/A') {
          const _machineLicenseDb = new MachineLicense();
          _machineLicenseDb.Identifier = 'RDP';
          _machineLicenseDb.Quantity = 1;
          bundleDto.BundleMachine.MachineLicenses.push(_machineLicenseDb);
        }
        if (element.objCloudServerOut.Agente !== 'N/A') {
          const _machineLicenseDb = new MachineLicense();
          _machineLicenseDb.Identifier = 'Agente -' + element.objCloudServerOut.Agente;
          _machineLicenseDb.Quantity = 1;
          bundleDto.BundleMachine.MachineLicenses.push(_machineLicenseDb);
        }
        if (element.objCloudServerOut.Sophos !== 'NO') {
          const _machineLicenseDb = new MachineLicense();
          _machineLicenseDb.Identifier = 'Sophos -' + element.objCloudServerOut.Sophos;
          _machineLicenseDb.Quantity = 1;
          bundleDto.BundleMachine.MachineLicenses.push(_machineLicenseDb);
        }
        if (element.objCloudServerOut.DcAlterno !== 'NO') {
          const _machineLicenseDcAlterno = new MachineLicense();
          _machineLicenseDcAlterno.Identifier = 'D -' + element.objCloudServerOut.DcAlterno;
          _machineLicenseDcAlterno.Quantity = 1;
          bundleDto.BundleMachine.MachineLicenses.push(_machineLicenseDcAlterno);
        }
          this.bundleQuoteDto.CloudConfiguration.push(bundleDto);
      });
    this.bundleQuoteDto.TotalBundleCost = totalData;
  }

  addDataWithIdQuotation(data: any) {

    this.resumenCalculatorList = data;
  }

  ChangeTotalMRC() {
    const d = this.dialog.open(CloudServerEditValuesComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: 'Modificar MRC',
        value : this.cloudServerCalcInObj.Cost.MRC,
        Ok: (response) => {
          this.cloudServerCalcOutObj.Cost.MRC =  response.value;
          this.cloudServerCalcOutObj.editMRC =  true;
          this.onChanges(true);
        }
      }
    });

    d.afterClosed().subscribe(result => {
      if (result) {
        return;
      }
    });
  }

  createNewQuote() {
    const d = this.dialog.open(SaveBundleQuoteModalComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: 'Guardar Cotización',
        CustomerName : this.BundleQuote.CustomerName
      }
    });
    d.afterClosed().subscribe(result => {
      if (result) {
        this.SaveQuoteObject(result);
      }
    });
  }

  updateBundleQuote() {
    if ( this.BundleQuote.ID !==  this.idBundleQuote ) {
       this.BundleQuote.ParentID =  this.idBundleQuote ;
    } else {
      this.BundleQuote.ParentID  = null;
    }
  }


  SaveQuoteObject( value: any) {
    this.BundleQuote.Name =  value.Name;
    this.BundleQuote.CustomerName = (typeof value.Customer === 'object') ?  value.Customer.Name : value.Customer;
    this.BundleQuote.CustomerID = (typeof value.Customer === 'object') ? value.Customer.ID : null;
    this.BundleQuote.Description = value.Description;
    this.BundleQuote.ID = null;
    this.BundleQuote.CloudConfiguration = this.resumenCalculatorList;
    this.service.SaveBundleQuote(this.BundleQuote).subscribe(
      response => {
        this.loading = false;
        this.snackbar.open('Cotización Guardada Correctamente', null, {
          duration: 2000,
        });
      },
      error => {
        this.loading = false;
        this.snackbar.open('Ocurrió un error por favor intentelo nuevamente', null, {
          duration: 2000,
        });
      }
    );
  }

  viewQuotes() {
    if (this.totaldata().MRC > 0) {
      this.dialog.open(ConfirmationDialog, {
        data: {
          Title: '⚠ Advertencia',
          Message: 'Los cambios en la cotización que no hayan sido guardados se perderán, ¿Está seguro?',
          Ok: () => this.router.navigateByUrl('/home/(main:viewQuotes)')
        },
        disableClose: true
      });
    } else {
      this.router.navigateByUrl('/home/(main:viewQuotes)');
    }
  }

  getBundleQuoteByID(idBundleQuote: number) {
    this.loading =  true;
     this.service.getBundleQuoteByID(idBundleQuote).subscribe((dataBundleQuote: BundleQuoteDTO) => {
       this.BundleQuote = dataBundleQuote;
       this.resumenCalculatorList =  this.BundleQuote.CloudConfiguration;
        this.loading =  false;
     },
       (     error: { error: string; }) => {
       this.snackbar.open('Error al cargar cotización, por favor intente nuevamente.', error.error, {
         duration: 3000
       });
     }
   );
 }

 typeServerChange() {
  if (this.cloudServerCalcOutObj.Uso === 'Database' || this.cloudServerCalcOutObj.Uso === 'SAP') {
     this.iopsRecursosList = this.iopsRecursosList.filter(x => parseInt(x.Name)  == 5000 || parseInt(x.Name) === 8000 || parseInt(x.Name) === 10000  );
     this.cloudServerCalcOutObj.Iops = 5000;
     this.onChanges();
} else {
     this.service.GetGenericListOptionsByListID('iopsRecursosCloudServer').subscribe(
       (response) => {
         this.iopsRecursosList = response;
         this.cloudServerCalcOutObj.Iops = 1000;
         this.onChanges();
       }
     );
 }
}

operativeSystem() {
  if (this.cloudServerCalcOutObj.OS === 'Windows Server') {
    this.cloudServerCalcOutObj.BaseDatos = 'N/A';
    this.service.GetGenericListOptionsByListID('bdLicenciamientoCloudServer')
    .subscribe(
      (response) => {
        this.bdList = response;
      }
    );

  } else {
    this.cloudServerCalcOutObj.BaseDatos = 'N/A';
    this.bdList = this.bdList.filter(x => x.ParentID === null );
  }
}



storageChange() {
 if (this.cloudServerCalcOutObj.Gb > 4000 && this.sizeMessage) {
   this.sizeMessage = false;
   this.snackbar.open('Recuerda que una MV despues de 4Tb no tiene backup, debes agregar un servicio IFX CLOUD Backup', null, {
     duration: 5500,
   });
 }
}


modalVDI() {
    this.dialog.open(ConfirmationDialog, {
      data: {
        Title: '⚠ Advertencia',
        Message: 'Para ambientes de acceso remoto o VDI por favor contactar al equipo de producto.',
        Ok: () => console.log('ok')
      },
      disableClose: true
    });
}


modalLicenceseDB(){

  this.dialog.open(ConfirmationDialog, {
    data: {
      Title: '⚠ Advertencia',
      Message: 'Configuración no valida, Limitación de capacidad del motor de DB.',
      Ok: () => console.log('ok')
    },
    disableClose: true
  });

}



}
