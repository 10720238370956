<div class="container-fluid">
    <div class="row">
        <div class="col-7">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngFor="let leftQuestion of leftQuestions; let i = index">
                    <div class="card cardCtrl">
                        <div class="card-body">
                            <div (click)="ShowQuestion(leftQuestion,false)" [style.backgroundColor]="leftQuestion.Color" class="card-title card-title-body puntero"
                                [style.background-color]="leftQuestion.Color">
                                <h5><span class="card-titlePos">{{leftQuestion.Question}}</span></h5>
                            </div>
                            <div style="margin-top:30px" *ngIf="leftQuestion.show">
                                <p class="card-text card-contentPos">{{leftQuestion.Answer}}</p>
                            </div>
                            <div [style.backgroundColor]="leftQuestion.Color" (click)="ShowQuestion(leftQuestion,false)" class="card-footer-body">
                                <h6 class="card-subtitle mb-2 card-contentPos"><span class="subtFooterPos"> {{leftQuestion.Module}}</span></h6>
                                <em class="material-icons mat-dark pull-right iconPos">{{leftQuestion.Icon}}</em>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5 topFix">
            <mat-form-field class="full-width">
                <input [(ngModel)]="searchDocument" matInput placeholder="¿No encontraste lo que buscabas? Seguro aqui esta...">
            </mat-form-field>
            <div *ngFor="let question of rightQuestions | filter : 'Question' :searchDocument; let i = index"
                (click)="ShowQuestion(question,true)">
                <div class="row right-container" *ngIf="i < 5 && searchDocument != ''">
                    <div class="question-header full-width">
                        <p class="col-10">{{question.Question}}</p>
                        <em class="col-2 material-icons mat-dark pull-right">{{question.Icon}}</em>
                    </div>
                    <div *ngIf="question.show" class="question-body">
                        <p class="card-text card-contentPos">{{question.Answer}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>