import { BundleCost, Machine } from './machine-dto';
export interface IselectProperty {
  Name: string;
  ID: number;
  Value?: string;
  Identifier?: string;
}


export class CloudServerCalculatorOut {
  public Uso: string;
  public Cores: number;
  public Ram: number;
  public Gb: number;
  public Iops: number;
  public Internet: number;
  public AutorizaPresidencia: string;
  public OS: string;
  public BaseDatos: string;
  public Rdp: number;
  public Sophos: string;
  public DcAlterno: string;
  public Agente: string;
  public PeriodoContrato: string;
  public OPEXType: string;
  public CAPEXType: string;
  public Cost: BundleCost;
  public editMRC: boolean;
  constructor() {
    this.Uso = 'General';
    this.Cores = 2;
    this.Ram = 2;
    this.Gb = 100;
    this.Iops = 1000;
    this.Internet = 5;
    this.AutorizaPresidencia = 'NO';
    this.OS = 'Windows Server';
    this.BaseDatos = 'N/A';
    this.Rdp = 0;
    this.Sophos = 'SI';
    this.DcAlterno = 'Principal';
    this.Agente = 'N/A';
    this.PeriodoContrato = '12';
    this.OPEXType =  'Real';
    this.CAPEXType = 'Real';
    this.Cost =  new BundleCost();
    this.editMRC =  false;
  }
}

export class ResumenMRC {
  public CoresUnidad: number;
  public MemoriaRam: number;
  public StorageTb: number;
  public Iops: number;
  public BDSqlEnterprice: number;
  public BDSqlStandard: number;
  public BDSqlWeb: number;
  public WindowsStandard: number;
  public Rdp: number;
  public LcSuseLinuxEnterprise: number;
  public LcSuseLinuxEnterpriseSap: number;
  public LcRedHatSapEnterprise: number;
  public LinuxRedHatSap: number;
  public Doubletake: number;
  public Zerto: number;
  public BackupServidor: number;
  public Sophos: number;
  public Watcher: number;
  public Ip: number;
  public MrcSugerido: number;
  public MrcManual: number;
  public Total: number;
  public PorcentajeCoresUnidad: number;
  public PorcentajeMemoriaRam: number;
  public PorcentajeStorageTb: number;
  public PorcentajeIops: number;
  public PorcentajeBDSqlEnterprice: number;
  public PorcentajeBDSqlStandard: number;
  public PorcentajeBDSqlWeb: number;
  public PorcentajeWindowsStandard: number;
  public PorcentajeRdp: number;
  public PorcentajeLcSuseLinuxEnterprise: number;
  public PorcentajeLcSuseLinuxEnterpriseSap: number;
  public PorcentajeLcRedHatSapEnterprise: number;
  public PorcentajeLinuxRedHatSap: number;
  public PorcentajeDoubletake: number;
  public PorcentajeZerto: number;
  public PorcentajeBackupServidor: number;
  public PorcentajeSophos: number;
  public PorcentajeWatcher: number;
  public PorcentajeIp: number;
  public PorcentajeMrcSugerido: number;
  public PorcentajeTotal: number;
  constructor() {
    this.CoresUnidad = 0;
    this.MemoriaRam = 0;
    this.StorageTb = 0;
    this.Iops = 0;
    this.BDSqlEnterprice = 0;
    this.BDSqlStandard = 0;
    this.BDSqlWeb = 0;
    this.WindowsStandard = 0;
    this.Rdp = 0;
    this.LcSuseLinuxEnterprise = 0;
    this.LcSuseLinuxEnterpriseSap = 0;
    this.LcRedHatSapEnterprise = 0;
    this.LinuxRedHatSap = 0;
    this.Doubletake = 0;
    this.Zerto = 0;
    this.BackupServidor = 0;
    this.Sophos = 0;
    this.Watcher = 0;
    this.Ip = 0;
    this.MrcSugerido = 0;
    this.Total = 0;
    this.PorcentajeCoresUnidad = 0;
    this.PorcentajeMemoriaRam = 0;
    this.PorcentajeStorageTb = 0;
    this.PorcentajeIops = 0;
    this.PorcentajeBDSqlEnterprice = 0;
    this.PorcentajeBDSqlStandard = 0;
    this.PorcentajeBDSqlWeb = 0;
    this.PorcentajeWindowsStandard = 0;
    this.PorcentajeRdp = 0;
    this.PorcentajeLcSuseLinuxEnterprise = 0;
    this.PorcentajeLcSuseLinuxEnterpriseSap = 0;
    this.PorcentajeLcRedHatSapEnterprise = 0;
    this.PorcentajeLinuxRedHatSap = 0;
    this.PorcentajeDoubletake = 0;
    this.PorcentajeZerto = 0;
    this.PorcentajeBackupServidor = 0;
    this.PorcentajeSophos = 0;
    this.PorcentajeWatcher = 0;
    this.PorcentajeIp = 0;
    this.PorcentajeMrcSugerido = 0;
    this.PorcentajeTotal = 0;
  }
}

export class ResumenCapex {
  public Procesamiento: number;
  public Ram: number;
  public Storage: number;
  public Chasis: number;
  public ServerProtection: number;
  public Watcher: number;
  public Total: number;
  public PorcentajeProcesamiento: number;
  public PorcentajeRam: number;
  public PorcentajeStorage: number;
  public PorcentajeChasis: number;
  public PorcentajeServerProtection: number;
  public PorcentajeWatcher: number;
  public PorcentajeTotal: number;
  constructor() {
    this.Procesamiento = 0;
    this.Ram = 0;
    this.Storage = 0;
    this.Chasis = 0;
    this.ServerProtection = 0;
    this.Watcher = 0;
    this.Total = 0;
    this.PorcentajeProcesamiento = 0;
    this.PorcentajeRam = 0;
    this.PorcentajeStorage = 0;
    this.PorcentajeChasis = 0;
    this.PorcentajeServerProtection = 0;
    this.PorcentajeWatcher = 0;
    this.PorcentajeTotal = 0;
  }
}

export class ResumenOpex {
  public LicenciamientoVware: number;
  public LicenciamientoRdp: number;
  public LicenciamientoBackup: number;
  public OpexIp: number;
  public LicenciamientoBD: number;
  public LicenciamientoSO: number;
  public Replicacion: number;
  public Watcher: number;
  public Total: number;
  public PorcentajeLicenciamientoVware: number;
  public PorcentajeLicenciamientoRdp: number;
  public PorcentajeLicenciamientoBackup: number;
  public PorcentajeOpexIp: number;
  public PorcentajeLicenciamientoBD: number;
  public PorcentajeLicenciamientoSO: number;
  public PorcentajeReplicacion: number;
  public PorcentajeWatcher: number;
  public PorcentajeTotal: number;
  constructor() {
    this.LicenciamientoVware = 0;
    this.LicenciamientoRdp = 0;
    this.LicenciamientoBackup = 0;
    this.OpexIp = 0;
    this.LicenciamientoBD = 0;
    this.LicenciamientoSO = 0;
    this.Replicacion = 0;
    this.Watcher = 0;
    this.Total = 0;
    this.PorcentajeLicenciamientoVware = 0;
    this.PorcentajeLicenciamientoRdp = 0;
    this.PorcentajeLicenciamientoBackup = 0;
    this.PorcentajeOpexIp = 0;
    this.PorcentajeLicenciamientoBD = 0;
    this.PorcentajeLicenciamientoSO = 0;
    this.PorcentajeReplicacion = 0;
    this.PorcentajeWatcher = 0;
    this.PorcentajeTotal = 0;
  }
}

export class ResumenIndicadores {
  public RoiPorContrato: number;
  public MargenPorContrato: number;
  public RoiMrcIndicadores: number;
  public MargenIndicadores: number;
  constructor() {
    this.RoiPorContrato = 0;
    this.MargenPorContrato = 0;
    this.RoiMrcIndicadores = 0;
    this.MargenIndicadores = 0;
  }
}

export class CloudServerCalculatorIn {
  public Name: string;
  public Cores: number;
  public Ram: number;
  public Gb: number;
  public resumenMrc: ResumenMRC;
  public resumenCapex: ResumenCapex;
  public resumenOpex: ResumenOpex;
  public resumenIndicadores: ResumenIndicadores;
  public addResumen: boolean;
  public UUID: string;
  public Cost: BundleCost;
  public currency?: string;
  public objCloudServerOut: CloudServerCalculatorOut;
  public configValidation: boolean;
  constructor() {
    this.Name =  '' ;
    this.resumenMrc = null;
    this.resumenCapex = null;
    this.resumenOpex = null;
    this.resumenIndicadores = null;
    this.Cost = new BundleCost();
    this.objCloudServerOut = new CloudServerCalculatorOut();
    this.currency = 'USD';
    this.configValidation = true;
  }
}

export class GenericListOption {
  public ID: number;
  public Name: string;
  public Identifier: string;
  public Value: string;
  // public GenericListsID: number;
  // public NetSuiteID: string;
   public ParentID: number;
  // public Active: boolean;
  consturctor() {
    this.ID = 0;
    this.Name = '';
    this.Identifier = '';
    this.Value = '';
    // this.GenericListsID = 0;
    // this.NetSuiteID = '';
     this.ParentID = null;
    // this.Active = false
  }
}

export class MachineLicense {
  public Identifier: string;
  public Quantity: number;
  public LicenseType: number;
  public Cost: BundleCost;
  constructor() {
    this.Identifier = 'WINRDP';
    this.Quantity = 0;
    this.LicenseType = 0;
    this.Cost = new BundleCost();
  }
}

export class Ha {
  public availabilityType: number;
  public replicationServer: number;
  public Cost: BundleCost;
  constructor() {
    this.availabilityType = 0;
    this.replicationServer = null;
    this.Cost = new BundleCost();
  }
}

export class Hypervisor {
  public plataform: number;
  public Cost: BundleCost;
  constructor() {
    this.plataform = 1;
    this.Cost = new BundleCost();
  }
}

export class Cores {
  public Value: number;
  public Cost: BundleCost;
  constructor() {
    this.Value = 2;
    this.Cost = new BundleCost();
  }
}

export class Ram {
  public Value: number;
  public Cost: BundleCost;
  constructor() {
    this.Value = 2;
    this.Cost = new BundleCost();
  }
}

export class DataBase {
  public Db: number;
  public Name: string;
  public Cost: BundleCost;
  constructor() {
    this.Cost = new BundleCost();
    this.Name = '';
  }
}

export class Networking {
  public localNetworkType: number;
  public Internet: number;
  public Cost: BundleCost;
  constructor() {
    this.localNetworkType = 0;
    this.Internet = 5;
    this.Cost = new BundleCost();
  }
}

export class OperativeSystem {
  public Os: string;
  public Cost: BundleCost;
  constructor() {
    this.Cost = new BundleCost();
    this.Os = '';
  }
}

export class ServerProtection {
  public Active: boolean;
  public Cost: BundleCost;
  constructor() {
    this.Active = true;
    this.Cost = new BundleCost();
  }
}

export class MachineService {
  public LicenseName: string;
  public LicenseType: string;
  public Quantity: number;
  public Cost: BundleCost;
  constructor() {
    this.LicenseName = '';
    this.LicenseType = '2';
    this.Cost = new BundleCost();
  }
}

export class MachineServerDTO {
  public ID: string;
  public Duration: number;
  public Cores: Cores;
  public Ram: Ram;
  public Hypervisor: Hypervisor;
  public Availability: Ha;
  public Network: Networking;
  public MachineStorage: [Storage];
  public OperativeSystem: OperativeSystem;
  public DataBase: any;
  public MachineName: string;
  public RemoteAccess: any;
  public PrivateIp: string;
  public MacAddress: string;
  public MachineLicenses: Array<MachineLicense>;
  public MachineServices: Array<MachineService>;
  public Monitoring: BundleCost;
  public ServerProtection: ServerProtection;
  public Cost: BundleCost;
  public Vcenter: string;
  public ServiceID: string;
  constructor() {
    this.ID = null;
    this.Duration = null;
    this.Cores = new Cores();
    this.Ram = new Ram();
    this.Hypervisor = new Hypervisor();
    this.Availability = new Ha();
    this.Network = new Networking();
    this.MachineStorage = [new Storage()];
    this.OperativeSystem = new OperativeSystem();
    this.DataBase = new DataBase();
    this.MachineName = '';
    this.RemoteAccess = '';
    this.MacAddress = null;
    this.MachineLicenses = [new MachineLicense()];
    this.MachineServices = [new MachineService()];
    this.Monitoring = new BundleCost();
    this.ServerProtection = new ServerProtection();
    this.Cost = new BundleCost();
    this.Vcenter = null;
    this.ServiceID = null;
  }
}


export class  TotalDatacenterCost {
 principal: TotalResources;
 alternate: TotalResources;
 constructor() {
   this.principal = new TotalResources();
   this.alternate = new TotalResources();
 }

}


export class TotalResources {
  dataCenterType: string;
  Cores: number;
  RAM: number;
  Storage: number;
  DB: number
  CoresDB: number;
  RAMDB: number;
  StorageDB: number;
  Cost: BundleCost;
  constructor() {
    this.dataCenterType = null;
    this.Cores = 0;
    this.RAM = 0;
    this.Storage = 0;
    this.DB =  0;
    this.CoresDB = 0;
    this.RAMDB = 0;
    this.StorageDB = 0;
    this.Cost =  new BundleCost();
  }
}



export class BundleDTO {
  ID: string;
  BundleMachine: Machine;
  BundleServices: Array<any>;
  BundleLicenses: Array<any>;
  BundleCost: any;
  constructor() {
      this.ID = '';
      this.BundleMachine = new Machine();
      this.BundleServices = null;
      this.BundleLicenses = null;
      this.BundleCost = {};
  }
}


export class CloudserverEmailDTO{
  CloudConfiguration: Array<BundleDTO>;
  contructor() {
     this.CloudConfiguration =  new Array<BundleDTO>();
  }
}


export interface IcurrencyOptions {
  align: string;
  allowNegative: boolean;
  allowZero: boolean;
  decimal: string;
  precision: number;
  prefix: string;
  suffix: string;
  thousands: string;
}


export class InternalRole {
  public ID: string;
  public Name: string;

  constructor() {
    this.ID = "";
    this.Name = "";
  }
}


export class BundleQuoteDTO {
  ID: number;
  Name: string;
  ParentID: number;
  CustomerName: string;
  CustomerID: number;
  Description: string;
  CloudConfiguration: Array<CloudServerCalculatorIn>;
  Status: boolean;
  Date: Date;
  PlanDiscountCount: number;
  Viewers:  Array<string>;
  Owner: boolean;
  constructor() {
    this.ID = null;
    this.ParentID = null;
    this.Name = '';
    this.CustomerID = null;
    this.CustomerName = '';
    this.Description =  '';
    this.CloudConfiguration = new Array<CloudServerCalculatorIn>();
    this.Status = true;
    this.Date = new Date();
    this.PlanDiscountCount = 0;
    this.Viewers = new Array<string>();
 }
}

export  class BundleCloudQuoteDto {
  CloudConfiguration:  Array<BundleDTO>;
  TotalBundleCost: BundleCost;
    constructor() {
     this.CloudConfiguration = new  Array<BundleDTO>();
     this.TotalBundleCost = new BundleCost();
 };
}




