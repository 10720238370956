export class CredentialDTO{
    public email : string;
    public productType: string;
    public credentialHref : string;
    public password : string;
    constructor(){
        this.email = null;
        this.productType = null;
        this.credentialHref = null;
        this.password = null;
    }
}