<div class="card">
    <div class="card-header-component card-block container-fluid margin-bottom-10">
      <div class="card-title modal-title">
        <h6 class="pull-left">{{data.Title}}</h6>
        <div class="pull-right" (click)="cancelClick()">
          <mat-icon>close</mat-icon>
        </div>
      </div>
      <div class="full-width">
        <mat-table #table [dataSource]="StatsArray">
          <ng-container matColumnDef="LocalSubnet">
              <mat-header-cell *matHeaderCellDef>Local Subnet </mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.LocalSubnet}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="PeerSubnet">
              <mat-header-cell *matHeaderCellDef>Peer Subnets</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.PeerSubnet}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="LastInformationalMessage">
              <mat-header-cell *matHeaderCellDef>Last Informational Message</mat-header-cell>
              <mat-cell *matCellDef="let row" matTooltip="{{row.LastInformationalMessage}}"> {{row.LastInformationalMessage}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="TunnelStatus">
              <mat-header-cell *matHeaderCellDef>Tunnel Status</mat-header-cell>
              <mat-cell *matCellDef="let row" matTooltip="{{row.TunnelStatus.toUpperCase()}}">  
                <mat-icon [ngClass]="{
                  'circle-inactive': row.TunnelStatus.toUpperCase() == 'DOWN', 
                  'circle-active': row.TunnelStatus.toUpperCase() == 'UP' 
                  }">
                  fiber_manual_record
                </mat-icon> 
              </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <div class="resources-detail-row">
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </div>
        </mat-table>
      </div>
      <div class="full-width margin-top-20">
        <button class="pull-right" color="warn" mat-raised-button (click)="cancelClick()">Cerrar</button>
    </div>
  </div>