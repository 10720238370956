import { Component, ViewChild } from '@angular/core';
import { WlService } from '../../../services/wlservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';
import { Globalization } from '../../../globalization/globalization';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
    selector: 'certificate-upload',
    templateUrl: './certificate-upload.component.html',
    styleUrls: ['./certificate-upload.component.scss'],
})
export class CertificateUploadComponent {
    @ViewChild("fileInput") fileInput;
    globalization: any = Globalization;
    loaded: boolean = false;
    fileSrc: string = '';
    pwd : string;
    saveButton : boolean = false;
    SSLCertificate : boolean = false;
    SSLLoading : boolean = true;
    fileToUpload: any;
    token: string;
    language: string;
    constructor( private  service: WlService , public snackbar: MatSnackBar, public auth: AuthService){}

    public ngOnInit(){
        this.inicializate();
        this.getDomainData(); 
    }

    inicializate(){
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    public getDomainData(){
        this.service.GetDomainInformation().subscribe(
            (response:any)=>{
                this.SSLCertificate = response.SSLCertificate;
                this.SSLLoading = false;
            },    
            error=>{
                this.snackbar.open('Error consultando domainInfo ' + error.error, null, {
                    duration: 2000,
                });
            }
          
        )
    }

    saveFile(): void {
        if(this.validate()){
            let fi = this.fileInput.nativeElement;
            if (fi.files && fi.files[0]) {
                this.fileToUpload = fi.files[0];
                let params = {
                    Type :"CertificateSSL",
                    password : this.pwd
                }
                this.SSLLoading = true;
                this.service
                .uploadFile(this.fileToUpload,"Domain/UploadCertificateSSL", params)
                .subscribe(
                    res => {
                        this.saveButton = false;    
                        this.snackbar.open('Certificado cargado correctamente', null, {
                            duration: 2000,
                        });
                        this.pwd = "";
                        this.SSLLoading = false;

                    },    
                    error=>{
                        this.snackbar.open('Error al cargar el certificado por favor verifique el archivo y el password', null, {
                            duration: 2000,
                        });
                        this.SSLLoading = false;
                    }
                );
            }

        }else{
            this.snackbar.open('Por favor verifique el password' , null, {
             duration: 2000,
            });
        }
        
    }

    uploadFile():void {
        let fi = this.fileInput.nativeElement;
        if (fi.files && fi.files[0]) {
            this.fileToUpload = fi.files[0];
            this.saveButton = true;
        }else{
            this.saveButton = false;
        }
    }
    validate():boolean{ 
        let validate = (this.pwd && this.pwd.length != null && this.pwd.length !== 0 ) ? true: false;
        return validate;
    }
    cancelUpload():void {
        this.saveButton = false;
        this.fileToUpload = {};
        this.pwd = "";
    }
}