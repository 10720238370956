import { Component, OnInit , ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'welcome-window',
    templateUrl: './welcome-window.component.html',
    styleUrls: ['../desktop.component.scss']
})
export class WelcomeComponent implements OnInit {    
    ngOnInit(){
        
    }
}