import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { WlService } from '../../../services/wlservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
@Component({
    selector: 'service-name-modal',
    templateUrl: 'service-name-modal.component.html',
    styleUrls: ['../../../tools/modals/modals.scss']
})
export class ServiceNameModal {
    public loading: boolean = false;
    public ServiceName: string = "";
    ServiceNameFormFb : FormGroup;
    
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ServiceNameModal>, public snackbar: MatSnackBar, private service: WlService,  private formBuilder : FormBuilder) { 
        this.ServiceName = data.ServiceName;
        this.ServiceNameFormFb = this.formBuilder.group({
            'ServiceName' : [{value:'',disabled: this.loading},[Validators.required]]
        });
    }
    
    ChangeName() {
        this.loading = true;
        this.service.ChangeServiceName(this.data.ServiceID,this.data.ID, this.ServiceName).subscribe(
            response=>{
                this.loading = false;
                this.dialogRef.close(response);
            },
            error=>{
                this.loading = false;
                this.snackbar.open('Error Modificando Nombre ' + error.error, null, {
                    duration: 4000,
                });
            }
        );
    }
    CloseDialog(){
        this.dialogRef.close();
    }
}