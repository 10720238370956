<mat-sidenav-container class="main-container">
  <mat-sidenav #sidenav position="end" class="" (close-start)="closeSidenav()">
    <plan-edition (savedProduct)="finishSaving($event)" #pedit [sidenav]="sidenav" [product]=selectedProduct></plan-edition>
  </mat-sidenav>
  <div class="container-fluid botFix">
    <div class="row btnPosFixTop">
      <div class="col-xl-4 col-lg-4 col-sm-6 col-xs-12" *ngFor="let product of domainProducts">
        <div class="card cardCtrl card-shadow">
          <div class="badgediv">
            <span *ngIf="product.State" class="badge badge-success badgeFix">{{'VIRTUAL_SERVERS.ACTIVE' | translate}}</span>
            <span *ngIf="!product.State" class="badge badge-danger badgeFix ">{{'VIRTUAL_SERVERS.INACTIVE' | translate}}</span>
          </div>
          <img class="card-img-top cardHeaderFix" [src]="product.Image" alt="Card image cap">
          <button matTooltip="{{'VIRTUAL_SERVERS.EDIT' | translate}}" matTooltipPosition="left" mat-mini-fab class="btnstyle03" (click)="editProduct(product);"><i class="fa fa-pencil"></i></button>
          <div class="card-block cardFix">
            <h4 class="card-title text-wrap">{{product.Name}}</h4>
            <hr>
            <mat-progress-bar *ngIf="product.IsSaving!=null && product.IsSaving==true" mode="indeterminate"></mat-progress-bar>
            <div id="mostrar/ocultar" *ngIf="product.shown">
              <p class="card-text text-wrap txt-lineHeight">{{product.Description}}</p>
              <table class="table">
                <thead class="">
                  <tr>
                    <th class="" scope="row" class="txtFix"><span>{{'VIRTUAL_SERVERS.CONFIGURATION' | translate}}</span></th>
                    <th class="txtFix text-right "> <span>{{'VIRTUAL_SERVERS.PRICE' | translate}} <small class="">{{'VIRTUAL_SERVERS.TOTAL' | translate}}</small></span> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="txtFix"><span class="text-right">{{product.Configuration.BundleMachine.Cores.Value}} {{'VIRTUAL_SERVERS.CORES' | translate}} {{product.Configuration.BundleMachine.Ram.Value}}GB
                      Ram 100GB/SSD </span></td>
                    <td class="txtFix text-right"><span>{{ product.Configuration.Cost.MRC | currency:'USD':true:'1.2-2'}}</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <mat-card-actions>
                <button *ngIf="!product.State" color="primary" mat-button (click)="disableProduct(product);">{{'VIRTUAL_SERVERS.ENABLED' | translate}}</button>
                <button *ngIf="product.State" color="warn" mat-button (click)="disableProduct(product);">{{'VIRTUAL_SERVERS.DISABLED' | translate}}</button>
                <button mat-button (click)="removeProduct(product);">{{'VIRTUAL_SERVERS.DELETE' | translate}}</button>
                <span matTooltip="" (click)="product.shown = !product.shown;" class="chevPosFix" >
                  <i *ngIf="!product.shown" class="fa fa-angle-down chevronFix"></i>
                  <i *ngIf="product.shown" class="fa fa-angle-up chevronFix"></i>
                </span>
              </mat-card-actions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
