<div class="container-fluid pdt-20 bg-gray">
  <div class="row">
    <div class="col-7">
      <form [formGroup]="calculatorFormGroup" style="width: 100%;">
        <mat-progress-bar *ngIf="loading === true" mode="indeterminate"></mat-progress-bar>
        <mat-horizontal-stepper [linear]="false" #stepper>
          <mat-step [stepControl]="calculatorFormGroup">
            <ng-template matStepLabel>Infraestructura</ng-template>
            <div>
              <div class="row margin-in-row">
                <div class="col-sm-6">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Tipo</div>
                    <div class="card-body p-0">
                      <mat-form-field appearance="standard" class="col-xs-9 col-sm-9 col-md-9">
                        <mat-label>Uso</mat-label>
                        <mat-select [compareWith]="compareObjects" name="Uso" formControlName="Uso" placeholder="Uso"
                          [(ngModel)]="cloudServerCalcOutObj.Uso" (selectionChange)="typeServerChange();">
                          <mat-option *ngFor="let item of tipoUsoList" [(value)]="item.Name">
                            {{ item.Name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6" *ngIf="GetActions(79)">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Datacenter</div>
                    <div class="card-body p-0 datacenter" >

                      <div class="row justify-content-center align-items-center h-100">
                        <div class="col-3">
                          <span class="pull-right">Principal</span>
                        </div>
                        <div class="col-4">
                          <mat-slide-toggle class="text-center" formControlName="validateChasis"
                            [(ngModel)]="isDcAlternoChecked" [checked]="false" (change)="onChanges()">
                          </mat-slide-toggle>
                        </div>
                        <div class="col-3">
                          <span class="pull-right">Alterno</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row margin-in-row">
                <div class="col-sm-12">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Definir recursos de cómputo</div>
                    <div class="card-body p-0">
                      <div class="row justify-content-center">
                        <mat-form-field appearance="standard" class="col-xs-9 col-sm-6 col-md-3">
                          <mat-label>Cores</mat-label>
                          <mat-select [compareWith]="compareObjects" name="Cores" formControlName="Cores"
                            [(ngModel)]="cloudServerCalcOutObj.Cores" (selectionChange)="onChanges()"
                            placeholder="Cores">
                            <mat-option *ngFor="let item of coreList" [(value)]="item.Name">
                              {{item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="col-xs-9 col-sm-6 col-md-4">
                          <mat-label>Ram</mat-label>
                          <mat-select [compareWith]="compareObjects" name="Ram" formControlName="Ram"
                            [(ngModel)]="cloudServerCalcOutObj.Ram" (selectionChange)="onChanges()" placeholder="Ram">
                            <mat-option *ngFor="let item of ramList" [(value)]="item.Name">
                              {{ item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="col-xs-9 col-sm-6 col-md-3">
                          <mat-label>Internet</mat-label>
                          <mat-select [compareWith]="compareObjects" name="Internet" formControlName="Internet"
                            [(ngModel)]="cloudServerCalcOutObj.Internet" (selectionChange)="onChanges()"
                            placeholder="Internet">
                            <mat-option *ngFor="let item of internetList" [(value)]="item.Name">
                              {{ item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="row justify-content-center margin-in-row">
                        <div class="col-11 card text-center p-0">
                          <div class="card-header card-header-style">Almacenamiento</div>
                          <div class="card-body p-0">
                            <div class="row justify-content-center">
                              <mat-form-field appearance="standard" class="col-xs-9 col-sm-7 col-md-5">
                                <mat-label>Gb</mat-label>
                                <mat-select [compareWith]="compareObjects" name="Gb" formControlName="Gb"
                                  [(ngModel)]="cloudServerCalcOutObj.Gb" (selectionChange)=" storageChange(); onChanges()"
                                  placeholder="Gb">
                                  <mat-option *ngFor="let item of gbRecursosList" [(value)]="item.Name">
                                    {{ item.Name }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="standard" class="col-xs-9 col-sm-7 col-md-5">
                                <mat-label>Iops</mat-label>
                                <mat-select [compareWith]="compareObjects" name="Iops" formControlName="Iops"
                                  [(ngModel)]="cloudServerCalcOutObj.Iops" (selectionChange)="onChanges()"
                                  placeholder="Iops">
                                  <mat-option *ngFor="let item of iopsRecursosList" [(value)]="item.Name">
                                    {{ item.Name }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<div class="row margin-in-row">
                <div class="col-sm-12">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Definir Chasis Hundido</div>
                    <div class="card-body p-0">
                      <div class="row justify-content-center">
                        <div class="col-xs-9 col-sm-7 col-md-5">
                          <div class="row justify-content-center align-items-center h-100">
                            <div class="col-3">
                              <span class="pull-right">No</span>
                            </div>
                            <div class="col-4">
                              <mat-slide-toggle class="text-center" formControlName="validateChasis"
                                [(ngModel)]="isChasisChecked" [checked]="false" (change)="onChanges()">
                              </mat-slide-toggle>
                            </div>
                            <div class="col-3">
                              <span class="pull-right">Si</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
              <div class="pull-right">
                <div class="mt-1 mb-1 text-xs-left text-sm-center" fxflex="100" fxflex.gt-sm="25" fxflex.gt-xs="50" >
                <h6 class="text-uppercase text-sm ma-0 text-bold"><strong>Total MRC </strong> </h6>
                <h4  *ngIf="cloudServerCalcInObj.configValidation" class="ma-0 mat-text-accent"> {{currencyOptions.prefix}}{{  cloudServerCalcInObj.Cost.MRC  | number : '1.2-2' }} {{currencyOptions.suffix}}</h4>
                <h4  *ngIf="!cloudServerCalcInObj.configValidation" class="ma-0 mat-text-accent">N/A</h4>
              </div>
                <div class="col-12">
                  <button class="button-style button-next pull-right" type="button" matStepperNext>Siguiente</button>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step [stepControl]="calculatorFormGroup">
            <ng-template matStepLabel>Licenciamientos y Continuidad</ng-template>
            <div>
              <div class="row margin-in-row">
                <div class="col-sm-12">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Definir Licenciamientos </div>
                    <div class="card-body p-0">
                      <div class="row justify-content-center">
                        <mat-form-field appearance="standard" class="col-xs-9 col-sm-7 col-md-5">
                          <mat-label>OS</mat-label>
                          <mat-select [compareWith]="compareObjects" name="OS" formControlName="OS"
                            [(ngModel)]="cloudServerCalcOutObj.OS" (selectionChange)="operativeSystem(); onChanges()" placeholder="OS">
                            <mat-option *ngFor="let item of osRecursosList" [(value)]="item.Name">
                              {{ item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="cloudServerCalcOutObj.Uso == 'Database'" appearance="standard"
                          class="col-xs-9 col-sm-7 col-md-5">
                          <mat-label>BaseDatos</mat-label>
                          <mat-select [compareWith]="compareObjects" name="BaseDatos"
                            [(ngModel)]="cloudServerCalcOutObj.BaseDatos" formControlName="BaseDatos"
                            (selectionChange)="onChanges()" placeholder="BaseDatos">
                            <mat-option *ngFor="let item of bdList" [(value)]="item.Name">
                              {{ item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="row justify-content-center">
                        <mat-form-field *ngIf="cloudServerCalcOutObj.OS === 'Windows Server'" appearance="standard"
                          class="col-xs-9 col-sm-7 col-md-5">
                          <mat-label>Rdp</mat-label>
                          <mat-select [compareWith]="compareObjects" name="Rdp" formControlName="Rdp"
                            [(ngModel)]="cloudServerCalcOutObj.Rdp" (selectionChange)="onChanges()" placeholder="Rdp">
                            <mat-option *ngFor="let item of rdpList" [(value)]="item.Name">
                              {{ item.Name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="col-xs-9 col-sm-7 col-md-5">
                          <div class="row h-100">
                            <div class="col-12">
                              <div class="row justify-content-center text-toggle-style">
                                <span class="pull-right">Server Protection</span>
                              </div>
                              <div class="row justify-content-center">
                                <div class="col-3">
                                  <span class="pull-right">No</span>
                                </div>
                                <div class="col-4">
                                  <mat-slide-toggle class="text-center" formControlName="Sophos"
                                    [(ngModel)]="isSophosChecked" [checked]="false" (change)="serverProtection()">
                                  </mat-slide-toggle>
                                </div>
                                <div class="col-3">
                                  <span class="pull-right">Si</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row margin-in-row">
                <div class="col-sm-12">
                  <div class="card text-center card-style">
                    <div class="card-header card-header-style">Definir Continuidad a nivel de infraestructura</div>
                    <div class="card-body p-0">
                      <div class="row justify-content-center">
                        <div class="col-xs-9 col-sm-7 col-md-5">
                          <div class="row h-100">
                            <div class="col-12">
                              <div class="row justify-content-center text-toggle-style">
                                <span class="pull-right">Replicación</span>
                              </div>
                              <div class="row justify-content-center">
                                <div class="col-3">
                                  <span class="pull-right">No</span>
                                </div>
                                <div class="col-4">
                                  <mat-slide-toggle class="text-center" [(ngModel)]="isDcAlternoChecked"
                                    formControlName="DcAlterno" [checked]="false" (change)=" DcAlterno()">
                                  </mat-slide-toggle>
                                </div>
                                <div class="col-3">
                                  <span class="pull-right">Si</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <mat-form-field appearance="standard" class="col-xs-9 col-sm-7 col-md-5" *ngIf="isDcAlternoChecked">
                          <mat-label>Agente</mat-label>
                          <mat-select [compareWith]="compareObjects" name="Agente" formControlName="Agente"
                            [(ngModel)]="cloudServerCalcOutObj.Agente" (selectionChange)="onChanges()"
                            placeholder="Agente">
                            <mat-option *ngFor="let item of replicationList" [(value)]="item.Name">
                              {{ item.Name }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="pull-right">
                  <div class="mt-1 mb-1 text-sm-center" fxflex="100" fxflex.gt-sm="25" fxflex.gt-xs="50">
                    <h6 class="text-uppercase text-sm ma-0 text-bold"><strong>Total MRC </strong> </h6>
                    <h4  *ngIf="cloudServerCalcInObj.configValidation" class="ma-0 mat-text-accent"> {{currencyOptions.prefix}} {{ cloudServerCalcInObj.Cost.MRC  | number : '1.2-2' }} {{currencyOptions.suffix}}</h4>
                    <h4  *ngIf="!cloudServerCalcInObj.configValidation" class="ma-0 mat-text-accent">N/A</h4>
                  </div>
                  <div class="col-12">
                    <button class="button-style button-back" type="button" matStepperPrevious>Anterior</button>
                    <button class="button-style button-next" type="button" matStepperNext>Siguiente</button>
                  </div>
                </div>
              </div>

            </div>
          </mat-step>
          <mat-step [stepControl]="calculatorFormGroup">
            <ng-template matStepLabel>Resumen Servidor Actual</ng-template>
            <mat-form-field appearance="standard" class="col-xs-12 col-sm-3 col-md-3" style="left: 250px;">
              <mat-label>Periodo de Contratacion</mat-label>
              <mat-select [compareWith]="compareObjects" name="PeriodoContrato"
                formControlName="PeriodoContrato" [(ngModel)]="cloudServerCalcOutObj.PeriodoContrato"
                (selectionChange)="onChanges()" placeholder="PeriodoContrato">
                <mat-option *ngFor="let item of periodoContratoList" [(value)]="item.Name">
                  {{item.Name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="row margin-in-row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6" style="margin-bottom:10px;">
                    <mat-grid-list cols="3" rowHeight="40px" class="grid-style" *ngIf="cloudServerCalcInObj.resumenMrc != null" >
                      <mat-grid-tile [style.background]="colortitle" [style.color]="texColor" class="text-center"> Indicadores </mat-grid-tile>
                      <mat-grid-tile [style.background]="colortitle" [style.color]="texColor" class="text-center"> MRC Sugerido</mat-grid-tile>
                      <mat-grid-tile [style.background]="colortitle" [style.color]="texColor" class="text-center"> MRC Manual </mat-grid-tile>
                      <mat-grid-tile [style.background]="color" [style.color]="texColor" class="text-center">ROI</mat-grid-tile>
                      <mat-grid-tile>{{ cloudServerCalcInObj.resumenIndicadores?.RoiPorContrato}}</mat-grid-tile>
                      <mat-grid-tile> {{ cloudServerCalcInObj.resumenIndicadores?.RoiMrcIndicadores }}</mat-grid-tile>
                      <mat-grid-tile [style.background]="color" [style.color]="texColor">Margen</mat-grid-tile>
                      <mat-grid-tile *ngIf="cloudServerCalcInObj.configValidation"> {{ cloudServerCalcInObj.resumenIndicadores?.MargenPorContrato | percent:'1.1-1'}}</mat-grid-tile>
                      <mat-grid-tile *ngIf="!cloudServerCalcInObj.configValidation"> Configuración invalida</mat-grid-tile>
                      <mat-grid-tile  *ngIf="cloudServerCalcInObj.configValidation" > {{  cloudServerCalcInObj.resumenIndicadores?.MargenIndicadores | percent:'1.1-1'}} </mat-grid-tile>
                      <mat-grid-tile *ngIf="!cloudServerCalcInObj.configValidation"> Configuración invalida</mat-grid-tile>

                      <mat-grid-tile [style.background]="colortitle" [colspan]="3" [style.color]="texColor" class="text-center"> Analisis Financiero</mat-grid-tile>
                      <mat-grid-tile *ngIf="GetActions(76)" [style.background]="color" [colspan]="2" [style.color]="texColor" class="text-center" >Capex Real</mat-grid-tile>
                      <mat-grid-tile *ngIf="GetActions(76)" >USD $ {{ cloudServerCalcInObj.resumenCapex.Total }}</mat-grid-tile>
                      <mat-grid-tile *ngIf="GetActions(76)" [style.background]="color" [colspan]="2" [style.color]="texColor" class="text-center">Opex real</mat-grid-tile>
                      <mat-grid-tile *ngIf="GetActions(76)">USD $ {{ cloudServerCalcInObj.resumenOpex.Total }}</mat-grid-tile>
                      <mat-grid-tile [style.background]="color" [colspan]="2" [style.color]="texColor" class="text-center"> <strong>MRC Sugerido </strong></mat-grid-tile>
                      <mat-grid-tile>USD $ {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.MrcSugerido | number : '1.2-2' ) : 'N/A' }}</mat-grid-tile>
                    </mat-grid-list>
                  </div>

                  <div class="col-sm-6" >
                    <div>
                      <div class="card text-center card-style" *ngIf="GetActions(76)">
                        <div class="card-header card-header-style">Tipo</div>
                          <div class="card-body p-0">
                              <mat-form-field appearance="standard" class="col-xs-6 col-sm-6 col-md-6">
                                <mat-label>CAPEX</mat-label>
                                <mat-select [compareWith]="compareObjects" name="CapexReal" formControlName="CAPEXType" placeholder="CAPEX"
                                  [(ngModel)]="cloudServerCalcOutObj.CAPEXType" (selectionChange)="onChanges()">
                                  <mat-option *ngFor="let item of PriceTYPEList" [(value)]="item.Name">
                                    {{ item.Name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              <mat-form-field appearance="standard" class="col-xs-6 col-sm-6 col-md-6">
                                <mat-label>OPEX</mat-label>
                                <mat-select [compareWith]="compareObjects" name="OpexReal" formControlName="OPEXType" placeholder="OPEX"
                                  [(ngModel)]="cloudServerCalcOutObj.OPEXType" (selectionChange)="onChanges()">
                                  <mat-option *ngFor="let item of PriceTYPEList" [(value)]="item.Name">
                                    {{ item.Name }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                          </div>
                      </div>
                      <div class="total-add">
                        <div class="btm-10" class="edit-button">
                          <h5 *ngIf="cloudServerCalcInObj.configValidation"><strong>MRC MANUAL : </strong>{{currencyOptions.prefix}} {{ cloudServerCalcInObj.Cost.MRC }} {{currencyOptions.suffix}} <mat-icon *ngIf="GetActions(75)" (click)="ChangeTotalMRC()" matTooltip="Editar MRC Sugerido">edit</mat-icon> </h5>
                          <h5 *ngIf="!cloudServerCalcInObj.configValidation"><strong>Error de licencia de base de datos.</strong> </h5>
                        </div>
                        <div>
                          <button class="button-style button-back" type="button" matStepperPrevious>Anterior</button>
                          <button [disabled]="loading || !cloudServerCalcInObj.configValidation" class="button-style button-next" (click)="addCloudServerCalculatorOut()">Agregar a la Cotización</button>
                        </div>
                      </div>
                    </div>
                 </div>
                </div>
                <div>
                  <div class="card text-center card-style" *ngIf="GetActions(77)">
                    <div class="card-header card-header-style">Resumen</div>
                    <div class="card-body p-0">
                      <div>
                        <mat-tab-group>
                          <mat-tab label="Resumen CAPEX">
                            <table *ngIf="cloudServerCalcInObj.resumenCapex != null"
                              class="table table-bordered table-hover wl-table" id="capexResume">
                              <thead class="wl-thead">
                                <tr>
                                  <th style="width: 50%;">Característica</th>
                                  <th>Capex Distribuido</th>
                                  <th>Porcentaje de Distribucion</th>
                                </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td class="td-total">
                                  <strong>Total</strong>
                                </td>
                                <td class="td-total">
                                  USD $ {{ cloudServerCalcInObj.resumenCapex.Total  | number : '1.2-2'}}
                                </td>
                                <td class="td-total">
                                  {{ cloudServerCalcInObj.resumenCapex?.PorcentajeTotal | percent:'1.1-1'}}
                                </td>
                              </tr>
                                <tr>
                                  <td>Procesamiento </td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.Procesamiento  | number : '1.2-2'}}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeProcesamiento | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Ram</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.Ram | number : '1.2-2' }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeRam | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Storage</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.Storage | number : '1.2-2' }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeStorage | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Chasis</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.Chasis }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeChasis | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Server Protection</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.ServerProtection | number : '1.2-2'}}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeServerProtection | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Watcher</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenCapex?.Watcher }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenCapex?.PorcentajeWatcher | percent:'1.1-1'}}
                                  </td>
                                </tr>


                              </tbody>
                            </table>
                          </mat-tab>
                          <mat-tab label="Resumen OPEX">
                            <table *ngIf="cloudServerCalcInObj.resumenOpex != null"
                              class="table table-bordered table-hover wl-table" id="capexResume">
                              <thead class="wl-thead">
                                <tr>
                                  <th style="width: 50%;">Característica</th>
                                  <th>Opex Distribuido </th>
                                  <th>Porcentaje de Distribucion</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="td-total">
                                    <strong>Total</strong>
                                  </td>
                                  <td class="td-total">
                                    USD $ {{ cloudServerCalcInObj.resumenOpex.Total }}
                                  </td>
                                  <td class="td-total">
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeTotal | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Licenciamiento VMware</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.LicenciamientoVware }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeLicenciamientoVware | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Licenciamiento RDP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.LicenciamientoRdp }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeLicenciamientoRdp  | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Licenciamiento Backup</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex.LicenciamientoBackup }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeLicenciamientoBackup  | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>OPEX IP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.OpexIp }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeOpexIp | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Licenciamiento Bases de Datos</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.LicenciamientoBD }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeLicenciamientoBD | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Licenciamiento Sistema Operativo</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.LicenciamientoSO }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeLicenciamientoSO | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Replicación</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.Replicacion }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeReplicacion | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Watcher</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenOpex?.Watcher }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenOpex?.PorcentajeWatcher | percent:'1.1-1' }}
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </mat-tab>
                          <mat-tab label="Resumen MRC">
                            <table *ngIf="cloudServerCalcInObj.resumenMrc != null"
                              class="table table-bordered table-hover wl-table" id="capexResume">
                              <thead class="wl-thead">
                                <tr>
                                  <th style="width: 50%;">Característica/Versión</th>
                                  <th>MRC Distribuido</th>
                                  <th>Porcentaje de Distribucion</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="td-total">
                                    <strong>Total</strong>
                                  </td>
                                  <td class="td-total">
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.MrcSugerido }}
                                  </td>
                                  <td class="td-total">
                                    {{ cloudServerCalcInObj.resumenMrc?.PorcentajeMrcSugerido | percent:'1.1-1' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Cores Unidad </td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.CoresUnidad }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeCoresUnidad | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Ram</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.MemoriaRam}}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeMemoriaRam | percent:'1.1-1') : 'N/A' }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Storage</td>
                                  <td>
                                    USD $ {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.StorageTb) :''}}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeMemoriaRam | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>IOPS</td>
                                  <td>
                                    USD $ {{  cloudServerCalcInObj.resumenMrc.Iops }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc.PorcentajeIops | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Base de Datos SQL Enterprise</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.BDSqlEnterprice }}
                                  </td>
                                  <td>
                                    {{  (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeBDSqlEnterprice | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Base de Datos SQL Standard </td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.BDSqlStandard }}
                                  </td>
                                  <td>
                                    {{  (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeBDSqlStandard | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Base de Datos SQL Web</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.BDSqlWeb }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeBDSqlWeb | percent:'1.1-1'):'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Windows Standard X MV</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.WindowsStandard }}
                                  </td>
                                  <td>
                                    {{ cloudServerCalcInObj.resumenMrc?.PorcentajeWindowsStandard | percent:'1.1-1'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>RDP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Rdp }}
                                  </td>
                                  <td>
                                    {{  (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeRdp | percent:'1.1-1') : 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Linux Suse Enterprise</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.PorcentajeLcSuseLinuxEnterprise }}
                                  </td>
                                  <td>
                                    {{  (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeLcSuseLinuxEnterprise | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Linux Suse Enterprise SAP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.LcSuseLinuxEnterpriseSap }}
                                  </td>
                                  <td>
                                    {{   (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeLcSuseLinuxEnterpriseSap | percent:'1.1-1') : 'N/A'  }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Linux Red Hat Enterprise</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.LcRedHatSapEnterprise }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeLcRedHatSapEnterprise | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Linux Red Hat SAP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.PorcentajeLinuxRedHatSap }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeLinuxRedHatSap | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Doubletake</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Doubletake }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeDoubletake | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Zerto</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Zerto }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? ( cloudServerCalcInObj.resumenMrc?.PorcentajeZerto | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Backup</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.BackupServidor }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ?( cloudServerCalcInObj.resumenMrc?.PorcentajeBackupServidor | percent:'1.1-1'):'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Sophos</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Sophos }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ?( cloudServerCalcInObj.resumenMrc?.PorcentajeSophos | percent:'1.1-1') :'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Watcher</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Watcher }}
                                  </td>
                                  <td>
                                    {{  (cloudServerCalcInObj.configValidation) ?(cloudServerCalcInObj.resumenMrc?.PorcentajeWatcher | percent:'1.1-1'): 'N/A'}}
                                  </td>
                                </tr>
                                <tr>
                                  <td>IP</td>
                                  <td>
                                    USD $ {{ cloudServerCalcInObj.resumenMrc?.Ip }}
                                  </td>
                                  <td>
                                    {{ (cloudServerCalcInObj.configValidation) ? (cloudServerCalcInObj.resumenMrc?.PorcentajeIp | percent:'1.1-1') :'N/A' }}
                                  </td>
                                </tr>

                              </tbody>
                            </table>
                          </mat-tab>
                        </mat-tab-group>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </form>
    </div>


    <div class="col-5">

      <div class="fixed-menu">
        <button  *ngIf= "idBundleQuote === null"  matTooltip="Guardar Cotización" class="button" mat-icon-button (click)='createNewQuote()'>
          <mat-icon>save</mat-icon>
        </button>

        <button  *ngIf= "idBundleQuote !== null"  matTooltip="Guardar Cotización" class="button" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>save</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item  (click)='updateBundleQuote()'>
          <span>Guardar </span>
          </button>
          <button mat-menu-item (click)='createNewQuote()'>
            <span>Guardar Como</span>
          </button>
        </mat-menu>
        <button  matTooltip="Mis cotizaciones"  mat-icon-button class="button" (click)="viewQuotes()">
            <mat-icon>folder_shared</mat-icon>
        </button>
         <button  matTooltip="Enviar Cotización"  mat-icon-button class="button" (click)="sendQuote()">
            <mat-icon>email</mat-icon>
        </button>
      </div>
      <div class="card text-center">
        <div class="card-header blue-card">
          <div fxlayout="row wrap" style="flex-flow: row wrap; box-sizing: border-box; display: flex;">
            <div class="mt-1 mb-1" fxflex="100" fxflex.gt-sm="25" fxflex.gt-xs="50"
              style="flex: 1 1 33%;box-sizing: border-box;max-width: 33%;" >
              <h6 class="text-uppercase text-md ma-0 text-bold" *ngIf="GetActions(74)">Total OPEX {{currencyOptions.suffix}}</h6>
              <h4 class="ma-0"*ngIf="GetActions(74)">${{ totaldata().OPEX | number : '1.2-2' }}</h4>
            </div>
            <div class="mt-1 mb-1 text-sm-right text-md-left" fxflex="100" fxflex.gt-sm="25" fxflex.gt-xs="50"
              style="flex: 1 1 33%; box-sizing: border-box; max-width: 33%;" >
              <h6 class="text-uppercase text-md ma-0 text-bold" *ngIf="GetActions(74)">Total CAPEX {{currencyOptions.suffix}}</h6>
              <h4 class="ma-0" *ngIf="GetActions(74)">${{ totaldata().CAPEX | number : '1.2-2' }}</h4>
            </div>
            <div class="mt-1 mb-1 text-xs-left text-sm-center" fxflex="100" fxflex.gt-sm="25" fxflex.gt-xs="50">
              <h6 class="text-uppercase text-sm ma-0 text-bold">Total MRC </h6>
              <h4 class="ma-0 mat-text-accent"> {{currencyOptions.prefix}} {{ totaldata().MRC | number : '1.2-2' }}  {{currencyOptions.suffix}}</h4>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="GetActions(78)">
          <div class="card-header">
              Analisís
          </div>
          <div class="card-body">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Recursos
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="7" rowHeight="50px" class="grid-style">
                  <mat-grid-tile > </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2" [colspan]="2"> DC PRINCIPAL</mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2" [colspan]="2"> DC ALTERNO  </mat-grid-tile>
                  <mat-grid-tile>  </mat-grid-tile>
                  <mat-grid-tile>  </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> RECURSOS</mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> DB </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> NO DB </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> DB </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> NO DB </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> TOTAL DB </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> TOTAL NO DB </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2" [style.color]="texColor2">
                      CORES
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.Cores | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.CoresDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.Cores | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.CoresDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.Cores +  TotalResources().alternate.Cores  | number  }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.CoresDB +  TotalResources().alternate.CoresDB  | number  }}
                    </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2" [style.color]="texColor2">
                      RAM
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.RAM | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.RAMDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.RAM | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.RAMDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.RAM +  TotalResources().alternate.RAM  }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.RAMDB +  TotalResources().alternate.RAMDB  | number  }}
                    </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2" [style.color]="texColor2">
                      STORAGE
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.Storage | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.StorageDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.Storage | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().alternate.StorageDB | number}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.Storage +  TotalResources().alternate.Storage  | number  }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{TotalResources().principal.StorageDB +  TotalResources().alternate.StorageDB  | number  }}
                    </mat-grid-tile>
                </mat-grid-list>
              </mat-expansion-panel>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Financieros
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="6" rowHeight="50px" class="grid-style">
                  <mat-grid-tile > </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> CAPEX REAL {{currencyOptions.suffix}} </mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> CAPEX HUNDIDO {{currencyOptions.suffix}}</mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> OPEX REAL {{currencyOptions.suffix}}</mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> OPEX HUNDIDO  {{currencyOptions.suffix}}</mat-grid-tile>
                  <mat-grid-tile [style.background]="color2" [style.color]="texColor2"> MRC SUGERIDO {{currencyOptions.suffix}} </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2" [style.color]="texColor2">
                      DC PRINCIPAL
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.CAPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost._CAPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.OPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost._OPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.MRC | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2"   [style.color]="texColor2">
                     DC ALTERNO
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().alternate.Cost.CAPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().alternate.Cost._CAPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().alternate.Cost.OPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().alternate.Cost._OPEX | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().alternate.Cost.MRC | number : '1.2-2'}}
                    </mat-grid-tile>
                    <mat-grid-tile [style.background]="color2"   [style.color]="texColor2">
                     TOTALES
                     </mat-grid-tile>
                     <mat-grid-tile >
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.CAPEX  + TotalResources().alternate.Cost.CAPEX  | number : '1.2-2' }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost._CAPEX  + TotalResources().alternate.Cost._CAPEX  | number : '1.2-2' }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.OPEX  + TotalResources().alternate.Cost.OPEX  | number : '1.2-2' }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost._OPEX  + TotalResources().alternate.Cost._OPEX  | number : '1.2-2' }}
                    </mat-grid-tile>
                    <mat-grid-tile>
                      {{currencyOptions.prefix}} {{TotalResources().principal.Cost.MRC  + TotalResources().alternate.Cost.MRC  | number : '1.2-2' }}
                    </mat-grid-tile>
                </mat-grid-list>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="card-header">
          Resumen de la cotización
        </div>
        <div class="card-body">
          <div class="row">
            <mat-accordion *ngIf="resumenCalculatorList.length != 0" class="col-12">
              <mat-expansion-panel *ngFor="let resumenMRCItem of resumenCalculatorList; let i = index">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <button mat-icon-button (click)="deleteServerBySummary(i)">
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="row">
                      <div class="col-10">
                        <strong class="no-wrap"><small>{{ resumenMRCItem.Name}} - <strong>MRC:</strong> USD
                            ${{ resumenMRCItem.Cost.MRC}} <strong>Cores</strong>: {{resumenMRCItem.Cores}} -
                            <strong>RAM</strong>: {{resumenMRCItem.Ram}} - <strong>GB</strong> : {{resumenMRCItem.Gb}}
                          </small></strong>
                      </div>
                      <div class="col-2">
                        <mat-slide-toggle matTooltip="Agregar a Resumen" [color]="'primary'"
                          [checked]="resumenMRCItem.addResumen" (change)="addOrRemoveResumenMethod(resumenMRCItem)">
                        </mat-slide-toggle>
                      </div>
                    </div>
                  </mat-panel-description>

                </mat-expansion-panel-header>
                <mat-list>
                  <mat-list-item>
                    <p mat-line>
                      <strong class="pull-left">
                        Cores Unidad: {{resumenMRCItem.Cores}}
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.CoresUnidad | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p mat-line>
                      <strong class="pull-left">
                        Memoria RAM: {{resumenMRCItem.Ram}}
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.MemoriaRam | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p mat-line>
                      <strong class="pull-left">
                        Storage: {{resumenMRCItem.Gb}}
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.StorageTb | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p mat-line>
                      <strong class="pull-left">
                        IOPS:
                      </strong>
                      <span class="pull-right">
                        {{ resumenMRCItem.resumenMrc?.Iops }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.BDSqlEnterprice != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Base de Datos SQL Enterprise:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.BDSqlEnterprice  | number : '1.2-2'}}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.BDSqlWeb != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Base de Datos BDSqlWeb:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.BDSqlWeb | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.WindowsStandard != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sistema Operativo Windows Standard:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.WindowsStandard  | number : '1.2-2'}}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.LinuxRedHatSap === 0 &&
                  resumenMRCItem.resumenMrc?.LcSuseLinuxEnterprise === 0 &&
                  resumenMRCItem.resumenMrc?.LcSuseLinuxEnterpriseSap === 0 &&
                  resumenMRCItem.resumenMrc?.LcSuseLinuxEnterpriseSap != 0 &&
                  resumenMRCItem.resumenMrc?.WindowsStandard === 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sistema Operativo Linux Free:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.LcSuseLinuxEnterprise }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.LcSuseLinuxEnterprise != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sistema Operativo Suse Linux Enterprise:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.LcSuseLinuxEnterprise | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.LcSuseLinuxEnterpriseSap != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sistema Operativo Suse Linux Enterprise SAP:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.LcSuseLinuxEnterpriseSap | number : '1.2-2' }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.LinuxRedHatSap != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sistema Operativo Red Hat Linux Enterprise SAP:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.LinuxRedHatSap }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Rdp != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        RDP:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Rdp }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.LcRedHatSapEnterprise != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Licenciamiento Linux:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.LcRedHatSapEnterprise }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Doubletake != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Agente:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Doubletake }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Zerto != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Zerto:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Zerto }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.BackupServidor != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Backup:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.BackupServidor }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Sophos != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Sophos:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Sophos }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Watcher != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        Watcher:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Watcher }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="resumenMRCItem.resumenMrc?.Ip != 0">
                    <p mat-line>
                      <strong class="pull-left">
                        IP:
                      </strong>
                      <span class="pull-right">
                        $ {{ resumenMRCItem.resumenMrc?.Ip }}
                      </span>
                    </p>
                  </mat-list-item>
                  <mat-list-item>
                    <p mat-line>
                      <strong class="pull-left">
                        Total
                      </strong>
                      <span class="pull-right">
                        <strong>
                          $ {{ cloudServerCalcInObj.Cost.MRC }}
                        </strong>
                      </span>
                    </p>
                  </mat-list-item>
                </mat-list>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <div class="basket-body">
          <div class="content-basket-empty ng-star-inserted" style="cursor: pointer;"
            *ngIf="resumenCalculatorList.length <= 0 ">
            <div>
              <i class="material-icons"> shopping_cart</i>
              <p><strong>Tu lista de cloud server esta vacia </strong></p>
              <p> Haz click en agregar cotizacion </p>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted text-center">
        </div>
      </div>
    </div>
  </div>
</div>
