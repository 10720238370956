import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-outh2-modal',
  templateUrl: './outh2-modal.component.html',
  styleUrls: ['./outh2-modal.component.scss']
})
export class Outh2ModalComponent implements OnInit {

  public outhCode: string = '';
  public isValid: boolean = true;
  public validateOuthForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<Outh2ModalComponent>,
    private formBuilder: FormBuilder,
  ) {
    this.validateOuthForm = this.formBuilder.group(
      {
        'OuthCode': [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
      });
  }

  ngOnInit() {  }

  cancelClick() {
    this.dialog.close(this.outhCode);
  }

  saveClick() {
    this.dialog.close(this.outhCode);
  }
}
