import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from "../../../tools/grid/grid.component";
import { WlService } from '../../../services/wlservice.service';
import { UserInfoDTO, ProfileDTO, ServiceDTO, AdditionalServiceDTO } from '../../../models/dtos.component';
import { machineOperations } from '../machineOperations/machineOperations.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BroadcastEmitter } from '../../../services/BroadcastEmitter';
import { Globalization } from '../../../globalization/globalization';
import { Constants } from '../../../models/constants.component';
import { ServiceNameModal } from '../service-name-modal/service-name-modal.component';
import { AuthService } from '../../../services/auth/auth.service';
import { LogsService } from 'src/app/services/logs/logs-services.service';

@Component({
    selector: 'machine-settings',
    templateUrl: './machine-settings.component.html',
    styleUrls: ['../dataCenter.component.scss']
})

export class MachineSettings implements OnInit {
    @ViewChild('AdditionalServicesGrid') AdditionalServicesGrid: GridComponent;
    @ViewChild('sidenav') sidenav: MatSidenav;

    machine: ServiceDTO;
    SelectedDisk: AdditionalServiceDTO;

    MachineOperations: machineOperations = new machineOperations(this.service, this.router, this.snackbar, this.modal,this.auth, this.logsServices);
    globalization: any = Globalization;
    constants: Constants = new Constants();
    token: string;
    language: string;
    dataGrid = [];
    public additionalServicesGrid = {
        columns: [
            { name: "State", title: "Descripción" },
            { name: "Configuration.size", title: "Tamaño" },
            { name: "Configuration.diskType", title: "Velocidad" },
            { name: "Configuration.totalMRC", title: "Valor" , format: "currency"},
        ],
        data: [],
        toolbar: [
            { "type": "button", "click": () => { this.AddDisk() }, "text": "AGREGAR DISCO", "align": "left" }
        ],
        filter: "ServiceID",
        refresh: (u) => { this.getAdditionalServices() }
    }

    constructor(private service: WlService, public navbarBroadcast: BroadcastEmitter, private route: ActivatedRoute, public snackbar: MatSnackBar, private router: Router, private modal: MatDialog, public auth: AuthService, private logsServices: LogsService) {
        this.route.params.subscribe(params => {
            this.GetServiceInformation(params["MachineID"]);
        });
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    closeSidenav() {
        this.SelectedDisk = new AdditionalServiceDTO();
    }

    RenameMachineModal(machine: ServiceDTO) {
        let dialogRef = this.modal.open(ServiceNameModal, {
            data: {
                ServiceID: machine.ServiceID,
                ServiceName: machine.ServiceName,
                ID: machine.ID
            },
            width: "440px",
        });
        dialogRef.afterClosed().subscribe(
            result => {
                if(result)
                    this.machine.ServiceName = result;
            }
        )
    }

    getAdditionalServices() {
        this.service.GetAdditionalServices(this.machine.ID).subscribe(
            (response:any) => {
                this.navbarBroadcast.SendMessage({
                    text: this.ReplaceName(this.machine.ServiceName),
                    actions: [
                        { icon: 'add', name: 'Agregar Disco', action: () => { this.AddDisk() } },
                        { icon: 'undo', name: 'Volver a Máquinas', action: () => { this.GoBack() } }
                    ],
                    display: true
                });
                this.dataGrid = [];
                response.forEach(element => {
                        element.Configuration.totalMRC = (element.Configuration.Cost.MRC + element.Configuration.Backup.Cost.MRC );
                        this.dataGrid.push(element);
                });
                this.AdditionalServicesGrid.setData(this.dataGrid);
            },
            error => {

            }
        )
    }

    ngOnInit() {
        this.machine = new ServiceDTO;
        this.navbarBroadcast.SendMessage({
            text: " ",
            actions: []
        });

    }

    AddDisk() {
        this.SelectedDisk = new AdditionalServiceDTO();
        this.SelectedDisk.ServiceParentID = this.machine.ID;
        this.sidenav.open();
    }

    SaveDisk(row) {
        this.AdditionalServicesGrid.pushRow(row);
    }

    GoBack() {
        this.router.navigateByUrl('/home/(main:dataCenter/' + encodeURIComponent(this.machine.VcenterID) + ')', { skipLocationChange: true });
    }

    GetServiceInformation(ServiceID: string) {
        this.service.GetService(ServiceID).subscribe(
            (response:any) => {
                this.machine = response;
                this.getAdditionalServices();
            },
            error => {
                this.router.navigateByUrl('/home/(main:dataCenter/' + this.machine.VcenterID + ')', { skipLocationChange: true });
                this.snackbar.open("Error consultando servicio" + error.error, null, {
                    duration: 3000
                });
            }
        )
    }
    ReplaceName(name) {
        let response = name.replace(/_/g, ' ');
        let capitalizeName = "";
        response.split(' ').forEach(element => {
            if (capitalizeName.length != 0) {
                capitalizeName += " ";
            }
            capitalizeName += element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
        });
        return capitalizeName;
    }
    getMachineImage(machine: ServiceDTO) {
        if (machine.Configuration.OperativeSystem.Os.indexOf("WIN") != -1)
            return "sidenav-detail-windows";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("DEBIAN") != -1)
            return "sidenav-detail-debian";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("CENTOS") != -1)
            return "sidenav-detail-centos";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("UBUNTU") != -1)
            return "sidenav-detail-ubuntu";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("SUSE") != -1)
            return "sidenav-detail-suse";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("REDHAT") != -1)
            return "sidenav-detail-redhat";
        else if (machine.Configuration.OperativeSystem.Os.indexOf("Other") != -1)
            return "sidenav-detail-noos";
        else
            return "sidenav-detail-linux";
    }
    GetMachineState(state: string) {
        let response;
        if (state) {
            response = this.constants.MachineStates.filter(f => f.State == state);
            if (response && response[0]) {
                response = response[0].Name;
            } else {
                response = null;
            }
        }
        return (response ? response : '-');

    }

    GetVMState(state){
        return "circle-"+state;
    }

}
