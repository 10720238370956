import { Component, OnInit } from '@angular/core';
import { Globalization } from '../../globalization/globalization';
import { WlService } from '../../services/wlservice.service';
import { FaqQuestion } from '../../models/dtos.component';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-Faq',
    templateUrl: './Faq.component.html',
    styleUrls: ['./Faq.component.scss']
})
export class FaqComponent implements OnInit {
    preguntas: Array<FaqQuestion> = [];
    leftQuestions: Array<FaqQuestion> = [];
    rightQuestions: Array<FaqQuestion> = [];
    globalization: any = Globalization;
    searchDocument: string = "";
    token: string;
    language: string;

    constructor(private service: WlService, public auth : AuthService ) {
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    ngOnInit() {
        this.getFaq();
    }

    ShowQuestion(question: FaqQuestion, increaseServer: boolean) {
        if (!question.show && increaseServer)
            this.increasePuntuation(question);
        question.show = !question.show;
    }

    increasePuntuation(question: FaqQuestion) {
        question.Points++;
        this.service.IncreasePoints(question.ID).subscribe(
            response => { },
            error => {

            }
        )
    }

    getFaq() {
        this.service.getQuestions().subscribe(
            (response:any) => {
                this.preguntas = response;
                this.leftQuestions = response.slice(0, 4);
                this.rightQuestions = response.slice(4);
            },
            error => {

            }
        );
    }
}