<div class="card cardbck">
    <div class="card-header card-header-color">
        <h2 mat-dialog-title>
            Administración de perfiles
        </h2>
    </div>
    <div class="card-block">
        <form [formGroup]="addRoleForm" novalidate  (ngSubmit)="SaveForm()">
            <br><br>
            <mat-form-field style="width: 100%">
                <input style="color:black !important" required type="text" class="wtxt" [(ngModel)]="data.Name" name="RoleName" matInput
                    placeholder="Nombre" maxlength="50" formControlName="RoleName">
                    <mat-icon matSuffix *ngIf="addRoleForm.get('RoleName').valid && addRoleForm.get('RoleName').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!addRoleForm.get('RoleName').valid && addRoleForm.get('RoleName').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="addRoleForm.get('RoleName').hasError('required') && addRoleForm.get('RoleName').touched">Campo requerido</mat-error>    
                <mat-hint class="wtxt" align="end">{{data && data.Name ? data.Name.length : 0}} / 50</mat-hint>
            </mat-form-field><br><br>
            <div>
                <mat-dialog-actions class="optFix pull-right">
                    <button color="accent" class="btnPosFixClientUser btn" type="submit" [disabled]="!addRoleForm.valid" mat-button >Guardar</button>
                </mat-dialog-actions>
            </div>
        </form>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>