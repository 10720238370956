<div *ngIf="selectedCustomer == null && !isLoading">
  No se encontró el cliente, por favor valida nuevamente
</div>
<mat-card class="mat-card-all-content" *ngIf="selectedCustomer != null">
  <mat-card-header>
    <mat-card-title class="mat-card-title-left">
      <span class="mat-card-title-span">{{selectedCustomer ? selectedCustomer.Name : ""}}</span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="container-fluid">
    <div class="empty-cloud-container" *ngIf="clouds !== null && clouds.length === 0 && isLoading">
      <mat-spinner color="accent" class="cloud-spinner"></mat-spinner>
      <span class="cloud-text">Obteniendo información del cliente</span>
    </div>
    <div class="empty-container-cloud" *ngIf="clouds !== null && clouds.length === 0 && !isLoading">
      <span class="mat-text-cloud">El cliente no cuenta con nubes creadas.</span>
    </div>

<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      Cloud Server
    </ng-template>
    <mat-tab-group class="mat-tab-group" (selectedTabChange)="onCloudChanged($event)">
      <mat-tab *ngFor="let cloud of clouds" class="tab-content">
        <ng-template mat-tab-label>
          {{cloud.DataCenterName}} <span *ngIf="cloud.machines != null">({{cloud.machines.length}})</span>
          <button mat-icon-button [matMenuTriggerFor]="cloudmenu" class="machine-menu-container">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #cloudmenu="matMenu">
            <button mat-menu-item (click)="openNetworkSettings(cloud.DataCenterID)"
              class="edit-network-option">
              <mat-icon>network_wifi</mat-icon>
              <span>Administrar Red</span>
            </button>
          </mat-menu>
        </ng-template>

        <div class="container-fluid machine-container">
          <app-DataCenter [machinesChildren]="{CS: cloud.machines}" [dataCenterID]="cloud.ID" ></app-DataCenter>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-tab>
  <mat-tab *ngIf="validShowPool()">
    <ng-template mat-tab-label>
      Pool de recursos
    </ng-template>
    <mat-tab-group class="mat-tab-group" (selectedTabChange)="onCloudChanged($event)">
      <mat-tab *ngFor="let cloud of clouds" class="tab-content">
        <ng-template mat-tab-label>
          {{cloud.DataCenterName}} <span *ngIf="cloud.machinesResources != null"></span>
          <button mat-icon-button [matMenuTriggerFor]="cloudmenu" class="machine-menu-container">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #cloudmenu="matMenu">
            <button mat-menu-item (click)="openNetworkSettings(cloud.DataCenterID)"
              class="edit-network-option">
              <mat-icon>network_wifi</mat-icon>
              <span>Administrar Red</span>
            </button>
          </mat-menu>
        </ng-template>
        <div class="container-fluid machine-container">
          <div class="row">
          <div class="col-xl-12  col-md-12 col-sm-12">
              <app-DataCenter (SelectedGraphPool)="SelectedGraphPoolMethod($event)" [machines]="services" [machinesChildren]="cloud.machinesResources" [customerInfo]="selectedCustomer"  [dataCenterID]="cloud.ID" ></app-DataCenter>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

  </mat-tab>
</mat-tab-group>


  </mat-card-content>
</mat-card>
