<div class="card">
  <div class="card-block container-fluid margin-bottom-10">
    <div class="card-title modal-title">
      <h6 class="pull-left">{{data.Title}}</h6>
      <div class="pull-right close-button" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <form [formGroup]="TotalValueForm" novalidate (ngSubmit)="savePrice()">
      <div class="full-width margin-top-10" style="display: inline-block">
        <mat-form-field >
          <input matInput placeholder="Total value" [(ngModel)]="resource.value" formControlName="value" >
          <div *ngIf="TotalValueForm.get('value').hasError('required') && TotalValueForm.get('value').touched">
           Campo requerido *</div>
        </mat-form-field>
      </div>
      <div class="full-width margin-top-20">
        <button color="primary" mat-button class="pull-right" type="submit">Aceptar</button>
        <button color="warn" mat-button mat-dialog-close type="button">Cancelar</button>

      </div>
    </form>
  </div>
</div>


