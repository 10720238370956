<mat-horizontal-stepper #stepper [linear]="true">
    <mat-step [stepControl]="bundleSelectFG">
        <ng-template matStepLabel>
            <mat-icon class="mat-24 wl-stepper-header">
                desktop_mac
            </mat-icon>
        </ng-template>
        <div class="service-title">
            <div class="row">
                <div class="col-12">
                    <h6>Seleccione un plan</h6>
                </div>
            </div>
        </div>
        <form [formGroup]="bundleSelectFG">
            <div class="service-container container-fluid">
                <mat-form-field floatLabel="always" class="full-width margin-top-10">
                    <mat-select class="full-width" name="DataCenter" id="DataCenteridform" formControlName="datacenter" [(ngModel)]="DatacenterID"
                        required placeholder="Centro de datos" floatPlaceholder="always">
                        <mat-option *ngFor="let dc of constants.DataCenter" selected [value]="dc.ID">{{dc.Name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="bundleSelectFG.get('datacenter').hasError('required') && bundleSelectFG.get('datacenter').touched">Campo requerido.</mat-error>
                </mat-form-field>
                <mat-radio-group class="row" [(ngModel)]="Os" formControlName="bundle">
                    <mat-radio-button required value="1" class="wl-demo-radio-button">
                        <span class="wl-demo-text">Linux Ubuntu</span>
                        <img style="width: 300px" class="os-img" src="/assets/img/ubuntuLogoBg.png" alt="ubuntu">
                    </mat-radio-button>
                    <mat-radio-button required value="2" class="wl-demo-radio-button">
                        <span class="wl-demo-text">Windows</span>
                        <img style="width: 300px" class="os-img" src="/assets/img/windows.png" alt="Windows">
                    </mat-radio-button>
                </mat-radio-group>

            </div>
            <div class="service-button-container container-fluid">
                <button color="primary" class="pull-right" mat-raised-button matStepperNext type="submit" [disabled]="!bundleSelectFG.valid">Continuar</button>
            </div>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <mat-icon class="mat-24 wl-stepper-header">
                check_circle
            </mat-icon>
        </ng-template>
        <div class="service-title">
            <div class="row">
                <div class="col-12">
                    Confirmación
                </div>
            </div>
        </div>
        <div class="service-container">
            <div class="container-fluid">
                <mat-slide-toggle #accepted class="text-center topremFix colorFixToogle gtxt" [(ngModel)]="confirmedTerms">Acepto los terminos y condiciones</mat-slide-toggle>
                <br>
                <br>
                <button class="pull-right" color="primary" mat-button (click)="ShowTerms()">ver terminos y condiciones</button>
            </div>
        </div>
        <div class="service-button-container">
            <div class="row">
                <div class="col-6 text-left">
                    <div class="bundle-total-price">
                        <span class="total-bundle-text">Total</span>
                        <span class="service-cost total-bundle-cost">$ 0.00</span>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <button class="pull-right" mat-button [ngClass]="(!accepted.checked) ? 'form-disabled' : 'form-enabled-color'" [disabled]="!accepted.checked || loading"
                        (click)="savePlan()">Guardar</button>
                </div>
            </div>
        </div>
    </mat-step>
</mat-horizontal-stepper>