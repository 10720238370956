import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NatDTO, NATSaveRequestDTO, AllNATSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'nat-table',
  templateUrl: './nat-table.component.html',
  styleUrls: ['./nat-table.component.scss']
})
export class NatTableComponent implements OnInit, OnChanges {
    @Input("NatRules") NatRules: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public resultsLength = 10;
    changeOrder: boolean = false;
    dataSource: MatTableDataSource<NatDTO> = new MatTableDataSource();
    saveRequest : AllNATSaveRequestDTO = new AllNATSaveRequestDTO();
    displayedColumns = ['Index','Description', 'Protocol', 'OriginalAddress', 'OriginalPort', 'TranslatedAddress', 'TranslatedPort', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar, private changeDetectorRefs: ChangeDetectorRef) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      this.dataSource.data = this.NatRules;
      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      this.dataSource.data = this.NatRules;
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    }

    RemoveNatRuleModal(rule: NatDTO) {
      this.dialog.open(ConfirmationDialog, {
          data: {
              Title: "Eliminar Regla NAT",
              Message: "Esta seguro?",
              Ok: () => this.RemoveNatRule(rule),
          }
      });
  }

  RemoveNatRule(rule: NatDTO) {
    let req = new NATSaveRequestDTO();

    req.natRules.natRule = [];
    req.natRules.natRule.push(rule);
    req.DatacenterID = this.DatacenterID;
    req.natRules.natRule[0].RuleTag = "0";
    this.wlservice.RemoveNatRule(req).subscribe(
        response => {
            this.OnDelete.emit(rule);
            this.snackbar.open('Regla de NAT eliminada satisfactoriamente', null, {
                duration: 3000,
            });
        },
        error => {
            this.snackbar.open('Error eliminando regla de NAT ' + error.error, null, {
                duration: 3000,
            });
        }
    )
}

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.changeOrder = false;
      this.OnDelete.emit();
    }

    EditNatRule(rule: NatDTO) {
      this.OnEdit.emit(rule);
    }

    RuleUp(index){
      var temp = this.dataSource.data[index - 1];
      this.dataSource.data[index - 1] = this.dataSource.data[index]
      this.dataSource.data[index] = temp;
      this.changeOrder = true;
      this.dataSource.sort = this.sort;
      this.changeDetectorRefs.detectChanges();
    }

    RuleDown(index){
      var temp = this.dataSource.data[index + 1];
      this.dataSource.data[index + 1] = this.dataSource.data[index]
      this.dataSource.data[index] = temp;
      this.changeOrder = true;
      this.dataSource.sort = this.sort;
      this.changeDetectorRefs.detectChanges();
    }
    
    SaveNatRules() {
      this.Loading = true;
      this.saveRequest.nat.natRules.natRule = this.dataSource.data;
      this.saveRequest.DatacenterID = this.DatacenterID;

      for(let rule of this.saveRequest.nat.natRules.natRule){
        rule.RuleId = null;
        rule.RuleTag = null;

        if(rule.Action == "snat")
        {
          rule.OriginalPort = null;
          rule.TranslatedPort = null;
        }
      }
      this.wlservice.SaveAllNat(this.saveRequest).subscribe(
          response => {
              this.snackbar.open('Reglas de NAT guardadas satisfactoriamente.', null, {
                  duration: 2000,
              });
              this.ReloadNetwork();
          },
          error => {
              this.snackbar.open('Error guardando reglas NAT ' + error.error, null, {
                  duration: 2000,
              });
              this.ReloadNetwork();
          }
      )
      
  }
}