<div class="virtualmachine-settings">
    <div *ngIf="machine.ServiceID && machine.State != 'PENDING' && machine.State != 'BUSY'">
        <button mat-icon-button matTooltip="Cambiar nombre" matTooltipPosition="left" (click)="RenameMachineModal(machine)">
            <mat-icon class="mat-24">edit</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Iniciar" matTooltipPosition="left" (click)="PowerOnVM(machine)" *ngIf="machine.State=='POWER_OFF'">
            <mat-icon class="mat-24">play_circle_outline</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Detener" matTooltipPosition="left" (click)="PowerOffVm(machine)" *ngIf="machine.State=='POWER_ON'">
            <mat-icon class="mat-24">stop</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Suspender" matTooltipPosition="left" (click)="SuspendVM(machine)" *ngIf="machine.State != 'SUSPEND' && machine.State !='POWER_OFF'">
            <mat-icon class="mat-24">pause_circle_outline</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Resumir" matTooltipPosition="left" (click)="ResumeVm(machine)" *ngIf="machine.State == 'SUSPEND'">
            <mat-icon class="mat-24">pause_circle_filled</mat-icon>
        </button>
        <hr class="virtualmachine-separator"/>
        <button mat-icon-button matTooltip="Configuración" matTooltipPosition="left" (click)="LoadMachineConfiguration()">
            <mat-icon class="mat-24">settings</mat-icon>
        </button>
    </div>
</div>
<div class="sidenav-detail-header {{getMachineImage(machine)}}">
    <span># {{machine.ServiceID}}</span>
    <div>
        {{ReplaceName(machine.Configuration.OperativeSystem.Os)}}
    </div>
</div>
<div class="container-fluid virtualmachine-detail-settings">
    <div class="row container-fluid no-margin">
        <div class="col-12 no-default-padding virtualmachine-subtitle">
          <h6>
            {{'DATACENTER.MACHINE_CONFIGURATION' | translate}}
          </h6>
        </div>
        <div class="col-4">
          <div class="virtualmachine-detail-value virtualmachine-state-circle">
              <mat-icon [ngClass]="GetOperationsState(machine.State)" [matTooltip]="GetMachineState(machine.State)" matTooltipPosition="below">fiber_manual_record</mat-icon>
          </div>
          <div class="virtualmachine-detail-text">
            {{'DATACENTER.MACHINESTATUS' | translate}}:
          </div>
        </div>
        <div class="col-4">
          <div class="virtualmachine-detail-value">
            {{machine.Configuration.Cores.Value}}
          </div>
          <div class="virtualmachine-detail-text">
            {{'DATACENTER.CORES' | translate}}:
          </div>
        </div>
        <div class="col-4">
          <div class="virtualmachine-detail-value">
            {{machine.Configuration.Ram.Value}}
          </div>
          <div class="virtualmachine-detail-text">
            {{'DATACENTER.RAM' | translate}}:
          </div>
        </div>
        <div class="col-12 no-default-padding virtualmachine-subtitle">
          <h6>
            {{'DATACENTER.NETWORK_CONFIGURATION' | translate}}
          </h6>
        </div>
        <div class="col-12">
          <div class="virtualmachine-detail-text">
              {{'DATACENTER.PRIVATE_IP' | translate}}:
          </div>
          <div class="virtualmachine-detail-value">
              {{machine.Configuration.PrivateIp}}
          </div>
        </div>
        <div class="col-12">
          <div class="virtualmachine-detail-text">
              {{'DATACENTER.MAC_ADDRESS' | translate}}:
          </div>
          <div class="virtualmachine-detail-value">
              {{machine.Configuration.MacAddress}}
          </div>
        </div>
        <div class="col-12">
          <div class="virtualmachine-detail-text">
              {{'DATACENTER.RDP_ACCESS' | translate}}:
          </div>
          <div class="virtualmachine-detail-value">
              {{machine.Configuration.RemoteAccess}}
          </div>
        </div>
        <div class="col-12">
          <div class="virtualmachine-detail-text">
              {{'DATACENTER.PASSWORD' | translate}}:
          </div>
          <div class="virtualmachine-detail-value">
              <mat-form-field>
                  <input type="password" id="passwordMachine" matInput disabled [(ngModel)]="machinePassword">
                  <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="showPassword()">
                      <mat-icon>visibility</mat-icon>
                  </button>
              </mat-form-field>
          </div>
        </div>
    </div>
</div>
