import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormControl } from '@angular/forms';
import { WlService } from '../../../services/wlservice.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PasswordEditDialog } from '../../accountConfiguration/accountConfiguration.component';
import { Globalization } from '../../../globalization/globalization';
import { BroadcastEmitter } from '../../../services/BroadcastEmitter';
import { SupportContact } from '../../../models/dtos.component';
import { AuthService } from '../../../services/auth/auth.service';


@Component({
  selector: 'app-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})

export class ControlPanelComponent implements OnInit {
  Changelog: Array<any> = [];
  user: any = {};
  supportContact : SupportContact = null;
  colorHome = {
    blue: '#3a60a3',
    green: '#3aad60',
    purple: '#64277a',
    darkBlue: '#075384',
    orange: '#ed8a20',
    darkGray: '#424242',
    mediumGray: '#636363',
    lightGray: '#8e8e8e',
    darkWhite: '#e5e5e5',
  }
  myControl = new FormControl();
  public loading: boolean = true;
  private AllUsers: Array<any> = [];
  globalization: any = Globalization;
  contactLoading :boolean = true;
  token: string;
  language: string;
  filteredOptions: Observable<string[]>;
  constructor(private service: WlService, private router: Router, public dialog: MatDialog, private navbarBroadcast: BroadcastEmitter,  public auth: AuthService) {
    this.token = auth.getToken();
    this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
  }

  getSupport(){
    this.service.GetFavouriteSupportContact().subscribe(
      (response:any)=>{
        this.contactLoading = false;
        this.supportContact = response;
      },
      error=>{
        this.contactLoading = false;
      }
    )
  }

  getAllUsers() {
    this.service.getAllUsers().subscribe(
      (response:any) => {
        console.log("all users",response )
        this.AllUsers = response;
      }
    )
  }

  getUserInformation() {
    this.service.GetUserInformation().subscribe(
      response => {
   
        this.user = response;
        if (this.user.DefaultPassword && !JSON.parse(atob(this.auth.getToken().split(".")[1])).Impersonation) {
          this.dialog.open(PasswordEditDialog, {
            hasBackdrop: true, disableClose: true
          });
        }
        this.loading = false;
        this.user.initials = this.user.Name.split(' ').map(function (e, i) { if (i < 2) { return e.substring(0, 1).toUpperCase() } else { return '' } }).join('')
      },
      error => {
        if (error.status == 401)
          this.router.navigateByUrl('/');
      }
    )
  }

  getChangeLog() {
    this.service.getChangeLog().subscribe(
      (response:any) => {
        this.Changelog = response;
      },
      error => {
        console.log(error);
      }
    )
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges
      .startWith(null)
      .map(val => val ? this.filter(val) : this.AllUsers.slice());
    this.getUserInformation();
    //this.getAllUsers();
    this.getChangeLog();
    this.getSupport();
    this.navbarBroadcast.SendMessage({
      text: ""
    })
  }

  filter(val: string): string[] {
    return this.AllUsers.filter(option => new RegExp(`^${val}`, 'gi').test(option.Name));
  }
}
