import { Component, Input, Output, OnInit, EventEmitter, OnChanges } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { RoutingDTO, RoutingSaveRequestDTO, InterfaceDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';


@Component({
    selector: 'routing-settings',
    templateUrl: './routing-configuration.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class RoutingConfiguration implements OnInit, OnChanges {
    @Input("RoutingRule") Rule: RoutingDTO;
    @Input("RoutingRuleId") RuleID: string;
    @Input("DatacenterID") DatacenterID: string;
    @Input("sidenav") sidenav: MatSidenav;
    @Input("Vnics") Vnics: Array<InterfaceDTO>;
    @Output() NewBindings = new EventEmitter();
    constants: Constants = new Constants();
    loading: boolean = false;
    globalization: any = Globalization;
    token: string;
    language: string;
    routingForm : any;
    saveRoutingRequest : RoutingSaveRequestDTO = new RoutingSaveRequestDTO();
    routing: any = null;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder:FormBuilder,  public auth: AuthService) {
        this.token =  this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.routingForm = this.formBuilder.group({
            'description' : [{value: '', disabled:this.loading},[Validators.required]],
            'vnic' : [{value: '', disabled:this.loading},[Validators.required]],
            // tslint:disable-next-line:max-line-length
            'network' : [{value: '', disabled:this.loading},[Validators.required, Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1}(\/([0-9]|[1-2][0-9]|3[0-2]))$/)]],
            // tslint:disable-next-line:max-line-length
            'nextHop' : [{value: '', disabled:this.loading},[Validators.required, Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]]
        });
        this.Rule = new RoutingDTO();
    }

    ngOnInit() {
        this.Rule = new RoutingDTO();
    }

    ngOnChanges(changes) {
    }

    saveRule() {
        this.loading = true;
        this.routing = null;
        this.Rule.Mtu = "9000";
        this.Rule.AdminDistance = "1";
        this.wlservice.GetNetworkInformation(this.DatacenterID).subscribe(
        (response:any) => {
            this.routing = response.Routing;

            if(this.RuleID != null)
            {
                this.routing.StaticRoutes.Route.splice(this.RuleID,1);
            }
            this.routing.StaticRoutes.Route.push(this.Rule);
            this.saveRoutingRequest.DatacenterID = this.DatacenterID;
            this.saveRoutingRequest.StaticRouting = this.routing;
            this.wlservice.SaveRouting(this.saveRoutingRequest).subscribe(
                response => {
                    this.snackbar.open('Regla de Routing guardada satisfactoriamente.', null, {
                        duration: 2000,
                    });
                    this.loading = false;
                    this.sidenav.close();
                    this.NewBindings.emit(response);
                },
                error => {
                    this.loading = false;
                    this.snackbar.open('Error guardando regla Routing ' + error.error, null, {
                        duration: 2000,
                    });
                }
            )
        },
        error => {

        });
    }
}
