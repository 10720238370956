<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Rule.RuleId == null">{{'NAT_CONFIGURATION.ADD_NAT_RULE' | translate}}</h5>
        <h5 *ngIf="Rule.RuleId != null">{{'NAT_CONFIGURATION.EDIT_NAT_RULE' | translate}}</h5>
    </div>
    <form (ngSubmit)="saveRule()" [formGroup]="natRuleForm" novalidate>
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'NAT_CONFIGURATION.INITIAL_SETUP' | translate}}</h6>
            </div>
            <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'NAT_CONFIGURATION.PROTOCOL' | translate}}"
                name="Protocolo" required id="protocolo" formControlName="protocolo" [(ngModel)]="Rule.Protocol" floatPlaceholder="always">
                <mat-option *ngFor="let protocol of constants.Protocols" [value]="protocol">{{protocol}}</mat-option>
            </mat-select>
            <div class="error-messages col-12">
                <div *ngIf="natRuleForm.get('protocolo').hasError('required') && natRuleForm.get('protocolo').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                <textarea class="txtSmall txtArea" name="description" required id="description" formControlName="description" [(ngModel)]="Rule.Description"
                    matInput placeholder="{{'NAT_CONFIGURATION.DESCRIPTION' | translate}}" maxlength="50">
                        </textarea>
                <mat-icon matSuffix *ngIf="natRuleForm.get('description').valid && natRuleForm.get('description').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!natRuleForm.get('description').valid && natRuleForm.get('description').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="natRuleForm.get('description').hasError('required') && natRuleForm.get('description').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{Rule.Description ? Rule.Description.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="sidenav-subtitle col-12">
                <h6>{{'NAT_CONFIGURATION.SOURCE_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'NAT_CONFIGURATION.IP_SOURCE' | translate}}"
                name="IpSource" required id="ipSource" formControlName="ipSource" [(ngModel)]="Rule.OriginalAddress" floatPlaceholder="always">
                <mat-option *ngFor="let publicip of PublicIps" [value]="publicip">{{publicip}}</mat-option>
            </mat-select>
            <div class="error-messages col-12">
                <div *ngIf="natRuleForm.get('ipSource').hasError('required') && natRuleForm.get('ipSource').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                <input name="originalPort" required id="originalPort" formControlName="originalPort" [(ngModel)]="Rule.OriginalPort" required
                    matInput placeholder="{{'NAT_CONFIGURATION.SOURCE_PORT' | translate}}" maxlength="50">
                <mat-icon matSuffix *ngIf="natRuleForm.get('originalPort').valid && natRuleForm.get('originalPort').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!natRuleForm.get('originalPort').valid && natRuleForm.get('originalPort').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="natRuleForm.get('originalPort').hasError('required') && natRuleForm.get('originalPort').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{ Rule.OriginalPort ? Rule.OriginalPort.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="sidenav-subtitle col-12">
                <h6>{{'NAT_CONFIGURATION.DESTINATION_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'NAT_CONFIGURATION.IP_DESTINATION' | translate}}"
                name="ipDestination" required id="ipDestination" formControlName="ipDestination" [(ngModel)]="Rule.TranslatedAddress"
                floatPlaceholder="always">
                <mat-option *ngFor="let privateip of PrivateIps" [value]="privateip">{{privateip}}</mat-option>
            </mat-select>
            <div class="error-messages">
                <div *ngIf="natRuleForm.get('ipDestination').hasError('required') && natRuleForm.get('ipDestination').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                <input name="portDestination" required id="portDestination" formControlName="portDestination" [(ngModel)]="Rule.TranslatedPort"
                    required matInput placeholder="{{'NAT_CONFIGURATION.DESTINATION_PORT' | translate}}" maxlength="30">
                <mat-icon matSuffix *ngIf="natRuleForm.get('portDestination').valid && natRuleForm.get('portDestination').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!natRuleForm.get('portDestination').valid && natRuleForm.get('portDestination').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages col-12">
                        <div *ngIf="natRuleForm.get('portDestination').hasError('required') && natRuleForm.get('portDestination').touched">{{'NAT_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{Rule.TranslatedPort ? Rule.TranslatedPort.length : 0}} / 30</mat-hint>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'NAT_CONFIGURATION.CANCEL' | translate}}</button>
            </div>
            <div class="col-6 full-width">
                <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!natRuleForm.valid">{{'NAT_CONFIGURATION.SAVE' | translate}}</button>
            </div>
        </div>
    </form>
</div>
