import { Component, OnInit, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { GridComponent } from "../../../tools/grid/grid.component";
import { WlService } from '../../../services/wlservice.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SupportContact } from '../../../models/dtos.component';
import { BroadcastEmitter } from '../../../services/BroadcastEmitter';
import { ConfirmationDialog } from '../../../tools/modals/confirmation/modal.component';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Globalization } from '../../../globalization/globalization';
import {CustomFormValidator} from '../../../tools/CustomValidator';

@Component({
    selector: 'admin-support-contact', 
    templateUrl: './admin-support-contact.component.html',
    styleUrls: ['../support.component.scss']
})

export class AdminSupportContact implements OnInit {
    @Input() supportContact: SupportContact;
    @Output() savedContact = new EventEmitter<SupportContact>();
    @Input() sidenavin: MatSidenav;
    loading : boolean = false;
    supportForm: any;  
     constructor(public service: WlService, public snackbar: MatSnackBar, public navbarBroadcast: BroadcastEmitter, private formBuilder: FormBuilder) {
        this.supportForm = this.formBuilder.group({
            'name' : ['',[Validators.required]],
            'phone' : ['',[Validators.required]],
            'email' : ['',[Validators.required,Validators.minLength(10),CustomFormValidator.EmailValidator]],
            'country' : ['',[Validators.required]],
        });
    }

    ngOnInit() {

    }

    saveContact(){
        this.loading = true;
        this.service.SaveContact(this.supportContact).subscribe(
            (response:any)=>{
                this.loading = false;
                this.savedContact.emit(response);
                this.sidenavin.close();
            },
            error=>{
                this.loading = false;
                this.snackbar.open('Error guardando contacto. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}