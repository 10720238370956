import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

export const TOKEN_NAME: string = 'usertoken';

export function tokennGetter() { 
  return localStorage.getItem(TOKEN_NAME);
}

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem(TOKEN_NAME);
    if(token!=null){
      return !this.jwtHelper.isTokenExpired(token);
    }
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  public getTokenExpirationDate(token: string): Date {
    return this.jwtHelper.getTokenExpirationDate(token); 
  }

  public tokenPayload( token: string) :object{
    return  this.jwtHelper.decodeToken(token);
  }

  public clearStorage(){
    return localStorage.clear();
  }



}



