import { Component, Input, OnInit } from '@angular/core';
import { SnatRulesDto } from '../DTO/snat-rules-dto';


@Component({
  selector: 'app-firewall-rules-oneone',
  templateUrl: './firewall-rules-oneone.component.html',
  styleUrls: ['./firewall-rules-oneone.component.scss']
})

export class FirewallRulesOneoneComponent implements OnInit {
  @Input() item:SnatRulesDto[] = []; // decorate the property with @Input()
  displayedColumns: string[] = ['Interface', 'External IP', 'Internal IP', 'Destination IP', 'Description'];
  dataSource = this.item;

  constructor() {
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource =  this.item;
    }
  }

}
