import { Component, OnInit, Input, ViewChild, Inject, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globalization } from '../../globalization/globalization';
import { WlService } from '../../services/wlservice.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'simple-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    providers: [CurrencyPipe]
})

export class GridComponent implements OnInit {
    @ViewChild('FilterInput') FilterInput: any;
    public columns = [];
    private tempData = [];
    public filtering = false;
    public filterText = "";
    public pageNumber = 0;
    public pageSize = 5;
    public pageData = [];
    public loading = true;
    globalization: any = Globalization;
    token: string;
    language: string;
    @Input() settings: any = {
        columns: [],
        data: [],
        toolbar: [],
        filter: "",
        refresh: null,
        hideActions: null,
        actions: null,
        pageSize: 5,
        id: "ID",
        sortable: false,
        minWidth: "100%",
    };

    constructor(private currencyPipe: CurrencyPipe, public dialog: MatDialog, public service: WlService, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    getValue(row, column) {
        if (typeof (row[column.name]) == "boolean") {
            return row[column.name] ? '<i class="material-icons" style="color:green">done</i>' : '<i class="material-icons" style="color:red">clear</i>';
        } else {
            var retValue = "";
            column.name.split('.').forEach((u) => {
                if (retValue != "")
                    retValue = retValue[u];
                else
                    retValue = row[u];
            });
            if (column.format) {
                if (column.format == "currency")
                    retValue = this.currencyPipe.transform(retValue, 'USD', true);
                else
                    console.log("format not supported");
            }
            return retValue == null ? "" : retValue;
        }
    }

    GetIcon(row, column) {

    }
    GetGridIconName(row, name){
        return "icon-" + row[name];
    }
    viewDetail(row, column) {
        if (column.showDetail) {
            let data = { title: column.title, data: this.getValue(row, column).split(", ") }
            this.dialog.open(GridDetailComponent, { data: data });
        }
    }

    ShowFilter() {
        this.filtering = true;
        this.FilterInput.nativeElement.focus();
    }

    ngOnChanges(changes) {
        this.settings = changes['settings'].currentValue;
        if (this.settings) {
            if (this.settings.data.length > 0) {
                this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
            }
        }
    }

    filterGrid() {
        this.pageNumber = 0;
        if (this.filterText == "")
            this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
        else {
            this.pageData = this.settings.data.filter(d => d[this.settings.filter] != null && d[this.settings.filter].toString().toLowerCase().includes(this.filterText.toString().toLowerCase())).slice(this.pageNumber, this.pageSize);
        }
    }

    clearFilter() {
        if (this.filterText == "")
            this.hideFilter();
        else {
            this.filterText = "";
            this.pageNumber = 0;
            this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
            this.FilterInput.nativeElement.focus();
        }
    }

    hideFilter() {
        if (this.filterText == "")
            this.filtering = false;
    }

    public getData() {
        return this.settings.data;
    }

    public setData(data) {
        this.loading = false;
        this.settings.data = data;
        this.pageNumber = 0;
        this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
    }

    public RemoveRow(row) {
        this.settings.data = this.settings.data.filter(f => f != row);
        this.pageNumber = 0;
        this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
    }

    public pushRow(row) {
        var update = false;
        this.settings.data.forEach((i) => {
            if (i[this.settings.id] != null && i[this.settings.id] != undefined && row[this.settings.id] != null && row[this.settings.id] != undefined && row[this.settings.id] == i[this.settings.id]) {
                i = row;
                update = true;
            }
        });
        if (!update)
            this.settings.data.push(row);
        this.pageNumber = 0;
        this.pageData = this.settings.data.slice(this.pageNumber, this.settings.pageSize == null ? this.pageSize : this.settings.pageSize);
    }
    log(a) {
        console.log(a);
    }

    ngOnInit() {
    }

    previousPage() {
        if (this.pageNumber > 0) {
            this.pageNumber--;
            this.pageData = this.settings.data.slice(this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize), (this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize)) + (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize));
        }
    }

    nextPage() {
        if (this.pageNumber < (this.settings.data.length / (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize)) - 1) {
            this.pageNumber++;
            this.pageData = this.settings.data.slice(this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize), (this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize)) + (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize));
        }
    }


    GetActionButtons(index, row) {
        if (!this.settings.sortable) {
            return this.settings.actions.filter(f => !f.hide || !f.hide(row))
        }
        let response = Object.assign([], this.settings.actions);
        /*
        if (index > 0)
            response.push({
                text: "Subir",
                action: (u) => { this.UpRow(index, row) },
            });
        if (index < this.settings.data.length - 1)
            response.push({
                text: "Bajar",
                action: (u) => { this.DownRow(index, row) },
            });
        */
        return response;
    }

    UpRow(index, row) {
        this.settings.data.splice(index, 1);
        this.settings.data.splice(index - 1, 0, row);
        this.pageData = this.settings.data.slice(this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize), (this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize)) + (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize));
    }
    DownRow(index, row) {
        this.settings.data.splice(index, 1);
        this.settings.data.splice(index + 1, 0, row);
        this.pageData = this.settings.data.slice(this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize), (this.pageNumber * (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize)) + (this.settings.pageSize == null ? this.pageSize : this.settings.pageSize));
    }
}

@Component({
    selector: 'grid-detail',
    template: `
        <div class="card cardbck">
            <div class="card-header bckTitle">
                <h6>{{data.title}}</h6>
            </div>
            <div class="card-block">
                <mat-list>
                    <mat-list-item *ngFor="let item of data.data">{{item}}</mat-list-item>
                </mat-list>
            </div>
        </div>
    `,
    styleUrls: ['./grid.component.scss']
})
export class GridDetailComponent {
    rememberEmail: string;
    loading: boolean = false;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GridDetailComponent>) { }

    closeDialog() {
      this.dialogRef.close();
    }
}
