import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from 'src/app/services/wlservice.service';
import { PfSenseMenuService } from './pf-sense-menu.service';
import { NewPfSenseIpComponent } from './pfsense-ip/forms/new-pf-sense-ip/new-pf-sense-ip.component';
import { RulesResponseDetailsDto} from './DTO/rules-response-detail-dto';
import { PfenseRequestDto } from './DTO/pfsenseRequest-dto';


@Component({
  selector: 'app-pf-sense-network-settings',
  templateUrl: './pf-sense-network-settings.component.html',
  styleUrls: ['./pf-sense-network-settings.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class PfSenseNetworkSettingsComponent implements OnInit {
  menus = [];
  PfenseRequest :PfenseRequestDto = new PfenseRequestDto() ;
  RulesResponseDetail : RulesResponseDetailsDto = new  RulesResponseDetailsDto();
  loading: boolean;
  activeItem : string = 'alias';

  constructor(private menuService : PfSenseMenuService,  
    public dialog: MatDialog, 
    private wlservice: WlService, 
    public snackbar: MatSnackBar,
    private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
    this.PfenseRequest.datacenterId = params.datacenterID;
    this.PfenseRequest.customerId =  params.customerID;

    //this.PfenseRequest.datacenterId =  '1565';
    //this.PfenseRequest.customerId = null
    });
    this.menus = this.menuService.getMenuList();
    this.loading= false;
   }

  ngOnInit() {
    this.getNetworkRules();
  }

  getSideBarState() {
    return this.menuService.getSidebarState();
  }

  toggle (level :number, currentMenu) {
    if (currentMenu.type === 'dropdown') {
    
        this.menus.forEach(element => {
          if(level ===1){
            if (element === currentMenu) {
              currentMenu.active = !currentMenu.active;
            } else {
              element.active = false;
            }
          }else if(level ===2){
              
            if( element.submenus  && element.submenus.length > 0){
              element.submenus.forEach(data => {
                if (data === currentMenu) {
                  currentMenu.active = !currentMenu.active;
                } else {
                  data.active = false;
                }
              });
            }
          }
        });
      
    }
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  hasBackgroundImage() {
    return this.menuService.hasBackgroundImage;
  }
  

  getNetworkRules(){
     this.wlservice.getNeworkPfsenseRules(this.PfenseRequest).subscribe(
          response => {
            this.loading = false;
            if (response.data){
              this.RulesResponseDetail = response.data;
            } else{
              this.snackbar.open('No se encontraron redes de tipo PfSense', null, {
                duration: 2000,
              });
            }
          },
          error => {
            this.loading = false;
            this.snackbar.open('No se encontró una red de tipo pfsense', null, {
              duration: 2000,
            });
          }
        ); 

  }


  public displayItem(data: string ) {
    this.activeItem = data;
  }

  addPfsense(){
    const d = this.dialog.open(NewPfSenseIpComponent, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: 'Agregar ALLIACES'
      }
    });

    d.afterClosed().subscribe(result => {
      if (result) {

        console.log("result", result);
      
       /* this.service.sendCalculatorCloudServerSendEmail(this.dataSendMail).subscribe(
          response => {
            this.loading = false;
            this.snackbar.open('Correo Enviado Correctamente', null, {
              duration: 2000,
            });
          },
          error => {
            this.loading = false;
            this.snackbar.open('Ocurrió un error por favor intentelo nuevamente'+ error, null, {
              duration: 2000,
            });
          }
        ); */

      }
    });

    
  }

 

}
