import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'terms-component',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
    rememberEmail: string;
    loading: boolean = false;

    constructor(public dialogRef: MatDialogRef<TermsComponent>, public snackbar: MatSnackBar) {
    }

    closeDialog() {
       this.dialogRef.close();
    }
}
