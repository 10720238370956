import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggled = false;
  _hasBackgroundImage = true;
  menus = [
    {
      title: 'general',
      type: 'header'
    },
    {
      title: 'Inicio',
      icon: 'fa fa-home',
      active: false,
      type: 'simple',
      component : "home",
      Id :''
    },
    {
      title: 'Centro de datos',
      icon: 'fa fa-server',
      active: false,
      type: 'dropdown',
      Id :'',
      badge: {
        text: '3',
        class: 'badge-danger'
      },
      submenus: [
        {
          title: 'Colombia',
          active: false,
          icon : 'fa fa-globe',
          type: 'dropdown',
          submenus:[
            {
              title: 'Seidor Colombia sas',
              active: false,
              icon : 'fa fa-user',
              type: 'simple',
              component : "datacenter",
              Id :'V5fEK79GV/MMN6hLbE/ZvQ=='
            },
            {
              title: 'Seidor Colombia Auren',
              active: false,
              icon : 'fa fa-user',
              type: 'simple',
              component : "datacenter",
              Id :'V5fEK79GV/MMN6hLbE/ZvQ=='
            },
            {
              title: 'Seidor Colombia National Clinics',
              active: false,
              icon : 'fa fa-user',
              type: 'simple',
              component : "datacenter",
              Id :'V5fEK79GV/MMN6hLbE/ZvQ=='
            }
          ]

        },
        {
          title: 'Argentina',
          icon : 'fa fa fa-globe',
          active: false,
          type: 'simple',
          Id :''
        },
        {
          title: 'Honduras',
          icon : 'fa fa fa-globe',
          active: false,
          type: 'simple',
          Id :''
        }
      ]
    },
    {
      title: 'Servicios',
      icon: 'fa fa-align-center',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Servidores Virtuales',
          icon :'fa fa fa-globe',
          component : "virtualServers",
          Id :''
        },
        {
          title: 'Cotizador',
          icon :'fa fa fa-globe',
          component : "calculator",
          Id :''
        }
      ]
    },
    {
      title: 'Administración',
      icon: 'fa fa-globe',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Mi portal',
          icon :'fa fa fa-globe',
          component : "calculator",
          Id :''
        },
        {
          title: 'Cuentas de usuario',
          icon :'fa fa fa-globe',
          component : "calculator",
          Id :''
        }
      ]
    },
    {
      title: 'Configuración',
      icon: 'fa fa-cog',
      active: false,
      type: 'dropdown',
      submenus: [
        {
          title: 'Mi cuenta',
          icon :'fa fa fa-globe',
          component : "Administration",
          Id :''
        }
      ]
    },
    {
      title: 'Extra',
      type: 'header'
    },
    {
      title: 'Información',
      icon: 'fa fa-info-circle',
      active: false,
      type: 'simple',
      badge: {
        text: 'Beta',
        class: 'badge-primary'
      },
    },
    {
      title: 'Maquinas Pendientes',
      icon: 'fa fa-folder',
      active: false,
      type: 'simple'
    },
    {
      title: 'Entrega de credenciales',
      icon: 'fa fa-id-card',
      active: false,
      type: 'simple'
    }  
  ];
  constructor() { }

  toggle() {
    this.toggled = ! this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  getMenuList() {
    return this.menus;
  }

  get hasBackgroundImage() {
    return this._hasBackgroundImage;
  }

  set hasBackgroundImage(hasBackgroundImage) {
    this._hasBackgroundImage = hasBackgroundImage;
  }
}
