<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Rule.Description == null">{{'ROUTING_CONFIGURATION.ADD_ROUTING' | translate}}</h5>
        <h5 *ngIf="Rule.Description != null">{{'ROUTING_CONFIGURATION.MODIFY_ROUTING' | translate}}</h5>
    </div>
    <form [formGroup]="routingForm" novalidate (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'ROUTING_CONFIGURATION.SERVICE_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input required name="description" id="descriptionRouting" formControlName="description" [(ngModel)]="Rule.Description"
                    matInput placeholder="{{'ROUTING_CONFIGURATION.DESCRIPTION' | translate}}" maxlength="50">
                    <mat-icon matSuffix *ngIf="routingForm.get('description').valid && routingForm.get('description').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!routingForm.get('description').valid && routingForm.get('description').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="!routingForm.get('description').hasError('required') && !routingForm.get('description').valid && routingForm.get('description').touched" class="form-rejected">{{'ROUTING_CONFIGURATION.INVALID_SUBNET' | translate}}</mat-error>
                    <mat-error *ngIf="routingForm.get('description').hasError('required') && routingForm.get('description').touched">{{'ROUTING_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                    <mat-hint class="" align="end">{{Rule.Description ? Rule.Description.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input matInput class="full-width" type="text" placeholder="{{'ROUTING_CONFIGURATION.NETWORK' | translate}}"  formControlName="network" [(ngModel)]="Rule.Network"
                    name="network" />
                    <mat-icon matSuffix *ngIf="routingForm.get('network').valid && routingForm.get('network').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!routingForm.get('network').valid && routingForm.get('network').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="!routingForm.get('network').hasError('required') && !routingForm.get('network').valid && routingForm.get('network').touched" class="form-rejected">{{'ROUTING_CONFIGURATION.INVALID_SUBNET' | translate}}</mat-error>
                    <mat-error *ngIf="routingForm.get('network').hasError('required') && routingForm.get('network').touched">{{'ROUTING_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                    <mat-hint class="" align="end">{{Rule.Network ? Rule.Network.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input matInput class="full-width" type="text" placeholder="{{'ROUTING_CONFIGURATION.NEXTHOP' | translate}}"  formControlName="nextHop" [(ngModel)]="Rule.NextHop"
                    name="nextHop" />
                    <mat-icon matSuffix *ngIf="routingForm.get('nextHop').valid && routingForm.get('nextHop').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!routingForm.get('nextHop').valid && routingForm.get('nextHop').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="!routingForm.get('nextHop').hasError('required') && !routingForm.get('nextHop').valid && routingForm.get('nextHop').touched" class="form-rejected">{{'ROUTING_CONFIGURATION.INVALID_IP' | translate}}</mat-error>
                    <mat-error *ngIf="routingForm.get('nextHop').hasError('required') && routingForm.get('nextHop').touched">{{'ROUTING_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{Rule.NextHop ? Rule.NextHop.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{'ROUTING_CONFIGURATION.VNIC' | translate}}"
                    name="vnic" id="vnic" formControlName="vnic" [(ngModel)]="Rule.Vnic"
                    floatPlaceholder="always">
                    <mat-option *ngFor="let vnic of Vnics" [value]="vnic.Index">{{vnic.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'ROUTING_CONFIGURATION.CANCEL' | translate}}</button>
            </div>

            <div class="col-6 full-width">
                <button class="pull-right" color="primary" type="submit" mat-raised-button [disabled]="!routingForm.valid">{{'ROUTING_CONFIGURATION.SAVE' | translate}}</button>
            </div>
        </div>
    </form>

</div>
