<div class="container-fluid fixTop row">
    <div class="card mat-box-shadow cardCtrlPos col">
        <div class="card-block">
                <div [hidden]="SSLCertificate">
                    <h5>{{ 'WHITELABELCONFIGURATION.UPLOADCERTIFICATE' | translate}}</h5>
                    <small class="text-danger"><em>{{ 'WHITELABELCONFIGURATION.PASSWORD' | translate}}</em></small>
                    <mat-divider></mat-divider>
                    <br>
                </div>
            <form (ngSubmit)="saveFile()" class="fixTopForm">
                <mat-spinner style="margin-top: calc(20vh - 50px - 64px);margin-left: 30%" *ngIf="SSLLoading"></mat-spinner>
                <div [hidden]="SSLLoading">
                    <div>
                        <small class="text-secondary" *ngIf ="fileToUpload"><em>{{fileToUpload.name}}</em></small>
                        <mat-divider *ngIf ="fileToUpload"></mat-divider>
                        <input #fileInput id="fileToUploadSSL" type="file" accept=".pfx" name="file" (change)="uploadFile()" required style="display:none">
                        <button type="button" class="btn btn-block" mat-raised-button color="primary" id="uploadCertificate" name="uploadCertificate" onclick="document.getElementById('fileToUploadSSL').click()" >{{ 'WHITELABELCONFIGURATION.UPLOADCERTIFICATE' | translate}}</button>
                    </div>
                    <div>
                        <mat-form-field style="width:100%">
                            <input matInput type="text" placeholder="{{ 'WHITELABELCONFIGURATION.UPLOADCERTIFICATE' | translate}}" [(ngModel)]="pwd" name="pwd" required>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-card-actions class="pull-right">
                            <button type="button" mat-button class="text-danger" *ngIf ="saveButton" (click)="cancelUpload()" >{{ 'WHITELABELCONFIGURATION.CANCEL' | translate}}</button>
                            <button type="button" mat-button color="primary" *ngIf ="saveButton" (click)="saveFile()" >{{ 'WHITELABELCONFIGURATION.SAVE' | translate}}</button>
                        </mat-card-actions>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
