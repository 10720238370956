<div class="container-fluid fixTop row">
       <div class="col card mat-box-shadow">
        <label class="uploader"
            [class.loaded]="loaded"
            [style.outlineColor]="dragging ? activeColor : baseColor">
            <img [src]="imageSrc" (load)="handleImageLoad()" [class.loaded]="imageLoaded" alt="Loading"/>
        </label>
        <div class="card-block">
                <h5>{{ 'WHITELABELCONFIGURATION.UPLOADFILE' | translate}}</h5>
                <small class="text-secondary"><em>{{ 'WHITELABELCONFIGURATION.DESCRIPTION' | translate}}, ({{ 'WHITELABELCONFIGURATION.SIZEIMAGE' | translate}}).</em></small>
                <mat-divider></mat-divider>
                <br>
            <button class="btn btn-block" mat-raised-button color="primary"  onclick="document.getElementById('fileToUpload').click()">{{ 'WHITELABELCONFIGURATION.UPLOADIMAGE' | translate}}</button>
            <input #fileInput type="file" id="fileToUpload" name="file" accept="image/*"(change)="handleInputChange($event)" style="display:none">
            <hr>
            <div>
                <mat-card-actions class="pull-right">
                    <button mat-button class="text-danger" *ngIf ="editImage" (click)="cancelUpload()">{{ 'WHITELABELCONFIGURATION.CANCEL' | translate}}</button>
                    <button mat-button color="primary" *ngIf ="editImage" (click)="saveFile()" >{{ 'WHITELABELCONFIGURATION.ACCEPT' | translate}}</button>
                </mat-card-actions>
            </div>
        </div>
    </div>
</div>




