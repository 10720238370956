 <div class="container-fluid">
 <div class="control-panel-container fixPOS">
    <div class="row">
        <div class="col-md-6">
            <mat-grid-list cols="6" rowHeight="150px">
             <mat-grid-tile class="user-tile user-detail-tile" [colspan]="3" [rowspan]="1">
                <div class="circleTop"></div>
                <mat-list dense class="topFixList">
                    <mat-list-item>Name: </mat-list-item>
                    <mat-list-item>Id:</mat-list-item>
                    <mat-list-item>Email:</mat-list-item>
                    <mat-list-item>Telephone:</mat-list-item>
                    <mat-list-item>Registration date:</mat-list-item>
                </mat-list>
             </mat-grid-tile>
            </mat-grid-list>
        </div>
        <div class="col-md-6"></div>
    </div>
  </div>
  </div>