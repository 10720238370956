
<mat-toolbar color="primary">
    Edit Firewall Rule
    <div class="close-modal full-width">
      <button mat-icon-button (click)="Close()" class="pull-right">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

    <div class="modal-form-container" >
      <div class="container-fluid rule-container mt-5">
       
        <form class="add-row" #addRule="ngForm" (ngSubmit)="AddRule()">
          <mat-dialog-content class="mat-typography">
           <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;" >Edit Firewall Rule</legend>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 select-container"> 
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width" placeholder="Action">
                              <mat-option value="Pass">
                                Pass
                              </mat-option>
                              <mat-option value="Block">
                                Block
                              </mat-option>
                              <mat-option value="TCP/UPD">
                                Reject
                              </mat-option>                            
                            </mat-select>
                        </mat-form-field>
                          <label class="example-margin">Choose what to do with packets that match the criteria specified below.
                            Hint: the difference between block and reject is that with reject, a packet (TCP RST or ICMP port unreachable for UDP) is returned to the sender, whereas with block the packet is dropped silently. In either case, the original packet is discarded.</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 select-container"> 
                        <mat-checkbox class="example-margin" >Disabled</mat-checkbox>  
                        <p>This option is rarely needed. Don't use this without thorough knowledge of the implications.</p>
                    </div>
                </div>
               
                <div class="row">
                    <div class="col-xl-4 col-lg-4 select-container"> 
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <mat-select name="Type" required class="full-width"  placeholder="Interface">
                            <mat-option value="wan">
                                WAN
                            </mat-option>
                            <mat-option value="lan">
                                LAN
                            </mat-option>
                            <mat-option value="mgmt">
                                MGMT
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 select-container"> 
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width"  placeholder="Address Family">
                                <mat-option value="IPV4">
                                    IPv4
                                </mat-option>
                                <mat-option value="IPV6">
                                    IPv6
                                </mat-option>
                                <mat-option value="IPV4&IPV6">
                                    IPv4 + IPv6
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-lg-4 select-container"> 
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width"  placeholder="Protocol">
                              <mat-option value="any">
                                any
                              </mat-option>
                              <mat-option value="tcp">
                                TCP
                              </mat-option>
                              <mat-option value="mgmt">
                                MGMT
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
    
            <fieldset class="properties-border">
                <legend class="properties-border" style="width: auto; font-size: 1rem;">Source</legend>
                <div class="row">
                    <div class="col-xl-2 col-lg-2">
                        <mat-checkbox class="example-margin" >Invert match</mat-checkbox>  
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width" placeholder="Type">
                              <mat-option value="any">
                                Any
                               </mat-option>
                              <mat-option value="any">
                                Network
                              </mat-option>
                              <mat-option value="SAlias">
                                Single host or alas
                              </mat-option>
                              <mat-option value="Firewall">
                                This Firewall (Self)
                               </mat-option>
                               <mat-option value="SAlias">
                                 PPPoE clients
                               </mat-option>
                               <mat-option value="wan">
                                L2TP clients
                              </mat-option>
                              <mat-option value="wan">
                                WAN Address
                              </mat-option>
                              <mat-option value="WNET">
                                WAN NET
                              </mat-option>
                              <mat-option value="LNET">
                                LAN NET 
                              </mat-option>
                              <mat-option value="MGMTNET">
                                MGMT net
                              </mat-option>
                              <mat-option value="MGMTAddress">
                                MGMT Address
                              </mat-option>
        
                            </mat-select>
                        </mat-form-field> 
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <input name="Source Address" required class="full-width" matInput placeholder="Source Address">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
          
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">Destination</legend>
                <div class="row">
                    <div class="col-xl-2 col-lg-2">
                        <mat-checkbox class="example-margin" >Invert match</mat-checkbox>  
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <mat-select name="Type" required class="full-width" placeholder="Type">
                                <mat-option value="any">
                                Any
                                </mat-option>
                                <mat-option value="any">
                                Network
                                </mat-option>
                                <mat-option value="SAlias">
                                Single host or alas
                                </mat-option>
                                <mat-option value="Firewall">
                                This Firewall (Self)
                                </mat-option>
                                <mat-option value="SAlias">
                                    PPPoE clients
                                </mat-option>
                                <mat-option value="wan">
                                L2TP clients
                                </mat-option>
                                <mat-option value="wan">
                                WAN Address
                                </mat-option>
                                <mat-option value="WNET">
                                WAN NET
                                </mat-option>
                                <mat-option value="LNET">
                                LAN NET 
                                </mat-option>
                                <mat-option value="MGMTNET">
                                MGMT net
                                </mat-option>
                                <mat-option value="MGMTAddress">
                                MGMT Address
                                </mat-option>
        
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <input name="Source Address" required class="full-width" matInput placeholder="Source Address">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
            <fieldset class="properties-border">
                <legend class="properties-border" style="width: auto; font-size: 1rem;">Destination Port Range </legend>
                <div class="row">
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <mat-select name="Type" required class="full-width" placeholder="From port">
                          <mat-option value="Other">
                            Other
                           </mat-option>
                          <mat-option value="any">
                            Any
                          </mat-option>
                          <mat-option value="BGP">
                            BGP
                          </mat-option>
                          <mat-option value="CVsup">
                            CVsup
                           </mat-option>
                           <mat-option value="DNS">
                             DNS
                           </mat-option>
                           <mat-option value=" DNSoverTLS">
                            DNS over TLS
                          </mat-option>
                          <mat-option value="FTP">
                            FTP
                          </mat-option>
                          <mat-option value="HBCI">
                            HBCI
                          </mat-option>
                          <mat-option value="HTTP">
                            HTTP 
                          </mat-option>
                          <mat-option value="HTTPS">
                            HTTPS
                          </mat-option>
                          <mat-option value="ICQ">
                            ICQ
                          </mat-option>
  
                        </mat-select>
                      </mat-form-field> 
                    </div>
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                          <input name="SourceNetwork" required class="full-width" matInput placeholder="Custom">
                      </mat-form-field> 
                    </div>
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <mat-select name="To port" required class="full-width" placeholder="To port">
                          <mat-option value="Other">
                            Other
                           </mat-option>
                          <mat-option value="any">
                            Any
                          </mat-option>
                          <mat-option value="BGP">
                            BGP
                          </mat-option>
                          <mat-option value="CVsup">
                            CVsup
                           </mat-option>
                           <mat-option value="DNS">
                             DNS
                           </mat-option>
                           <mat-option value=" DNSoverTLS">
                            DNS over TLS
                          </mat-option>
                          <mat-option value="FTP">
                            FTP
                          </mat-option>
                          <mat-option value="HBCI">
                            HBCI
                          </mat-option>
                          <mat-option value="HTTP">
                            HTTP 
                          </mat-option>
                          <mat-option value="HTTPS">
                            HTTPS
                          </mat-option>
                          <mat-option value="ICQ">
                            ICQ
                          </mat-option>
  
                        </mat-select>
                      </mat-form-field>
                    </div>
                   <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                          <input name="SourceNetwork" required class="full-width" matInput placeholder="Custom">
                      </mat-form-field>
                  </div>
                </div>
            </fieldset>
            <fieldset class="properties-border">
                <legend class="properties-border" style="width: auto; font-size: 1rem;">Extra Options</legend>
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <mat-checkbox class="example-margin"> Log</mat-checkbox>
                        <p class="example-margin">Hint: the firewall has limited local log space. Don't turn on logging for everything. If doing a lot of logging, consider using a remote syslog server (see the Status: System Logs: Settings page).</p>
                    </div>
                    <div class="col-xl-12 col-lg-12">
                        <mat-form-field class="full-width col-12" floatLabel="always">
                            <input name="Description" required class="full-width" matInput placeholder="Description">
                        </mat-form-field>
                        <p>A description may be entered here for administrative reference. A maximum of 52 characters will be used in the ruleset and displayed in the firewall log.</p>
                    </div>
                    <div class="col-xl-12 col-lg-12">
                        <button mat-button color="primary" (click) = "advancedOptions=!advancedOptions"> <span class="material-icons">save</span> Display Advanced</button>
                    </div> 
                </div>
            </fieldset>
            <fieldset class="properties-border" *ngIf="advancedOptions">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">Advanced Options</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="Source" required class="full-width" matInput placeholder="Source OS">
                    </mat-form-field>
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Diffserv" required class="full-width" matInput placeholder="Diffserv Code Point">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-checkbox class="example-margin">Allow IP options</mat-checkbox>
                    <p>Allow packets with IP options to pass. Otherwise they are blocked by default. This is usually only seen with multicast traffic.</p>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-checkbox class="example-margin">Disable reply-to</mat-checkbox>
                    <p>Disable auto generated reply-to for this rule.</p>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Tag" required class="full-width" matInput placeholder="Tag">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Tagged" required class="full-width" matInput placeholder="Tagged">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Max. states" required class="full-width" matInput placeholder="Max. states">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Max. src nodes" required class="full-width" matInput placeholder="Max. src nodes">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Max. connections" required class="full-width" matInput placeholder="Max. connections">
                    </mat-form-field>  
                  </div>
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                        <input name="Max. src. conn. Rate" required class="full-width" matInput placeholder="Max. src. conn. Rate">
                    </mat-form-field>  
                  </div>
                </div>
            </fieldset>
          </mat-dialog-content>  
      
          <div class="action-buttons">
            <button mat-button color="primary"> <span class="material-icons">save</span> Guardar</button>
          </div>
        </form>

      </div>
    </div>
 
 
