import { LogsService } from "src/app/services/logs/logs-services.service";
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WlService } from "../../services/wlservice.service";
import {
  ServiceDTO,
  CustomerMachinesService,
} from "../../models/dtos.component";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Constants } from "../../models/constants.component";
import { BroadcastEmitter } from "../../services/BroadcastEmitter";
import { environment } from "../../../environments/environment";
import { Globalization } from "../../globalization/globalization";
import { AuthService } from "../../services/auth/auth.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { GraphDto } from "./DTO/dtos";
import { ThemePalette } from "@angular/material/core";
import { ProgressBarMode } from "@angular/material/progress-bar";
import { machineOperations } from "./machineOperations/machineOperations.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialog } from "../../tools/modals/confirmation/modal.component";
import { subscribeOn } from "rxjs/operators";
import { PfenseRequestDto } from "./pf-sense-network-settings/DTO/pfsenseRequest-dto";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "app-DataCenter",
  templateUrl: "./dataCenter.component.html",
  styleUrls: ["./dataCenter.component.scss"],
})
export class DataCenterComponent implements OnChanges {
  color: ThemePalette = "primary";
  color2: ThemePalette = "warn";
  mode: ProgressBarMode = "determinate";
  bufferValue = 100;

  public fecha: Date = null;
  public loading = false;
  public selectedMachine: ServiceDTO = new ServiceDTO();
  public constants: Constants = new Constants();
  public globalization: any = Globalization;
  public userInformation: any;
  public selectedPool: any = null;
  public customerInfo: any = null;
  public PfenseRequest: PfenseRequestDto = new PfenseRequestDto();
  public displayedColumns = [
    "Estado",
    "SID",
    "SistemaOperativo",
    "RAM",
    "Cores",
    "HDD",
    "Options",
  ];
  public dataSource = new MatTableDataSource();
  public inPool: boolean = false;
  public poolChardKeys = {};
  public chartData: boolean = true;

  @Output() SelectedGraphPool = new EventEmitter<any>();
  @Input("machinesChildren") machinesChild: Array<any> = [];
  @Input() machines: Array<CustomerMachinesService> = [];
  @Input("customerInfo") selectedCustomer: any;
  @Input() dataCenterID: string;
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public machinesResourcePool: any;
  public barChart: any = {
    colorScheme: {
      domain: ["#FD443A", "#3AB05A"],
    },
    data: [],
    showXAxis: true,
    showYAxis: true,
    gradient: false,
    showLegend: true,
    showXAxisLabel: true,
    showYAxisLabel: true,
    yAxisLabel: "RAM",
    xAxisLabel: "Status",
    autoScale: true,
  };
  public modal: MatDialog;
  MachineOperations: machineOperations = new machineOperations(
    this.service,
    this.router,
    this.snackbar,
    this.modal,
    this.auth,
    this.logsServices
  );
  constructor(
    private service: WlService,
    private router: Router,
    private route: ActivatedRoute,
    public snackbar: MatSnackBar,
    public navbarBroadcast: BroadcastEmitter,
    private dialog: MatDialog,
    public auth: AuthService,
    private logsServices: LogsService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.children !== "true") {
        this.machines = [];
        this.dataCenterID = params.id;
        this.RefreshDatacenter();
      }
    });
    this.fecha = new Date();
  }

  buildVmsParent() {
    if (!this.selectedCustomer) {
      this.service.GetUserInformation().subscribe(
        (response: any) => {
          this.customerInfo = response;
          this.buildPool();
        },
        (error) => {
          console.log("Error obteniendo al usuario => ", error);
        }
      );
    } else {
      this.customerInfo = this.selectedCustomer;
      this.buildPool();
    }
  }

  buildPool() {
    this.loading = true;
    this.machinesResourcePool = this.machines.filter(
      (f) => f.category === "RP"
    );
    if (this.machinesResourcePool.length > 0) {
      this.inPool = true;
      this.machinesChild = [];
    }

    this.getFilteredReorucesPool(this.machinesResourcePool[0]);
    this.navbarBroadcast.SendMessage({
      actions: [
        {
          icon: "public",
          name: "DATACENTER.MANAGE_NETWORKS",
          action: () => {
            this.OpenNetworkSettings();
          },
        },
        {
          icon: "public",
          name: "DATACENTER.MANAGEPF_NETWORKS",
          action: () => {
            this.OpenPfsenseNetworkSettings();
          },
        },
        {
          icon: "autorenew",
          name: "DATACENTER.REFRESH_DATA_CENTER",
          action: () => {
            this.RefreshDatacenter();
          },
        },
        {
          icon: "timeline",
          name: "DATACENTER.STATISTICS",
          action: () => {
            this.OpenInterfaceStatics();
          },
        },
      ],
      display: true,
    });

    this.service
      .GetNetworkType(this.dataCenterID)
      .subscribe((response: any) => {
        if (response == 3) {
          this.navbarBroadcast.SendMessage({
            actions: [
              {
                icon: "autorenew",
                name: "DATACENTER.REFRESH_DATA_CENTER",
                action: () => {
                  this.RefreshDatacenter();
                },
              },
              {
                icon: "public",
                name: "DATACENTER.MANAGEPF_NETWORKS",
                action: () => {
                  this.OpenPfsenseNetworkSettings();
                },
              },
            ],
            display: true,
          });
        } else if (response === 0) {
          this.navbarBroadcast.SendMessage({
            actions: [
              {
                icon: "autorenew",
                name: "DATACENTER.REFRESH_DATA_CENTER",
                action: () => {
                  this.RefreshDatacenter();
                },
              },
            ],
            display: true,
          });
        } else if (response === 2) {
          this.navbarBroadcast.SendMessage({
            actions: [
              {
                icon: "autorenew",
                name: "DATACENTER.REFRESH_DATA_CENTER",
                action: () => {
                  this.RefreshDatacenter();
                },
              },
              {
                icon: "public",
                name: "DATACENTER.MANAGE_NETWORKS",
                action: () => {
                  this.OpenNetworkSettings();
                },
              },
            ],
            display: true,
          });
        } else {
          this.navbarBroadcast.SendMessage({
            actions: [
              {
                icon: "public",
                name: "DATACENTER.MANAGE_NETWORKS",
                action: () => {
                  this.OpenPfsenseNetworkSettings();
                },
              },
              {
                icon: "autorenew",
                name: "DATACENTER.REFRESH_DATA_CENTER",
                action: () => {
                  this.RefreshDatacenter();
                },
              },
              {
                icon: "timeline",
                name: "DATACENTER.STATISTICS",
                action: () => {
                  this.OpenInterfaceStatics();
                },
              },
            ],
            display: true,
          });
        }

        this.loading = false;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.machines && changes.machines.currentValue.length > 0) {
      this.buildVmsParent();
    }
  }

  getMachineImage(machine: ServiceDTO) {
    if (machine.Configuration.OperativeSystem.Os.indexOf("WIN") !== -1) {
      return "/assets/img/windows.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("DEBIAN") !== -1
    ) {
      return "/assets/img/debianLogoBg.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("CENTOS") !== -1
    ) {
      return "/assets/img/centosLogoBg.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("UBUNTU") !== -1
    ) {
      return "/assets/img/ubuntuLogoBg.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("SUSE") !== -1
    ) {
      return "/assets/img/suseLogoBg.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("REDHAT") !== -1
    ) {
      return "/assets/img/redhatLogoBg.png";
    } else if (
      machine.Configuration.OperativeSystem.Os.indexOf("Other") !== -1
    ) {
      return "/assets/img/noOS-en.png";
    } else {
      return "../assets/img/linuxM.png";
    }
  }

  ShowSnackbar() {
    this.snackbar.open("Escoja su opción", null, {
      duration: 2000,
    });
  }

  ShowMachineConsole(machine) {
    this.snackbar.open("Generando acceso remoto, por favor espere.", null, {
      duration: 3000,
    });
    const currentState = machine.State;
    machine.State = "BUSY";
    this.service.GetMachineToken(machine.ID).subscribe(
      (response: any) => {
        if (response == null) {
          this.snackbar.open(
            "Error generando token, Intente nuevamente o contacte a soporte técnico.",
            null,
            {
              duration: 2000,
            }
          );
          this.logsServices.postAppLog(
            "Acción Maquina",
            "Consola VM",
            false,
            "No fue posible mostrar la consola de la VM",
            machine.ServiceID
          );
        }

        this.logsServices.postAppLog(
          "Acción Maquina",
          "Consola VM",
          true,
          "Mostrar consola de la VM fue exitoso",
          machine.ServiceID
        );

        if (response && response.Type) {
          switch (response.Type) {
            case "HYPERV":
              const link = document.createElement("a");
              link.download = "a";
              link.href = environment.serviceUrl + "tmp/" + response.Data;
              document.body.appendChild(link);
              link.click();
              link.remove();
              break;
            case "VMWARE":
              window.open(environment.console + response.Data, "_blank");
              break;
            case "ORACLEVM":
              // Ajuste para permitir la conexión a traves de OracleVM
              let url = "";
              if (response.Data.id.uri.includes("oraclevmmanagercl")) {
                url = environment.OVMConsoleCl + response.Data.consoleURI;
              } else {
                url = environment.OVMConsole + response.Data.consoleURI;
              }
              window.open(url + response.Data.consoleURI, "_blank");
              break;
            default:
              break;
          }
        }

        machine.State = currentState;
      },
      (error) => {
        machine.State = currentState;
        this.snackbar.open("Error generando token, " + error.error, null, {
          duration: 2000,
        });
        this.logsServices.postAppLog(
          "Acción Maquina",
          "Consola VM",
          false,
          "No fue posible mostrar la consola de la VM",
          machine.ServiceID
        );
      }
    );
  }

  validShow(machine: any) {
    return (
      (machine.category !== "CP" &&
        !(
          this.machines.some((vm) => vm.category === "RP") &&
          machine.category === "CS"
        )) ||
      (machine.category === "CS" && this.machinesChild.length === 0)
    );
  }

  ShowMachineActions(machine) {
    this.selectedMachine = machine;
    this.sidenav.open();
  }

  RefreshDatacenter() {
    this.service.GetUserInformation().subscribe(
      (response: any) => {
        this.customerInfo = response;
        this.inPool = false;
        this.loading = true;
        this.service.GetDataCenterMachines(this.dataCenterID).subscribe(
          (response: any) => {
            this.loading = false;
            this.machines = response;

            this.machinesResourcePool = this.machines.filter(
              (f) => f.category === "RP"
            );
            if (this.machinesResourcePool.length > 0) {
              this.inPool = true;
              this.machinesChild = [];
            }
            console.log("Respuesta del POOL", this.machinesResourcePool);
            this.getFilteredReorucesPool(this.machinesResourcePool[0]);
            this.navbarBroadcast.SendMessage({
              actions: [
                {
                  icon: "public",
                  name: "DATACENTER.MANAGE_NETWORKS",
                  action: () => {
                    this.OpenNetworkSettings();
                  },
                },
                {
                  icon: "public",
                  name: "DATACENTER.MANAGEPF_NETWORKS",
                  action: () => {
                    this.OpenPfsenseNetworkSettings();
                  },
                },
                {
                  icon: "autorenew",
                  name: "DATACENTER.REFRESH_DATA_CENTER",
                  action: () => {
                    this.RefreshDatacenter();
                  },
                },
                {
                  icon: "timeline",
                  name: "DATACENTER.STATISTICS",
                  action: () => {
                    this.OpenInterfaceStatics();
                  },
                },
              ],
              display: true,
            });
            /** */
            this.service.GetNetworkType(this.dataCenterID).subscribe(
              (response: any) => {
                console.log("response", response);
                if (response == 3) {
                  this.navbarBroadcast.SendMessage({
                    actions: [
                      {
                        icon: "autorenew",
                        name: "DATACENTER.REFRESH_DATA_CENTER",
                        action: () => {
                          this.RefreshDatacenter();
                        },
                      },
                      {
                        icon: "public",
                        name: "DATACENTER.MANAGEPF_NETWORKS",
                        action: () => {
                          this.OpenPfsenseNetworkSettings();
                        },
                      },
                    ],
                    display: true,
                  });
                } else if (response === 0) {
                  console.log("llega aqui");

                  this.navbarBroadcast.SendMessage({
                    actions: [
                      {
                        icon: "autorenew",
                        name: "DATACENTER.REFRESH_DATA_CENTER",
                        action: () => {
                          this.RefreshDatacenter();
                        },
                      },
                    ],
                    display: true,
                  });
                } else if (response === 2) {
                  this.navbarBroadcast.SendMessage({
                    actions: [
                      {
                        icon: "autorenew",
                        name: "DATACENTER.REFRESH_DATA_CENTER",
                        action: () => {
                          this.RefreshDatacenter();
                        },
                      },
                      {
                        icon: "public",
                        name: "DATACENTER.MANAGE_NETWORKS",
                        action: () => {
                          this.OpenNetworkSettings();
                        },
                      },
                    ],
                    display: true,
                  });
                } else {
                  this.navbarBroadcast.SendMessage({
                    actions: [
                      {
                        icon: "public",
                        name: "DATACENTER.MANAGE_NETWORKS",
                        action: () => {
                          this.OpenPfsenseNetworkSettings();
                        },
                      },
                      {
                        icon: "autorenew",
                        name: "DATACENTER.REFRESH_DATA_CENTER",
                        action: () => {
                          this.RefreshDatacenter();
                        },
                      },
                      {
                        icon: "timeline",
                        name: "DATACENTER.STATISTICS",
                        action: () => {
                          this.OpenInterfaceStatics();
                        },
                      },
                    ],
                    display: true,
                  });
                }
              },
              (error) => {
                this.snackbar.open(
                  "Error consultando el tipo red " + error.error,
                  null,
                  {
                    duration: 2000,
                  }
                );
              }
            );
          },
          (error) => {
            if (error.status === 401) {
              this.router.navigateByUrl("/");
            } else {
              this.loading = false;
              this.snackbar.open(
                "Error consultando maquinas " + error.error,
                null,
                {
                  duration: 2000,
                }
              );
            }
          }
        );
      },
      (error) => {}
    );
  }

  ReplaceName(name) {
    const response = name.replace(/_/g, " ");
    let capitalizeName = "";
    response.split(" ").forEach((element) => {
      if (capitalizeName.length !== 0) {
        capitalizeName += " ";
      }
      capitalizeName +=
        element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
    });
    return capitalizeName;
  }

  OpenNetworkSettings(datacenterID?: string) {
    const datacenter = datacenterID ? datacenterID : this.dataCenterID;
    this.service.VerifyVirtualNetwork(datacenter).subscribe(
      (response) => {
        this.router.navigateByUrl(
          "/home/(main:networkSettings/" + encodeURIComponent(datacenter) + ")",
          { skipLocationChange: true }
        );
      },
      (error) => {
        this.snackbar.open(
          "Error consultando nube del cliente" + error.error,
          null,
          {
            duration: 2000,
          }
        );
      }
    );
  }

  OpenPfsenseNetworkSettings(datacenterID?: string) {
    this.PfenseRequest.customerId = this.customerInfo.ID;
    this.PfenseRequest.datacenterId = datacenterID
      ? datacenterID
      : this.dataCenterID;
    this.router.navigateByUrl(
      "/home/(main:PfsenseNetworkSettings/" +
        encodeURIComponent(this.PfenseRequest.datacenterId) +
        "/" +
        encodeURIComponent(this.PfenseRequest.customerId) +
        ")",
      { skipLocationChange: true }
    );
  }

  OpenInterfaceStatics() {
    this.router.navigateByUrl(
      "/home/(main:interfacesStats/" +
        encodeURIComponent(this.dataCenterID) +
        ")",
      { skipLocationChange: true }
    );
  }

  GetAlias(machine: ServiceDTO) {
    if (machine.ServiceID == null) {
      return this.ReplaceName(machine.Configuration.OperativeSystem.Os);
    } else if (machine.ServiceName != null) {
      return this.ReplaceName(machine.ServiceName);
    } else {
      return this.ReplaceName(machine.Configuration.OperativeSystem.Os);
    }
  }

  GetMachineStateClass(machine) {
    return "circle-" + machine.State + " datacenter-vm-circle";
  }

  setTabTitle(machinesKeyName: string) {
    switch (machinesKeyName) {
      case "CS":
        return "Cloud Server";
      case "OP":
        return "Service Order";
      default:
        return "<b>SID Pool: </b>" + machinesKeyName;
    }
  }

  getChartInfo(serviceID: string, customerID: string) {
    this.SelectedGraphPool.emit({ serviceID, customerID });
  }

  getFilteredReorucesPool(resorcePool) {
    if (resorcePool != null) {
      const grouped = resorcePool.machinesList.reduce((r, a) => {
        const sid = a.ServiceID.split("_")[0];
        r[sid] = r[sid] || [];
        r[sid].push(a);
        return r;
      }, Object.create(null));
      console.log("Elgrouped", grouped);
      this.machinesResourcePool = grouped;
      this.getPoolChart(Object.keys(this.machinesResourcePool));
    }
  }

  GetColorState(state) {
    switch (state) {
      case "PENDING":
        return "machine-pending";
      case "POWER_ON":
        return "machine-on";
      case "POWER_OFF":
        return "machine-off";
      case "BUSY":
        return "machine-busy";
      case "SUSPEND":
        return "machine-suspended";
      case "POWER_OFF_GUEST":
        return "machine-off";
      case "SUSPEND_GUEST":
        return "machine-suspended";
      default:
        return "";
    }
  }

  GetStateMachine(state) {
    switch (state) {
      case "PENDING":
        return "Pendiente de aprovisionamiento";
      case "POWER_ON":
        return "Encendida";
      case "POWER_OFF":
        return "Apagada";
      case "BUSY":
        return "Ocupada";
      case "SUSPEND":
        return "Suspendida";
      case "POWER_OFF_GUEST":
        return "Apagada";
      case "SUSPEND_GUEST":
        return "Suspendida";
      default:
        return "-";
    }
  }

  selectPool(poolKey: any) {
    this.selectedPool = poolKey;
  }

  getPoolChart(arraysKeys: any) {
    arraysKeys.forEach((element) => {
      // this.service.GetGraphPool("hTU2xdt1kEcPdgLNkcME8Q==", "1151896").subscribe((data: any) => {
      this.service.GetGraphPool(this.customerInfo.ID, element).subscribe(
        (data: any) => {
          console.log("infografica", data);
          if (data === null) {
            this.chartData = false;
            this.poolChardKeys[element] = [];
          } else {
            this.chartData = true;
            this.poolChardKeys[element] = data;
          }
        },
        (error) => {
          this.chartData = false;
          this.poolChardKeys[element] = [];
          console.error("Error consultando nube del cliente" + error.error);
        }
      );
    });
    console.log("El thisPoolChar", this.poolChardKeys);
  }

  getUser() {
    this.service.GetUserInformation().subscribe(
      (response: any) => {
        this.customerInfo = response;
      },
      (error) => {}
    );
  }

  onChangeTypeMachine(change) {
    if (change === 1) {
      this.inPool = true;
    } else {
      this.inPool = false;
      this.selectedPool = null;
    }
  }

  ShowVmSnackbar(message, action) {
    this.snackbar.open(message, action, {
      duration: 2000,
    });
  }

  PowerOnVM(machine: ServiceDTO) {
    this.ShowVmSnackbar("Encendiendo máquina por favor espere", null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.service.PowerOnVm(machine.ID).subscribe(
      () => {
        machine.State = "POWER_ON";
        this.ShowVmSnackbar(
          machine.ServiceID + " encendida correctamente",
          null
        );
        this.logsServices.postAppLog(
          "Acción Maquina",
          "Encender VM",
          true,
          "VM encendida correctamente",
          machine.ServiceID
        );
      },
      (error) => {
        this.ShowVmSnackbar(error.error, null);
        machine.State = currentState;
        this.logsServices.postAppLog(
          "Acción Maquina",
          "Encender VM",
          false,
          "No fue posible encender la VM",
          machine.ServiceID
        );
      }
    );
  }
  PowerOffVm(machine: ServiceDTO) {
    // this.ShowVmSnackbar('Apagando máquina por favor espere', null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Apagar VM",
        Message: "Va a apagar esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.PowerOffVm(machine.ID).subscribe(
            () => {
              machine.State = "POWER_OFF";
              this.ShowVmSnackbar(
                machine.ServiceID + " apagada correctamente",
                null
              );
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Apagar VM",
                true,
                "VM apagada correctamente",
                machine.ServiceID
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Apagar VM",
                true,
                "No fue posible apagar la VM",
                machine.ServiceID
              );
            }
          );
        },
      },
    });
  }

  SuspendVM(machine: ServiceDTO) {
    // this.ShowVmSnackbar('Suspendiendo máquina por favor espere', null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Suspender VM",
        Message: "Va a suspender esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.SuspendVm(machine.ID).subscribe(
            () => {
              machine.State = "SUSPEND";
              this.ShowVmSnackbar(
                machine.ServiceID + " suspendida correctamente",
                null
              );
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Suspender VM",
                true,
                "VM suspendida correctamente",
                machine.ServiceID
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Suspender VM",
                false,
                "No fue posible suspender la VM",
                machine.ServiceID
              );
            }
          );
        },
      },
    });
  }

  ResumeVm(machine: ServiceDTO) {
    this.ShowVmSnackbar("Reactivando máquina por favor espere", null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Suspender VM",
        Message: "Va a suspender esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.ResumeVm(machine.ID).subscribe(
            () => {
              machine.State = "POWER_ON";
              this.ShowVmSnackbar(
                machine.ServiceID + " reactivada correctamente",
                null
              );
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Reactivar VM",
                true,
                "VM reactivada correctamente",
                machine.ServiceID
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Reactivar VM",
                false,
                "No fue posible reactivar la VM",
                machine.ServiceID
              );
            }
          );
        },
      },
    });
  }

  RestartVm(machine: ServiceDTO) {
    // this.ShowVmSnackbar('Reiniciando máquina por favor espere', null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Reiniciar VM",
        Message: "Va a reiniciar esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.RestartVm(machine.ID).subscribe(
            () => {
              machine.State = "SUSPEND";
              this.ShowVmSnackbar(
                machine.ServiceID + " suspendida correctamente",
                null
              );
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Reiniciar VM",
                true,
                "VM reiniciada correctamente",
                machine.ServiceID
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
              this.logsServices.postAppLog(
                "Acción Maquina",
                "Reiniciar VM",
                false,
                "No fue posible reiniciar la VM",
                machine.ServiceID
              );
            }
          );
        },
      },
    });
  }

  GuestPowerOffVmAPI(machine: ServiceDTO) {
    // this.ShowVmSnackbar('Apagando máquina por favor espere', null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Aapagar sistema Operativo Invitado",
        Message:
          "Va a apagar sistema Operativo Invitado de esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.GuestPowerOffVmAPI(machine.ID).subscribe(
            () => {
              machine.State = "POWER_OFF";
              this.ShowVmSnackbar(
                machine.ServiceID + " apagada correctamente",
                null
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
            }
          );
        },
      },
    });
  }

  GuestSuspendVmAPI(machine: ServiceDTO) {
    this.ShowVmSnackbar("Suspendiendo máquina por favor espere", null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Suspender sistema Operativo Invitado",
        Message:
          "Va a suspender sistema Operativo Invitado de esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.GuestSuspendVmAPI(machine.ID).subscribe(
            () => {
              machine.State = "SUSPEND";
              this.ShowVmSnackbar(
                machine.ServiceID + " suspendida correctamente",
                null
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
            }
          );
        },
      },
    });
  }

  GuestResetVMAPI(machine: ServiceDTO) {
    this.ShowVmSnackbar("Reiniciando máquina por favor espere", null);
    const currentState = machine.State;
    machine.State = "BUSY";
    this.dialog.open(ConfirmationDialog, {
      hasBackdrop: true,
      disableClose: false,
      data: {
        Title: "Reiniciar sistema Operativo Invitado",
        Message:
          "Va a reiniciar sistema Operativo Invitado de esta máquina. ¿Está seguro?",
        Ok: () => {
          this.service.GuestResetVMAPI(machine.ID).subscribe(
            () => {
              machine.State = "SUSPEND";
              this.ShowVmSnackbar(
                machine.ServiceID + " suspendida correctamente",
                null
              );
            },
            (error) => {
              this.ShowVmSnackbar(error.error, null);
              machine.State = currentState;
            }
          );
        },
      },
    });
  }

  checkInNewVcenter(machine: ServiceDTO) {
    if (machine.vCenterVersion === "6.7") {
      return true;
    } else {
      return false;
    }
  }
}
