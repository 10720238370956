import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FirewallIPDto } from '../DTO/firewall-ip-dto';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NewPfSenseIpComponent } from '../pfsense-ip/forms/new-pf-sense-ip/new-pf-sense-ip.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-firewall-aliases-ports',
  templateUrl: './firewall-aliases-ports.component.html',
  styleUrls: ['./firewall-aliases-ports.component.scss']
})
export class FirewallAliasesPortsComponent  implements AfterViewInit {
  @Input() item:FirewallIPDto[] = []; // decorate the property with @Input()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'description', 'address'];
  dataSource: MatTableDataSource<FirewallIPDto>;


  constructor(public dialog: MatDialog) { 
  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data = this.item.filter(item => item.type ==='port');
    }
  }

  newRule() {
    const dialogRef = this.dialog.open(NewPfSenseIpComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
   
    });

  }

}
