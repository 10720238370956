import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { GridComponent } from "../../tools/grid/grid.component";
import { WlService } from '../../services/wlservice.service';
import { UserEdition } from './user-edition/user-edition.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserDTO } from '../../models/dtos.component';
import { BroadcastEmitter } from '../../services/BroadcastEmitter';
import { ConfirmationDialog } from '../../tools/modals/confirmation/modal.component';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';

@Component({
    selector: 'app-userAccounts',
    templateUrl: './userAccounts.component.html',
    styleUrls: ['./userAccounts.component.scss']
})
export class UserAccountsComponent implements OnInit {
    @ViewChild('sideNav') sideNav: MatSidenav;
    @ViewChild('usersGrid') usersGrid: GridComponent;
    public selectedUser: UserDTO;
    constructor(public service: WlService,
                private router: Router,
                public snackBar: MatSnackBar,
                public navbarBroadcast: BroadcastEmitter,
                public dialog: MatDialog,
                public auth: AuthService) {
        this.service.ModuleName = 'userAccounts';
    }
    message: string = 'Haz doble clic sobre el nombre del usuario para editar';
    openSnackBar(message: string) {
        this.snackBar.open(message, null, {
            duration: 4000,
        });
    }

    public grid = {
        columns: [
            { name: "Name", title: "Name" },
            { name: "Email", title: "Email" },
            { name: "TotalCost", title: "Cost", format: "currency" },
        ],
        data: [],
        toolbar: [
            { "type": "button", "permission": "AddCustomer", "click": () => { this.addUser() }, "text": "Agregar cliente", "align": "left" }
        ],
        filter: "Name",
        pageSize: 10,
        rowClick: (u) => { this.userSettings(u) },
        refresh: (u) => { this.getUsers() },
        minWidth: '900px',
        actions: [
            {
                text: "Detalle y Servicios",
                action: (u) => { this.userSettings(u) },
            },
            {
                text: "Editar",
                action: (u) => { this.editUser(u) },
                hide: (u) => { return u.CustomerTypeID != "CUSTOMER" }
            },
            {
                text: "Eliminar",
                action: (u) => { this.removeCustomerConfirm(u) },
                hide: (u) => { return u.CustomerTypeID != "CUSTOMER" || u.ID == JSON.parse(atob(this.auth.getToken().split('.')[1])).UserID }
            },
            {
                text: "Iniciar Sesión",
                action: (u) => { this.ImpersonateUser(u) },
                hide: (u) => { return JSON.parse(atob(this.auth.getToken().split('.')[1])).UserID == u.ID }
            },
            {
                text: "Reenviar Contraseña",
                action: (u) => { this.ResendUserMailWindow(u) }
            }
        ]
    }

    ResendUserMailWindow(user: UserDTO) {
        this.dialog.open(PasswordResendDialog, {
            data: user
        });
    }

    ImpersonateUser(user: UserDTO) {
        this.service.GenerateToken(user.ID).subscribe(
            (response:any) => {
                localStorage.setItem("usertoken", response.token);
                window.open(user.DomainName + "/?t=" + response.token, '_blank');
            }
        )
    }

    removeCustomerConfirm(User: UserDTO) {
        this.dialog.open(ConfirmationDialog, {
            data: {
                Title: "Eliminar Cliente",
                Message: "Esta seguro?",
                Ok: () => this.removeCustomer(User),
            }
        });
    }

    removeCustomer(User: UserDTO): void {
      if (this.usersGrid) {
        this.usersGrid.loading = true;
      }
      this.service.RemoveUser(User.ID).subscribe(
            () => {
                this.getUsers();
                this.snackBar.open('Cliente eliminado satisfactoriamente', null, {
                    duration: 3000,
                });
            },
            error => {
                this.usersGrid.loading = false;
                this.snackBar.open('Error eliminando cliente. ' + error.error, null, {
                    duration: 3000,
                });
            }
        );
    }

    userSettings(User) {
        this.router.navigateByUrl('/home/(main:userConfiguration/' + encodeURIComponent(User.ID) + ')', { skipLocationChange: true });
    }

    editUser(User) {
        this.selectedUser = User;
        this.sideNav.open();
    }

    addUser() {
        this.selectedUser = new UserDTO();
        this.selectedUser.ParentID = JSON.parse(atob(this.auth.getToken().split('.')[1])).UserID;
        this.selectedUser.Language = JSON.parse(atob(this.auth.getToken().split('.')[1])).Language;
        this.sideNav.open();
    }

    public savedUser(savedUser: UserDTO) {
        this.snackBar.open('Cliente guardado satisfactoriamente', null, {
            duration: 3000,
        });
    }


    finishSaving() {
        this.selectedUser = new UserDTO();
        this.usersGrid.loading = true;
        this.getUsers();
    }

    ngOnInit() {
        this.navbarBroadcast.SendMessage({ text: "Cuentas de usuario", actions: [{ icon: 'person_add', name: 'Agregar cliente', action: () => { this.addUser() } }] ,display : true});
        this.getUsers();
    }

    getUsers() {
      if (this.usersGrid) {
        this.usersGrid.loading = true;
      }
      this.service.getCustomerAccounts()
      .subscribe((response) => {
                this.usersGrid.setData(response);
            }, (error) => {
                if (error.status === 401) {
                    this.router.navigateByUrl('/');
                }
            }
        );
    }
}
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
@Component({
    selector: 'password-resend-dialog',
    templateUrl: 'password-resend.html',
    styleUrls: ['../../tools/modals/modals.scss']
})
export class PasswordResendDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<PasswordResendDialog>,
                public snackbar: MatSnackBar,
                private service: WlService) { }
    public loading = false;
    public emailAddress = '';
    emailFormControl = new FormControl('', [
        Validators.pattern(EMAIL_REGEX)]
    );
    ResendUserMail() {
        this.loading = true;
        this.service.ResendUserMail(this.data.ID, this.emailAddress).subscribe(
            response => {
                this.loading = false;
                this.dialogRef.close();
                this.snackbar.open('Correo enviado satisfactoriamente', null, {
                    duration: 3000,
                });
            },
            error => {
                this.loading = false;
                this.snackbar.open('Error enviando correo. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
