import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import  {DhcpServerDto} from '../DTO/dhcp-server-dto';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NewDhcpServiceComponent } from './new-dhcp-service/new-dhcp-service.component';


@Component({
  selector: 'app-services-dchserver',
  templateUrl: './services-dchserver.component.html',
  styleUrls: ['./services-dchserver.component.scss']
})
export class ServicesDchserverComponent implements AfterViewInit {

  @Input() item:DhcpServerDto[] = []; // decorate the property with @Input()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;  
  displayedColumns: string[] = ['Static ARP', 'MAC address', 'Client Id', 'IP address','Hostname', 'Description'];
  dataSource: MatTableDataSource<DhcpServerDto>;
  
  

  constructor( public dialog: MatDialog) { 
  
  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data =  this.item;
    }
  }

  newRule(){

    const dialogRef = this.dialog.open(NewDhcpServiceComponent, {
     
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("data!!")
      if(result){
        //this.dataSource.data.push = result;
        console.log("result", result);
      }

    });

  }

}
