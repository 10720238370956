<div class="card">
    <div class="card-block">
        <div class="card-title modal-title">
            <h6 class="pull-left">Recordar contraseña</h6>
            <div class="pull-right close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <form (ngSubmit)="rememberPassword()" [formGroup]="rememberForm" >
            <mat-card-title class="bckTitle"></mat-card-title>
            <mat-form-field class="full-width">
                <input type="email" [(ngModel)]="rememberEmail" name="rememberEmail" matInput placeholder="Email" required minlength="10"
                    id="rememberEmail" formControlName="rememberEmail" />
                <mat-icon matSuffix *ngIf="rememberForm.get('rememberEmail').valid && rememberForm.get('rememberEmail').touched && !loading" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!rememberForm.get('rememberEmail').valid && rememberForm.get('rememberEmail').touched && !loading" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="rememberForm.get('rememberEmail').hasError('minlength') && rememberForm.get('rememberEmail').touched">El correo debe contar con al menos 10 caracteres</div>
                        <div *ngIf="rememberForm.get('rememberEmail').hasError('required') && rememberForm.get('rememberEmail').touched">La correo es un campo requerido.</div>
                        <div *ngIf="rememberForm.get('rememberEmail').hasError('emailValidator') && rememberForm.get('rememberEmail').touched">El correo electrónico no cuenta con el formato adecuado.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{rememberEmail ? rememberEmail.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="text-right margin-top-10" style="margin-bottom: 10px;">
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
                <button type="submit" color="primary" [disabled]="!rememberForm.valid" mat-raised-button *ngIf="!loading">Enviar</button>
            </div>
        </form>
    </div>
</div>
