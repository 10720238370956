<div class="card">
    <div class="card-block">
        <div class="card-title modal-title">
            <h6 class="pull-left">Términos y condiciones</h6>
            <div class="pull-right close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <div class="card-terms-content">
            <div>
                <b>Responsabilidad:</b><br/>
                <b>IFX es responsable por:</b><br/>
                <ol type="A">
                    <li>La infraestructura física que soporta los servidores virtuales, así como la estabilidad de la plataforma
                        de virtualización.</li>
                    <li>Del licenciamiento de los sistemas operativos ofrecidos, así como el licenciamiento de los aplicativos
                        ofrecidos. Cualquier licenciamiento diferente a los ofrecidos, deberá ser suministrado por el cliente,
                        teniendo en cuenta el modelo de licenciamiento del proveedor de software y ajustándose a las condiciones
                        que cada uno maneje para ambientes tipo cloud.</li>
                    <li>La infraestructura física de hardware, la configuración de software y la custodia de medios para la retención
                        ofrecida en el servicio de backup. IFX no se responsabiliza por la información del cliente que no
                        está cubierta mediante la descripción de los servicios de backup ofrecidos al cliente.</li>
                </ol>
                <b>Alcance:</b><br/>
                <b>El alcance del servicio es:</b><br/>
                <ol type="A">
                    <li>El servicio cuenta con un sistema operativo instalado de forma básica. Los aplicativos ofertados se entregan
                        instalados de manera básica o se le presentan al servidor en una unidad de almacenamiento virtual
                        para que el cliente proceda con la instalación personalizada.</li>
                    <li>El servicio contempla el suministro de los recursos de poder de cómputo acordados previamente con el
                        cliente (vCPU, RAM, discos, backup, conectividad, sistema operativo y aplicaciones adicionales).</li>
                    <li>El espacio en disco entregado en los servidores virtuales constituye espacio total; es decir, es necesario
                        tener en consideración que parte de este espacio será consumido por el sistema operativo y las aplicaciones
                        que en él se instalen.</li>
                    <li>IFX Networks no se hace responsable por el manejo y acceso que el cliente brinde sobre la infraestructura
                        y los datos almacenados en la misma. En caso de daño o pérdida de información, IFX Networks será
                        responsable únicamente por las copias de seguridad pactadas.</li>
                    <li>Características adicionales de seguridad perimetral tales como: Application control, Web filtering, IPS,
                        IDS, DLP y Log inspection, no están incluidas en el alcance de seguridad de IFX CLOUD White Label,
                        en caso de ser requerido, deberán ofrecerse a través de servicios de “IFX SECURITY Solutions”.</li>
                    <li>Al finalizar la relación contractual con IFX Networks, el cliente podrá solicitar los "log de eventos"
                        que demuestren la eliminación total y definitiva de la información del cliente, alojada en la infraestructura
                        contratada. IFX realiza una operación de borrado seguro de acuerdo con las especificaciones de la
                        norma ISO 27001 y SAS70. Este procedimiento se realiza a solicitud formal del cliente e implica el
                        cobro de servicios profesionales.</li>
                    <li>La infraestructura puede ser contratada con dos términos diferentes: Mensual o con compromiso de permanencia
                        mínima de un año. En ambos casos, el contrato se renueva de forma automática con la misma periodicidad
                        contratada inicialmente, a no ser que el distribuidor solicite la cancelación del servicio mediante
                        la plataforma de gestión. La solicitud de cancelación se puede realizar en cualquier momento, pero
                        la cancelación real, el des-aprovisionamiento de los recursos y la suspensión de la facturación de
                        los mismos, solo ocurrirá cuando se haya completado el periodo actual de contratación.</li>
                </ol>
            </div>
        </div>
    </div>
</div>