import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
//import { GridComponent } from 'app/tools/grid/grid.component';
import { VpnDTO, VPNSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';
import { VpnStatsComponent } from '../vpn-stats/vpn-stats.component';

@Component({
  selector: 'vpn-table',
  templateUrl: './vpn-table.component.html',
  styleUrls: ['./vpn-table.component.scss']
})
export class VpnTableComponent implements OnInit, OnChanges {
    @Input("IpSec") IpSec: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Name', 'Enabled', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      if(this.IpSec != null)
        this.dataSource.data = this.IpSec.Sites.Site;
      else
        this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      if(this.IpSec != null)
        this.dataSource.data = this.IpSec.Sites.Site;
      else
        this.dataSource.data = [];
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemoveVpnModal(rule: VpnDTO) {
      this.dialog.open(ConfirmationDialog, {
          data: {
              Title: "Eliminar Regla Vpn",
              Message: "Esta seguro?",
              Ok: () => this.RemoveVpnRule(rule),
          }
      });
  }

  RemoveVpnRule(rule: VpnDTO) {
    var i = this.IpSec.Sites.Site.indexOf(rule);
    this.IpSec.Sites.Site.splice(i,1);

    if(this.IpSec.Sites.Site.length == 0)
        this.IpSec.Enabled = "false";
    else
        this.IpSec.Enabled = "true";
    
    let DeleteVPNRequest = new VPNSaveRequestDTO();
    DeleteVPNRequest.DatacenterID = this.DatacenterID;
    DeleteVPNRequest.IpSec = this.IpSec;

    this.wlservice.SaveVpn(DeleteVPNRequest).subscribe(
        response => {
          this.OnDelete.emit(rule);
            this.snackbar.open('Regla de VPN eliminada satisfactoriamente', null, {
                duration: 3000,
            });
        },
        error => {
            this.snackbar.open('Error eliminando regla de Vpn ' + error.error, null, {
                duration: 3000,
            });
        }
    )
}

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditVpnRule(rule: VpnDTO) {
      this.OnEdit.emit(rule);
    }

    GetIpSecStats(rule: VpnDTO) {
      this.wlservice.GetIpSecStats(this.DatacenterID).subscribe(
          (response:any) => {
              var stats = response.SiteStatistics.filter(s => s.IkeStatus.PeerId == rule.PeerId);
              var TunnelStats = [];
              for(let s of stats)
              {
                  TunnelStats.push(s.TunnelStats);
              }

              var totalUp = TunnelStats.filter(s => s.TunnelStatus.toUpperCase() == "UP").length;
              var totalDown = TunnelStats.filter(s => s.TunnelStatus.toUpperCase() == "DOWN").length;
              var title = totalUp + " UP " + totalDown + " DOWN";

              this.dialog.open(VpnStatsComponent, {
                  data: {
                      Title: title,
                      Stats: TunnelStats
                  }
              });
          },
          error => {
              this.snackbar.open('Error consultando estadísticas de Vpn ' + error.error, null, {
                  duration: 3000,
              });
          }
      )
  }
}