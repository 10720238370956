import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomFormValidator } from '../../../../tools/CustomValidator';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-cloud-server-edit',
  templateUrl: './cloud-server-edit-values.component.html',
  styleUrls: ['./cloud-server-edit-values.component.css']
})
export class CloudServerEditValuesComponent implements OnInit {
  resource: any = {};
  TotalValueForm: FormGroup;
  loading = false;
  constructor(private dialog : MatDialogRef<CloudServerEditValuesComponent> ,
    @Inject(MAT_DIALOG_DATA) public data: any,
              private auth: AuthService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.resource = data;
    this.TotalValueForm = this.formBuilder.group({
      value : [{value: '', disabled: false}, [Validators.required, CustomFormValidator.DecimalNumberValidator]],
  });

  }

  ngOnInit() {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['']);
      return false;
    }
  }

  savePrice() : void{
    if(this.TotalValueForm.valid){
      this.okClick();
    }
  }

  okClick(){
    this.dialog.close();
    this.data.Ok(this.data);
  }
  cancelClick(){
      this.dialog.close();
  }
}
