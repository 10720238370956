<div class="card cardbck">
    <div class="card-header card-header-color">
        <h2 mat-dialog-title>Reenvio de credenciales

        </h2>
    </div>
    <div class="card-block">
        <form #emailForm="ngForm" (ngSubmit)="ResendUserMail()">
            <mat-dialog-content style="margin:0px">Por favor ingrese el correo al cual se deben enviar las credenciales</mat-dialog-content>
            <br><br>
            <mat-form-field style="width: 100%">
                <input style="color:black !important" type="email" class="wtxt" [(ngModel)]="emailAddress" name="emailFormControl" matInput placeholder="Correo" [formControl]="emailFormControl"
                    maxlength="50">
                <mat-error *ngIf="emailFormControl.hasError('pattern')">
                    Please enter a valid email address
                </mat-error>
                <mat-hint class="wtxt" align="end">{{emailAddress.length}} / 50</mat-hint>
            </mat-form-field><br><br>
            <div>
                <mat-dialog-actions class="optFix pull-right">
                    <button class="btnPosFixClientUser btn " type="submit" [ngClass]="(!emailForm.form.valid) ? 'form-disabled' : 'form-enabled-color'"
                        [disabled]="!emailForm.form.valid || emailFormControl.hasError('pattern')" mat-button>Guardar</button>
                </mat-dialog-actions>
            </div>
        </form>
    </div>
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>