import { Component,Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'vpn-stats',
  templateUrl: './vpn-stats.component.html',
  styleUrls: ['./vpn-stats.component.scss']
})
export class VpnStatsComponent{
    StatsArray: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns = ['LocalSubnet', 'PeerSubnet', 'LastInformationalMessage', 'TunnelStatus'];
    loading:boolean = false;
    constructor(public dialogRef: MatDialogRef<VpnStatsComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
        this.StatsArray.data = data.Stats;
    }

    okClick(){
        this.dialogRef.close();
        this.data.Ok();
    }

    cancelClick(){
        if(!this.data.Cancel)
            this.dialogRef.close();
        else
            this.data.Cancel();
    }
}