import { Component, OnInit, Inject } from '@angular/core';
import { Globalization } from '../../globalization/globalization';
import { WlService } from '../../services/wlservice.service';
import { ProfileDTO, PermissionDTO } from '../../models/dtos.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BroadcastEmitter } from '../../services/BroadcastEmitter';
import {FormControl, Validators, FormGroup, FormBuilder, AbstractControl} from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';


@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit {
    profileList: Array<ProfileDTO> = [];
    globalization: any = Globalization;
    token: string;
    language: string;
    public searchProfile: string = "";
    public selectedProfile: ProfileDTO = null;
    public profilesLoading: boolean = true;
    public LoadingPermissions: boolean = false;
    public AvailablePermissions: Array<PermissionDTO> = [];

    constructor(private service: WlService, public snackbar: MatSnackBar, public dialog: MatDialog, public navbarBroadcast: BroadcastEmitter, public auth : AuthService ) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;

    }

    getProfileInitial(profile: ProfileDTO) {
        return profile.Name.substr(0, 1);
    }

    ngOnInit() {
        this.navbarBroadcast.SendMessage({
            actions: [
                { icon: 'add', name: 'Agregar Role', action: () => { this.AddProfile() } }
            ],
            display: true
        });
        this.GetProfiles();
    }

    GetProfiles() {
        this.profilesLoading = true;
        this.service.GetProfiles().subscribe(
            (response:any) => {
                this.profilesLoading = false;
                this.profileList = response;
            },
            error => {
                this.profilesLoading = false;
                this.snackbar.open('Error consultando perfiles. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    AddProfile() {
        this.dialog.open(ProfileEditComponent, {
            data: new ProfileDTO(),
        });
        this.dialog.afterAllClosed.subscribe(
            response=>{
                this.GetProfiles();
            }
        )
    }

    EditProfile(profile: ProfileDTO) {
        this.selectedProfile = profile;
        this.LoadingPermissions = true;
        this.service.GetProfilePermissions(profile.ID).subscribe(
            (response:any) => {
                this.LoadingPermissions = false;
                this.AvailablePermissions = response;
            }, error => {
                this.LoadingPermissions = false;
                this.snackbar.open('Error consultando permisos del perfil. ' + error.error, null, {
                    duration: 3000,
                });
            });
    }

    SaveProfile() {
        this.LoadingPermissions = true;
        this.service.SaveProfilePermissions(this.AvailablePermissions, this.selectedProfile.ID).subscribe(
            response => {
                this.LoadingPermissions = false;
                this.snackbar.open('Cambios guardados satisfactoriamente', null, {
                    duration: 3000,
                });
            }, error => {
                this.LoadingPermissions = false;
                this.snackbar.open('Error guardando datos. ' + error.error, null, {
                    duration: 3000,
                });
            }
        );
    }

    loadProfilePermissions(profile: ProfileDTO) {

    }
}
@Component({
    selector: 'role-edit-dialog',
    templateUrl: 'role-add.component.html',
    styleUrls: ['../../tools/modals/modals.scss']
})
export class ProfileEditComponent {
    public loading: boolean = false;
    addRoleForm : FormGroup;
    constructor( @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProfileEditComponent>, public snackbar: MatSnackBar, private service: WlService, private formBuilder : FormBuilder) {
        this.addRoleForm = this.formBuilder.group({
            'RoleName' : [{value:'',disabled: false},[Validators.required]],
        });
     }
    SaveForm() {
        this.loading = true;
        this.service.SaveProfile(this.data).subscribe(
            response=>{
                this.loading = false;
                this.dialogRef.close(response);
            },
            error=>{
                this.loading = false;
                this.snackbar.open('Error guardando datos. ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
