import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from "../../tools/grid/grid.component";
import { WlService } from '../../services/wlservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pending-machines',
  templateUrl: './pendingMachines.component.html',
  styleUrls: ['./pendingMachines.component.scss']
})

export class PendingMachinesComponent implements OnInit {
  @ViewChild('pendingMachines') pendingMachines: GridComponent;
  public grid = {
    columns: [
      { name: "Name", title: "Cliente" },
      { name: "ServiceID", title: "Service ID" },
      { name: "OperativeSystem", title: "Os" },
      { name: "Progress", title: "Progreso", type:"progressbar" },
      { name: "ElapsedTime", title: "Tiempo (Minutos)" }
    ],
    data: [],
    filter: "ServiceID",
    pageSize: 10,
    rowClick: (u) => { },
    refresh: (u) => { this.getMachines() },
    actions: [
      {
        text: "Reiniciar proceso",
        action: (u) => { this.resetProvisioning(u) },
      }
    ]
  }

  constructor(private service: WlService) { }


  getMachines() {
    this.pendingMachines.loading = true;
    this.service.GetPendingMachines().subscribe(
      response => {
        this.pendingMachines.setData(response);
      }
    )
  }

  resetProvisioning(row) {

  }

  ngOnInit() {
    this.getMachines();
  }
}
