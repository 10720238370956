<div class="modal-form-container">
  <div class="content-header">
      <div style='text-align:center' class="form-title">
          <div class="form-title-text pull-left" style="font-family: 'Times New Roman', Times, serif;">
            Detalles
          </div>
          <div class="close-modal full-width">
              <button mat-icon-button (click)="cancelClick()" class="pull-right">
                  <mat-icon>close</mat-icon>
              </button>
          </div>
      </div>
  </div>
  <div class="container-fluid">
    <mat-dialog-content style="margin:0px">
      <div class="row" id="portfolio">
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
          <div class="card" >
              <div class="card-title">Sistema Operativo</div>
              <div class="card-icon"><i class="material-icons">computer</i></div>
              <div class="card-data os-font"> 1231</div>
          </div>
      </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="card" >
                <div class="card-title">Cores</div>
                <div class="card-icon"><i class="material-icons">nfc</i></div>
                <div class="card-data"></div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-6 col-lg-4">
            <div class="card" >
                <div class="card-title">Ram</div>
                <div class="card-icon"><i class="material-icons">memory</i></div>
                <div class="card-data"> GB</div>
            </div>
        </div>

    </div>

    <div class="row" id="portfolio">
      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card" >
            <div class="card-title">Estado</div>
            <div class="card-icon"><i class="material-icons">adjust</i></div>
            <div class="card-data"></div>
        </div>
    </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
          <div class="card" >
              <div class="card-title">Hdd</div>
              <div class="card-icon"><i class="material-icons">storage</i></div>
              <div class="card-data"> </div>
          </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4">
          <div class="card" >
              <div class="card-title">Remote Access</div>
              <div class="card-icon"><i class="material-icons">important_devices</i></div>
              <div class="card-data">   </div>
          </div>
      </div>

   </div>

   <div class="row" id="portfolio">
    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
      <div class="card" >
          <div class="card-title">Mac Address</div>
          <div class="card-icon"><i class="material-icons">vpn_lock</i></div>
          <div class="card-data"></div>
      </div>
  </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card" >
            
        </div>
    </div>
    <div class="col-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card" >
            <div class="card-title">Orion IP</div>
            <div class="card-icon"><i class="material-icons">public</i></div>
            <div class="card-data">   </div>
        </div>
    </div>
 </div>
    </mat-dialog-content>
    </div>
      <br>
      <div style="height: 50px">
          <mat-dialog-actions class="optFix pull-right">
              <button color="warn" mat-raised-button (click)="cancelClick()">Cancelar</button>
          </mat-dialog-actions>
      </div>
  </div>