import { Component, OnInit, Input, Output } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-interfaces-stats',
  templateUrl: './interfaces-stats.component.html',
  styleUrls: ['./interfaces-stats.component.css']
})
export class InterfacesStatsComponent implements OnInit {

  dataCenterID: string;
  InterfacesCharts :  any = {
    colorScheme : {
      domain: ['#fac51d','#66bd6d','#faa026','#C7B42C','#29bb9c','#e96b56','#bbef94','#ff9f9f','#92e7ef','#5b8e7d']
    },
    // options
    showXAxis : true,
    showYAxis : true,
    roundDomains: true,
    gradient : true,
    showLegend : true,
    showXAxisLabel : true,
    showYAxisLabel : true,
    yAxisLabel : 'kbps',
    autoScale : true,
    data : [],
    schemeType : 'ordinal',
    animations : true
  }

  constructor( private wlservice: WlService , public snackbar: MatSnackBar,  private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
    this.dataCenterID = params["datacenterID"];
    });
  }

  filterData(timeFilter : string){   
    this.GetDataCharts(timeFilter);  
  }

  ngOnInit() {
    this.GetDataCharts("oneDay");
  }
  onSelect(event) {
    console.log(event);
  }
  GetDataCharts(timeFilter : string){
    this.wlservice.GetInterfacesStats(this.dataCenterID,timeFilter).subscribe((response:any) => {
    this.InterfacesCharts.data = response.Data;
    },error => {
       this.InterfacesCharts.data = [];
       this.snackbar.open('Error al consultar estadísticas en la nube'+ error.error, null, {
        duration: 3000,
      });
    });
  }
  Refresh(){
      this.InterfacesCharts.data = [];
      this.GetDataCharts("oneDay");
  }
  calendarAxisTickFormatting(date: string) {
    const day : Date = new Date(date);
    return day.toLocaleTimeString('en-US', { hour12: true, 
      hour: "numeric", 
      minute: "numeric"})
  }
}
