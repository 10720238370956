import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnatRulesDto } from '../../DTO/snat-rules-dto';

@Component({
  selector: 'app-new-firewall-rule',
  templateUrl: './new-firewall-rule.component.html',
  styleUrls: ['./new-firewall-rule.component.scss']
})
export class NewFirewallRuleComponent implements OnInit {

  newRule : SnatRulesDto;
  disabled: boolean = false;

  constructor(public dialogRef: MatDialogRef<NewFirewallRuleComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder : FormBuilder ) { 
    this.newRule = new SnatRulesDto();
  }

  ngOnInit(): void {
  }
  
  Close(): void {
    this.dialogRef.close(null);
  }

  AddRule(){
    /*this.service.AddFirewallRule(this.newRule).subscribe(
      response=>{
        this.rules.add(response);
      },
      error=>{}
    ) */
  }

  CloseRuleFormView(){

  }



}
