import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-eventsLog',
    templateUrl: './eventsLog.component.html',
    styleUrls: ['./eventsLog.component.css']
})
export class EventsLogComponent implements OnInit {

    ngOnInit(){

    }
}