import { Component, OnInit } from '@angular/core';
import { DhcpServerDto } from '../../DTO/dhcp-server-dto';

@Component({
  selector: 'app-new-dhcp-service',
  templateUrl: './new-dhcp-service.component.html',
  styleUrls: ['./new-dhcp-service.component.scss']
})
export class NewDhcpServiceComponent implements OnInit {
  newRule : DhcpServerDto;
  loading:boolean = false;
  dialogRef: any;

  constructor() { 
    this.newRule = new DhcpServerDto ();
  }

  ngOnInit(): void {
  }

  AddRule(){
    /*this.service.AddFirewallRule(this.newRule).subscribe(
      response=>{
        this.rules.add(response);
      },
      error=>{}
    ) */
  }
  Close(): void {
    this.dialogRef.close(null);
  }



}
