import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirewallIPInDto, RequestAliasFirewallDto, ipObject} from '../../../DTO/firewall-ip-dto';
import { WlService } from '../../../../../..//services/wlservice.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-pf-sense-ip',
  templateUrl: './new-pf-sense-ip.component.html',
  styleUrls: ['./new-pf-sense-ip.component.scss']
})
export class NewPfSenseIpComponent implements OnInit {
  loading:boolean = false;
  newIpForm : FormGroup;
  newRule : FirewallIPInDto;
  AddHostRuleForm : FormGroup;
  AddNetworkRuleForm : FormGroup;
  AddPortRuleForm : FormGroup;
  AddURLRuleForm : FormGroup;
  AddURLPortsRuleForm : FormGroup;
  AddURLTableRuleForm :FormGroup;
  AddURLTablePortsRuleForm :FormGroup;
  requestAliasFirewall : RequestAliasFirewallDto = new RequestAliasFirewallDto();

  rule : ipObject = new ipObject();
  rulesRows: ipObject[] = [];
 

  constructor(public dialogRef: MatDialogRef<NewPfSenseIpComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder : FormBuilder, private service :WlService) { 
    this.newIpForm = this.formBuilder.group({
      'Name' : [{value:'',disabled: this.loading},[Validators.required]],
      'Description' : [{value:'',disabled: this.loading},[Validators.required]],
      'Type' :  [{value:''} ,[Validators.required]]
  });

  this.newRule = new FirewallIPInDto();
  this.newRule.type = 'host';
  
  }

  ngOnInit(): void {

    this.AddHostRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    this.AddNetworkRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });
    
    this.AddPortRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    this.AddURLRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    this.AddURLPortsRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    this.AddURLTableRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    this.AddURLTablePortsRuleForm = this.formBuilder.group({
      rulesRows: this.formBuilder.array([this.initRulesRows()])
    });

    
  }

  Close(): void {
    this.dialogRef.close(null);
  }

  AddRules(){

    this.requestAliasFirewall.NewAliasFirewallDto =  this.newRule;
    this.requestAliasFirewall.customerId = this.data.customerId,
    this.requestAliasFirewall.datacenterId = this.data.datacenterId,

    console.log("AliasFirewall", this.requestAliasFirewall);

    this.service.PfCreateAliasFirewall(this.requestAliasFirewall)
    .subscribe(
      response => {
         console.log("regla agregada correctamente", response)
      },
      error => {
        console.log("Error al guardar las reglas ", error)
      }
    ) 
  }



  initRulesRows() {
    this.rule.type =  this.newRule.type;
    return this.formBuilder.group(this.rule);
  }


  addElements(){

    let control = null; 

    switch (this.newRule.type) {
      case 'host':
        control = <FormArray>this.AddHostRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'network': 
        control  = <FormArray>this.AddNetworkRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'port': 
        control  = <FormArray>this.AddPortRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'url': 
        control  = <FormArray>this.AddURLRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'url_ports': 
        control  = <FormArray>this.AddURLPortsRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'urltable': 
        control  = <FormArray>this.AddURLTableRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;
      case 'urltable_ports': 
        control  = <FormArray>this.AddURLTablePortsRuleForm.controls['rulesRows'];
        control.push(this.initRulesRows());
        break;         
      default:
        break;
    }
    this.updateRulesArray();

  }

  updateRulesArray(){  

    let values =  this.AddHostRuleForm.controls['rulesRows'].value +
      this.AddNetworkRuleForm.controls['rulesRows'].value +
      this.AddPortRuleForm.controls['rulesRows'].value +
      this.AddURLRuleForm.controls['rulesRows'].value +
      this.AddURLPortsRuleForm.controls['rulesRows'].value +  
      this.AddURLTableRuleForm.controls['rulesRows'].value +
      this.AddURLTablePortsRuleForm.controls['rulesRows'].value;
   

    this.newRule.value = values ; 

    console.log("values", this.newRule);
  }

  deleteHost(index){
    this.newRule.value.splice(index, 1);
  }

  deleteDisk(index: number) {
    if(this.newRule.value.length > 1){
      const control = <FormArray>this.AddHostRuleForm.controls['rulesRows'];
      control.removeAt(index);
      this.updateRulesArray();
    } 
  }
  


}
