import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirewallRulesDto } from '../../DTO/firewall-rules-dto';


@Component({
  selector: 'app-detail-pfsense-ip',
  templateUrl: './detail-pfsense-ip.component.html',
  styleUrls: ['./detail-pfsense-ip.component.scss']
})
export class DetailPfsenseIpComponent implements OnInit {
  resource : FirewallRulesDto;

  constructor( private dialog : MatDialogRef<DetailPfsenseIpComponent> ,@Inject(MAT_DIALOG_DATA) public data: FirewallRulesDto ) { 
    this.resource = new FirewallRulesDto();
    if(data !=null)
      this.resource = data;
  }

  ngOnInit(): void {
  }

  okClick(){
    this.dialog.close();
  }
  cancelClick(){
      this.dialog.close();
  }
}
