<div class="container-fluid without-padding">
  <mat-card class="event-card-container">
    <div class="content-header padding-card">
      <div class="row">
        <div class="col" style="text-align: center">
          <div class="logo-module">
            <img src="assets/img/GrabacionesCSJ.png" width="300px" alt="Rdp" />
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group class="top-network" [(selectedIndex)]="selectedIndex" *ngIf="show">
      <mat-tab label="Dashboard Principal">
        <app-csj-main-dashboard>
        </app-csj-main-dashboard>
      </mat-tab>
      <mat-tab label="Gráficos Generales">
        <app-csj-general-graphics>
        </app-csj-general-graphics>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
