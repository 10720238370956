<mat-sidenav-container class="wl-content">
  <mat-sidenav #sidenav position="end">
      <mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
      <div class="container-fluid">
          <div class="sidenav-title">
              <h5>{{ 'ACCOUNT_CONFIGURATION.EDIT_ACCOUNT' | translate}}</h5>
          </div>
          <div class="row container-fluid sidenav-form-content">
              <form [formGroup]="accountForm" (ngSubmit)="SaveUserInfo()" class="full-width">
                  <div class="sidenav-subtitle">
                      <h6>{{ 'ACCOUNT_CONFIGURATION.USER_INFORMATION' | translate}}</h6>
                  </div>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input required matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.NAME' | translate}}" name="name" id="name" formControlName="name" [(ngModel)]="user.Name">
                      <mat-icon matSuffix *ngIf="accountForm.get('name').valid && accountForm.get('name').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!accountForm.get('name').valid && accountForm.get('name').touched" class="form-rejected">error</mat-icon>
                      <mat-hint class="wtxt" align="end">{{user.Name ? user.Name.length : 0}} / 50</mat-hint>
                      <mat-error *ngIf="accountForm.get('name').hasError('required') && accountForm.get('name').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input required matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.USER_NAME' | translate}}" name="username" id="username" formControlName="username" [(ngModel)]="user.UserName">
                      <mat-icon matSuffix *ngIf="accountForm.get('username').valid && accountForm.get('username').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!accountForm.get('username').valid && accountForm.get('username').touched" class="form-rejected">error</mat-icon>
                      <mat-hint class="wtxt" align="end">{{user.UserName ? user.UserName.length : 0}} / 50</mat-hint>
                      <mat-error *ngIf="accountForm.get('username').hasError('required') && accountForm.get('username').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input required matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.EMAIL' | translate}}" name="email" id="email" formControlName="email" [(ngModel)]="user.Email">
                      <mat-icon matSuffix *ngIf="accountForm.get('email').valid && accountForm.get('email').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!accountForm.get('email').valid && accountForm.get('email').touched" class="form-rejected">error</mat-icon>
                      <mat-hint class="wtxt" align="end">{{user.Email ? user.Email.length : 0}} / 50</mat-hint>
                      <mat-error *ngIf="accountForm.get('email').hasError('required') && accountForm.get('email').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                  </mat-form-field>
                  <mat-form-field floatLabel="always" class="full-width">
                      <mat-select class="margin-top-10 col-12 full-width" required placeholder="{{ 'ACCOUNT_CONFIGURATION.LANGUAGE' | translate}}" name="language" id="language" formControlName="language"
                          [(ngModel)]="user.Language">
                          <mat-option *ngFor="let language of constants.Languages" [value]="language.Code">{{language.Name}}</mat-option>
                      </mat-select>
                      <mat-icon matSuffix *ngIf="accountForm.get('language').valid && accountForm.get('language').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!accountForm.get('language').valid && accountForm.get('language').touched" class="form-rejected">error</mat-icon>
                      <mat-error *ngIf="accountForm.get('language').hasError('required') && accountForm.get('language').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                  </mat-form-field>
                  <div class="col-12 margin-top-10">
                      <button class="pull-right" color="primary" type="submit" mat-raised-button [disabled]="!accountForm.valid">{{ 'ACCOUNT_CONFIGURATION.SAVE' | translate}}</button>
                  </div>
              </form>
              <form [formGroup]="passwordForm" (ngSubmit)="ChangePassword()" class="full-width">
                  <div class="sidenav-subtitle">
                      <h6>{{ 'ACCOUNT_CONFIGURATION.CHANGE_PASSWORD' | translate}}</h6>
                  </div>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input name="currentPassword" id="currentPassword" formControlName="currentPassword" [(ngModel)]="OldPassword" required type="password"
                          matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.CURRENT_PASSWORD' | translate}}">
                      <mat-icon matSuffix *ngIf="passwordForm.get('currentPassword').valid && passwordForm.get('currentPassword').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!passwordForm.get('currentPassword').valid && passwordForm.get('currentPassword').touched" class="form-rejected">error</mat-icon>
                      <mat-error *ngIf="passwordForm.get('currentPassword').hasError('required') && passwordForm.get('currentPassword').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                      <mat-hint class="wtxt" align="end">{{OldPassword ? OldPassword.length : 0}} / 50</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input name="newPassword" id="newPassword" formControlName="newPassword" [(ngModel)]="NewPassword" required type="password"
                          matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.NEW_PASSWORD' | translate}}">
                      <mat-icon matSuffix *ngIf="passwordForm.get('newPassword').valid && passwordForm.get('newPassword' ).touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!passwordForm.get('newPassword').valid && passwordForm.get('newPassword').touched" class="form-rejected">error</mat-icon>
                      <mat-error *ngIf="passwordForm.get('newPassword').hasError('required') && passwordForm.get('newPassword').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</mat-error>
                      <mat-hint class="wtxt" align="end">{{NewPassword ? NewPassword.length : 0}} / 50</mat-hint>
                  </mat-form-field>
                  <mat-form-field class="col-12 full-width no-default-padding">
                      <input name="repeatPassword" id="repeatPassword" formControlName="repeatPassword" [(ngModel)]="RepeatPassword" required type="password"
                          matInput placeholder="{{ 'ACCOUNT_CONFIGURATION.REPEAT_NEW_PASSWORD' | translate}}">
                      <mat-icon matSuffix *ngIf="passwordForm.get('repeatPassword').valid && passwordForm.get('repeatPassword').touched" class="form-accepted">done</mat-icon>
                      <mat-icon matSuffix *ngIf="!passwordForm.get('repeatPassword').valid && passwordForm.get('repeatPassword').touched" class="form-rejected">error</mat-icon>
                      <mat-error *ngIf="passwordForm.get('repeatPassword').hasError('required') && passwordForm.get('repeatPassword').touched">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_REQUIRED' | translate}}</mat-error>
                      <mat-hint class="wtxt" align="end">{{RepeatPassword ? RepeatPassword.length : 0}} / 50</mat-hint>
                  </mat-form-field>
                  <p class="messageError" *ngIf="NewPassword && NewPassword != RepeatPassword">{{ 'ACCOUNT_CONFIGURATION.VALIDATION_PASSWORD' | translate}}</p>
                  <div class="col-12 margin-top-10">
                      <button class="pull-right" color="primary" mat-raised-button type="submit" [disabled]="!passwordForm.valid || (RepeatPassword != NewPassword) ">{{ 'ACCOUNT_CONFIGURATION.CHANGE_PASSWORD' | translate}}</button>
                  </div>
              </form>
          </div>
      </div>
  </mat-sidenav>

  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="container-fluid">
      <div class="control-panel-container fixPOS">
          <div class="row">
              <div class="col-xl-5 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-grid-list cols="6" rowHeight="170px">
                      <mat-grid-tile class="user-detail-tile mat-box-shadow" [colspan]="6" [rowspan]="1">
                          <div class="circleTop">{{initials}}</div>
                          <mat-list dense class="user-data-account">
                              <mat-list-item>{{ 'ACCOUNT_CONFIGURATION.NAME' | translate}}: {{user.Name}}</mat-list-item>
                              <mat-list-item>{{ 'ACCOUNT_CONFIGURATION.USER_NAME' | translate}}: {{user.UserName}}</mat-list-item>
                              <mat-list-item>{{ 'ACCOUNT_CONFIGURATION.EMAIL' | translate}}: {{user.Email}}</mat-list-item>
                          </mat-list>
                          <div>
                              <button matTooltip="{{ 'ACCOUNT_CONFIGURATION.EDIT' | translate}}" mat-mini-fab class="btn1 btnstyle01" mat-button (click)="sidenav.open()"><em class="fa fa-pencil"></em></button>
                          </div>
                      </mat-grid-tile>
                  </mat-grid-list>
              </div>
          </div>
      </div>
  </div>


</mat-sidenav-container>
