<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Rule.id == null">{{'FIREWALL_CONFIGURATION.ADD_FIREWALL_RULE' | translate}}</h5>
        <h5 *ngIf="Rule.id != null">{{'FIREWALL_CONFIGURATION.EDIT_FIREWALL_RULE' | translate}}</h5>
    </div>
    <form [formGroup]="firewallForm" (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'FIREWALL_CONFIGURATION.SETTING_THE_RULE' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input name="Name" id="Name" formControlName="name" [(ngModel)]="Rule.Name" required matInput placeholder="{{'FIREWALL_CONFIGURATION.NAME' | translate}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="firewallForm.get('name').valid && firewallForm.get('name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!firewallForm.get('name').valid && firewallForm.get('name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="firewallForm.get('name').hasError('required') && firewallForm.get('name').touched">{{'FIREWALL_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{Rule.Name ? Rule.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-option class="col-12 full-width" floatPlaceholder="always">
                <mat-select class="col-12 full-width margin-top-10" required placeholder="{{'FIREWALL_CONFIGURATION.RULE_TYPE' | translate}}"
                    name="RuleType" id="RuleType" formControlName="ruleType" [(ngModel)]="Rule.Action" floatPlaceholder="always">
                    <mat-option [value]="'accept'">Aceptar</mat-option>
                    <mat-option [value]="'deny'">{{'FIREWALL_CONFIGURATION.DENY' | translate}}</mat-option>
                </mat-select>
                <div class="error-messages col-12">
                    <div *ngIf="firewallForm.get('ruleType').hasError('required') && firewallForm.get('ruleType').touched">{{'FIREWALL_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
                </div>
            </mat-option>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{'FIREWALL_CONFIGURATION.IP_SOURCE' | translate}}"
                    name="SourceIp" id="SourceIp" formControlName="sourceIp" [(ngModel)]="source.IpAddress"
                    floatPlaceholder="always" multiple>
                    <mat-option *ngFor="let ipset of ipSets" [value]="ipset.Value">{{ipset.Value}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-10" required
                 placeholder="{{'FIREWALL_CONFIGURATION.ACTION' | translate}}" name="Status" id="Status" formControlName="status" [(ngModel)]="Rule.Enabled">
                    <mat-option [value]="'true'">{{'FIREWALL_CONFIGURATION.ENABLED' | translate}}</mat-option>
                    <mat-option [value]="'false'">{{'FIREWALL_CONFIGURATION.DISABLED' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="firewallForm.get('status').hasError('required') && firewallForm.get('status').touched">{{'FIREWALL_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{'FIREWALL_CONFIGURATION.IP_DESTINATION' | translate}}"
                    name="DestinationIp" id="DestinationIp" formControlName="destinationIP" [(ngModel)]="Destination.IpAddress"
                    floatPlaceholder="always" multiple>
                    <mat-option *ngFor="let ipset of ipSets" [value]="ipset.Value">{{ipset.Value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <form [formGroup]="applicationForm" (ngSubmit)="saveApplication()">
                <div class="sidenav-subtitle col-12">
                    <h6>{{'FIREWALL_CONFIGURATION.PORT_CONFIGURATION' | translate}}</h6>
                </div>
                <mat-list *ngIf="getPorts().length > 0" class="margin-bottom-10">
                    <mat-list-item class="col-12" *ngFor="let item of getPorts()">
                        <div class="row no-default-padding">
                            <div class="col-8 port-title">{{item}}</div>
                            <div class="col-4">
                                <button (click)="removePort(item)" mat-button><mat-icon>cancel</mat-icon></button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>

                <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                    <mat-select class="col-12 full-width margin-top-10" placeholder="{{'FIREWALL_CONFIGURATION.SPECIFY_PORTS' | translate}}" name="SpecifyPorts" id="SpecifyPorts"
                        formControlName="specifyPorts" [(ngModel)]="requirePort" floatPlaceholder="always">
                        <mat-option [value]="false">{{'FIREWALL_CONFIGURATION.NO' | translate}}</mat-option>
                        <mat-option [value]="true">{{'FIREWALL_CONFIGURATION.YES' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div *ngIf="requirePort" class="col-12 margin-top-10">
                    <div class="row no-default-padding">
                        <mat-form-field class="col-6 full-width margin-top-10" floatLabel="always">
                            <mat-select required placeholder="{{'FIREWALL_CONFIGURATION.PROTOCOL' | translate}}"
                                name="protocol" id="protocol" formControlName="protocol" [(ngModel)]="newApplication.protocol" name="NewProtocolN"
                                floatPlaceholder="always">
                                <mat-option [value]="'tcp'">TCP</mat-option>
                                <mat-option [value]="'udp'">UDP</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-6 full-width margin-top-10" floatLabel="always">
                            <input matInput name="port" id="port" formControlName="port" [(ngModel)]="newApplication.port" placeholder="{{'FIREWALL_CONFIGURATION.PORT' | translate}}"
                                maxlength="50">
                        </mat-form-field>
                        <div class="col-12">
                            <button color="primary" [disabled]="!applicationForm.valid" class="pull-right" mat-raised-button>{{'FIREWALL_CONFIGURATION.ADD_PORT' | translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'FIREWALL_CONFIGURATION.CANCEL' | translate}}</button>
            </div>

            <div class="col-6 full-width">
                <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!firewallForm.valid">{{'FIREWALL_CONFIGURATION.SAVE' | translate}}</button>
            </div>
        </div>
    </form>
</div>
