<div class="card">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
    <div class="wl-grid container-fluid">
        <div class="wl-grid-head">
            <div class="wl-grid-toolbar row">
                <div class="col-6 toolbar-buttons">
                    <div class="wl-toolbar-component-container" *ngFor="let comp of settings.toolbar">
                        <div *ngIf="!comp.permission || service.Permissions.indexOf(comp.permission) != -1">
                            <button [disabled]="loading" color="primary" *ngIf='comp.type=="button"' (click)="comp.click()" mat-button>
                               {{comp.text}}</button>
                            <button [disabled]="loading" color="accent" matTooltip="comp.text" *ngIf='comp.type=="icon-button"' (click)="comp.click()"
                                mat-button>
                               <i class="material-icons" color="accent">{{comp.icon}}</i></button>
                        </div>
                    </div>
                </div>
                <div class="col-6">

                    <button class="pull-right wl-grid-refresh-button" matTooltip="Refrescar" [hidden]="settings.refresh==null" (click)="settings.refresh()"
                        mat-icon-button><mat-icon class="mat-24">autorenew</mat-icon></button>
                    <button class="pull-right wl-grid-search-button" matTooltip="Buscar" [hidden]="filtering" (click)="ShowFilter()" mat-icon-button><mat-icon class="mat-24">filter_list</mat-icon></button>
                    <mat-form-field [hidden]="!filtering" class="wl-filter-container pull-right wl-grid-input-search-button" floatLabel="never">
                        <mat-icon matPrefix>search</mat-icon>
                        <input #FilterInput [(ngModel)]="filterText" (blur)="hideFilter()" (input)="filterGrid()" matInput>
                        <div matSuffix class="search-suffix" (click)="clearFilter()">
                            <mat-icon class="mat-24">clear</mat-icon>
                        </div>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="wl-grid-body" [ngClass]="{'scroll-body' : settings.minWidth && settings.minWidth!='100%'}">
            <div class="wl-grid-columns" [style.min-width]="settings.minWidth">
                <div style="width:100%">
                    <div class="wl-grid-head-column" [style.width.%]="(settings.actions==null?100:90) / settings.columns.length" *ngFor="let column of settings.columns">
                        <label>{{column.title}}</label>
                    </div>
                </div>
            </div>
            <div class="wl-grid-body-row" [style.min-width]="settings.minWidth" [style.cursor]="settings.rowClick?'pointer':'cursor'"
                (dblclick)="settings.rowClick?settings.rowClick(row):null" *ngFor="let row of pageData; let i = index">
                <div style="width:100%; height: 100%">
                    <div style="height:100%" class="wl-grid-head-column" [style.width.%]="(settings.actions==null?100:90) / settings.columns.length"
                        *ngFor="let column of settings.columns; let ind = index">
                        <span *ngIf="!column.type" class="cell-content" (click)="viewDetail(row,column)" [style.cursor]="settings.rowClick || column.showDetail ?'pointer':'cursor'"
                            matTooltip="{{!column.showDetail ? getValue(row,column) : 'Ver Detalle'}} " [innerHTML]="getValue(row,column) | SafeHtml">
                        </span>
                        <span *ngIf="column.type == 'progressbar'">
                            <mat-progress-bar matTooltip="{{row[column.name]}}%" style="margin-top:10px" color="primary" mode="determinate" [value]="row[column.name]"></mat-progress-bar>
                        </span>
                        <span *ngIf="column.type == 'icon'">
                                <mat-icon matTooltip="{{column.icon(row[column.name]).tooltip}}" class="mat-24" [ngClass]="GetGridIconName(row,column.name)"> {{column.icon(row[column.name]).icon}}</mat-icon>
                        </span>
                    </div>
                    <div style="height: 100%;width:10%;overflow-x:hidden;text-align: center" *ngIf="settings.actions!=null">
                        <button [hidden]="GetActionButtons(i,row).length==0" style="width:100%;height: 100%" mat-button [matMenuTriggerFor]="gridMenu"><mat-icon>more_vert</mat-icon></button>
                        <mat-menu #gridMenu="matMenu">
                            <div *ngFor="let menuOption of GetActionButtons(i,row)">
                                <button (click)="menuOption.action(row)" mat-menu-item>{{menuOption.text}}</button>
                            </div>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="wl-grid-footer">
            <div class="wl-grid-navigator col-12 pull-right">
                <div class="wl-grid-paginator">
                    <span>{{globalization.grid.gridFirstPart[language]}} </span>
                    <span>{{settings.data.length == 0 ? 0 : ((pageNumber * (settings.pageSize==null?5:settings.pageSize))+1)}}</span>
                    <span> a </span>
                    <span *ngIf="(pageNumber * (settings.pageSize==null?5:settings.pageSize)) + 1 + (settings.pageSize==null?5:settings.pageSize) <= settings.data.length">{{(pageNumber * (settings.pageSize==null?5:settings.pageSize)) + (settings.pageSize==null?5:settings.pageSize)}}</span>
                    <span *ngIf="(pageNumber * (settings.pageSize==null?5:settings.pageSize)) + 1 + (settings.pageSize==null?5:settings.pageSize) > settings.data.length">{{settings.data.length}}</span>
                    <span> de </span>
                    <span>{{settings.data.length}} {{globalization.grid.gridSecondPart[language]}}</span>
                    <button [disabled]="pageNumber==0" mat-icon-button (click)="previousPage()"><mat-icon class="mat-24">keyboard_arrow_left</mat-icon></button>
                    <button [disabled]="pageNumber>=((settings.data.length/(settings.pageSize==null?5:settings.pageSize))-1)" mat-icon-button
                        (click)="nextPage()"><mat-icon class="mat-24">keyboard_arrow_right</mat-icon></button>
                </div>
            </div>
        </div>
