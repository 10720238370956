<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Rule.Name == null">{{'VPN_CONFIGURATION.CREATE_VPN_CONNECTION' | translate}}</h5>
        <h5 *ngIf="Rule.Name != null && !Rule.IsNew">{{'VPN_CONFIGURATION.EDIT_VPN_CONNECTION' | translate}}</h5>
    </div>
    <form [formGroup]="vpnForm" novalidate (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'VPN_CONFIGURATION.INITIAL_SETUP' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VPN_CONFIGURATION.STATUS' | translate}}"
                    name="status" id="status" formControlName="status" [(ngModel)]="Rule.Enabled" name="enabled" floatPlaceholder="always">
                    <mat-option [value]="'true'">{{'VPN_CONFIGURATION.ENABLED' | translate}}</mat-option>
                    <mat-option [value]="'false'">{{'VPN_CONFIGURATION.DISABLED' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('status').hasError('required') && vpnForm.get('status').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input required [disabled]="!Rule.IsNew" name="name" id="name" formControlName="name" [(ngModel)]="Rule.Name" name="name"
                    matInput placeholder="{{'VPN_CONFIGURATION.NAME' | translate}}" maxlength="50">
                <mat-icon matSuffix *ngIf="vpnForm.get('name').valid && vpnForm.get('name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!vpnForm.get('name').valid && vpnForm.get('name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="end">{{Rule.Name ? Rule.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('name').hasError('required') && vpnForm.get('name').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <div class="sidenav-subtitle col-12">
                <h6>{{'VPN_CONFIGURATION.SECURITY_SETTINGS' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VPN_CONFIGURATION.ALGORITHM' | translate}}"
                    name="algorithm" id="algorithm" formControlName="algorithm" [(ngModel)]="Rule.EncryptionAlgorithm" name="encryptionAlgorithm"
                    floatPlaceholder="always">
                    <mat-option *ngFor="let encryptionAlgorithm of constants.encryptionAlgorithms" [value]="encryptionAlgorithm.Value">{{encryptionAlgorithm.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('algorithm').hasError('required') && vpnForm.get('algorithm').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VPN_CONFIGURATION.DIFFIEHELLMAN' | translate}}"
                    name="group" id="group" formControlName="group" [(ngModel)]="Rule.DhGroup" name="dhGroup" floatPlaceholder="always">
                    <mat-option *ngFor="let dhGroup of constants.dhGroups" [value]="dhGroup.Value">{{dhGroup.Name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('group').hasError('required') && vpnForm.get('group').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VPN_CONFIGURATION.ENABLE_PFS' | translate}}"
                    name="habilitarPFS" id="habilitarPFS" formControlName="habilitarPFS" [(ngModel)]="Rule.EnablePfs" name="enablePfs"
                    floatPlaceholder="always">
                    <mat-option [value]="'true'">{{'VPN_CONFIGURATION.YES' | translate}}</mat-option>
                    <mat-option [value]="'false'">{{'VPN_CONFIGURATION.NO' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('habilitarPFS').hasError('required') && vpnForm.get('habilitarPFS').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-checkbox [(ngModel)]="checked" [checked]="checked"  formControlName="editPassword">{{'VPN_CONFIGURATION.EDIT_PASSWORD' | translate}}</mat-checkbox>
                <input required type="password" name="password" id="password" formControlName="password" [(ngModel)]="Rule.Psk" name="psk"
                    matInput placeholder="{{'VPN_CONFIGURATION.PASSWORD' | translate}}" maxlength="64"
                    [readonly]="!checked">
                <mat-icon matSuffix *ngIf="vpnForm.get('password').valid && vpnForm.get('password').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!vpnForm.get('password').valid && vpnForm.get('password').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="end">{{Rule.Psk ? Rule.Psk.length : 0}} / 64</mat-hint>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('password').hasError('maxlength') && vpnForm.get('password').touched">{{'VPN_CONFIGURATION.THE_FIELD_MUST_BE_A_MAXIMUM_15' | translate}}</div>
                <div *ngIf="vpnForm.get('password').hasError('required') && vpnForm.get('password').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <div class="sidenav-subtitle col-12">
                <h6>{{'VPN_CONFIGURATION.SOURCE_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{'VPN_CONFIGURATION.LOCAL_IP' | translate}}"
                    name="localIp" id="localIp" formControlName="localIp" [(ngModel)]="Rule.LocalIp" name="localIp" floatPlaceholder="always">
                    <mat-option *ngFor="let publicip of PublicIps" [value]="publicip">{{publicip}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('localIp').hasError('required') && vpnForm.get('localIp').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input pattern="^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1})(,((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1})){0,}$"
                    required name="localSubnet" id="localSubnet" formControlName="localSubnet" [(ngModel)]="localSubnets" name="localSubnets"
                    matInput placeholder="{{'VPN_CONFIGURATION.LOCAL_SUBNETS' | translate}}" maxlength="600">
                <mat-icon matSuffix *ngIf="vpnForm.get('localSubnet').valid && vpnForm.get('localSubnet').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!vpnForm.get('localSubnet').valid && vpnForm.get('localSubnet').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="end">{{localSubnets ? localSubnets.length : 0}} / 600</mat-hint>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('localSubnet').hasError('maxlength') && vpnForm.get('localSubnet').touched">{{'VPN_CONFIGURATION.THE_FIELD_MUST_BE_A_MAXIMUM_600' | translate}}</div>
                <div *ngIf="vpnForm.get('localSubnet').hasError('required') && vpnForm.get('localSubnet').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <div class="sidenav-subtitle col-12">
                <h6>{{'VPN_CONFIGURATION.DESTINATION_SETTINGS' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input pattern="^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$"
                    required name="peerIp" id="peerIp" formControlName="peerIp" [(ngModel)]="Rule.PeerIp" name="peerIp" matInput
                    placeholder="{{'VPN_CONFIGURATION.DESTINATION_IP' | translate}}" maxlength="600">
                <mat-icon matSuffix *ngIf="vpnForm.get('peerIp').valid && vpnForm.get('peerIp').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!vpnForm.get('peerIp').valid && vpnForm.get('peerIp').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="end">{{Rule.PeerIp ? Rule.PeerIp.length : 0}} / 600</mat-hint>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('peerIp').hasError('maxlength') && vpnForm.get('peerIp').touched">{{'VPN_CONFIGURATION.THE_FIELD_MUST_BE_A_MAXIMUM_600' | translate}}</div>
                <div *ngIf="vpnForm.get('peerIp').hasError('required') && vpnForm.get('peerIp').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input pattern="^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1})(,((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/[0-9]{1,2}){0,1})){0,}$"
                    required name="destinationIp" id="destinationIp" formControlName="destinationIp" [(ngModel)]="peerSubnets"
                    name="peerSubnets" matInput placeholder="{{'VPN_CONFIGURATION.DESTINATION_SUBNETS' | translate}}"
                    maxlength="600">
                <mat-icon matSuffix *ngIf="vpnForm.get('destinationIp').valid && vpnForm.get('destinationIp').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!vpnForm.get('destinationIp').valid && vpnForm.get('destinationIp').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="end">{{peerSubnets ? peerSubnets.length : 0}} / 600</mat-hint>
            </mat-form-field>
            <div class="error-messages col-12">
                <div *ngIf="vpnForm.get('destinationIp').hasError('maxlength') && vpnForm.get('destinationIp').touched">{{'VPN_CONFIGURATION.THE_FIELD_MUST_BE_A_MAXIMUM_600' | translate}}</div>
                <div *ngIf="vpnForm.get('destinationIp').hasError('required') && vpnForm.get('destinationIp').touched">{{'VPN_CONFIGURATION.REQUIRED_FIELD' | translate}}.</div>
            </div>

        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'VPN_CONFIGURATION.CANCEL' | translate}}</button>
            </div>

            <div class="col-6 full-width">
                <button class="pull-right" color="primary" type="submit" mat-raised-button [disabled]="!vpnForm.valid">{{'VPN_CONFIGURATION.SAVE' | translate}}</button>
            </div>
        </div>
    </form>

</div>
