<div class="card" style="width:500px">
  <div class="card-block container-fluid margin-bottom-10">
    <div class="card-title modal-title">
      <h6 class="pull-left">{{data.Title}}</h6>
      <div class="pull-right close-button" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
      <div class="full-width margin-top-10" style="display: inline-block">
        <section class="example-section">
          <label class="example-margin">Por favor seleccione una de estas opciones para poder deshabilitar el serverProtection </label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="optionSelected">
              <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option">
                {{option}}
              </mat-radio-button>
            </mat-radio-group>
        </section>
      </div>
      <div class="full-width margin-top-20">
        <button type = "submit" color="primary" mat-button (click)="okClick()" class="pull-right" *ngIf="!loading" [disabled] ="optionSelected == null" id="disabledServerProtection">Aceptar</button>
        <button type = "button" color="warn" mat-button (click)="cancelClick()" class="pull-right" *ngIf="!loading" id="cancelEmail">Cancelar</button>
      </div>
  </div>
</div>
