<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Rule.BindingId == null">{{'DHCP_CONFIGURATION.ADD_DHCP' | translate}}</h5>
        <h5 *ngIf="Rule.BindingId != null">{{'DHCP_CONFIGURATION.EDIT_DHCP' | translate}}</h5>
    </div>
    <form [formGroup]="dhcpConfigurationFb" novalidate (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>{{'DHCP_CONFIGURATION.SERVICE_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-form-field class="full-width col-12" floatLabel="always">
                <mat-select (selectionChange)="selectServiceID($event)" [(ngModel)]="selectedMac" class="col-12 full-width margin-top-20" required
                    placeholder="{{'DHCP_CONFIGURATION.SERVICE_ID' | translate}}" name="CustomerType" formControlName="mac">
                    <mat-option *ngFor="let mac of AvailableMacs" [value]="mac" >{{mac.ServiceID}}</mat-option>
                </mat-select>
                <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('mac').valid && dhcpConfigurationFb.get('mac').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('mac').valid && dhcpConfigurationFb.get('mac').touched" class="form-rejected">error</mat-icon>
                <mat-error *ngIf="dhcpConfigurationFb.get('mac').hasError('required') && dhcpConfigurationFb.get('mac').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                <input class="" [(ngModel)]="Rule.IpAddress" name="ipAddress" required matInput placeholder="{{'DHCP_CONFIGURATION.IP_ADDRESS' | translate}}"
                    maxlength="50" formControlName="ipAddress">
                    <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('ipAddress').valid && dhcpConfigurationFb.get('ipAddress').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('ipAddress').valid && dhcpConfigurationFb.get('ipAddress').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="dhcpConfigurationFb.get('ipAddress').hasError('required') && dhcpConfigurationFb.get('ipAddress').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{ Rule.IpAddress ? Rule.IpAddress.length : 0}} / 50</mat-hint>
            </mat-form-field>
            <div class="sidenav-subtitle col-12">
                <h6>{{'DHCP_CONFIGURATION.NETWORK_CONFIGURATION' | translate}}</h6>
            </div>
            <mat-form-field class="col-12 full-width margin-top-10" floatLabel="always">
                <input required class="" [(ngModel)]="Rule.DefaultGateway" name="defaultGateway" matInput placeholder="{{'DHCP_CONFIGURATION.DEFAULT_GATEWAY' | translate}}"
                    maxlength="50" formControlName="defaultGateway">
                    <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('defaultGateway').valid && dhcpConfigurationFb.get('defaultGateway').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('defaultGateway').valid && dhcpConfigurationFb.get('defaultGateway').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="dhcpConfigurationFb.get('defaultGateway').hasError('required') && dhcpConfigurationFb.get('defaultGateway').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{Rule.DefaultGateway ? Rule.DefaultGateway.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input class="" [(ngModel)]="Rule.PrimaryNameServer" name="primaryNameServer" required matInput placeholder="{{'DHCP_CONFIGURATION.DNS_PRIMARY' | translate}}"
                    maxlength="20" formControlName="primaryNameServer">
                    <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('primaryNameServer').valid && dhcpConfigurationFb.get('primaryNameServer').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('primaryNameServer').valid && dhcpConfigurationFb.get('primaryNameServer').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="dhcpConfigurationFb.get('primaryNameServer').hasError('required') && dhcpConfigurationFb.get('primaryNameServer').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{Rule.PrimaryNameServer ? Rule.PrimaryNameServer.length : 0}} / 20</mat-hint>
            </mat-form-field>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input class="" [(ngModel)]="Rule.SecondaryNameServer" name="secondaryNameServer" required matInput placeholder="{{'DHCP_CONFIGURATION.DNS_SECONDARY' | translate}}"
                    maxlength="30" formControlName="secondaryNameServer">
                    <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('secondaryNameServer').valid && dhcpConfigurationFb.get('secondaryNameServer').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('secondaryNameServer').valid && dhcpConfigurationFb.get('secondaryNameServer').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="dhcpConfigurationFb.get('secondaryNameServer').hasError('required') && dhcpConfigurationFb.get('secondaryNameServer').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{Rule.SecondaryNameServer ? Rule.SecondaryNameServer.length : 0}} / 30</mat-hint>
            </mat-form-field>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input class="" [(ngModel)]="Rule.SubnetMask" name="subnetMask" required matInput placeholder="{{'DHCP_CONFIGURATION.NET_MASK' | translate}}"
                    maxlength="30" formControlName="subnetMask">
                    <mat-icon matSuffix *ngIf="dhcpConfigurationFb.get('subnetMask').valid && dhcpConfigurationFb.get('subnetMask').touched" class="form-accepted">done</mat-icon>
                    <mat-icon matSuffix *ngIf="!dhcpConfigurationFb.get('subnetMask').valid && dhcpConfigurationFb.get('subnetMask').touched" class="form-rejected">error</mat-icon>
                    <mat-error *ngIf="dhcpConfigurationFb.get('subnetMask').hasError('required') && dhcpConfigurationFb.get('subnetMask').touched">{{'DHCP_CONFIGURATION.REQUIRED_FIELD' | translate}}</mat-error>
                <mat-hint class="" align="end">{{Rule.SubnetMask ? Rule.SubnetMask.length : 0}} / 30</mat-hint>
            </mat-form-field>
        </div>
        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">{{'DHCP_CONFIGURATION.CANCEL' | translate}}</button>
            </div>

            <div class="col-6 full-width">
                    <button class="pull-right" type="submit" color="primary" [disabled]="!dhcpConfigurationFb.valid"   mat-raised-button>{{'DHCP_CONFIGURATION.SAVE' | translate}}</button>
            </div>
        </div>
    </form>

</div>
