<mat-sidenav-container style="height: 100%">
    <mat-sidenav (closed)="closeSidenav()" #sidenav position="end" class="detail-sidenav">
        <disk-configuration [sidenav]="sidenav" [Machine]="machine" [AdditionalService]="SelectedDisk" (savedAdditionalService)="SaveDisk($event)"></disk-configuration>
    </mat-sidenav>
    <div class="margin-top-10">
        <div class="row" style="margin-left: 0px;margin-right: 0px;">
            <div class="col-lg-4">
                <div class="row container-fluid">
                    <div class="col-12 no-default-padding virtualmachine-settings-card">
                        <div class="virtualmachine-settings">
                            <div *ngIf="machine.ServiceID && machine.State != 'PENDING' && machine.State != 'BUSY'">
                                <button mat-icon-button matTooltip="Iniciar" matTooltipPosition="left" (click)="MachineOperations.PowerOnVM(machine)" *ngIf="machine.State=='POWER_OFF'">
                                            <mat-icon class="mat-24">play_circle_outline</mat-icon>
                                        </button>
                                <button mat-icon-button matTooltip="Detener" matTooltipPosition="left" (click)="MachineOperations.PowerOffVm(machine)" *ngIf="machine.State=='POWER_ON'">
                                            <mat-icon class="mat-24">stop</mat-icon>
                                        </button>
                                <button mat-icon-button matTooltip="Suspender" matTooltipPosition="left" (click)="MachineOperations.SuspendVM(machine)" *ngIf="machine.State != 'SUSPEND' && machine.State !='POWER_OFF'">
                                            <mat-icon class="mat-24">pause_circle_outline</mat-icon>
                                        </button>
                                <button mat-icon-button matTooltip="Resumir" matTooltipPosition="left" (click)="MachineOperations.ResumeVm(machine)" *ngIf="machine.State == 'SUSPEND'">
                                            <mat-icon class="mat-24">pause_circle_filled</mat-icon>
                                        </button>
                            </div>
                        </div>
                        <div class="container-fluid virtualmachine-settings-detail-card">
                            <div class="row no-default-padding">
                                <div class="col-12 virtualmachine-subtitle">
                                    <h6>
                                        Configuración del servicio
                                    </h6>
                                </div>
                                <div class="col-12">
                                    <div class="virtualmachine-detail-value">
                                        {{machine.ServiceID}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        # de Servicio
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="virtualmachine-detail-value">
                                        {{ReplaceName(machine.Configuration.OperativeSystem.Os)}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        Sistema operativo
                                    </div>
                                </div>
                                <div class="col-12 virtualmachine-subtitle">
                                    <h6>
                                        Configuración de la máquina
                                    </h6>
                                </div>
                                <div class="col-4">
                                    <div class="virtualmachine-detail-value virtualmachine-state-circle">
                                        <mat-icon [ngClass]="GetVMState(machine.State)" matTooltip="{{GetMachineState(machine.State)}}">fiber_manual_record</mat-icon>
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.machinestatus[language]}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.Cores.Value}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.cores[language]}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.Ram.Value}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.ram[language]}}
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.MachineStorage[0].size}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.diskSize[language]}}
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="virtualmachine-detail-value">
                                        {{(machine.VcenterID != null) ? constants.GetDataCenterName(machine.VcenterID):''}}
                                    </div>
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.datacenter[language]}}
                                    </div>
                                </div>
                                <div class="col-12 virtualmachine-subtitle">
                                    <h6>
                                        Configuración de red
                                    </h6>
                                </div>
                                <div class="col-12">
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.privateip[language]}}
                                    </div>
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.PrivateIp}}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.macaddress[language]}}
                                    </div>
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.MacAddress}}
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="virtualmachine-detail-text">
                                        {{globalization.dcSidenav.rdpaccess[language]}}
                                    </div>
                                    <div class="virtualmachine-detail-value">
                                        {{machine.Configuration.RemoteAccess}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <mat-tab-group>
                    <mat-tab label="Servicios Adicionales">
                        <div class="container-fluid margin-top-10">
                            <simple-grid #AdditionalServicesGrid [settings]=additionalServicesGrid></simple-grid>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</mat-sidenav-container>
