<div class="modal-form-container">
    <div class="content-header">
        <div class="form-title">
            <div class="form-title-text pull-left">
                Autenticación por doble factor
            </div>
            <div class="close-modal full-width">
                <button mat-icon-button (click)="cancelClick()" class="pull-right">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <mat-dialog-content style="margin:0px">
            <p class="col-12">Ingrese el código de 4 dígitos que fue envíado a su correo</p>
            <form class="row" [formGroup]="validateOuthForm" #addOs="ngForm" (ngSubmit)="saveClick()">
                <mat-form-field class="full-width col-12" floatPlaceholder="always">
                    <input type="text" matInput [(ngModel)]="outhCode" name="OuthCode" formControlName="OuthCode"
                        required>
                    <mat-error *ngIf="validateOuthForm.get('OuthCode').hasError('required')">
                        Campo obligatorio
                    </mat-error>
                    <mat-error *ngIf="validateOuthForm.get('OuthCode').hasError('maxlength')">
                        Longitud numérica no válida
                    </mat-error>
                    <mat-error *ngIf="validateOuthForm.get('OuthCode').hasError('minlength')">
                        Longitud numérica no válida
                    </mat-error>
                </mat-form-field>
                <div class="col-12 action-buttons">
                    <button mat-raised-button type="button" color="warn" (click)="cancelClick()"
                        [disabled]="!validateOuthForm.valid" class="machine-form-cancel-button pull-right">
                        Validar
                    </button>
                </div>
            </form>
        </mat-dialog-content>
    </div>
</div>