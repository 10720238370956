import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SnatRulesDto } from '../DTO/snat-rules-dto';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NewFirewallRuleComponent } from './new-firewall-rule/new-firewall-rule.component';

@Component({
  selector: 'app-firewall-nat-outbound',
  templateUrl: './firewall-nat-outbound.component.html',
  styleUrls: ['./firewall-nat-outbound.component.scss']
})
export class FirewallNatOutboundComponent implements AfterViewInit {

  @Input() item:SnatRulesDto[] = []; // decorate the property with @Input()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['Interface', 'Source', 'Source Port', 'Destination', 'Destination Port','NAT Address', 'NAT Port', 'Static Port','Description' ];
  dataSource: MatTableDataSource<SnatRulesDto>;

  constructor(public dialog: MatDialog) { 

  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  ngOnChanges() {
    if(this.item) {
      this.dataSource.data =  this.item;
    }
  }

  newRule() {

    const dialogRef = this.dialog.open(NewFirewallRuleComponent, {
     
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("data!!")
      if(result){
        //this.dataSource.data.push = result;
        console.log("result", result);
      }

    });

  }

}
