<div class="wrapper default-theme toggeled-sidebar"> 
  <aside class="sidebar shadow-sm side">
    <nav id="sidebar" class="sidebar" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
      <div class="sidebar-content">
          <div class="sidebar-menu">
            <div class="menu-header mt-3 mb-4">
              Administración de redes pfSense
            </div>

            <ul class="menu-items">
              <li *ngFor="let menu of menus" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">
                <!--<span *ngIf="menu.type === 'header'">{{menu.title}}</span>-->
                <a *ngIf="menu.type !== 'header'"   (click)='toggle(1,menu); displayItem(menu.link) '>
                  <i class="{{menu.icon}}"></i>
                  <span>{{menu.title}}</span>
                  <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
                </a>
                <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
                  <ul>
                    <li *ngFor="let submenu of menu.submenus" [ngClass]="{'active': submenu.active , 'sidebar-dropdown':submenu.type === 'dropdown' , 'header-menu':submenu.type === 'header'}">
                      <a (click)='displayItem(submenu.link)' (click)='toggle(2,submenu)' > 
                        <i class="{{submenu.icon}}"></i>
                        <span>{{submenu.title}}</span>
                        <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                      </a>
                      <div *ngIf="submenu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(submenu)">
                        <ul>
                          <li *ngFor="let submenuTwo of submenu.submenus">
                            <a  (click)='displayItem(submenu.link)'>
                              <i class="{{submenu.icon}}"></i> 
                              <span>{{submenuTwo.title}}</span>
                              <span *ngIf="submenuTwo.badge" class="badge badge-pill" [ngClass]="submenuTwo.badge.class">{{submenuTwo.badge.text}}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
      </div>
    </nav>
  </aside>
  <section class="network-options" style="width:80%" >
    <mat-tab-group mat-align-tabs="start" *ngIf="activeItem === 'aliases'" >
      <mat-tab label="IP"  >
        <div class="network-data">  
          <app-pfsense-ip [item]="RulesResponseDetail.aliasFirewallRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId" > </app-pfsense-ip>
        </div>
      </mat-tab>
      <mat-tab label="PORTS">
        <div class="network-data">
          <app-firewall-aliases-ports [item]="RulesResponseDetail.aliasFirewallRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId" > </app-firewall-aliases-ports>
        </div>
      </mat-tab>
      <mat-tab label="ALL">
        <div class="network-data">
         <app-firewall-aliases-all [item]="RulesResponseDetail.aliasFirewallRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId"></app-firewall-aliases-all>
        </div>
      </mat-tab>
  </mat-tab-group>

  <mat-tab-group mat-align-tabs="start" *ngIf="activeItem === 'nat'" >
    <mat-tab label="Port Forward1"  >
      <div class="network-data">  
        <app-firewall-nat-port-forward [item]="RulesResponseDetail.dnatRule" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId" > </app-firewall-nat-port-forward>
      </div>
    </mat-tab>
    <!--<mat-tab label="1:1">
      <div class="network-data">
        <app-firewall-rules-oneone [item]="RulesResponseDetail.dnatRule"> </app-firewall-rules-oneone>
      </div>
    </mat-tab>-->
    <mat-tab label="Outbound">
      <div class="network-data">
        <app-firewall-nat-outbound [item]="RulesResponseDetail.snatRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId" > </app-firewall-nat-outbound>
      </div>
    </mat-tab>
 </mat-tab-group>

 <mat-tab-group mat-align-tabs="start" *ngIf="activeItem === 'rules'" >
  <mat-tab label="LAN"  >
    <div class="network-data">  
      <app-firewall-rules-lan [item]="RulesResponseDetail.firewallRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId"> </app-firewall-rules-lan>
    </div>
  </mat-tab>
  <mat-tab label="WAN">
    <div class="network-data">
      <app-firewall-rules-wan [item]="RulesResponseDetail.firewallRules" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId" > </app-firewall-rules-wan>
    </div>
  </mat-tab>
</mat-tab-group>


<mat-tab-group mat-align-tabs="start" *ngIf="activeItem === 'dhcp'" >
  <mat-tab label="DHCP Static Mappings for this Interface"  >
    <div class="network-data">  
      <app-services-dchserver [item]="RulesResponseDetail.dhcpServer" [customerId]="PfenseRequest.customerId" [datacenterId] ="PfenseRequest.datacenterId"> </app-services-dchserver>
    </div>
  </mat-tab>
</mat-tab-group>
  </section>
</div>