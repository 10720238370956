<div class="card">
  <div class="card-block container-fluid margin-bottom-10">
    <div class="card-title modal-title">
      <h6 class="pull-left">{{data.Title}}</h6>
      <div class="pull-right close-button" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="full-width margin-top-10" style="display: inline-block">
      {{data.Message}}
    </div>
    <div class="full-width margin-top-20">
      <button color="primary" mat-button (click)="okClick()" class="pull-right" *ngIf="!loading">Aceptar</button>
      <button color="warn" mat-button (click)="cancelClick()" class="pull-right" *ngIf="!loading">Cancelar</button>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
