<div class="card" style="width:500px">
  <div class="card-block container-fluid margin-bottom-10">
    <div class="card-title modal-title">
      <h6 class="pull-left">{{data.Title}}</h6>
      <div class="pull-right close-button" (click)="cancelClick()">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <form [formGroup]="SendMailForm" novalidate (ngSubmit)="sendMail()">
        <div class="full-width margin-top-10" style="display: inline-block">
          <mat-form-field style="width:100%">
            <input matInput type="text" formControlName="userName" placeholder="Nombre" [(ngModel)]="data.name" name="name" required>
          </mat-form-field>
          <mat-error *ngIf="SendMailForm.get('userName').hasError('required') && SendMailForm.get('userName').touched">Campo requerido.</mat-error>
          <mat-form-field style="width:100%">
            <input matInput type="text" formControlName="emailInput" placeholder="Email" [(ngModel)]="data.email" name="email" required>
          </mat-form-field>
          <mat-icon matSuffix *ngIf="SendMailForm.get('emailInput').valid && SendMailForm.get('emailInput').touched" class="form-accepted">done</mat-icon>
          <mat-icon matSuffix *ngIf="!SendMailForm.get('emailInput').valid && SendMailForm.get('emailInput').touched" class="form-rejected">error</mat-icon>
          <mat-error *ngIf="SendMailForm.get('emailInput').hasError('required') && SendMailForm.get('emailInput').touched">Campo requerido.</mat-error>
          <mat-error *ngIf="SendMailForm.get('emailInput').hasError('minlength') && SendMailForm.get('emailInput').touched">El tamaño minimo del correo son 10 caracteres.</mat-error>
          <mat-error *ngIf="SendMailForm.get('emailInput').hasError('emailValidator') && SendMailForm.get('emailInput').touched">El correo electrónico no cuenta con el formato adecuado.</mat-error>
          <mat-hint class="" align="end">{{data.email ? data.email.length : 0}} / 50</mat-hint>
          <div style="width:100%">
          <mat-checkbox [(ngModel)]="data.detail" formControlName="Capex">Enviar detalle CAPEX Y OPEX</mat-checkbox>
          </div>
        </div>
        <div class="full-width margin-top-20">
          <button type = "submit" color="primary" mat-button class="pull-right" *ngIf="!loading" [disabled] ="!SendMailForm.valid" id="sendEmail">Enviar</button>
          <button type = "button" color="warn" mat-button (click)="cancelClick()" class="pull-right" *ngIf="!loading" id="cancelEmail">Cancelar</button>
        </div>
      </form>
  </div>
</div>
