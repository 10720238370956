import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-emailSetup',
    templateUrl: './emailSetup.component.html',
    styleUrls: ['./emailSetup.component.css']
})
export class EmailSetupComponent implements OnInit {

    ngOnInit(){

    }
}