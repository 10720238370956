export class DnatRuleDto{

    public source:  SourceDnatRuleDto;
    public destination : DestinationDnatRuleDto ; 
    public protocol : string;
    public target: string;
    public localPort:string;
    public interface : string;
    public descr: string;
    public associatedRuleId :string;   
    public updated : UpdatedDnatRuleDto;   
    public created : UpdatedDnatRuleDto; 
}

export class SourceDnatRuleDto{  
    public address?: string;
    public any?: string;
    public network? :string;
    public port ?: string;
}

export class  DestinationDnatRuleDto {
    public address?: string;
    public any?: string;
    public network? :string;
    public port ?: string;
}

export  class UpdatedDnatRuleDto
{
    public time : string; 
    public username : string; 
}