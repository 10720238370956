<mat-toolbar color="primary">
  Crear nueva regla
  <div class="close-modal full-width">
    <button mat-icon-button (click)="Close()" class="pull-right">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div class="modal-form-container" >
  <div class="container-fluid rule-container mt-5">
    <form class="add-row" #addRule="ngForm" (ngSubmit)="AddRules()">
      <fieldset class="properties-border">
        <legend class="properties-border" style="width: auto; font-size: 1rem;" >Properties</legend>
        <div class="row">
          <div class="col-xl-12 col-lg-12 select-container"> 
            <mat-form-field class="full-width col-12" floatLabel="always">
              <mat-select name="Type"  required class="full-width" [(ngModel)]="newRule.type" placeholder="Tipo de regla">
                <mat-option value="host">Host(s)</mat-option>
                <mat-option value="network">Network(s)</mat-option>
                <mat-option value="port">Port(s)</mat-option>
                <mat-option value="url">URL (IPs)</mat-option>
                <mat-option value="url_ports">URL (Ports)</mat-option>
                <mat-option value="urltable">URL Table (IPs)</mat-option>
                <mat-option value="urltable_ports">URL Table (Ports)</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <mat-form-field class="full-width col-12" floatLabel="always">
              <input name="Name" required class="full-width" [(ngModel)]="newRule.name" matInput placeholder="Nombre de la regla">
            </mat-form-field>
          </div>
          <div class="col-xl-5 col-lg-5">
            <mat-form-field class="full-width col-12" floatLabel="always">
              <input name="Description" required class="full-width" [(ngModel)]="newRule.description" matInput placeholder="Descripción  de la regla">
            </mat-form-field>
          </div>
        </div>
       </fieldset> 
    
        <fieldset class="properties-border">
          <legend class="properties-border" style="width: auto; font-size: 1rem;">{{newRule.type}} (s)</legend>
            
          
            <div class="row">
              <div class="col-xl-10 col-lg-10 text-right">
                <button (click)="addElements()" class="float-right"  type="button" mat-icon-button>
                  <mat-icon color="primary">
                    add_circle  
                  </mat-icon> Agregar {{newRule.type}}
                </button>
              </div>
            </div>  
            <div class="form"  *ngIf="newRule.type == 'host' ">
              <form [formGroup]="AddHostRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddHostRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address" pattern="(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)|(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])-([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)" name="SourcePort" required  matInput placeholder="IP or FQDN">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="form" *ngIf="newRule.type == 'network' "> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                <p> Networks are specified in CIDR format. Select the CIDR mask that pertains to each entry. /32 specifies a single IPv4 host, /128 specifies a single IPv6 host, /24 specifies 255.255.255.0, /64 specifies a normal IPv6 network, etc. Hostnames (FQDNs) may also be specified, using a /32 mask for IPv4 or /128 for IPv6. An IP range such as 192.168.1.1-192.168.1.254 may also be entered and a list of CIDR networks will be derived to fill the range.</p>
              </div>
              <form [formGroup]="AddNetworkRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddNetworkRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address" pattern="(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)|(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])-([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)" name="SourcePort" required  matInput placeholder="Network or FQDN">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form" *ngIf="newRule.type == 'port' "> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                  <p>Enter ports as desired, with a single port or port range per entry. Port ranges can be expressed by separating with a colon.</p>
              </div>
              <form [formGroup]="AddPortRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddPortRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address" name="SourcePort" required  matInput placeholder="Port">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form" *ngIf="newRule.type == 'url' "> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                <p> Enter as many URLs as desired. After saving, the URLs will be downloaded and the items imported into the alias. Use only with small sets of IP addresses (less than 3000).</p>
              </div>
              <form [formGroup]="AddURLRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddURLRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address"   name="SourcePort" required  matInput placeholder="URL (IPs)">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 colv-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form" *ngIf="newRule.type == 'url_ports'"> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                  <p>Enter as many URLs as desired. After saving, the URLs will be downloaded and the items imported into the alias. Use only with small sets of Ports (less than 3000).
                </p>
              </div>
              <form [formGroup]="AddURLPortsRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddURLPortsRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address" name="SourcePort" required  matInput placeholder="URL (Ports)">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 colv-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form> 
              
            </div>

            <div class="form" *ngIf="newRule.type == 'urltable'"> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                  <p>Enter a single URL containing a large number of IPs and/or Subnets. After saving, the URLs will be downloaded and a table file containing these addresses will be created. This will work with large numbers of addresses (30,000+) or small numbers.
                     The value after the "/" is the update frequency in days.
                  </p>
              </div>
              <form [formGroup]="AddURLTableRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddURLTableRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address"  name="SourcePort" required  matInput placeholder="URL">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 colv-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="form" *ngIf="newRule.type == 'urltable_ports'"> 
              <div class="col-xl-12 col-lg-12">
                <h5>Hint</h5>
                  <p>Enter a single URL containing a list of Port numbers and/or Port ranges. After saving, the URL will be downloaded.
                    The value after the "/" is the update frequency in days.
                  </p>
              </div>
              <form [formGroup]="AddURLTablePortsRuleForm">
                <div formArrayName="rulesRows">
                  <div class="row" *ngFor="let rule of AddURLTablePortsRuleForm.get('rulesRows')['controls'] ; let i = index" [formGroupName]="i" >
                    <div class="col-xl-5 col-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="address"  name="SourcePort" required  matInput placeholder="URL">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-5 colv-lg-5">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <input formControlName="descr"  name="SourcePort" required  matInput placeholder="Description">
                      </mat-form-field>
                    </div>
                    <div class="col-xl-2 col-lg-2">
                        <button (click)= "deleteHost(i)" type="button" mat-icon-button>
                          <mat-icon color="primary">
                            delete  
                          </mat-icon>
                        </button>
                    </div>
                  </div>
                </div>
              </form>
              
              
            </div>
        </fieldset>
      <div class="action-buttons">
        <button mat-button color="primary" (click) = "AddRules()"> <span class="material-icons">save</span> Guardar</button>
      </div>
    </form>
  </div>
</div>