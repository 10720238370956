import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FirewallRulesDto } from '../DTO/firewall-rules-dto';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { NewFirewallRuleWanComponent} from '../firewall-rules-wan/new-firewall-rule-wan/new-firewall-rule-wan.component';


@Component({
  selector: 'app-firewall-rules-lan',
  templateUrl: './firewall-rules-lan.component.html',
  styleUrls: ['./firewall-rules-lan.component.scss']
})
export class FirewallRulesLanComponent implements AfterViewInit {
  @Input() item:FirewallRulesDto[] = []; // decorate the property with @Input()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['States', 'Protocol', 'Source', 'Port','Destination', 'Gateway', 'Queue', 'Schedule','Description'];
  dataSource: MatTableDataSource<FirewallRulesDto>;

  constructor( public dialog: MatDialog) { 

  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data = this.item.filter(item => item.interface ==='lan');
    }
  }

  newRule() {
    const dialogRef = this.dialog.open(NewFirewallRuleWanComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
   
    });

  }






}
