import { CsjModalComponent } from "./../csj-modal/csj-modal.component";
import { CsjService } from "./../../../services/csj/csj.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
// import domtoimage from "dom-to-image";
import { ExcelService } from "src/app/services/excel/excel.service";

class Disk {
  unit = "";
  total = 0;
  noDestino = 0;
  colors = { class: "success", hex: "#32AB4E" };
  graphicFiles = {};
  graphicTimeUseDisk = {};
  tableHistoryFailedFiles = {
    name: "",
    series: [],
  };
  pie = [
    {
      name: "Diferencia",
      value: 0,
    },
  ];
}

@Component({
  selector: "app-csj-main-dashboard",
  templateUrl: "./csj-main-dashboard.component.html",
  styleUrls: ["./csj-main-dashboard.component.scss"],
})
export class CsjMainDashboardComponent {
  loadData: boolean = false;
  loadInfoDisk: boolean = false;
  dataSumm: any[];
  dataDisk: any[];
  maxDate: Date;

  copyView: any[] = [200, 170];

  constructor(
    public csjService: CsjService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private excelService: ExcelService
  ) {
    this.getInfoDisks(csjService);
    this.constructObjectDisk(csjService);
  }

  getInfoDisks(csjService: CsjService) {
    csjService.getDiskDetails().subscribe(
      (res: any) => {
        this.dataSumm = res.disks;
        this.getMaxDate();
        this.loadInfoDisk = true;
      },
      (err) => console.log(err)
    );
  }

  constructObjectDisk(csjService: CsjService) {
    csjService.getInfoDisk().subscribe(
      (res: any) => {
        this.dataDisk = res.data.discos;

        let resultlist: any[] = [];

        for (const item of this.dataDisk) {
          const unit = item.Path.replace(":\\", "");
          const server = item.Server.substring(0, 20);

          let infoDisk = new Disk();

          if (this.loadInfoDisk) {
            const indexDisk: number = this.dataSumm.findIndex(
              (disk: any) => disk.unit === unit
            );

            if (indexDisk != -1) {
              infoDisk = this.dataSumm[indexDisk];
              infoDisk.pie = [
                {
                  name: "Diferencia",
                  value: this.dataSumm[indexDisk].noDestino,
                },
              ];
            }
          }
          resultlist.push({
            name: `${unit}: ${server}`,
            value: 100 - item.Percent,
            info: infoDisk,
          });
        }

        this.dataDisk = resultlist;
        this.sortData();
        this.loadData = true;
      },
      (err) => {
        console.error(err);
        this.loadData = true;
        this.snackbar.open(
          "No podemos realizar esta consulta actualmente, intenta nuevamente más tarde",
          null,
          {
            duration: 3000,
          }
        );
      }
    );
  }

  sortData(): void {
    this.dataDisk.sort((a, b) => {
      if (a.info.noDestino > b.info.noDestino) return -1;
      if (a.info.noDestino < b.info.noDestino) return 1;
      if (a.info.noDestino == b.info.noDestino) {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
      }
    });
  }

  refresh() {
    this.dataDisk = [];
    this.loadData = false;
    this.getInfoDisks(this.csjService);
    this.constructObjectDisk(this.csjService);
  }

  stringParseInt(data: any) {
    return typeof data == "string" ? parseInt(data.trim()) : data;
  }

  getMaxDate() {
    this.dataSumm.forEach((disk) => {
      disk.total = this.stringParseInt(disk.total);
      disk.noDestino = this.stringParseInt(disk.noDestino);

      if (disk.graphicFiles.name) {
        disk.graphicFiles.series.forEach((serie: any) => {
          if (!this.maxDate) this.maxDate = serie.name;
          else {
            if (this.maxDate < serie.name) this.maxDate = serie.name;
          }
        });
      }
      if (disk.graphicTimeUseDisk.name) {
        disk.graphicTimeUseDisk.series.forEach((serie: any) => {
          if (!this.maxDate) this.maxDate = serie.name;
          else {
            if (this.maxDate < serie.name) this.maxDate = serie.name;
          }
        });
      }
    });
  }

  exportToExcel() {
    const data = [];

    this.dataDisk.forEach((disk) => {
      disk.info.tableHistoryFailedFiles.series.forEach((serie: any) => {
        data.push(serie);
      });
    });

    this.excelService.exportAsJsonExcelFile(data, "Estado de los discos");
  }

  OnDetailClick(data: any, graphic: any, showTab) {
    let dataModal = {
      name: data.name,
      showTab,
      graphic,
      table: data.info.tableHistoryFailedFiles,
    };
    if (graphic.name) {
      this.dialog.open(CsjModalComponent, {
        data: dataModal,
        width: "90%",
        disableClose: true,
      });
    } else {
      this.snackbar.open("Esta unidad no tiene datos para graficar", null, {
        duration: 3000,
      });
    }
  }

  downloadToJpeg(id: string) {
    // domtoimage
    //   .toJpeg(document.getElementById(id), { bgcolor: "#FFF" })
    //   .then(function (dataUrl) {
    //     var link = document.createElement("a");
    //     link.download = id + ".jpeg";
    //     link.href = dataUrl;
    //     link.click();
    //   });
  }
}
