<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5>Configurar disco adicional</h5>
    </div>
    <form [formGroup]="diskConfigurationFb" (ngSubmit)="saveDisk()">
        <div class="row container-fluid sidenav-form-content">
            <mat-form-field class="full-width" floatLabel="always">
                <mat-select (selectionChange)="getDiskPrice()" class="" required placeholder="Velocidad del disco" [(ngModel)]="AdditionalService.Configuration.diskType"
                    name="iops" formControlName="iops" resizeToFitContent>
                    <mat-option *ngFor="let speed of constants.Iops" [value]="speed">{{speed}} Iops</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width" floatLabel="always">
                <mat-select (selectionChange)="getDiskPrice()" class="" required placeholder="Tamaño del disco" [(ngModel)]="AdditionalService.Configuration.size"
                    name="size" formControlName="size">
                    <mat-option *ngFor="let size of constants.Size" [value]="size.value">{{size.value}} GB</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="full-width" floatLabel="always">
                <mat-select disabled class="" [(ngModel)]="backup" placeholder="Backup" name="backup" formControlName="backup">
                    <mat-option [value]="1">1 Mes</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row container-fluid no-default-padding sidenav-form-actionbuttons no-margin">
            <div class="col-6 ">
                <span class="text-left cost-span btnPosPrecio">${{AdditionalService.Configuration.Cost.MRC + AdditionalService.Configuration.Backup.Cost.MRC | number : '1.2-2' }}</span>
            </div>
            <div class="col-6 no-default-padding">
                <button mat-raised-button type="submit" color="primary" class="pull-right" [disabled]="!diskConfigurationFb.valid || loading">Guardar</button>
            </div>
        </div>
    </form>
</div>