<div class="modal-form-container">
  <div class="content-header">
    <div class="form-title">
      <div class="form-title-text pull-left">
        <span style="color: #007eb7">{{ name }}</span>
      </div>
      <div class="">
        <button mat-icon-button (click)="cancelClick()" class="pull-right">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-tab-group class="top-network" [(selectedIndex)]="selectedIndex">
    <mat-tab label="Gráfico de línea de tiempo">
      <div class="row mx-0 justify-content-center px-3 pb-3">
        <button
          class="box-md-20 pull-right wl-grid-refresh-button"
          matTooltip="Exportar Graficas"
          (click)="downloadToJpeg('graphic')"
          style="position: absolute; right: 20px; top: 10px"
          mat-icon-button
        >
          <span class="material-icons md-24 al-top">get_app</span>
        </button>
        <div class="col-auto" id="graphic">
          <ngx-charts-line-chart
            *ngIf="showTab"
            [view]="view"
            [scheme]="colorScheme"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [autoScale]="true"
            [results]="dataGraph"
            [legendTitle]="unit"
          >
          </ngx-charts-line-chart>
          <ngx-charts-line-chart
            *ngIf="!showTab"
            [view]="view"
            [scheme]="colorScheme"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxis]="xAxis"
            [yAxis]="yAxis"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [yScaleMin]="0"
            [yScaleMax]="100"
            [results]="dataGraph"
            [legendTitle]="unit"
          >
          </ngx-charts-line-chart>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Listado archivos faltantes último día" *ngIf="showTab">
      <div class="padding-card">
        <div class="container">
          <div class="row">
            <div class="col">
              <mat-form-field style="width: 100%">
                <input
                  matInput
                  (keyup)="applyFilter($event.target.value)"
                  placeholder="{{ 'CSJ.SEARCH' | translate }}..."
                />
              </mat-form-field>
            </div>
            <div class="col-auto px-0">
              <button
                mat-icon-button
                class="pull-right add-customer-cloud"
                (click)="exportToExcel()"
                matTooltip="{{ 'RESOURCES.TO_EXPORT' | translate }}"
                matTooltipPosition="below"
              >
                <mat-icon>save</mat-icon>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="Disco">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Disco
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{ row.Unit }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Path">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Path
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{ row.PathFileName }}
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Size">
                  <mat-header-cell *matHeaderCellDef class="minos-header-cell"
                    ><div class="full-width ellipsis-on-row">
                      Size (Mb)
                    </div></mat-header-cell
                  >
                  <mat-cell *matCellDef="let row" class="minos-row-text-detail">
                    <div class="full-width ellipsis-on-row">
                      {{ row.Size }}
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row
                  *matHeaderRowDef="displayedColumns"
                  class="minos-header-row"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
              <mat-paginator
                [length]="resultsLength"
                [pageSizeOptions]="[5, 10, 20]"
                [showFirstLastButtons]="true"
              ></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
