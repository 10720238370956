
<mat-toolbar color="primary">
    Edit Redirect Entry
    <div class="close-modal full-width">
      <button mat-icon-button (click)="Close()" class="pull-right">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

    <div class="modal-form-container" >
      <div class="container-fluid rule-container mt-5">
       
        <form class="add-row" #addRule="ngForm" (ngSubmit)="AddRule()">
          <mat-dialog-content class="mat-typography">
           <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;" >Edit Redirect Entry</legend>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 select-container"> 
                    <mat-checkbox class="example-margin" ></mat-checkbox>
                    <label class="example-margin">Disable this rule:</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6 col-lg-6 select-container"> 
                    <mat-checkbox class="example-margin" >No RDR (NOT)</mat-checkbox>  
                    <label class="example-margin">This option is rarely needed. Don't use this without thorough knowledge of the implications.</label>
                  </div>
                </div>
    
            <div class="row">
                <div class="col-xl-6 col-lg-6 select-container"> 
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <mat-select name="Type" required class="full-width"  placeholder="Interface">
                      <mat-option value="wan">
                        WAN
                      </mat-option>
                      <mat-option value="lan">
                        LAN
                      </mat-option>
                      <mat-option value="mgmt">
                        MGMT
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-lg-6 select-container"> 
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <mat-select name="Type" required class="full-width" placeholder="Protocol">
                        <mat-option value="IPV4">
                          TCP
                        </mat-option>
                        <mat-option value="IPV6">
                          UDP
                        </mat-option>
                        <mat-option value="TCP/UPD">
                          TCP/UPD
                        </mat-option>
                        <mat-option value="ICMP">
                          ICMP
                        </mat-option>
                        <mat-option value="ESP">
                          ESP
                        </mat-option>
                        <mat-option value="AH">
                          AH
                        </mat-option>
                        <mat-option value="GRE">
                          GRE
                        </mat-option>
                        <mat-option value="IPV6">
                          IPV6
                        </mat-option>
                        <mat-option value="IGMP">
                          IGMP
                        </mat-option>
                        <mat-option value="IGMP">
                          PIM
                        </mat-option>
                        <mat-option value="IGMP">
                          OSPF
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
              </div>

             <div class="row">
                <div class="col-xl-4 col-lg-4">
                    <mat-checkbox class="example-margin" ></mat-checkbox>
                    <label class="example-margin">Invert match.</label>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <mat-select name="Type" required class="full-width" placeholder="Type">
                      <mat-option value="any">
                        Any
                       </mat-option>
                      <mat-option value="any">
                        Network
                      </mat-option>
                      <mat-option value="SAlias">
                        Single host or alas
                      </mat-option>
                      <mat-option value="Firewall">
                        This Firewall (Self)
                       </mat-option>
                       <mat-option value="SAlias">
                         PPPoE clients
                       </mat-option>
                       <mat-option value="wan">
                        L2TP clients
                      </mat-option>
                      <mat-option value="wan">
                        WAN Address
                      </mat-option>
                      <mat-option value="WNET">
                        WAN NET
                      </mat-option>
                      <mat-option value="LNET">
                        LAN NET 
                      </mat-option>
                      <mat-option value="MGMTNET">
                        MGMT net
                      </mat-option>
                      <mat-option value="MGMTAddress">
                        MGMT Address
                      </mat-option>

                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="SourceNetwork" required class="full-width" matInput placeholder="address/mask">
                    </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="Type" required class="full-width" matInput placeholder="Destination Type">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="SourceNetwork" required class="full-width" matInput placeholder="Destination network">
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="SourceNetwork" required class="full-width" matInput placeholder="Destination Port or Range">
                  </mat-form-field>
                </div>
              </div>
            </fieldset>
    
            <fieldset class="properties-border">
                <legend class="properties-border" style="width: auto; font-size: 1rem;">Destination port range</legend>
                <div class="row">
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <mat-select name="Type" required class="full-width" placeholder="From port">
                          <mat-option value="Other">
                            Other
                           </mat-option>
                          <mat-option value="any">
                            Any
                          </mat-option>
                          <mat-option value="BGP">
                            BGP
                          </mat-option>
                          <mat-option value="CVsup">
                            CVsup
                           </mat-option>
                           <mat-option value="DNS">
                             DNS
                           </mat-option>
                           <mat-option value=" DNSoverTLS">
                            DNS over TLS
                          </mat-option>
                          <mat-option value="FTP">
                            FTP
                          </mat-option>
                          <mat-option value="HBCI">
                            HBCI
                          </mat-option>
                          <mat-option value="HTTP">
                            HTTP 
                          </mat-option>
                          <mat-option value="HTTPS">
                            HTTPS
                          </mat-option>
                          <mat-option value="ICQ">
                            ICQ
                          </mat-option>
  
                        </mat-select>
                      </mat-form-field> 
                    </div>
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                          <input name="SourceNetwork" required class="full-width" matInput placeholder="Custom">
                      </mat-form-field> 
                    </div>
                    <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                        <mat-select name="To port" required class="full-width" placeholder="To port">
                          <mat-option value="Other">
                            Other
                           </mat-option>
                          <mat-option value="any">
                            Any
                          </mat-option>
                          <mat-option value="BGP">
                            BGP
                          </mat-option>
                          <mat-option value="CVsup">
                            CVsup
                           </mat-option>
                           <mat-option value="DNS">
                             DNS
                           </mat-option>
                           <mat-option value=" DNSoverTLS">
                            DNS over TLS
                          </mat-option>
                          <mat-option value="FTP">
                            FTP
                          </mat-option>
                          <mat-option value="HBCI">
                            HBCI
                          </mat-option>
                          <mat-option value="HTTP">
                            HTTP 
                          </mat-option>
                          <mat-option value="HTTPS">
                            HTTPS
                          </mat-option>
                          <mat-option value="ICQ">
                            ICQ
                          </mat-option>
  
                        </mat-select>
                      </mat-form-field>
                    </div>
                   <div class="col-xl-3 col-lg-3">
                      <mat-form-field class="full-width col-12" floatLabel="always">
                          <input name="SourceNetwork" required class="full-width" matInput placeholder="Custom">
                      </mat-form-field>
                  </div>
                </div>
            </fieldset>
          
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">Redirect target IP</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="Des" required class="full-width" matInput placeholder="Enter the internal IP address of the server on which to map the ports.
                      e.g.: 192.168.1.12">
                    </mat-form-field>
                  </div> 
                </div>
            </fieldset>
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">Description</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <input name="Des" required class="full-width" matInput placeholder="A description may be entered here for administrative reference (not parsed).">
                    </mat-form-field>
                  </div> 
                </div>
            </fieldset>
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">No XMLRPC Sync</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-checkbox class="example-margin" > Do not automatically sync to other CARP members</mat-checkbox>
                    <label class="example-margin">This prevents the rule on Master from automatically syncing to other CARP members. This does NOT prevent the rule from being overwritten on Slave.</label>
                  </div> 
                </div>
            </fieldset>
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">NAT reflection</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <mat-select name="Type" required class="full-width" placeholder="">
                        <mat-option value="default">
                          Use System default
                        </mat-option>
                        <mat-option value="NATPROXY">
                          Enable (NAT + proxy)
                        </mat-option>
                        <mat-option value="TCP/UPD">
                          Enable (Pure NAT)
                        </mat-option>
                        <mat-option value="Disable">
                          Disable
                        </mat-option>
                        
                      </mat-select>
                    </mat-form-field>
                  </div> 
                </div>
            </fieldset>
            <fieldset class="properties-border">
              <legend class="properties-border" style="width: auto; font-size: 1rem;">Filter rule association</legend>
                <div class="row">
                  <div class="col-xl-12 col-lg-12">
                    <mat-form-field class="full-width col-12" floatLabel="always">
                      <mat-select name="Type" required class="full-width" placeholder="">
                        <mat-option value="default">
                         None
                        </mat-option>
                        <mat-option value="NATPROXY">
                         Add associated filter rule
                        </mat-option>
                        <mat-option value="TCP/UPD">
                         Add unassociated filter rule
                        </mat-option>
                        <mat-option value="Pass">
                          Pass
                        </mat-option>     
                      </mat-select>
                    </mat-form-field>
                  </div> 
                </div>
            </fieldset>


          </mat-dialog-content>  
      
          <div class="action-buttons">
            <button mat-button color="primary"> <span class="material-icons">save</span> Guardar</button>
          </div>
        </form>

      </div>
    </div>
 
 