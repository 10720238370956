export class Constants {

    FormFontSize = 15;

    CoreList =  this.quantityValueList(2,32,2);
    CoreListDb = this.quantityValueList(2,4,2);
    CoreListDb2 = this.quantityValueList(2,16,2);
    Gb = this.quantityValueList(100,4000,100,[5000]);
    QuantityRDP = this.quantityValueList(0,500,1);
    Users = this.quantityValueList(1,20,1);
    QuantityIp = this.quantityValueList(1,10,1);
    RamList = this.quantityValueList(2,96,2,[128,256,512, 768, 1024]);
    Backups = this.quantityValueList(1,12,1);
    Iops = [100, 500, 1000, 2000, 5000, 8000, 10000];
    IopsDB = [5000];
    IopsServer = [5000, 8000, 10000];
    Size = this.quantityValueList(100,1000,100);
    discountMRC = [];
    ServerProfile = [
       {ID:0, Name :"Servidor Proposito General"},
       {ID:1 ,Name : "Servidor SAP HANA"},
       {ID:2 ,Name : "Servidor SQL SERVER"}
    ];

    Hypervisor = [
        {"ID": 2, "Name": "Vmware"}
    ];

    NetworkList = [
        {"ID": 1, "Name": "1 Mbps"},
        {"ID": 5, "Name": "5 Mbps" },
        {"ID": 10, "Name": "10 Mbps" },
        {"ID": 15, "Name": "15 Mbps" },
        {"ID": 20, "Name": "20 Mbps"},
        {"ID": 25, "Name": "25 Mbps" },
        {"ID": 30, "Name": "30 Mbps" },
        {"ID": 35, "Name": "35 Mbps" },
        {"ID": 40, "Name": "40 Mbps" },
        {"ID": 45, "Name": "45 Mbps" },
        {"ID": 50, "Name": "50 Mbps" },
        {"ID": 55, "Name": "55 Mbps" },
        {"ID": 60, "Name": "60 Mbps" },
        {"ID": 65, "Name": "65 Mbps" },
        {"ID": 70, "Name": "70 Mbps" },
        {"ID": 75, "Name": "75 Mbps" },
        {"ID": 80, "Name": "80 Mbps" },
        {"ID": 85, "Name": "85 Mbps" },
        {"ID": 90, "Name": "90 Mbps" },
        {"ID": 95, "Name": "95 Mbps" },
    ];

    MachineStates = [
        { State: "PENDING", Name: "Creando" },
        { State: "POWER_ON", Name: "Encendida" },
        { State: "SUSPEND", Name: "Suspendida" },
        { State: "POWER_OFF", Name: "Detenida" },
        { State: "ERROR", Name: "Error" },
        { State: "READY", Name: "Listo" },
        { State: "BUSY", Name: "Ocupada" },
        { State: "CANCELED", Name: "Cancelada" },
        { State: "CANCEL_PENDING", Name: "Pendiente por cancelar" },
        { State: "DELETED", Name: "Eliminada" }
    ]
    DataCenter = [
        { "ID": 'cc5RtZcIAzlH6z3ZgzgN8A==', "Name": "Colombia WBP" },
        { "ID": 'Na6QThAtGhG3rG7kEjXo+Q==', "Name": 'Colombia 67'},
        { "ID": 'SMN0EiXAe8iqDjEupG+QBw==', "Name": 'Colombia vcdtc'},
        { "ID": 'HZMRO7Y3fC/5/GvKZTvT7g==', "Name": "Miami" },
        { "ID": 'GojyQtp3+QinPereA5PD/A==', "Name": "Chile" },
        { "ID": 's+7Szdbb/0JIdIcFTZKMoQ==', "Name": "Argentina" },
        { "ID": 'nriJ68AI8WWFbkoVEc4x9g==', "Name": "Peru" },
        { "ID": 'dA/wnfBKTWLH4Ecgc2vO1g==', "Name": "Panamá" },
        { "ID": 'LdOyveD+2juFgfRSLDi6eA==', "Name": "Guatemala"},
        { "ID": 'cq5cAWNTGTnPD7ugZ41Cew==', "Name": "Honduras tgu"},
        { "ID": 'iEADs+2thehXTNlprt/S4g==', "Name": "Honduras sap"},
    ];

    OSHANA = [
        { "ID": 4, "Name": "Linux SUSE for SAP", "RelationApplication" :"Linux SUSE"}
    ];
    OS = [
        { "ID": 1, "Name": "Windows Server", "RelationApplication" :"WINSER_STD" },
        { "ID": 3, "Name": "Ubuntu / Centos / Debian / Sin SO", "RelationApplication" :"LINUX" },
        { "ID": 4, "Name": "Linux RHEL", "RelationApplication" :"Linux REHL" },
        { "ID": 4, "Name": "Linux SUSE for SAP", "RelationApplication" :"Linux SUSE" }
    ];


    OSSQLSERVER = [
        { "ID": 1, "Name": "Windows Server", "RelationApplication" :"WINSER_STD" },
    ];
    ProvisioningOS = [
        {"ID": 1 , "Name" : "Windows"},
        {"ID": 2 , "Name" : "Linux"}
    ];
    Database = [];

    Application =[
        {"SO":1, "ID":1, "Name": "Windows Remote Desktop", "Identifier": "WINRDP"},
        {"SO":2, "ID":1, "Name": "Linux Remote Desktop", "Identifier": "RDP" }
    ];
    IOPS = [
        {"ID": 100},
        {"ID": 500},
        {"ID": 1000},
        {"ID": 2000},
        {"ID": 5000},
        {"ID": 8000},
        {"ID": 10000},
    ];
    Availability = [
        { "ID": 1, "Name": "99.9%" }
    ];

    AvailabilityDB = [
        { "ID": 1, "Name": "99.9%"}
    ];
    HypervisorDB = [
        {"ID": 2, "Name": "Vmware"}
    ]

    PaymentConditions = [
        { "ID": 1, "Name": "Mes a mes sin contrato" },
        { "ID": 12, "Name": "Un año contrato mensual" }
    ];
    ProductImages = [
        { "Value": "/assets/img/gralServer.png", "Name": "General" },
        { "Value": "/assets/img/sapLogoBg.png", "Name": "SAP" },
    ];
    dhGroups = [
        { "Value": "dh2", "Name": "DH2" },
        { "Value": "dh5", "Name": "DH5" },
        { "Value": "dh14", "Name": "DH14" },
        { "Value": "dh15", "Name": "DH15" },
        { "Value": "dh16", "Name": "DH16" },
    ];
    encryptionAlgorithms = [
        { Value: "aes", Name: "AES" },
        { Value: "aes256", Name: "AES256" },
        { Value: "3des", Name: "3DES" },
        { Value: "aes-gcm", Name: "AES-GCM" },
    ];
    Protocols = ["tcp", "udp", "any"];
    Languages = [
        { Code: "es-co", Name: "Español" },
        { Code: "en-us", Name: "Inglés" }
    ];

    DiscountMRCList = [
        {"ID": 0, "Name": "Mensual","value" :"oneMonth", "span" :"0%" , "icon":"local_offer"},
        {"ID": 1, "Name": "1 año","value" :"OneYear", "span" :"-5%" , "icon":"local_offer"},
        {"ID": 2, "Name": "2 años ", "value" :"TwoYear", "span" :"-10%" ,"icon":"local_offer" },
        {"ID": 3, "Name":" 3 años " ,"value" : "ThreeYear", "span" :"-15%", "icon":"local_offer"},
    ];

    public GetDataCenterName(DataCenterID: string) {
        let response;
        if (DataCenterID) {
            response = this.DataCenter.filter(dc => dc.ID == DataCenterID);
            if (response && response[0]) {
                response = response[0].Name;
            } else {
                response = null;
            }
        }
        return (response ? response : 'Sin registro');
    }

    public quantityValueList(startVal:number, endVal :number, incrementVal , extraoptions : number[] = [] ): any{
        let arrayList = [];
        for (var _i = startVal; _i <= endVal; _i+=incrementVal) {
            arrayList.push({"value":_i })
        }
        if(extraoptions.length> 0){
            extraoptions.forEach(element => {
                arrayList.push({"value":element})
            });
        }
        return arrayList;
    }
}
