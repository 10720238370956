import { Component, Input, Output,OnChanges, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WlService } from '../../../services/wlservice.service';
import { UserDTO,ProfileDTO } from '../../../models/dtos.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormControl, Validators, FormGroup, FormBuilder, AbstractControl} from '@angular/forms';
import {CustomFormValidator} from '../../../tools/CustomValidator';
import { LogsService } from 'src/app/services/logs/logs-services.service';


@Component({
    selector: 'user-edition',
    templateUrl: './user-edition.component.html',
    styleUrls: ['../userAccounts.component.scss']
})

export class UserEdition implements OnInit, OnChanges {
    @Input() user: UserDTO;
    @Input() sidenav: MatSidenav;
    @Input() customerType: string;
    @Output() savedUser = new EventEmitter<UserDTO>();
    public profiles: Array<ProfileDTO> = [];
    public loading = false;
    public addClientForm: FormGroup = null;

    constructor(private service: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, private logsServices: LogsService) {
        this.getProfileList();
        this.addClientForm =  this.formBuilder.group({
            userType : [{value: '', disabled: this.loading}, [Validators.required]],
            userProfile : [{value: '', disabled: this.loading}, this.customerType === 'SYSTEM_USER' ?  [Validators.required] : []],
            name : [{value: '', disabled: this.loading}, [Validators.required, Validators.maxLength(100)]],
            login : [{value: '', disabled: this.loading}, [Validators.required]],
            phone : [{value: '', disabled: this.loading}, [Validators.required, Validators.pattern(/^\d{1,10}$/)]],
            id : [{value: '', disabled: this.loading}, [Validators.required]],
            address : [{value: '', disabled: this.loading}, [Validators.required]],
            emailInput : [{value: '', disabled: this.loading}, [Validators.required,
             Validators.minLength(10), Validators.pattern(/^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]]
        });
    }

    clear(){
        this.user.Name = '';
    }

    ngOnChanges(changes) {
        if (changes.user.currentValue) {
            if (!changes.user.currentValue.ID) {
              this.addClientForm.reset();
            }
        }
    }

    ngOnInit() {
        this.user = new UserDTO();
    }

    getProfileList() {
        this.service.GetProfiles().subscribe(
            (response: any) => {
              this.profiles = response;
            },
            error => {
                this.snackbar.open('Error obteniendo perfiles ' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }

    saveUser() {
        this.addClientForm.disable();
        this.loading = true;
        this.user.CustomerTypeID = this.customerType;
        this.service.SaveUser(this.user).subscribe(
            (response: any) => {
                this.addClientForm.enable();
                this.loading = false;
                this.sidenav.close();
                this.savedUser.emit(response);
                this.logsServices.postAppLog('Agregar Usuario', 'Uso', true, 'Usuario creado correctamente', null)
            },
            (error) => {
                this.addClientForm.enable();
                this.loading = false;
                this.snackbar.open('Error guardando usuario ' + error.error, null, {
                    duration: 2000,
                });
                this.logsServices.postAppLog('Agregar Usuario', 'Uso', false, 'No fue posible crear el usuario', null)
            }
        )
    }
}


