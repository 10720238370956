import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DHCPDTO, DHCPSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'dhcp-table',
  templateUrl: './dhcp-table.component.html',
  styleUrls: ['./dhcp-table.component.scss']
})
export class DhcpTableComponent implements OnInit, OnChanges {
    @Input("DHCPRules") DHCPRules: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['IpAddress', 'ServiceID', 'ServiceName', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      this.dataSource.data = this.DHCPRules;
      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      this.dataSource.data = this.DHCPRules;
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemoveDHCPRuleModal(rule: DHCPDTO) {
        this.dialog.open(ConfirmationDialog, {
            data: {
                Title: "Eliminar Regla DHCP",
                Message: "Esta seguro?",
                Ok: () => this.RemoveDHCPRule(rule),
            }
        });
    }

    RemoveDHCPRule(rule: DHCPDTO) {
        let DeleteDHCPRequest = new DHCPSaveRequestDTO();
        DeleteDHCPRequest.staticBinding = rule;
        DeleteDHCPRequest.DatacenterID = this.DatacenterID;
        this.wlservice.RemoveDHCPRule(DeleteDHCPRequest).subscribe(
            response => {
                this.OnDelete.emit(rule);
                this.snackbar.open('Regla DHCP eliminada satisfactoriamente', null, {
                    duration: 3000,
                });
            },
            error => {
                this.snackbar.open('Error eliminando regla DHCP ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditDHCPRule(rule: DHCPDTO) {
      this.OnEdit.emit(rule);
    }
}