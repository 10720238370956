import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-CancelledServices',
    templateUrl: './CancelledServices.component.html',
    styleUrls: ['./CancelledServices.component.scss']
})
export class CancelledServicesComponent implements OnInit {

    ngOnInit(){

    }
}