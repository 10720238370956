import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { PoolDTO, PoolSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'pool-table',
  templateUrl: './pool-table.component.html',
  styleUrls: ['./pool-table.component.scss']
})
export class PoolTableComponent implements OnInit, OnChanges {
    @Input("Pool") Pool: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Name', 'Algorithm', 'MonitorName', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      if(this.Pool != null)
        this.dataSource.data = this.Pool;
      else
        this.dataSource.data = [];
      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      if(this.Pool != null)
        this.dataSource.data = this.Pool;
      else
        this.dataSource.data = [];
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemovePoolModal(rule: PoolDTO) {
        this.dialog.open(ConfirmationDialog, {
          data: {
            Title: "Eliminar Pool",
            Message: "Esta seguro?",
            Ok: () => this.RemovePool(rule),
          }
        });
      }

    RemovePool(rule: PoolDTO) {
      let req = new PoolSaveRequestDTO();
      req.Pool = rule;
      req.DatacenterID = this.DatacenterID;
      this.wlservice.RemovePool(req).subscribe(
        response => {
          this.OnDelete.emit(rule);
          this.snackbar.open('Pool eliminado satisfactoriamente', null, {
              duration: 3000,
          });
        },
        error => {
          this.snackbar.open('Error eliminando Pool ' + error.error, null, {
              duration: 3000,
          });
        }
      )
    }

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditPool(rule: PoolDTO) {
      this.OnEdit.emit(rule);
    }
}