import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { WlService } from '../../../services/wlservice.service';
import { ServiceDTO } from '../../../models/dtos.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../models/constants.component';
import { ServiceNameModal } from '../service-name-modal/service-name-modal.component';
import { AuthService } from '../../../services/auth/auth.service';
import { LogsService } from 'src/app/services/logs/logs-services.service';


@Component({
    selector: 'machineOperations',
    templateUrl: './machineOperations.component.html',
    styleUrls: ['../dataCenter.component.scss']
})
export class machineOperations implements OnInit,OnChanges {
    @Input('machine') machine: ServiceDTO;
    @Output() machineAction = new EventEmitter();
    constants: Constants = new Constants();
    token: string;
    language: string;
    machinePassword = '$5yXz4!3';

    ngOnInit() {}
    constructor(private service: WlService,
      private router: Router,
      private snackbar: MatSnackBar,
      public dialog: MatDialog,
      public auth: AuthService,
      private logsServices: LogsService
      ) {
        this.token = auth.getToken()
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }
    LoadMachineConfiguration() {
        this.router.navigateByUrl('/home/(main:MachineSettings/' +
        encodeURIComponent(this.machine.ServiceID.toString()) +
        ')', { skipLocationChange: true });
    }
    GetMachineState(state: string) {
        if (state) {
          return this.constants.MachineStates.filter((f) => f.State === state)[0].Name;
        }
    }
    ngOnChanges(changes) {
        this.machine = changes['machine'].currentValue;
        if (this.machine.ID) {
            this.service.GetMachinePassword(this.machine.ID)
            .subscribe((response:any) => {
              this.machinePassword = response;
              console.log(response);
            }, (error) => {
              this.machinePassword = 'Error consultando contraseña';
              this.snackbar.open('Error consultando contraseña, ' + error.error, null, {
              duration: 2000
              });
          });
        }
    }
    RenameMachineModal(machine: ServiceDTO) {
        const dialogRef = this.dialog.open(ServiceNameModal, {
            data: {
                ServiceID : machine.ServiceID,
                ServiceName : machine.ServiceName,
                ID: machine.ID
            },
            width : '440px',
        });
        dialogRef.afterClosed().subscribe(
            result => {
                if (result) {
                  this.machine.ServiceName = result;
                }
            }
        )
    }
    PowerOnVM(machine: ServiceDTO) {
        this.ShowSnackbar('Encendiendo máquina por favor espere', null);
        const currentState = machine.State;
        machine.State = 'BUSY';
        this.service.PowerOnVm(machine.ID).subscribe(
            () => {
                machine.State = 'POWER_ON';
                this.ShowSnackbar(machine.ServiceID + ' encendida correctamente', null);
                this.logsServices.postAppLog('Acción Maquina', 'Encender VM', true, 'VM encendida correctamente', machine.ServiceID)
            },
            error => {
                this.ShowSnackbar(error.error, null);
                machine.State = currentState;
                this.logsServices.postAppLog('Acción Maquina', 'Encender VM', false, 'No fue posible encender la VM', machine.ServiceID)
            }
        );
    }
    PowerOffVm(machine: ServiceDTO) {
        this.ShowSnackbar('Apagando máquina por favor espere', null);
        const currentState = machine.State;
        machine.State = 'BUSY';
        this.service.PowerOffVm(machine.ID).subscribe(
            () => {
                machine.State = 'POWER_OFF';
                this.ShowSnackbar(machine.ServiceID + ' apagada correctamente', null);
                this.logsServices.postAppLog('Acción Maquina', 'Apagar VM', true, 'VM apagada correctamente', machine.ServiceID)
            },
            error => {
                this.ShowSnackbar(error.error, null);
                machine.State = currentState;
                this.logsServices.postAppLog('Acción Maquina', 'Apagar VM', true, 'No fue posible apagar la VM', machine.ServiceID)
              }
        );
    }
    SuspendVM(machine: ServiceDTO) {
        this.ShowSnackbar('Suspendiendo máquina por favor espere', null);
        const currentState = machine.State;
        machine.State = 'BUSY';
        this.service.SuspendVm(machine.ID).subscribe(
            () => {
                machine.State = 'SUSPEND';
                this.ShowSnackbar(machine.ServiceID + ' suspendida correctamente', null);
                this.logsServices.postAppLog('Acción Maquina', 'Suspender VM', true, 'VM suspendida correctamente', machine.ServiceID)
              },
            error => {
                this.ShowSnackbar(error.error, null);
                machine.State = currentState;
                this.logsServices.postAppLog('Acción Maquina', 'Suspender VM', false, 'No fue posible suspender la VM', machine.ServiceID)
              }
        );
    }
    ResumeVm(machine: ServiceDTO) {
        this.ShowSnackbar('Reactivando máquina por favor espere', null);
        const currentState = machine.State;
        machine.State = 'BUSY';
        this.service.ResumeVm(machine.ID).subscribe(
            () => {
                machine.State = 'POWER_ON';
                this.ShowSnackbar(machine.ServiceID + ' reactivada correctamente', null);
                this.logsServices.postAppLog('Acción Maquina', 'Reactivar VM', true, 'VM reactivada correctamente', machine.ServiceID)
              },
            error => {
                this.ShowSnackbar(error.error, null);
                machine.State = currentState;
                this.logsServices.postAppLog('Acción Maquina', 'Reactivar VM', false, 'No fue posible reactivar la VM', machine.ServiceID)
              }
        );
    }
    ShowSnackbar(message, action) {
        this.snackbar.open(message, action, {
            duration: 2000
        });
    }
    ReplaceName(name) {
        const response = name.replace(/_/g, ' ');
        let capitalizeName = '';
        response.split(' ').forEach(element => {
            if (capitalizeName.length !== 0) {
                capitalizeName += ' ';
            }
            capitalizeName += element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
        });
        return capitalizeName;
    }
    getMachineImage(machine: ServiceDTO) {
      if (machine.Configuration.OperativeSystem.Os.indexOf('WIN') !== -1) {
        return 'sidenav-detail-windows';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('DEBIAN') !== -1) {
        return 'sidenav-detail-debian';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('CENTOS') !== -1) {
        return 'sidenav-detail-centos';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('UBUNTU') !== -1) {
        return 'sidenav-detail-ubuntu';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('SUSE') !== -1) {
        return 'sidenav-detail-suse';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('REDHAT') !== -1) {
        return 'sidenav-detail-redhat';
      } else if (machine.Configuration.OperativeSystem.Os.indexOf('Other') !== -1) {
        return 'sidenav-detail-noos';
      } else {
        return 'sidenav-detail-linux';
      }
    }
    GetOperationsState(state) {
        return 'circle-' + state;
    }
    showPassword() {
        const x = document.getElementById('passwordMachine');
        const type = x.getAttribute('type');
        if (type === 'password') {
            x.setAttribute('type', 'text');
        } else {
            x.setAttribute('type', 'password');
        }
    }
}
