import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource} from '@angular/material/table';
import { FirewallIPDto} from '../DTO/firewall-ip-dto'
import { DetailPfsenseIpComponent } from './detail-pfsense-ip/detail-pfsense-ip.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NewPfSenseIpComponent } from './forms/new-pf-sense-ip/new-pf-sense-ip.component';


@Component({
  selector: 'app-pfsense-ip',
  templateUrl: './pfsense-ip.component.html',
  styleUrls: ['./pfsense-ip.component.scss']
})
export class PfsenseIpComponent  implements AfterViewInit {
  @Input() item:FirewallIPDto[] = []; // decorate the property with @Input()
  @Input() customerId:string = null; // decorate the property with @Input()
  @Input() datacenterId:string = null; // decorate the property with @Input()
  

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'description', 'address'];
  dataSource: MatTableDataSource<FirewallIPDto>;

  constructor( public dialog: MatDialog) { 
    this.dataSource = new MatTableDataSource(this.item);
  }

  ngOnInit(): void {

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    if(this.item) {
      this.dataSource.data =  this.item.filter(item => item.type ==='host' ||  item.type ==='network');

  
    }
  }

  OpenDetail(item) {
    this.dialog.open(DetailPfsenseIpComponent, {
      width: '65%',
      disableClose: true,
      data: item
    })
  }

  newRule() {
    const dialogRef = this.dialog.open(NewPfSenseIpComponent, {
      data: {
          customerId: this.customerId,
          datacenterId : this.datacenterId,
          ccid : null,
          rulePosition : 1,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result){
          
      }

    });

  }


}
