<mat-spinner style="margin-top: calc(50vh - 50px - 64px);margin-left: calc(50% - 50px)" *ngIf="loading"></mat-spinner>
<div *ngIf="!loading" class="control-panel-container fixPOS">

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid container-dashboard">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-lg-6">
          <div class="card bg-light user-info-box">
            <div class="card-header text-muted border-bottom-0"></div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-12">
                  <div class="user-initials">
                    <span class="circleTop logo-tile user-tile mat-circleTop-shadow">{{user.initials}}</span>
                    <h2 class="lead"><b>{{user.Name}}</b></h2>
                  </div>
                  <div class="contact-info">
                    <p class="text-muted text-sm">{{'DESKTOP.CONTACT' | translate}}</p>
                    <ul class="ml-4 mb-0 fa-ul text-muted">
                      <li class="small"><span class="fa-li"><i class="material-icons">email</i></span>{{'DESKTOP.EMAIL' | translate}}: {{user.Email}}</li>
                      <li class="small"><span class="fa-li"><i class="material-icons">phone</i></span>{{'DESKTOP.TELEPHONE' | translate}}: {{user.Telephone}}</li>
                      <li class="small"><span class="fa-li"><i class="material-icons">traffic</i></span>{{'DESKTOP.STATUS' | translate}}: {{user.Status==true?"Activo":"Inactivo"}}</li>
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col-lg-12 col-12">
              <!-- small box -->
              <div class="small-box">
                <div class="inner">
                  <h3 class="dotted-spaced left">{{user.UsersCount}}</h3>
                  <p>{{'DESKTOP.USERS' | translate}}</p>
                </div>
                <div class="icon">
                  <!--<i class="material-icons">supervisor_account</i>-->
                  <img class="icon-image-1" src="./assets/img/dashboard/usuarios.png">
                </div>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-12 col-12">
              <!-- small box -->
              <div class="small-box">
                <div class="inner">
                  <h3 class="dotted-spaced left">{{user.ServiceCount}}</h3>
                  <p class="font-details-smart"> {{'DESKTOP.ACTIVE_MACHINES' | translate}}</p>
                </div>
                <div class="icon">
                  <img class="icon-image-2" src="./assets/img/dashboard/maquinas.png">
                </div>
              </div>
            </div>
          </div>
          <div class="row">
              <!-- ./col -->
            <div class="col-lg-12 col-12">
              <!-- small box -->
              <div class="small-box">
                <div class="inner">
                  <h3 class="dotted-spaced left" >{{user.pendingCancellations}}</h3>
                  <p class="font-details-smart"> {{'DESKTOP.PENDING_CANCELATIONS' | translate}}</p>
                </div>
                <div class="icon">
                  <img class="icon-image-2" src="./assets/img/dashboard/cancelaciones.png">                
                </div>
              </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-12 col-12">
              <div class="small-box">
                <div class="inner">
                  <h3 class="dotted-spaced left" >{{user.ActiveCustomers}}</h3>
                  <p class="font-details-smart"> {{'DESKTOP.ACTIVE_CUSTOMERS' | translate}}</p>
                </div>
                <div class="icon">
                  <img class="icon-image-2" src="./assets/img/dashboard/activos.png">    
                </div>
              </div>
            <!-- ./col -->
            </div>
          </div>
            <!-- small box -->
        </div>
      </div>
      <!-- /.row (main row) -->
    </div><!-- /.container-fluid -->
  </section>
</div>
