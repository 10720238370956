<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="btn-group pull-right" role="group" aria-label="Basic example" style="margin:10px;">
                <button type="button" class="btn btn-secondary" (click)="filterData('60')">1 H</button>
                <button type="button" class="btn btn-secondary" (click)="filterData('oneDay')">24 H</button>
                <button type="button" class="btn btn-secondary" (click)="filterData('oneWeek')">1 W</button>
                <button type="button" class="btn btn-secondary" (click)="filterData('oneMonth')">1 M</button>
                <button type="button" class="btn btn-secondary" (click)="filterData('oneYear')">1 Y</button>
            </div>
            <button mat-icon-button class="pull-right refresh" *ngIf="InterfacesCharts.data.length != 0" (click)="Refresh()">
                <mat-icon>refresh</mat-icon>
            </button>
        </div>
    </div>
    <div class="row">    
        <div class="col-12" style="height: calc(100vh - 150px);">
                <mat-spinner diameter="50" class="loading-chart" *ngIf="InterfacesCharts.data.length == 0"></mat-spinner>
                <ngx-charts-line-chart
                    [scheme]="InterfacesCharts.colorScheme"
                    [results]="InterfacesCharts.data"
                    [gradient]="InterfacesCharts.gradient"
                    [xAxis]="InterfacesCharts.showXAxis"
                    [yAxis]="InterfacesCharts.showYAxis"
                    [legend]="InterfacesCharts.showLegend"
                    [showXAxisLabel]="InterfacesCharts.showXAxisLabel"
                    [showYAxisLabel]="InterfacesCharts.showYAxisLabel"
                    [xAxisLabel]="InterfacesCharts.xAxisLabel"
                    [yAxisLabel]="InterfacesCharts.yAxisLabel"
                    [schemeType] = "InterfacesCharts.schemeType"
                    [roundDomains]= "InterfacesCharts.roundDomains"
                    [xAxisTickFormatting]="calendarAxisTickFormatting"
                    [animations] = "InterfacesCharts.animations"
                    (select)="onSelect($event)">
                </ngx-charts-line-chart>
        </div>
    </div>
</div>