import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeComponent } from './home/home.component';
import { BroadcastEmitter } from './services/BroadcastEmitter';
import { WlService } from './services/wlservice.service';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DatePipe } from '@angular/common';


//components
import { VirtualServersComponent } from './components/virtualServers/virtualServers.component';
import { UseStatisticsComponent } from './components/useStatistics/useStatistics.component';
import { EventsLogComponent } from './components/eventsLog/eventsLog.component';
import { AccountConfigurationComponent } from './components/accountConfiguration/accountConfiguration.component';
import { UserAccountsComponent } from './components/userAccounts/userAccounts.component';
import { HelpDeskComponent } from './components/helpDesk/helpDesk.component';
import { ActiveDirectoryComponent } from './components/activeDirectory/activeDirectory.component';
import { WhiteLabelConfigurationComponent } from './components/whiteLabelConfiguration/whiteLabelConfiguration.component';
import { ImageUploadComponent } from './components/whiteLabelConfiguration/imageUpload/image-upload.component';
import { CertificateUploadComponent } from './components/whiteLabelConfiguration/certificateUpload/certificate-upload.component';
import { BillingComponent } from './components/billing/billing.component';
import { RoleComponent } from './components/role/role.component';
import { sendMailDialog } from './tools/modals/sendMail/modal.component';
import { EmailSetupComponent } from './components/emailSetup/emailSetup.component';
import { CancelledServicesComponent } from './components/CancelledServices/CancelledServices.component';
import { FaqComponent } from './components/Faq/Faq.component';
import { ProspectsComponent } from './components/Prospects/Prospects.component';
import { MarketplaceComponent } from './components/Marketplace/Marketplace.component';
import { DesktopComponent } from './components/desktop/desktop.component';
import { LoginModalComponent } from './login/login.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { ControlPanelComponent } from './components/desktop/control-panel/control-panel.component';
import { DataCenterComponent } from './components/dataCenter/dataCenter.component';
import { ChildrenUsersComponent } from './components/dataCenter/childrenUsers/childrenUsers.component';
import { GridComponent, GridDetailComponent } from './tools/grid/grid.component';
import { SnackBarComponent } from './components/desktop/snackbar/snackbar.component';
import { machineOperations } from './components/dataCenter/machineOperations/machineOperations.component';
import { planEdition } from './components/virtualServers/planEdition/plan-edition.component';
import { UserEdition } from './components/userAccounts/user-edition/user-edition.component';
import { PasswordEditDialog } from './components/accountConfiguration/accountConfiguration.component';
import { UserSettings } from './components/userAccounts/user-settings/user-settings.component';
import { DemoServices } from './components/userAccounts/demoServices/demo-services.component';
import { ServiceSettings } from './components/userAccounts/service-configuration/service-configuration.component';
import { MachineSettings } from './components/dataCenter/machine-settings/machine-settings.component';
import { DiskConfiguration } from './components/dataCenter/machine-settings/disk-configuration/disk-configuration.component';
import { NetworkSettings } from './components/dataCenter/network-settings/networkSettings';
import { ConfirmationDialog } from './tools/modals/confirmation/modal.component';
import { DhcpConfiguration } from './components/dataCenter/network-settings/dhcpConfiguration/dhcp-configuration.component';
import { NatConfiguration } from './components/dataCenter/network-settings/NatConfiguration/nat-configuration.component';
import { FirewallConfiguration } from './components/dataCenter/network-settings/FirewallConfiguration/firewall-configuration.component';
import { VpnConfiguration } from './components/dataCenter/network-settings/VpnConfiguration/vpn-configuration.component';
import { RoutingConfiguration } from './components/dataCenter/network-settings/RoutingConfiguration/routing-configuration.component';
import { WelcomeComponent } from './components/desktop/welcome-window/welcome-window.component';
import { PendingMachinesComponent } from './components/pendingMachines/pendingMachines.component';
import { PasswordResendDialog } from './components/userAccounts/userAccounts.component';
import { TermsComponent } from './components/userAccounts/terms/terms.component';
import { ProfileEditComponent } from './components/role/role.component';
import { SupportNumbers } from './components/support/support.component';
import { AdminSupportContact } from './components/support/admin-support-contact/admin-support-contact.component';
import { ServiceNameModal } from './components/dataCenter/service-name-modal/service-name-modal.component';
import { VpnStatsComponent } from './components/dataCenter/network-settings/vpn-stats/vpn-stats.component';
// tslint:disable-next-line: max-line-length
import { SaveBundleQuoteModalComponent } from './components/calculator-cloud-server/modals/save-bundle-quote/saveBundleQuoteModal.component';
import { CredentialsComponent } from './components/credentials/credentials.component';

//Pipes
import { SafeHtml } from './tools/pipes/SafeHtml.pipe';
import { FilterPipe } from './tools/pipes/filter.pipe';
// Material Design
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ClipboardModule } from 'ngx-clipboard';

//AuthService
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { AuthService, TOKEN_NAME, tokennGetter } from './services/auth/auth.service';
import { MyHttpInterceptor} from './services/auth/auth-interceptor';
import { SessionCheckService } from './services/auth/session-check.service';
import { JwtModule } from '@auth0/angular-jwt';
import { ApplicationProfileComponent } from './components/dataCenter/network-settings/application-profile/application-profile.component';
import { VirtualServerComponent } from './components/dataCenter/network-settings/virtual-server/virtual-server.component';
import { PoolComponent } from './components/dataCenter/network-settings/pool/pool.component';
import { InterfacesStatsComponent } from './components/dataCenter/network-settings/interfaces-stats/interfaces-stats.component';
import { IpsetConfigurationComponent } from './components/dataCenter/network-settings/ipset-configuration/ipset-configuration.component';
import { FirewallComponent } from './components/dataCenter/network-settings/firewall-component/firewall-component.component';
import { NatTableComponent } from './components/dataCenter/network-settings/nat-table/nat-table.component';
import { DhcpTableComponent } from './components/dataCenter/network-settings/dhcp-table/dhcp-table.component';
import { VpnTableComponent } from './components/dataCenter/network-settings/vpn-table/vpn-table.component';
import { RoutingTableComponent } from './components/dataCenter/network-settings/routing-table/routing-table.component';
import { AppProfileTableComponent } from './components/dataCenter/network-settings/app-profile-table/app-profile-table.component';
import { PoolTableComponent } from './components/dataCenter/network-settings/pool-table/pool-table.component';
import { VirtualServerTableComponent } from './components/dataCenter/network-settings/virtual-server-table/virtual-server-table.component';
import { IpsetTableComponent } from './components/dataCenter/network-settings/ipset-table/ipset-table.component';

// Calculator

import { CalculatorCloudServerComponent } from './components/calculator-cloud-server/calculator-cloud-server.component';
import { ServerProtectionComponent } from './components/calculator-cloud-server/modals/server-protection/server-protection.component';
import { ViewQuotesComponent } from './components/calculator-cloud-server/view-quotes/view-quotes.component';
import {CloudServerEditValuesComponent} from './components/calculator-cloud-server/modals/edit-values/cloud-server-edit-values.component';


// services

import { ExcelService } from './services/excel/excel.service';
// import { ServerProtectionComponent } from './tools/modals/server-protection/server-protection.component';

//navbar

import { CollapseModule } from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


export const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  // { path: 'terms', component: TermsComponent },
  {
    path: 'home',
    component: HomeComponent ,
    canActivate: [AuthGuard],
    children: [
      { path: 'dataCenter/:id/:children', component: DataCenterComponent,  canActivate : [AuthGuard], outlet: 'main' },
      { path: 'childrenUsers/:id/:children', component: ChildrenUsersComponent,  canActivate : [AuthGuard], outlet: 'main' },
      { path: 'networkSettings/:datacenterID', component: NetworkSettings, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'welcome', component: WelcomeComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'MachineSettings/:MachineID', component: MachineSettings, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'virtualServers', component: VirtualServersComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'useStatistics', component: UseStatisticsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'eventsLog', component: EventsLogComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'accountConfiguration', component: AccountConfigurationComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'userAccounts', component: UserAccountsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'userConfiguration/:UserID', component: UserSettings,  canActivate : [AuthGuard], outlet: 'main' },
      { path: 'helpDesk', component: HelpDeskComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'activeDirectory', component: ActiveDirectoryComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'whiteLabelConfiguration', component: WhiteLabelConfigurationComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'billing', component: BillingComponent,  canActivate : [AuthGuard], outlet: 'main' },
      { path: 'role', component: RoleComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'calculator', component: CalculatorCloudServerComponent, canActivate: [AuthGuard], outlet: 'main'},
      { path: 'calculator/:idBundleQuote', component: CalculatorCloudServerComponent, canActivate: [AuthGuard], outlet: 'main'},
      { path: 'emailSetup', component: EmailSetupComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'CancelledServices', component: CancelledServicesComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'Faq', component: FaqComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'Prospects', component: ProspectsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'Marketplace', component: MarketplaceComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'desktop', component: DesktopComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'pendingMachines', component: PendingMachinesComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'supportContacts', component: SupportNumbers, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'viewQuotes', component: ViewQuotesComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'interfacesStats/:datacenterID', component: InterfacesStatsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'deliveryofcredentials', component: CredentialsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'PfsenseNetworkSettings/:datacenterID/:customerID', component: PfSenseNetworkSettingsComponent, canActivate : [AuthGuard], outlet: 'main' },
      { path: 'csj', component: ReplicasCSJComponent, canActivate : [AuthGuard], outlet: 'main' },
    ]

  }
];

// translate
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { Outh2ModalComponent } from './login/outh2-modal/outh2-modal.component';
import { PfSenseNetworkSettingsComponent } from './components/dataCenter/pf-sense-network-settings/pf-sense-network-settings.component';
import { PfsenseIpComponent } from './components/dataCenter/pf-sense-network-settings/pfsense-ip/pfsense-ip.component';
import { NewPfSenseIpComponent } from './components/dataCenter/pf-sense-network-settings/pfsense-ip/forms/new-pf-sense-ip/new-pf-sense-ip.component';
import { FirewallAliasesPortsComponent } from './components/dataCenter/pf-sense-network-settings/firewall-aliases-ports/firewall-aliases-ports.component';
import { FirewallAliasesAllComponent } from './components/dataCenter/pf-sense-network-settings/firewall-aliases-all/firewall-aliases-all.component';
import { FirewallNatPortForwardComponent } from './components/dataCenter/pf-sense-network-settings/firewall-nat-port-forward/firewall-nat-port-forward.component';
import { FirewallNatOutboundComponent } from './components/dataCenter/pf-sense-network-settings/firewall-nat-outbound/firewall-nat-outbound.component';
import { FirewallRulesWanComponent } from './components/dataCenter/pf-sense-network-settings/firewall-rules-wan/firewall-rules-wan.component';
import { FirewallRulesLanComponent } from './components/dataCenter/pf-sense-network-settings/firewall-rules-lan/firewall-rules-lan.component';
import { ServicesRulesLanComponent } from './components/dataCenter/pf-sense-network-settings/services-rules-lan/services-rules-lan.component';
import { ServicesDchserverComponent } from './components/dataCenter/pf-sense-network-settings/services-dchserver-lan/services-dchserver.component';
import { FirewallRulesOneoneComponent } from './components/dataCenter/pf-sense-network-settings/firewall-rules-oneone/firewall-rules-oneone.component';
import { DetailPfsenseIpComponent } from './components/dataCenter/pf-sense-network-settings/pfsense-ip/detail-pfsense-ip/detail-pfsense-ip.component';
import { NewFirewallRuleComponent } from './components/dataCenter/pf-sense-network-settings/firewall-nat-outbound/new-firewall-rule/new-firewall-rule.component';
import { NewFirewallPortRuleComponent } from './components/dataCenter/pf-sense-network-settings/firewall-nat-port-forward/new-firewall-port-rule/new-firewall-port-rule.component';
import { NewFirewallRuleWanComponent } from './components/dataCenter/pf-sense-network-settings/firewall-rules-wan/new-firewall-rule-wan/new-firewall-rule-wan.component';
import { NewDhcpServiceComponent } from './components/dataCenter/pf-sense-network-settings/services-dchserver-lan/new-dhcp-service/new-dhcp-service.component';
import { FilterTypePipe } from './components/dataCenter/pf-sense-network-settings/pfsense-ip/pipes/filter-type.pipe';
import { ReplicasCSJComponent } from './components/replicas-csj/replicas-csj.component';
import { CsjMainDashboardComponent } from './components/replicas-csj/csj-main-dashboard/csj-main-dashboard.component';
import { CsjModalComponent } from './components/replicas-csj/csj-modal/csj-modal.component';
import { CsjGeneralGraphicsComponent } from './components/replicas-csj/csj-general-graphics/csj-general-graphics.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    VirtualServersComponent,
    UseStatisticsComponent,
    EventsLogComponent,
    AccountConfigurationComponent,
    UserAccountsComponent,
    HelpDeskComponent,
    ActiveDirectoryComponent,
    WhiteLabelConfigurationComponent,
    ImageUploadComponent,
    CertificateUploadComponent,
    BillingComponent,
    RoleComponent,
    EmailSetupComponent,
    CancelledServicesComponent,
    FaqComponent,
    ProspectsComponent,
    MarketplaceComponent,
    DesktopComponent,
    LoginModalComponent,
    ConfirmationDialog,
    sendMailDialog,
    PasswordResendDialog,
    HeaderComponent,
    ControlPanelComponent,
    DataCenterComponent,
    ChildrenUsersComponent,
    GridComponent,
    SnackBarComponent,
    machineOperations,
    planEdition,
    UserEdition,
    UserSettings,
    PasswordEditDialog,
    GridDetailComponent,
    ServiceSettings,
    MachineSettings,
    DiskConfiguration,
    SafeHtml,
    NetworkSettings,
    DhcpConfiguration,
    NatConfiguration,
    RoutingConfiguration,
    FirewallConfiguration,
    WelcomeComponent,
    PendingMachinesComponent,
    SaveBundleQuoteModalComponent,
    VpnConfiguration,
    DemoServices,
    FilterPipe,
    TermsComponent,
    ProfileEditComponent,
    SupportNumbers,
    AdminSupportContact,
    ServiceNameModal,
    ApplicationProfileComponent,
    VirtualServerComponent,
    PoolComponent,
    VpnStatsComponent,
    InterfacesStatsComponent,
    IpsetConfigurationComponent,
    VpnStatsComponent,
    FirewallComponent,
    NatTableComponent,
    DhcpTableComponent,
    VpnTableComponent,
    RoutingTableComponent,
    AppProfileTableComponent,
    PoolTableComponent,
    VirtualServerTableComponent,
    IpsetTableComponent,
    ServerProtectionComponent,
    Outh2ModalComponent,
    CalculatorCloudServerComponent,
    ViewQuotesComponent,
    CloudServerEditValuesComponent,
    CredentialsComponent,
    PfSenseNetworkSettingsComponent,
    PfsenseIpComponent,
    SidebarComponent,
    FooterComponent,
    NewPfSenseIpComponent,
    FirewallAliasesPortsComponent,
    FirewallAliasesAllComponent,
    FirewallNatPortForwardComponent,
    FirewallNatOutboundComponent,
    FirewallRulesWanComponent,
    FirewallRulesLanComponent,
    ServicesRulesLanComponent,
    ServicesDchserverComponent,
    FirewallRulesOneoneComponent,
    DetailPfsenseIpComponent,
    NewFirewallRuleComponent,
    NewFirewallPortRuleComponent,
    NewFirewallRuleWanComponent,
    NewDhcpServiceComponent,
    FilterTypePipe,
    ReplicasCSJComponent,
    CsjMainDashboardComponent,
    CsjModalComponent,
    CsjGeneralGraphicsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    // HttpModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    FlexLayoutModule,
    FileUploadModule,
    NgxPaginationModule,
    NgxChartsModule,
    [BrowserModule, FormsModule],
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatMenuModule,
    MatSidenavModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatListModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatExpansionModule,
    MatToolbarModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatIconModule,
    ClipboardModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokennGetter
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  exports : [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule
  ],
  providers:[WlService,
    BroadcastEmitter,
    MatDialog,
    AuthGuard,
    SessionCheckService,
    AuthService,
    DatePipe,
    ExcelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass : MyHttpInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginModalComponent,
    PasswordResendDialog,
    ProfileEditComponent,
    ConfirmationDialog,
    sendMailDialog,
    ServerProtectionComponent,
    PasswordEditDialog,
    GridDetailComponent,
    ServiceNameModal,
    TermsComponent,
    VpnStatsComponent,
    FirewallComponent,
    SaveBundleQuoteModalComponent,
    CloudServerEditValuesComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})

export class AppModule { }
