import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductDTO } from '../../models/dtos.component';
import { Constants } from '../../models/constants.component';
import { WlService } from '../../services/wlservice.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BroadcastEmitter} from '../../services/BroadcastEmitter';
import { AuthService } from '../../services/auth/auth.service';

@Component({
// tslint:disable-next-line: component-selector
    selector: 'app-virtualServers',
    templateUrl: './virtualServers.component.html',
    styleUrls: ['./virtualServers.component.scss']
})
export class VirtualServersComponent implements OnInit {
    @ViewChild('sidenav') sidenav: MatSidenav;
    canCloseSidenav = true;
    dataCenterID: number;
    domainProducts = new Array<ProductDTO>();
    selectedProduct: ProductDTO = new ProductDTO();
    constants: Constants = new Constants();
    mostrar = false;
    token: string;
    language: string;


    constructor(private service: WlService,
      public snackbar: MatSnackBar,
      public navBrodcaster: BroadcastEmitter,
      public auth: AuthService) {
        this.token = this.auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
    }

    public ngOnInit() {
        this.getProducts();
        this.navBrodcaster.SendMessage({
            text : 'Servidores virtuales',
            actions : [
                {icon : 'note_add', name: 'Nueva plantilla', action : () => {this.AddProduct()}}
            ],
            display: true
        });
    }

    public finishSaving(_product) {
        if(this.selectedProduct.ID != _product.ID){
            this.domainProducts[this.domainProducts.indexOf(this.selectedProduct)] = _product;
        }
    }

    public closeSidenav() {
        this.domainProducts = this.domainProducts.filter((u)=>{return u.ID != null });
    }

    public getProducts() {
        this.service.GetProducts().subscribe(
            (response:any) => {
                this.domainProducts = response
            },
            error => {
                this.snackbar.open('Error consultando productos ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    public AddProduct(){
        this.domainProducts = this.domainProducts.filter((u) => {return u.ID != null });
        const p = new ProductDTO();
        p.ProductType = 'GENERAL';
        this.domainProducts.unshift(p);
        this.editProduct(p);
    }

    public editProduct(product) {
        this.selectedProduct = product;
        this.sidenav.open();
    }

    public disableProduct(product) {
        product.IsSaving = true;
        this.service.changeProductState(product).subscribe(
            (response :any) => {
                this.snackbar.open('estado de producto ' + product.Name + ' cambiado satisfactoriamente', null, {
                    duration: 3000,
                });
                product.State = response.State;
                product.IsSaving = false;
            },
            error => {
                product.IsSaving = false;
                this.snackbar.open('Error cambiando estado del producto ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }

    public removeProduct(product) {
        product.IsSaving = true;
        this.service.RemoveProduct(product).subscribe(
            () => {
                this.snackbar.open('Producto eliminado satisfactoriamente', null, {
                    duration: 3000,
                });
                this.getProducts();
            },
            error=>{
                product.IsSaving = false;
                this.snackbar.open('Error elminando producto ' + error.error, null, {
                    duration: 3000,
                });
            }
        )

    }
 }

