import { Component, OnInit, ViewChild } from '@angular/core';
import { WlService } from '../../../services/wlservice.service';
import { ServiceDTO, VpnDTO, RoutingDTO,
  FirewallDTO, DHCPDTO, NatDTO, InterfaceDTO, AppProfileDTO, PersistenceAppProfileDTO,
  VirtualServerDTO, PoolDTO, IPSetDTO, IPSetSaveRequestDTO, FirewallSourceDTO, CustomerMachinesService } from '../../../models/dtos.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastEmitter } from '../../../services/BroadcastEmitter';
import { Globalization } from '../../../globalization/globalization';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'network-settings',
    styleUrls: ['networkSettings.scss'],
    templateUrl: 'networkSettings.html'
})

export class NetworkSettings implements OnInit {
    
    @ViewChild('sidenav') sidenav: MatSidenav;
    loadBalancerOption = 'applicationProfile';
    dataCenterID: string;
    isNewFirewallRule = true;
    AvailableMacs: Array<ServiceDTO>;
    Vnics: Array<InterfaceDTO> = [];
    PublicIps: Array<string> = [];
    PrivateIps: Array<string> = [];
    selectedRule: DHCPDTO = new DHCPDTO();
    mostrarIp = false;
    selectedNatRule: NatDTO = new NatDTO();
    selectedVpnRule: VpnDTO = new VpnDTO();
    selectedVpnRuleID: string = null;
    ID: string = null;
    selectedRoutingRule: RoutingDTO = new RoutingDTO();
    selectedRoutingRuleID: string = null;
    selectedApplicationProfile: AppProfileDTO = new AppProfileDTO();
    selectedIPSet: IPSetDTO = new IPSetDTO();
    selectedVirtualServer: VirtualServerDTO = new VirtualServerDTO();
    selectedPool: PoolDTO = new PoolDTO();
    selectedFirewallRule: FirewallDTO = new FirewallDTO();
    machines: Array<ServiceDTO> = [];
    network: any = null;
    selectedService = 'FIREWALL';
    globalization: any = Globalization;
    token: string;
    language: string;
    DeleteIPSet: IPSetSaveRequestDTO = new IPSetSaveRequestDTO();
    FirewallRules: Array<FirewallDTO> = [];
    NatRules: Array<NatDTO> = [];
    DHCPRules: Array<DHCPDTO> = [];
    Routing: any;
    IpSec: any;
    AppProfiles: Array<AppProfileDTO>;
    Pool: Array<PoolDTO>;
    VirtualServer: Array<VirtualServerDTO>;
    IPSet: Array<IPSetDTO>;
    Loading: boolean;
    errorLoadingEdge = false;

    constructor(private wlservice: WlService,
                public dialog: MatDialog,
                private router: Router,
                private route: ActivatedRoute,
                public snackbar: MatSnackBar,
                private broadcast: BroadcastEmitter) {
        this.route.params.subscribe(params => {
            console.log('El Dtacenter', params)
        this.dataCenterID = params.datacenterID;
        this.token = localStorage.getItem('usertoken');
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        });
    }

    ngOnInit() {
      this.GetMachines();
      this.GetEdge();
    }

    SetToolbar() {
        this.broadcast.SendMessage({
            actions: [
                { icon: 'queue', name: 'Agregar regla de NAT',
                action: () => { this.ServiceButtonClick('NAT'); this.AddNat(); } },
                { icon: 'add_circle_outline', name: 'Agregar regla DHCP',
                action: () => { this.ServiceButtonClick('DHCP'); this.AddDhcp(); } },
                { icon: 'add_box_outline', name: 'Agregar VPN',
                action: () => { this.ServiceButtonClick('VPN'); this.AddVpn(); } },
                { icon: 'whatshot', name: 'Agregar regla de firewall',
                action: () => { this.ServiceButtonClick('FIREWALL'); this.AddFirewallRule(); } },
                { icon: 'compare_arrows', name: 'Agregar Routing',
                action: () => { this.ServiceButtonClick('ROUTING'); this.AddRouting(); } },
                { icon: 'http', name: 'Agregar Application Profile',
                action: () => { this.ServiceButtonClick('APP_PROFILE'); this.AddApplicationProfile(); } },
                { icon: 'dvr', name: 'Agregar Virtual Server',
                action: () => { this.ServiceButtonClick('VIRTUAL_SERVER'); this.AddVirtualServer(); } },
                { icon: 'view_list', name: 'Agregar Pool',
                action: () => { this.ServiceButtonClick('POOL'); this.AddPool(); } },
                { icon: 'link', name: 'Agregar IPSet',
                action: () => { this.ServiceButtonClick('IPSET'); this.AddIPSet(); } },
                { icon: 'undo', name: 'Ir a máquinas virtuales',
                action: () => { this.GoToMachinesView(); } },
            ],
            display : true
        });
    }

    GetEdge() {
        this.Vnics = [];
        this.wlservice.GetEdge(this.dataCenterID).subscribe(
            (response: any) => {
                this.Vnics = response.Vnics.Vnic.filter(v => v.IsConnected === 'true');
            },
            error => {
                if (error.status === 401) {
                  this.router.navigateByUrl('/');
                }
            }
        );
    }

    GetNetworkConfiguration() {
        this.network = null;
        this.Loading = true;
        this.wlservice.GetNetworkInformation(this.dataCenterID).subscribe(
            response => {
                this.network = response;
                this.SetToolbar();
                this.LoadDhcp();
                this.LoadNat();
                this.LoadVpn();
                this.LoadFirewall();
                this.LoadRouting();
                this.LoadAppProfile();
                this.LoadVirtualServer();
                this.LoadPool();
                this.LoadIpSet();
                this.Loading = false;
            },
            error => {
                if (error.status === 401) {
                  this.router.navigateByUrl('/');
                }
                this.errorLoadingEdge = true;
                console.error(error);
            });
    }

    AddFirewallRule() {
        this.isNewFirewallRule = true;
        this.selectedFirewallRule = new FirewallDTO();
        this.sidenav.open();
    }

    EditFirewall(u) {
        if (u.Source == null) {
            u.Source = new FirewallSourceDTO();
        }
        if (u.SourceIp.toString().indexOf(', ') === -1) {
            u.Source.IpAddress = [u.SourceIp.toString()];
        } else {
            u.Source.IpAddress = u.SourceIp.toString().split(', ');
        }

        if (u.Destination == null) {
            u.Destination = new FirewallSourceDTO();
        }
        if (u.DestinationIp.toString().indexOf(', ') === -1) {
            u.Destination.IpAddress = [u.DestinationIp.toString()];
        } else {
            u.Destination.IpAddress = u.DestinationIp.toString().split(', ');
        }
        this.isNewFirewallRule = false;
        this.selectedFirewallRule = u;
        this.sidenav.open();
    }

    EditApplicationProfile(u) {
        this.selectedService = 'APP_PROFILE';
        this.selectedApplicationProfile = u;
        this.sidenav.open();
    }

    EditPool(u) {
        this.selectedService = 'POOL';
        this.selectedPool = u;
        this.sidenav.open();
    }

    EditVirtualServer(u) {
        this.selectedService = 'VIRTUAL_SERVER';
        this.selectedVirtualServer = u;
        this.sidenav.open();
    }

    EditIPSet(u) {
        this.selectedService = 'IPSET';
        this.selectedIPSet = u;
        this.sidenav.open();
    }

    GoToMachinesView() {
        this.router.navigateByUrl('/home/(main:dataCenter/' + encodeURIComponent(this.dataCenterID) + ')', { skipLocationChange: true });
    }

    ServiceButtonClick(service) {
        this.selectedService = service;
    }

    GetMachines() {
      this.Loading = true;
      this.wlservice.GetDataCenterMachines(this.dataCenterID).subscribe(
          (response: Array<CustomerMachinesService>) => {
            response.forEach((machinesCategory) => {
              if (machinesCategory && machinesCategory.machinesList && machinesCategory.machinesList.length > 0) {
                this.machines = [...this.machines, ...machinesCategory.machinesList];
              }
            });
            this.GetNetworkConfiguration();
          },
          error => {
            this.GetNetworkConfiguration();
            this.Loading = false;
            console.error(error);
          }
      );
  }

    ReplaceName(name) {
        const response = name.replace(/_/g, ' ');
        let capitalizeName = '';
        response.split(' ').forEach(element => {
            if (capitalizeName.length !== 0) {
                capitalizeName += ' ';
            }
            capitalizeName += element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
        });
        return capitalizeName;
    }

    LoadFirewall() {

        this.network.Firewall.FirewallRules.Rule.forEach(element => {
            if (element.Source == null) {
                element.SourceIp = 'any';
            } else if (element.Source.GroupingObjectId === null || element.Source.GroupingObjectId.length === 0) {
                if (element.Source.IpAddress != null && element.Source.IpAddress.length > 0) {
                    element.SourceIp = '';
                    element.Source.IpAddress.forEach(ip => {
                        element.SourceIp += ip + ', ';
                    });
                } else if (element.Source.VnicGroupId == null) {
                    element.SourceIp = 'any';
                } else {
                    element.SourceIp = element.Source.VnicGroupId;
                }
            } else if (element.Source.GroupingObjectId.length > 0) {
                element.SourceIp = '';
                element.Source.GroupingObjectId.forEach(grob => {
                    const agr = this.network.groupingObjects.Ipset.filter(ag => ag.ObjectId === grob)[0];
                    element.SourceIp += agr.Value + ', ';
                });
            }

            if (element.Destination == null) {
                element.DestinationIp = 'any';
            } else if (element.Destination.GroupingObjectId == null || element.Destination.GroupingObjectId.length === 0) {
                if (element.Destination.VnicGroupId == null) {
                    element.DestinationIp = 'any';
                } else {
                    element.DestinationIp = element.Destination.VnicGroupId;
                }
            }  else if (element.Destination.GroupingObjectId.length > 0) {
                element.DestinationIp = '';
                element.Destination.GroupingObjectId.forEach(grob => {
                    const agr = this.network.groupingObjects.Ipset.filter(ag => ag.ObjectId === grob)[0];
                    if (agr) {
                        element.DestinationIp += agr.Value + ', ';
                    } else {
                        console.log('not found in groupingobjects ' + grob);
                    }
                });
                element.DestinationIp = element.DestinationIp.slice(0, -2);
            }

            if (element.Application == null || element.Application.ApplicationId.length === 0) {
                element.Port = 'any';
            } else {

                element.Port = '';
                element.Application.ApplicationId.forEach(app => {
                    const portsgr = this.network.ApplicationGroups.Application.filter(ag => ag.ObjectId === app)[0];
                    if (portsgr == null){
                        element.Port = ' any ';
                    }else{
                        if (portsgr.Element == null || portsgr.Element.length === 0){
                            element.Port = ' any ';
                        }else{
                            console.log("En el IF",portsgr.Element);
                            element.Port += portsgr.Element.ApplicationProtocol + ' ' + portsgr.Element.Value + ', ';
                        }

                    }
                    
                });
                element.Port = element.Port.slice(0, -2);
            }
        });
        const services = this.machines;
        this.PrivateIps = services.map(f => f.Configuration.PrivateIp).filter(f => f != null);
        this.FirewallRules = this.network.Firewall.FirewallRules.Rule;
    }

    LoadNat() {
      const services = this.machines;
      this.PublicIps = this.network.groupingObjects.Ipset.filter(f => f.Name.indexOf('PUBLIC') !== -1).map(f => f.Value);
      this.PrivateIps = services.map(f => f.Configuration.PrivateIp);
      this.NatRules = this.network.Nat.NatRules.NatRule;
    }

    LoadDhcp() {
        const services = this.machines;
        this.network.DHCP.StaticBindings.StaticBinding.forEach(element => {
            const service = services.filter(f => f.Configuration.MacAddress === element.MacAddress)[0];
            element.ServiceName = service ? this.ReplaceName(service.Configuration.OperativeSystem.Os) : null;
            element.ServiceID = service ? service.ServiceID : null;
        });
        this.AvailableMacs = services.filter(s => this.network.DHCP.StaticBindings.StaticBinding
          .map(u => u.MacAddress).indexOf(s.Configuration.MacAddress) === -1 && s.Configuration.MacAddress != null);
        this.DHCPRules = this.network.DHCP.StaticBindings.StaticBinding;
    }

    LoadVpn() {
        this.PublicIps = this.network.groupingObjects.Ipset.filter(f => f.Name.indexOf('PUBLIC') !== -1).map(f => f.Value);
        this.IpSec = this.network.IpSec;
    }

    LoadRouting() {
        this.Routing = this.network.Routing;
    }

    LoadAppProfile() {
        this.network.LoadBalancer.ApplicationProfile.forEach(element => {
            if (element.Persistence == null) {
                element.Persistence = new PersistenceAppProfileDTO();
                element.Persistence.Method = 'none';
            }
        });

        this.AppProfiles = this.network.LoadBalancer.ApplicationProfile;
    }

    LoadVirtualServer() {
        this.network.LoadBalancer.VirtualServer.forEach(element => {
            if (element.ApplicationProfileId == null) {
                element.ApplicationProfileName = '';
            } else {
                element.ApplicationProfileName = this.network.LoadBalancer.ApplicationProfile
                .filter(m => m.ApplicationProfileId === element.ApplicationProfileId)[0].Name;
            }
            if (element.DefaultPoolId == null) {
                element.DefaultPoolName = '';
            } else {
                element.DefaultPoolName = this.network.LoadBalancer.Pool.filter(m => m.PoolId === element.DefaultPoolId)[0].Name;
            }
        });
        this.VirtualServer = this.network.LoadBalancer.VirtualServer;
    }

    LoadPool() {
        this.network.LoadBalancer.Pool.forEach(element => {
            if (element.MonitorId == null) {
                element.MonitorName = '';
            } else {
                element.MonitorName = this.network.LoadBalancer.Monitor.filter(m => m.MonitorId === element.MonitorId)[0].Name;
            }
        });

        this.Pool = this.network.LoadBalancer.Pool;
    }

    LoadIpSet() {
        this.IPSet = this.network.groupingObjects.Ipset;
    }

    AddDhcp() {
        this.AvailableMacs = this.machines.filter(s => this.network.DHCP.StaticBindings.StaticBinding
          .map(u => u.MacAddress).indexOf(s.Configuration.MacAddress) === -1 && s.Configuration.MacAddress != null);
        if (this.AvailableMacs.length > 0) {
            this.selectedRule = new DHCPDTO();
            this.sidenav.open();
        } else {
            this.sidenav.close();
            this.snackbar.open('No hay maquinas disponibles', null, {
                duration: 3000,
            });
        }
    }

    EditDhcp(rule: DHCPDTO) {
        this.AvailableMacs = this.machines.filter(s => this.network.DHCP.StaticBindings.StaticBinding
          .map(u => u.MacAddress).filter(f => f !== rule.MacAddress)
          .indexOf(s.Configuration.MacAddress) === -1 && s.Configuration.MacAddress != null);
        this.selectedRule = rule;
        this.sidenav.open();
    }

    EditNat(rule: NatDTO) {
        this.selectedNatRule = rule;
        this.sidenav.open();
    }

    EditVpn(rule: VpnDTO) {
        this.selectedVpnRule = rule;
        this.selectedVpnRuleID = this.network.IpSec.Sites.Site.indexOf(rule);
        this.sidenav.open();
    }

    EditRouting(rule: RoutingDTO) {
        this.selectedRoutingRule = rule;
        this.selectedRoutingRuleID = this.network.Routing.StaticRoutes.Route.indexOf(rule);
        this.sidenav.open();
    }

    AddNat() {
        this.selectedNatRule = new NatDTO();
        this.sidenav.open();
    }

    AddVpn() {
        this.selectedVpnRule = new VpnDTO();
        this.selectedVpnRule.IsNew = true;
        this.selectedVpnRuleID = null;
        this.sidenav.open();
    }

    AddRouting() {
        this.selectedRoutingRule = new RoutingDTO();
        this.sidenav.open();
        this.selectedRoutingRuleID = null;
    }

    AddApplicationProfile() {
        this.selectedApplicationProfile = new AppProfileDTO();
        this.sidenav.open();
    }

    AddVirtualServer() {
        this.selectedVirtualServer = new VirtualServerDTO();
        this.sidenav.open();
    }

    AddPool() {
        this.selectedPool = new PoolDTO();
        this.sidenav.open();
    }

    AddIPSet() {
        this.selectedIPSet = new IPSetDTO();
        this.sidenav.open();
    }

    SavedNetworkConf(Bindings) {
        this.GetNetworkConfiguration();
    }

    NatTab(event: MatTabChangeEvent) {
        this.selectedService = event.tab.textLabel;
    }

    loadBalancerActions(loadBalancerOption: string) {
        this.loadBalancerOption = loadBalancerOption;
    }
}
