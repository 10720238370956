import { WlService } from "./../wlservice.service";
import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { appLog } from "./logs-interface";

@Injectable({
  providedIn: "root",
})
export class LogsService {
  private baseUrl: string = environment.urlApiLogs;
  private headers = new HttpHeaders({
    "Content-Type": "application/json",
    "No-Auth": "True",
  });

  constructor(private http: HttpClient, private service: WlService) {}

  postAppLog(
    moduleName: string,
    actionName: string,
    success: boolean,
    message: string,
    serviceId: number
  ): any {
    this.service.GetUserInformation().subscribe(
      (data: any) => {
        const { Email, Name, UserName } = data;

        const payload: appLog = {
          application: "White Label",
          moduleName,
          actionName,
          email: Email || "N/A",
          userName: UserName,
          customerName: Name,
          serviceId,
          success,
          message,
        };

        this.http
          .post<any>(this.baseUrl, payload, {
            headers: this.headers,
          })
          .subscribe((resp: any) => console.log("LogSubmitted"));
      },
      (error: any) => console.log("ERROR", error)
    );
  }
}
