import { Component, OnInit , ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BroadcastEmitter } from '../../services/BroadcastEmitter';
import { Globalization } from '../../globalization/globalization';

@Component({
    selector: 'app-desktop',
    templateUrl: './desktop.component.html',
    styleUrls: ['./desktop.component.scss']
})
export class DesktopComponent implements OnInit {
    @ViewChild('sidenavdesktop') secondSideNav : MatSidenav;
    name = null;
    lastName = null;
     globalization: any = Globalization;
    constructor(public navbarBroadcast: BroadcastEmitter){

    }

    ngOnInit(){
      this.navbarBroadcast.SendMessage({actions: [], text: ""});
    }

    SecondSidenavClick(){
        this.secondSideNav.open();
    }

    SubmitForm(event){
        event.preventDefault();
        alert();
    }
}
