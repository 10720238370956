import { Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from "../../../tools/grid/grid.component";
import { WlService } from '../../../services/wlservice.service';
import { UserInfoDTO, ProfileDTO, ServiceDTO, UserDTO } from '../../../models/dtos.component';
import { ConfirmationDialog } from '../../../tools/modals/confirmation/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ServiceSettings } from '../service-configuration/service-configuration.component';
import { BroadcastEmitter } from '../../../services/BroadcastEmitter';
import { AuthService } from '../../../services/auth/auth.service';
import { PasswordResendDialog } from '../userAccounts.component';

@Component({
    selector: 'user-settings-new',
    templateUrl: 'user-settings.component.html',
    styleUrls: ['../userAccounts.component.scss']
})

export class UserSettings implements OnInit {
    user: UserInfoDTO;
    @ViewChild('AdditionalServicesGrid') AdditionalServicesGrid: GridComponent;
    @ViewChild('UsersGrid') UsersGrid: GridComponent;
    @ViewChild('sideNav') sideNav: MatSidenav;
    @ViewChild('SerSet') SerSet: ServiceSettings;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource = new MatTableDataSource();
    public userServices: any;
    public selectedUser: UserDTO;
    private profiles: Array<ProfileDTO> = [];
    public loading: boolean = false;
    public selectedService: ServiceDTO = new ServiceDTO();
    sidenavMode: string = "";
    displayedColumns = ['State', 'ServiceName', 'ServiceID', 'Cores', 'RAM', 'Options'];
    public resultsLength = 10;
    tokenPayload :any;
    userRole: any;

    public usersGrid = {
        columns: [
            { name: "Name", title: "Nombre" },
            { name: "Email", title: "Email" },
            { name: "TotalCost", title: "Costo", format: "currency" },
        ],
        data: [],
        id:"ID",
        toolbar: [
            { "type": "button", "click": () => { this.AddUser() }, "text": "AGREGAR USUARIO", "align": "left" },
        ],
        filter: "Name",
        rowClick: (u) => { this.EditUser(u) },
        refresh: (u) => { this.getUsers() },
        actions: [
            {
                text: "Editar",
                action: (u) => { this.EditUser(u) },
                hide: (u) => { return u.ID == JSON.parse(atob(this.auth.getToken().split('.')[1])).UserID }
            },
            {
                text: "Eliminar",
                action: (u) => { this.removeCustomerConfirm(u) },
                hide: (u) => { return u.ID == JSON.parse(atob(this.auth.getToken().split('.')[1])).UserID }
            },
            {
                text: "Reenviar Contraseña",
                action: (u) => { this.ResendUserMailWindow(u) }
            }
        ]
    }

    public additionalServicesGrid = {
        columns: [
            { name: "ServiceID", title: "Service ID" },
            { name: "Configuration.diskType", title: "Velocidad(IOPS)" },
            { name: "Configuration.size", title: "Tamaño(GB)" },
        ],
        data: [],
        filter: "ServiceID",
    }

    constructor(private service: WlService, private route: ActivatedRoute, public snackbar: MatSnackBar, public router: Router, public dialog: MatDialog, public navbarBroadcast: BroadcastEmitter, public auth : AuthService) {
        this.route.params.subscribe(params => {
            this.GetUseriformation(params["UserID"]);
        });
        this.getProfileList();
        this.tokenPayload = this.auth.tokenPayload(this.auth.getToken());
        this.userRole = this.tokenPayload.CustomerType;
    }

    GetColorState(state) {
        switch (state) {
          case "PENDING": return "machine-pending";
          case "POWER_ON": return "machine-on";
          case "POWER_OFF": return "machine-off";
          case "BUSY": return "machine-busy";
          case "SUSPEND": return "machine-suspended";
          default: return "";
        }
      }

    applyFilter(filterValue: string) {
        this.dataSource.data = this.userServices;
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
        console.log(this.dataSource.filteredData);
      }

    ResendUserMailWindow(user: UserDTO) {

        this.dialog.open(PasswordResendDialog, {
            data: user
        });
    }

    public resetSidenav(){
        this.SerSet.resetComponent();
    }

    public AddFreeService() {
        console.log(this.user);
        this.sidenavMode = "demoservice"
        this.sideNav.open();
    }

    public AddUser() {
        this.sidenavMode = "user"
        this.selectedUser = new UserDTO();
        this.selectedUser.ParentID = this.user.ID;
        this.selectedUser.Language = JSON.parse(atob(this.auth.getToken().split('.')[1])).Language;
        this.sideNav.open();
    }

    public EditUser(user) {
        this.sidenavMode = "user"
        this.selectedUser = user;
        this.sideNav.open();
    }

    removeCustomerConfirm(User: UserDTO) {
        this.dialog.open(ConfirmationDialog, {
            data: {
                Title: "Eliminando Cliente",
                Message: "¿Desea continuar?",
                Ok: () => this.RemoveUser(User),
            }
        });
    }

    public RemoveUser(user) {
        this.service.RemoveUser(user.ID).subscribe(
            response => {
                this.getUsers();
                this.snackbar.open('Cliente eliminado satisfactoriamente', null, {
                    duration: 3000,
                });
            },
            error => {
                console.log(error);
                this.snackbar.open('Error elminando cliente. ' + error.error, null, {
                    duration: 3000,
                });
            });
    }

    public AddService() {
        this.sidenavMode = "service"
        this.selectedService = new ServiceDTO();
        this.selectedService.CustomerID = this.user.ID;
        this.sideNav.open();
    }

    public EditService(Service) {
        this.sidenavMode = "service"
    }

    public savedUser(savedUser: UserDTO) {
        this.snackbar.open('Usuario guardado satisfactoriamente', null, {
            duration: 3000,
        });
        this.UsersGrid.pushRow(savedUser);
    }

    public savedService(savedService: ServiceDTO) {
        this.snackbar.open('Servicio creado satisfactoriamente.', null, {
            duration: 3000,
        });
        this.getServices();
    }

    ngOnInit() {
        this.user = new UserInfoDTO();
        this.navbarBroadcast.SendMessage({
            actions: [
                { icon: 'person_add', name: 'Agregar Usuario', action: () => { this.AddUser() } },
/*                 { icon: 'add_box', name: 'Agregar Servicio', action: () => { this.AddService() } },
                { icon: 'add_to_photos', name: 'Agregar Servicio de prueba', action: () => { this.AddFreeService() } }, */
                { icon: 'keyboard_backspace', name: 'Volver a usuarios', action: () => { this.GoBack() } }
            ],
            display : true
        });
    }

    getServices() {
        this.loading = true;
        this.service.GetDataCenterMachines(null, this.user.ID).subscribe(
            (response: any) => {
                this.loading = false;
                const data = response.filter(f => f.category === 'CS');
                this.userServices = (data[0] && data[0].machinesList) ? data[0].machinesList : [];
                this.dataSource.data = this.userServices;
                this.dataSource.paginator = this.paginator;
            },
            (error) => {
              console.error(error);
            }
        );
    }

    getUsers() {
        this.service.getSystemUserAccounts(this.user.ID).subscribe(
            response => {
                this.UsersGrid.setData(response);
            },
            (error) => {
              console.error(error);
            }
        );
    }

    getAdditionalServices() {
        this.AdditionalServicesGrid.loading = true;
        this.service.GetAdditionalServicesByCustomer(this.user.ID).subscribe(
            response => {
                this.AdditionalServicesGrid.setData(response);
            },
            error => {

            }
        )
    }

    GoBack() {
        this.router.navigateByUrl('/home/(main:userAccounts)', { skipLocationChange: true });
    }

    GetUseriformation(CustomerID: string) {
        this.service.GetUserInformation(CustomerID).subscribe(
            (response : any) => {
                this.user = response;
                this.getServices();
                this.getUsers();
                this.getAdditionalServices();
            },
            error => {
                this.snackbar.open('Error obteniendo información. ' + error.error, null, {
                    duration: 3000,
                });
                this.router.navigateByUrl('/home/(main:userAccounts)', { skipLocationChange: true });
            }
        )
    }

    getProfileList() {
        this.service.GetProfiles().subscribe(
            (response:any) => { this.profiles = response },
            error => {
                this.snackbar.open('Error obteniendo perfiles ' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }
}

