import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-Marketplace',
    templateUrl: './Marketplace.component.html',
    styleUrls: ['./Marketplace.component.css']
})
export class MarketplaceComponent implements OnInit {

    ngOnInit(){

    }
}