import { Component,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirmation-modal',
    templateUrl: './modal.component.html',
    styleUrls:['../modals.scss']
})
export class ConfirmationDialog {
    loading:boolean = false;
    constructor(public dialogRef: MatDialogRef<ConfirmationDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {}

    okClick(){
        this.dialogRef.close();
        this.data.Ok();
    }

    cancelClick(){
        if(!this.data.Cancel)
            this.dialogRef.close();
        else
            this.data.Cancel();
            this.dialogRef.close();
    }
}
