<mat-sidenav-container>
    <div class="text-error">
      <span *ngIf="errorLoadingEdge">ERROR: La administración de redes no se encontró para esta nube</span>
    </div>
    <mat-sidenav #sidenav align="end">
        <dhcp-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID" [AvailableMacs]=AvailableMacs
            [hidden]="selectedService != 'DHCP'" [DhcpRule]=selectedRule></dhcp-settings>
        <nat-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID" [hidden]="selectedService != 'NAT'"
            [PublicIps]=PublicIps [PrivateIps]=PrivateIps [NatRule]=selectedNatRule></nat-settings>
        <vpn-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID" [hidden]="selectedService != 'VPN'"
            [VpnRule]=selectedVpnRule [VpnRuleId]="selectedVpnRuleID" [PublicIps]=PublicIps></vpn-settings>
        <routing-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID" [hidden]="selectedService != 'ROUTING'"
            [RoutingRule]=selectedRoutingRule [RoutingRuleId]=selectedRoutingRuleID [Vnics]=Vnics></routing-settings>
        <firewall-settings [IsNew]="isNewFirewallRule" [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID"
            [hidden]="selectedService != 'FIREWALL'" [FirewallRule]=selectedFirewallRule [PrivateIps]=PrivateIps [appGr]="network && network.ApplicationGroups ? network.ApplicationGroups.Application : [] "
            [IpSets]="network && network.groupingObjects ? network.groupingObjects.Ipset : []"></firewall-settings>
        <applicationProfile-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID"
            [hidden]="selectedService != 'APP_PROFILE'" [ApplicationProfile]=selectedApplicationProfile
            [appGr]="network && network.LoadBalancer ? network.LoadBalancer.ApplicationProfile : [] "></applicationProfile-settings>
        <virtualServer-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID"
            [hidden]="selectedService != 'VIRTUAL_SERVER'" [PublicIps]=PublicIps [appGr]="network && network.LoadBalancer ? network.LoadBalancer.VirtualServer : [] "
            [VirtualServer]=selectedVirtualServer [Pools]="network && network.LoadBalancer && network.LoadBalancer.Pool ? network.LoadBalancer.Pool : []"
            [AppProfiles]="network && network.LoadBalancer && network.LoadBalancer.ApplicationProfile ? network.LoadBalancer.ApplicationProfile : []"></virtualServer-settings>
        <pool-settings [Machines]="machines" [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID"
            [hidden]="selectedService != 'POOL'" [Pool]=selectedPool [appGr]="network && network.LoadBalancer ? network.LoadBalancer.Pool : [] "
            [Monitors]="network && network.LoadBalancer && network.LoadBalancer.Monitor ? network.LoadBalancer.Monitor : []"></pool-settings>
        <ipset-settings [sidenav]="sidenav" (NewBindings)="SavedNetworkConf($event)" [DatacenterID]="dataCenterID"
            [hidden]="selectedService != 'IPSET'" [IPSet]=selectedIPSet
            [appGr]="network && network.groupingObjects && network.groupingObjects.IPSet ? network.groupingObjects.IPSet : [] "></ipset-settings>
    </mat-sidenav>

    <mat-tab-group class="container-fluid" style="margin-top: 10px;" (selectedTabChange)="NatTab($event)">
        <mat-tab label="FIREWALL">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.Firewall || !network.Firewall.Enabled,'circle-active':network && network.Firewall && network.Firewall.Enabled}">fiber_manual_record</mat-icon>Firewall
            </ng-template>
            <div class="network-data">
                <firewall-component [FirewallRules]="FirewallRules" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditFirewall($event)"></firewall-component>
            </div>
        </mat-tab>
        <mat-tab label="NAT">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.Nat || !network.Nat.enabled,'circle-active':network && network.Nat && network.Nat.enabled}">fiber_manual_record</mat-icon>NAT
            </ng-template>
            <div class="network-data">
                <nat-table [NatRules]="NatRules" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditNat($event)"></nat-table>
            </div>
        </mat-tab>
        <mat-tab label="DHCP">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.DHCP || !network.DHCP.Enabled,'circle-active':network && network.DHCP && network.DHCP.Enabled}">fiber_manual_record</mat-icon>DHCP
            </ng-template>
            <div class="network-data">
                <dhcp-table [DHCPRules]="DHCPRules" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditDhcp($event)"></dhcp-table>
            </div>
        </mat-tab>
        <mat-tab label="VPN">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.IpSec || network.IpSec.Enabled=='false','circle-active':network && network.IpSec && network.IpSec.Enabled=='true'}">fiber_manual_record</mat-icon>VPN
            </ng-template>
            <div class="network-data">
                <vpn-table [IpSec]="IpSec" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditVpn($event)"></vpn-table>
            </div>
        </mat-tab>
        <mat-tab label="ROUTING">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.Routing,'circle-active':network && network.Routing}">fiber_manual_record</mat-icon>Routing
            </ng-template>
            <div class="network-data">
                <routing-table [Routing]="Routing" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditRouting($event)"></routing-table>
            </div>
        </mat-tab>
        <mat-tab label="LOAD_BALANCER">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.Routing,'circle-active':network && network.Routing}">fiber_manual_record</mat-icon>Load Balancer
            </ng-template>
            <div class="network-data">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-3">
                            <mat-list style="width: 100%;">
                                <mat-list-item class="load-balancer-menu-option" #applicationProfile tabindex="1"  (click)="loadBalancerActions('applicationProfile')">
                                    {{'NETWORK_SETTINGS.APPLICATION_PROFILE' | translate}}
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item class="load-balancer-menu-option" #resourcePool tabindex="2" (click)="loadBalancerActions('resourcePool')">
                                    {{'NETWORK_SETTINGS.RESOURCE_POOLS' | translate}}
                                </mat-list-item>
                                <mat-divider></mat-divider>
                                <mat-list-item class="load-balancer-menu-option" #virtualServer tabindex="3"  (click)="loadBalancerActions('virtualServer')">
                                    {{'NETWORK_SETTINGS.VIRTUAL_SERVERS' | translate}}
                                </mat-list-item>
                            </mat-list>
                        </div>
                        <div class="col-9">
                            <app-profile-table [hidden]="loadBalancerOption != 'applicationProfile'" [AppProfiles]="AppProfiles" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditApplicationProfile($event)"></app-profile-table>
                            <pool-table [hidden]="loadBalancerOption != 'resourcePool'" [Pool]="Pool" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditPool($event)"></pool-table>
                            <virtual-server-table [hidden]="loadBalancerOption != 'virtualServer'" [VirtualServer]="VirtualServer" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditVirtualServer($event)"></virtual-server-table>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="IPSET">
            <ng-template mat-tab-label>
                <mat-icon class="network-circle mat-24" [ngClass]="{'circle-inactive':!network||!network.groupingObjects||!network.groupingObjects.Ipset,'circle-active':network && network.groupingObjects && network.groupingObjects.Ipset}">fiber_manual_record</mat-icon>IPSet
            </ng-template>
            <div class="network-data">
                <ipset-table [IPSet]="IPSet" [DatacenterID]="dataCenterID" [Loading]="Loading" (OnDelete)="SavedNetworkConf($event)" (OnEdit)="EditIPSet($event)"></ipset-table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-sidenav-container>
