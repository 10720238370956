import { HttpHeaders } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CsjService } from "src/app/services/csj/csj.service";

@Component({
  selector: "app-replicas-csj",
  templateUrl: "./replicas-csj.component.html",
  styleUrls: ["./replicas-csj.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ReplicasCSJComponent implements OnInit {
  selectedIndex: number = 0;
  show: boolean = false;
  private snackbar: MatSnackBar;
  constructor(public csjService: CsjService) {
    csjService.login().subscribe(
      (res) => {
        localStorage.setItem("csjtoken", res.token);
        this.show = true;
      },
      (err) => {
        this.snackbar.open(`Error: ${err}`, null, {
          duration: 3100,
        });
      }
    );
  }

  ngOnInit(): void {}
}
