<div class="bg">
  <div class="row">
    <div class="left-container col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="content-top content-image text-center">
        <mat-progress-bar class="loading-progress-bar" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <img [src]="domainLogo" class="logo" alt="Logo"/>
      </div>
      <div class="content-under text-center">
        <form [formGroup]="loginForm" (ngSubmit)="login($event)" autocomplete="nope">
          <div class="row justify-content-center">
            <mat-form-field class="col-10" appearance="outline">
              <mat-label class="label-style">Usuario</mat-label>
              <input class="input-style" maxlength="50" name="username" matInput [(ngModel)]="loginDTO.UserName" #username formControlName="username" required>
              <mat-icon matSuffix *ngIf="loginForm.get('username').valid && loginForm.get('username').touched && !isLoading" class="form-accepted">done</mat-icon>
              <mat-icon matSuffix *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched && !isLoading" class="form-rejected">error</mat-icon>
              <mat-hint style="color: white;" align="end">{{loginDTO.UserName ? loginDTO.UserName.length : 0}} / 50</mat-hint>
              <mat-error *ngIf="loginForm.get('username').hasError('required') && loginForm.get('username').touched">Campo requerido.</mat-error>
            </mat-form-field>

            <mat-form-field class="col-10" appearance="outline">
              <mat-label class="label-style">Contraseña</mat-label>
              <input class="input-style" type="password" name="password" matInput [(ngModel)]="loginDTO.Password" #password formControlName="password" required>
              <mat-icon matSuffix *ngIf="loginForm.get('password').valid && loginForm.get('password').touched && !isLoading" class="form-accepted">done</mat-icon>
              <mat-icon matSuffix *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched && !isLoading" class="form-rejected">error</mat-icon>
              <mat-error *ngIf="loginForm.get('password').hasError('required') && loginForm.get('password').touched">Campo requerido.</mat-error>
            </mat-form-field>
            <div class="col-10 row">
              <div class="col-6">
                <button class="remember-pass-button-text pull-right font-size-style" mat-button (click)="openDialog()" type="button">{{glob.login.forgotPassword[domainLanguage]}}</button>
              </div>
              <div class="col-4">
                <button type="submit" class="btn btn-warning login-button-style" [disabled]="!loginForm.valid || isLoading">Ingresar</button>
                <!-- <button class="login-button-style" type="submit"  mat-raised-button>Ingresar</button> -->
              </div>
            </div>
          </div>
        </form>
        <footer class="login-footer font-size-style">
          <p>
            {{currentYear}} &copy; Todos los derechos reservados.
          </p>
        </footer>
      </div>
    </div>
    <div class="rigth-container col-xl-6 col-lg-6 col-md-6">

    </div>



  </div>
</div>
