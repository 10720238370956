export class SnatRulesDto { 
    public sourcereport: string;
    public descr :string;
    public target: string;
    public targetip :string;
    public targetip_subnet :string;
    public interface :string;
    public poopLopts :string;
    public destination : DestinationSnatRulesDto;
    public dstport :string;
    public protocol: string;
    public source_hash_key :string;
    public updated :UpdatedSnatRulesDto;
    public created :UpdatedSnatRulesDto;
    public source : SourceSnatRulesDto;
    public staticnatport? : string;
}

export class SourceSnatRulesDto{
   public address? : string;
   public any? : string;
   public port? : string;
   public network? :string;
}

export class DestinationSnatRulesDto
{
    public address? : string;
    public any? : string;
    public port? : string;
    public network? :string;
}

export  class UpdatedSnatRulesDto
{
    public time : string;
    public username : string;
}

