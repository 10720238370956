import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirewallRulesDto } from '../../DTO/firewall-rules-dto';
import { NewFirewallPortRuleComponent } from '../../firewall-nat-port-forward/new-firewall-port-rule/new-firewall-port-rule.component';

@Component({
  selector: 'app-new-firewall-rule-wan',
  templateUrl: './new-firewall-rule-wan.component.html',
  styleUrls: ['./new-firewall-rule-wan.component.scss']
})
export class NewFirewallRuleWanComponent implements OnInit {

  newRule : FirewallRulesDto;
  disabled: boolean = false;
  advancedOptions :boolean = false;
  constructor( public dialogRef: MatDialogRef<NewFirewallPortRuleComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder : FormBuilder) { }

  ngOnInit(): void {
  }

  Close(): void {
    this.dialogRef.close(null);
  }

  AddRule(){
    /*this.service.AddFirewallRule(this.newRule).subscribe(
      response=>{
        this.rules.add(response);
      },
      error=>{}
    ) */
  }

}
