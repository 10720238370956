<div class="container-fluid">
    <div class="row" style="margin-top: 20px;">
        <div class="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-xs-12">
            <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
            <mat-horizontal-stepper class="horizontal-container" linear="true" #stepper="matHorizontalStepper">
                <mat-step [stepControl]="credentialForm">
                    <ng-template matStepLabel>{{ 'CREDENTIALS.CREDENTIALS_GENERATION' | translate }}</ng-template>
                    <p class="specification-text">
                        <strong>{{'CREDENTIALS.IFX_NETWORKS' | translate}}</strong>
                        <span [innerHTML]="'CREDENTIALS.IT_PROVIDES_THE_FOLLOWING_INTERFACE' | translate"></span>
                    </p>
                    <form [formGroup]="credentialForm" (ngSubmit)="SendCredential()">
                        <mat-form-field class="full-width">
                            <mat-select placeholder="{{'CREDENTIALS.KIND_OF_PRODUCT' | translate}}" formControlName="product" name="product" required [(ngModel)]="credential.productType">
                                <mat-option *ngFor="let p of products" [value]="p.ID">
                                    {{p.Name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="credentialForm.get('product').hasError('required')">
                              {{'CREDENTIALS.REQUIRED_FIELD' | translate}}
                        </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width margin-top-10">
                            <input matInput type="email" placeholder="{{'CREDENTIALS.CUSTOMER_EMAIL' | translate}}" formControlName="usermail" name="usermail" [(ngModel)]="credential.email" required>
                            <mat-error *ngIf="credentialForm.get('usermail').hasError('required')">
                              {{'CREDENTIALS.REQUIRED_FIELD' | translate}}
                            </mat-error>
                            <mat-error *ngIf="credentialForm.get('usermail').hasError('email') && !credentialForm.get('usermail').hasError('required')">
                              {{'CREDENTIALS.ENTER_A_VALID_EMAIL_ADDRESS' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width margin-top-10">
                            <input matInput [type]="passwordVisible ? 'text' : 'password'" placeholder="{{'CREDENTIALS.PASSWORD' | translate}}" formControlName="password" name="password" [(ngModel)]="credential.password" required>
                            <span matSuffix>
                                <button type="button" mat-icon-button (click)="passwordVisible = !passwordVisible"><mat-icon>remove_red_eye</mat-icon></button>
                            </span>
                            <mat-error *ngIf="credentialForm.get('password').hasError('required')">
                              {{'CREDENTIALS.REQUIRED_FIELD' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <div class="submit-button-form">
                            <button mat-raised-button type="submit" color="primary" [disabled]="!credentialForm.valid || isLoading">
                              {{'CREDENTIALS.CONTINUE' | translate}}
                            </button>
                        </div>
                    </form>
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>{{'CREDENTIALS.CONFIRMATION' | translate}}</ng-template>
                    <p class="specification-text">
                        <span [innerHTML]="'CREDENTIALS.BY_CLICKING_ON_THE_BUTTON' | translate"></span>
                        <!-- Al hacer click en el boton de "copiar enlace", tendra el acceso a la credencial en el portapapeles,
                        Recuerde que este es el <strong>único</strong> medio en que se pueden enviar.<br/>
                        En caso de proporcionar de forma legible alguna contraseña, incurrira en un <strong>incidente de seguridad</strong>. -->
                    </p>
                    <div class="full-width text-align-center">
                        <button mat-raised-button ngxClipboard color="primary" [cbContent]="credential.credentialHref">{{'CREDENTIALS.COPY_LINK' | translate}}</button>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>

