<aside class="sidebar shadow-sm">
  <div class="sidebar-profile d-flex flex-column justify-content-center pb-3">
    <div class="user-initials">
      <span class="circleTop logo-tile user-tile">{{user.Initials}}</span>
    </div>
    <div class="profile-details text-center pb-4">
      <p class="mb-0 text-uppercase name">{{user.Name}}</p>
    </div>
  </div>
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
<nav id="sidebar" class="sidebar" [ngClass]="{'sidebar-bg' : hasBackgroundImage()}">
  <div class="sidebar-content">
    <perfect-scrollbar>
      <div class="sidebar-menu">
        <ul class="menu-items">
          <li *ngFor="let menu of menus" [ngClass]="{'active': menu.active , 'sidebar-dropdown':menu.type === 'dropdown' , 'header-menu':menu.type === 'header'}">
            <!--<span *ngIf="menu.type === 'header'">{{menu.title}}</span>-->
            <a *ngIf="menu.type !== 'header'"  routerLink="/calculator" routerLinkActive="active" (click)='toggle(1,menu)'>
              <i class="{{menu.icon}}"></i>
              <span>{{menu.title}}</span>
              <span *ngIf="menu.badge" class="badge badge-pill" [ngClass]="menu.badge.class">{{menu.badge.text}}</span>
            </a>
            <div *ngIf="menu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(menu)">
              <ul>
                <li *ngFor="let submenu of menu.submenus" [ngClass]="{'active': submenu.active , 'sidebar-dropdown':submenu.type === 'dropdown' , 'header-menu':submenu.type === 'header'}">
                  <a href="#" routerLink="/calculator" routerLinkActive="active" (click)='toggle(2,submenu)' > {{submenu.title}}
                    <span *ngIf="submenu.badge" class="badge badge-pill" [ngClass]="submenu.badge.class">{{submenu.badge.text}}</span>
                  </a>
                  <div *ngIf="submenu.type === 'dropdown'" class="sidebar-submenu" [@slide]="getState(submenu)">
                    <ul>
                      <li *ngFor="let submenuTwo of submenu.submenus">
                        <a href="#" routerLink="/calculator" routerLinkActive="active"> {{submenuTwo.title}}
                          <span *ngIf="submenuTwo.badge" class="badge badge-pill" [ngClass]="submenuTwo.badge.class">{{submenuTwo.badge.text}}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</nav>
</aside>
