import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { RoutingDTO, RoutingSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'routing-table',
  templateUrl: './routing-table.component.html',
  styleUrls: ['./routing-table.component.scss']
})
export class RoutingTableComponent implements OnInit, OnChanges {
    @Input("Routing") Routing: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Description', 'Network', 'NextHop', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      if(this.Routing != null)
        this.dataSource.data = this.Routing.StaticRoutes.Route;
      else
        this.dataSource.data = [];

      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      if(this.Routing != null)
        this.dataSource.data = this.Routing.StaticRoutes.Route;
      else
        this.dataSource.data = [];
        
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }


    RemoveRoutingModal(rule: RoutingDTO) {
      this.dialog.open(ConfirmationDialog, {
          data: {
              Title: "Eliminar Regla Routing",
              Message: "Esta seguro?",
              Ok: () => this.RemoveRoutingRule(rule),
          }
      });
  }

  RemoveRoutingRule(rule: RoutingDTO) {
      var i = this.Routing.StaticRoutes.Route.indexOf(rule);
      this.Routing.StaticRoutes.Route.splice(i,1);

      let req = new RoutingSaveRequestDTO();
      req.DatacenterID = this.DatacenterID;
      req.StaticRouting = this.Routing;

      this.wlservice.SaveRouting(req).subscribe(
          response => {
              this.OnDelete.emit(rule);
              this.snackbar.open('Regla de Routing eliminada satisfactoriamente', null, {
                  duration: 3000,
              });
          },
          error => {
              this.snackbar.open('Error eliminando regla de Routing ' + error.error, null, {
                  duration: 3000,
              });
          }
      )
  }

  ReloadNetwork() {
    this.dataSource.filter = "";
    this.OnDelete.emit();
  }

  EditRoutingRule(rule: RoutingDTO) {
    this.OnEdit.emit(rule);
  }
}