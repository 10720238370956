import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { IPSetDTO, IPSetSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'ipset-table',
  templateUrl: './ipset-table.component.html',
  styleUrls: ['./ipset-table.component.scss']
})
export class IpsetTableComponent implements OnInit, OnChanges {
    @Input("IPSet") IPSet: any;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Value', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      if(this.IPSet != null)
        this.dataSource.data = this.IPSet;
      else
        this.dataSource.data = [];

      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      if(this.IPSet != null)
        this.dataSource.data = this.IPSet;
      else
        this.dataSource.data = [];

      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemoveIPSetModal(rule: IPSetDTO) {
      this.dialog.open(ConfirmationDialog, {
        data: {
          Title: "Eliminar IPSet",
          Message: "Esta seguro?",
          Ok: () => this.RemoveIPSet(rule),
        }
      });
    }

    RemoveIPSet(rule: IPSetDTO) {
      let req = new IPSetSaveRequestDTO();
      req.IPSet = rule;
      req.DatacenterID = this.DatacenterID;
      this.wlservice.RemoveIPSet(req).subscribe(
        response => {
          this.OnDelete.emit(rule);
          this.snackbar.open('IP Set eliminado satisfactoriamente', null, {
              duration: 3000,
          });
        },
        error => {
          this.snackbar.open('Error eliminando IP Set ' + error.error, null, {
              duration: 3000,
          });
        }
      )
    }

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditIPSet(rule: IPSetDTO) {
      console.log(rule);
      this.OnEdit.emit(rule);
    }
}