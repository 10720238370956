
<!-- <section>
  <div class="add-button-ip pull-right">
    <button mat-raised-button color="primary" (click)="newRule()">Agregar</button>
  </div>
</section> -->
<mat-divider></mat-divider>

<div class="box content-margin row">
  <div class="container-1">
      <span class="icon"><i class="fa fa-search"></i></span>
      <input matInput type="text" (keyup)="applyFilter($event)" id="search" placeholder="Search..." />
  </div>
</div>

<table>
 <tr>

 </tr>
</table>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" style="width:100%" *ngIf="item" matSort>
  
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef> Description </th>
      <td mat-cell *matCellDef="let element"> {{element.descr}} </td>
    </ng-container>

    
    <!-- Symbol Column -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

  
   <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> Actions</th>
          <td mat-cell *matCellDef="let element ; let i = index">
              <button id="btnMoreAccions+ 'i'"  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  <button mat-menu-item >
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span> 
                  </button>
                  <button mat-menu-item  id="detail" (click)="OpenDetail(element)">
                    <mat-icon>remove_red_eye</mat-icon>
                    <span>Ver detalle</span>
                  </button>   
              </mat-menu>

              <button mat-icon-button matTooltip="Eliminar" id="removeNetworklLink">
                <mat-icon>delete</mat-icon>
              </button>
          </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]" *ngIf="item"></mat-paginator>
 </div> 