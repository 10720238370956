import { Component, Input, Output, OnInit, OnChanges, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { PoolDTO, PoolServerDTO, PoolSaveRequestDTO, MonitorDTO, ServiceDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'pool-settings',
  templateUrl: './pool.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class PoolComponent implements OnInit {
    @Input("Pool") Pool: PoolDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("IpSets") ipSets: Array<any>;
    @Input("appGr") appGr: Array<any>;
    @Input("PrivateIps") PrivateIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Input("IsNew") IsNew : boolean = true;
    @Input("Monitors") Monitors: Array<MonitorDTO>;
    @Input("Machines") Machines: Array<ServiceDTO>;
    @Output() NewBindings = new EventEmitter();
    const: Constants = new Constants();
    loading: boolean = false;
    requirePort: boolean = false;
    private validForm: boolean = false;
    Member: PoolServerDTO = new PoolServerDTO();
    saveRequest: PoolSaveRequestDTO = new PoolSaveRequestDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    poolForm: FormGroup;
    serverForm:FormGroup;
    showMemberDetail:boolean = false;
    newMember:boolean = false;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, public auth: AuthService, private cdRef: ChangeDetectorRef) {
        // this.Machines = this.Machines.filter(f => f.Configuration.PrivateIp != null)
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.poolForm = this.formBuilder.group({
            'Name' : ['',[Validators.pattern(/^[a-zA-Z0-9]+$/)]],
            'Algorithm' : ['',[Validators.required]],
            'MonitorId' : ['',''],
        });

        this.serverForm = this.formBuilder.group({
            'IpAddress' : ['',[Validators.required]],
            'Weight' : ['', [Validators.required]],
            'Port' : ['', [Validators.required]],
            'MonitorPort' : ['', [Validators.required]],
            'MaxConn' : ['', [Validators.required]],
            'MinConn' : ['', [Validators.required]],
        });
    }


    resetAll() {
        this.loading = false;
        this.requirePort = false;
        this.Member = new PoolServerDTO();
        this.saveRequest = new PoolSaveRequestDTO();
    }

    addMember() {
        this.Member = new PoolServerDTO();
        this.showMemberDetail = true;
        this.newMember = true;
        this.cdRef.detectChanges();
    }

    getMembers() {
        if (this.Pool.Member) {
            return this.Pool.Member;
        }
        else {
            return [];
        }
    }

    getMachines() {
        if (this.Machines) {
            return this.Machines.filter(f => f.Configuration.PrivateIp != null);
        } else {
            return [];
        }
    }

    saveMember() {
        if(this.newMember)
            this.Pool.Member.push(this.Member);

        this.showMemberDetail = false;
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.Pool = new PoolDTO();
    }

    ngOnChanges(changes) {
        if(changes["IsNew"]){
            if(changes["IsNew"].currentValue){
                this.resetAll();
                this.IsNew = false;
            }
        }
    }

    removeMember(item) {
        var i = this.Pool.Member.indexOf(item);
        this.Pool.Member.splice(i,1);
    }

    editMember(item) {
      this.Member = item;
      this.showMemberDetail = true;
      this.newMember = false;
      this.cdRef.detectChanges();
    }

    saveRule() {
        this.loading = true;
        this.poolForm.disable();
        this.serverForm.disable();

        for(let m of this.Pool.Member)
        {
            var machineName = this.Machines.filter(f => f.Configuration.PrivateIp == m.IpAddress).map(f => f.ServiceName)[0];
            m.Name = 'LB'+ machineName.replace(".","");
        }

        this.Pool.MonitorName = null;
        this.saveRequest.Pool = this.Pool;
        this.saveRequest.DatacenterID = this.DatacenterID;

        this.wlservice.SavePool(this.saveRequest).subscribe(
            response => {
                this.poolForm.enable();
                this.serverForm.enable();
                this.loading = false;
                this.snackbar.open('Pool creado satisfactoriamente', null, {
                    duration: 3000,
                });

                this.sidenav.close();
                this.NewBindings.emit(this.Pool);
            },
            error => {
                this.poolForm.enable();
                this.serverForm.enable();
                this.loading = false;
                this.snackbar.open('Error agregando Pool ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
