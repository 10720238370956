<div class="container-fluid">
    <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="titleFix">
                <br>
                <mat-form-field style="width: 100%" class="configInput">
                    <input matInput [(ngModel)]="searchProfile" placeholder="{{globalization.role.placeholder[language]}}" value="" name="laputamadre">
                </mat-form-field>
            </div>
            <mat-progress-bar *ngIf="profilesLoading" mode="indeterminate"></mat-progress-bar>
            <div class="container-fluid">
                <div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-grid-list cols="6" rowHeight="150px">
                                <mat-grid-tile (click)="EditProfile(profile)" *ngFor="let profile of profileList | filter : 'Name' :searchProfile; let i = index" class="user-tile user-detail-tile"
                                    [colspan]="2" [rowspan]="1">
                                    <div class="{{'circleTop-'+ (i % 5) }} wtxt puntero"> <span class="textTitle">{{profile.Name}}</span> </div>
                                    <div class="hiddenMenu"></div>
                                    <mat-list dense class="topFixList">
                                    </mat-list>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 container-fluid" [hidden]="!selectedProfile">
            <button class="pull-right btnTopStyleFix" [hidden]="!selectedProfile" mat-icon-button [disabled]="LoadingPermissions" (click)="SaveProfile()"><mat-icon>save</mat-icon></button>
            <div class="row">
                <h5 class="titleFixTop">Agregue o retire permisos.</h5>
            </div>
            <hr class="hrFix2">
            <mat-progress-bar *ngIf="LoadingPermissions" mode="indeterminate"></mat-progress-bar>

            <div *ngIf="selectedProfile">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" style="display:inline-block;" *ngFor="let comp of AvailablePermissions; let i = index">
                    <h6 class="titleFix">{{ globalization.role.components[comp.Template][language] }}</h6>
                    <mat-list>
                        <mat-list-item>
                            <mat-checkbox [(ngModel)]="comp.isChecked" class="dgtxt"><span class="letterStyle">Permiso concedido</span></mat-checkbox>
                        </mat-list-item>
                        <mat-list-item *ngFor="let child of comp.Children; let j = index">
                            <mat-checkbox [(ngModel)]="child.isChecked" class="dgtxt"><span class="letterStyle">{{globalization.role.components[child.Template][language]}}</span></mat-checkbox>
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>

    </div>
</div>
