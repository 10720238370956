<mat-toolbar color="primary">
    Edit Static Mapping
    <div class="close-modal full-width">
      <button mat-icon-button (click)="Close()" class="pull-right">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div class="modal-form-container" >
    <div class="container-fluid rule-container mt-5">
      <form class="add-row" #addRule="ngForm" (ngSubmit)="AddRule()">
        <fieldset class="properties-border">
          <legend class="properties-border" style="width: auto; font-size: 1rem;" >Static DHCP Mapping on LAN</legend>
          <div class="row">
            <div class="col-xl-12 col-lg-12 select-container"> 
                <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="MAC Address" required class="full-width"  [(ngModel)]="newRule.mac" matInput placeholder="MAC Address">
                </mat-form-field>
                <p>MAC address (6 hex octets separated by colons)</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12">
              <mat-form-field class="full-width col-12" floatLabel="always">
                <input name="Client" required class="full-width" matInput placeholder="Client Identifier">
              </mat-form-field>
            </div>
            <div class="col-xl-12 col-lg-12">
              <mat-form-field class="full-width col-12" floatLabel="always">
                <input name="Description" required class="full-width"  matInput placeholder="IP Address">
              </mat-form-field>
              <p>If an IPv4 address is entered, the address must be outside of the pool.</p>
              <p> If no IPv4 address is given, one will be dynamically allocated from the pool</p>
              <p>The same IP address may be assigned to multiple mappings.</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <mat-form-field class="full-width col-12" floatLabel="always">
                <input name="Hostname" required class="full-width" matInput placeholder="Hostname">
              </mat-form-field>
            </div>
            <div class="col-xl-6 col-lg-6">
              <mat-form-field class="full-width col-12" floatLabel="always">
                <input name="Description" required class="full-width"  matInput placeholder="Description">
              </mat-form-field>
            </div>
          </div>
         </fieldset> 
      
          <fieldset class="properties-border">
            <legend class="properties-border" style="width: auto; font-size: 1rem;">Host(s)</legend>
              <div class="row">
                <div class="col-xl-10 col-lg-10 text-right">
                  <button class="float-right" [disabled]="!addRule.form.valid" type="button" mat-icon-button>
                    <mat-icon color="primary">
                      add_circle  
                    </mat-icon> Agregar Host
                  </button>
                </div>
              </div>  
              <div class="row">
                <div class="col-xl-5 col-lg-5">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input pattern="(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)|(^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])-([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)" name="SourcePort" required  matInput placeholder="IP or FQDN">
                  </mat-form-field>
                </div>
                <div class="col-xl-5 col-lg-5">
                  <mat-form-field class="full-width col-12" floatLabel="always">
                    <input name="SourcePort" required  matInput placeholder="Description">
                  </mat-form-field>
                </div> 
                <div class="col-xl-2 col-lg-2">
                  <button [disabled]="!addRule.form.valid" type="button" mat-icon-button>
                    <mat-icon color="primary">
                      delete  
                    </mat-icon>
                  </button>
                </div>
              </div>
  
          </fieldset>
        <div class="action-buttons">
          <button mat-button color="primary"> <span class="material-icons">save</span> Guardar</button>
        </div>
      </form>
    </div>
  </div>
