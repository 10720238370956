import { Component,Inject, ViewChild, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AppProfileDTO, AppProfileSaveRequestDTO } from '../../../../models/dtos.component';
import { ConfirmationDialog } from '../../../../tools/modals/confirmation/modal.component';
import { WlService } from '../../../../services/wlservice.service';

@Component({
  selector: 'app-profile-table',
  templateUrl: './app-profile-table.component.html',
  styleUrls: ['./app-profile-table.component.scss']
})
export class AppProfileTableComponent implements OnInit, OnChanges {
    @Input("AppProfiles") AppProfiles: Array<AppProfileDTO>;
    @Input("DatacenterID") DatacenterID: string;
    @Input("Loading") Loading: boolean;
    @Output("OnDelete") OnDelete = new EventEmitter(); 
    @Output("OnEdit") OnEdit = new EventEmitter(); 
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resultsLength = 10;
    dataSource = new MatTableDataSource();
    displayedColumns = ['Name', 'Method', 'Template', 'CookieName', 'Options'];
    constructor(private wlservice: WlService, public dialog: MatDialog, public snackbar: MatSnackBar) {

    }

    ngOnInit() {
    }

    ngOnChanges() {
      if(this.AppProfiles == null)
        this.dataSource.data = [];
      else
        this.dataSource.data = this.AppProfiles;

      this.dataSource.paginator = this.paginator;
    }
    applyFilter(filterValue: string) {
      if(this.AppProfiles == null)
        this.dataSource.data = [];
      else
        this.dataSource.data = this.AppProfiles;

      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
      console.log(this.dataSource.filteredData);
    }

    RemoveApplicationProfileModal(rule: AppProfileDTO) {
      this.dialog.open(ConfirmationDialog, {
          data: {
              Title: "Eliminar Application Profile",
              Message: "Esta seguro?",
              Ok: () => this.RemoveApplicationProfile(rule),
          }
      });
  }

  RemoveApplicationProfile(rule: AppProfileDTO) {
    let req = new AppProfileSaveRequestDTO();
    req.ApplicationProfile = rule;
    req.DatacenterID = this.DatacenterID;
    this.wlservice.RemoveAppProfile(req).subscribe(
      response => {
          this.OnDelete.emit(rule);
          this.snackbar.open('Application Profile eliminada satisfactoriamente', null, {
              duration: 3000,
          });
      },
      error => {
          this.snackbar.open('Error eliminando Application Profile ' + error.error, null, {
              duration: 3000,
          });
      }
    )
  }

    ReloadNetwork() {
      this.dataSource.filter = "";
      this.OnDelete.emit();
    }

    EditAppProfile(rule: AppProfileDTO) {
      this.OnEdit.emit(rule);
    }
}