<mat-sidenav-container class="main-container">
    <mat-sidenav class="rightSidenavColor container-fluid" #sidenav position="end">
        <form #document="ngForm" (ngSubmit)="saveHelpDeskMaterial(document)" novalidate>
            <div class="topFix">
                <mat-form-field class="configInputNav">
                    <input matInput name="Title" ngModel required #document.Title="ngModel"  placeholder="{{ 'HELPDESK.SIDENAV_TITLE' | translate}}" maxlength="40">
                    <mat-hint align="end">{{(document.value.Title)? document.value.Title.length :"" }}  / 40</mat-hint>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="configInputNav">
                    <textarea matInput name="Description"
                    ngModel required #document.Description="ngModel" class="txtSmall txtArea" maxlength="60"  placeholder="{{ 'HELPDESK.SIDENAV_DESCRIPTION' | translate}}">
                    </textarea>
                    <mat-hint align="end">{{(document.value.Description)? document.value.Description.length :"" }}  / 60</mat-hint>
                </mat-form-field>
            </div>
            <h5 class="txtFix">{{ 'HELPDESK.SIDENAV_SELECTTOPIC' | translate}}</h5>
            <div class="row">
                <div class="col-12">
                    <div *ngFor="let option of customersType">
                        <mat-checkbox [(ngModel)]="option.check" name="check"(click)="changeCustomer(option)" required>{{option.Label  | translate}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" *ngIf="uploader?.queue?.length == 0">
                    <label for="uploader-input"
                    ng2FileDrop
                    [ngClass]="{'another-file-over-class': hasAnotherDropZoneOver}"
                    (fileOver)="fileOverAnother($event)"
                    [uploader]="uploader" class="well my-drop-zone">{{ 'HELPDESK.UPLOADFILE' | translate}}</label>
                    <input type="file" ng2FileSelect [uploader]="uploader" id="uploader-input" style="display:none" />
                </div>
            </div>
            <div class="row" *ngIf="uploader?.queue?.length > 0">
                <div  class="col-12"  *ngFor="let item of uploader.queue">
                    <p>{{ item?.file?.name }}</p>
                    <button (click)="item.remove()" type="button" class="close" aria-hidden="true">&times;</button>
                </div>
            </div>
            <div class="row ">
                <mat-card-actions class="btnPosFix">
                    <button mat-button type="submit" id="submit" [disabled]="!document.form.valid" >{{ 'HELPDESK.SAVE' | translate}}</button>
                    <button mat-button type="button"(click)="restoreData(document)">{{ 'HELPDESK.RESTORE' | translate}}</button>
                </mat-card-actions>
            </div>
        </form>
    </mat-sidenav>
    <div class="container-fluid titleFix">
        <h4>{{ 'HELPDESK.MAIN_INFO' | translate}}</h4>
        <mat-form-field class="configInput">
        <input [(ngModel)]="searchDocument" matInput placeholder="{{ 'HELPDESK.PLACEHOLDER' | translate}}"  >
        </mat-form-field>
    </div>
    <div class="">
        <button type="button" mat-button (click)="sidenav.open()">
            {{ 'HELPDESK.ADDHELPMATERIAL' | translate }}
        </button>
    </div>

    <mat-list class="configInputMat">
        <h3 mat-subheader>{{ 'HELPDESK.RECENTMATERIAL' | translate}}</h3>
        <mat-spinner style="margin-top: calc(50vh - 50px - 64px);margin-left: 100px" *ngIf="documentLoading"></mat-spinner>
        <mat-list-item  (click)="downloadDocument(document.File)" *ngFor="let document of HelpDeskMaterial  | filter : 'Title' :searchDocument | paginate: { itemsPerPage: 1, currentPage: p, totalItems: total  } " >
            <mat-icon mat-list-icon>description</mat-icon>
            <h4 mat-line>{{document.Title}}</h4>
            <p mat-line> {{document.Description}}</p>
            <p mat-line> {{document.UpdateDate | date }} </p>
        </mat-list-item>
        <mat-divider class="container-fluid"></mat-divider>
    </mat-list>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</mat-sidenav-container>
