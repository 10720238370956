import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { VirtualServerDTO, VirtualServerSaveRequestDTO, AppProfileDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';



@Component({
  selector: 'virtualServer-settings',
  templateUrl: './virtual-server.component.html',
  styleUrls: ['../networkSettings.scss']
})
export class VirtualServerComponent implements OnInit {
    @Input("VirtualServer") VirtualServer: VirtualServerDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("IpSets") ipSets: Array<any>;
    @Input("AppProfiles") AppProfiles: Array<AppProfileDTO>;
    @Input("Pools") Pools: Array<any>;
    @Input("appGr") appGr: Array<any>;
    @Input("PublicIps") PublicIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Input("IsNew") IsNew : boolean = true;
    @Output() NewBindings = new EventEmitter();
    const: Constants = new Constants();
    loading: boolean = false;
    private validForm: boolean = false;
    saveRequest: VirtualServerSaveRequestDTO = new VirtualServerSaveRequestDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    virtualServerForm: FormGroup;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.virtualServerForm = this.formBuilder.group({
            'Name' : ['',[Validators.required]],
            'ApplicationProfileId' : ['',[Validators.required]],
            'IpAddress' : ['',[Validators.required]],
            'Protocol' : ['',[Validators.required]],
            'Port' : ['',[Validators.required]],
            'DefaultPoolId' : ['',[Validators.required]],
            'ConnectionLimit' : ['',[]],
            'ConnectionRateLimit' : ['',[]],
        });
    }

    resetAll() {
        this.loading = false;
        this.saveRequest = new VirtualServerSaveRequestDTO();
    }

    ngOnInit() {
        this.VirtualServer = new VirtualServerDTO();
    }

    ngOnChanges(changes) {
        if(changes["IsNew"]){
            if(changes["IsNew"].currentValue){
                this.resetAll();
                this.IsNew = false;
            }
        }
    }

    saveRule() {
        this.loading = true;
        this.virtualServerForm.disable();

        this.VirtualServer.ApplicationProfileName = null;
        this.VirtualServer.DefaultPoolName = null;

        this.saveRequest.VirtualServer = this.VirtualServer;
        this.saveRequest.DatacenterID = this.DatacenterID;
        this.wlservice.SaveVirtualServer(this.saveRequest).subscribe(
            response => {
                this.virtualServerForm.enable();
                this.loading = false;
                this.snackbar.open('Virtual Server creado satisfactoriamente', null, {
                    duration: 3000,
                });

                this.sidenav.close();
                this.NewBindings.emit(this.VirtualServer);
            },
            error => {
                this.virtualServerForm.enable();
                this.loading = false;
                this.snackbar.open('Error agregando Virtual Server ' + error.error, null, {
                    duration: 3000,
                });
            }
        )
    }
}
