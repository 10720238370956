<mat-progress-bar *ngIf="loading" mode="indeterminate" class="loading-sidenav"></mat-progress-bar>
<div class="container-fluid">
    <div class="sidenav-title">
        <h5 *ngIf="Pool.PoolId == null">Agregar Pool</h5>
        <h5 *ngIf="Pool.PoolId != null">Editar Pool</h5>
    </div>
    <form [formGroup]="poolForm" (ngSubmit)="saveRule()">
        <div class="row container-fluid sidenav-form-content">
            <div class="sidenav-subtitle col-12">
                <h6>Configuración</h6>
            </div>
            <mat-form-field class="col-12 full-width" floatLabel="always">
                <input Name="PoolName" id="PoolName" formControlName="Name" [(ngModel)]="Pool.Name" required matInput placeholder="{{globalization.networkSettings.sideNavPool.name[language]}}"
                    maxlength="50">
                <mat-icon matSuffix *ngIf="poolForm.get('Name').valid && poolForm.get('Name').touched" class="form-accepted">done</mat-icon>
                <mat-icon matSuffix *ngIf="!poolForm.get('Name').valid && poolForm.get('Name').touched" class="form-rejected">error</mat-icon>
                <mat-hint align="start">
                    <div class="error-messages">
                        <div *ngIf="poolForm.get('Name').hasError('required') && poolForm.get('Name').touched">Campo requerido.</div>
                    </div>
                </mat-hint>
                <mat-hint align="end">{{Pool.Name ? Pool.Name.length : 0}} / 50</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" placeholder="{{globalization.networkSettings.sideNavPool.algorithm[language]}}"
                    name="Algorithm" id="Algorithm" formControlName="Algorithm" [(ngModel)]="Pool.Algorithm" floatPlaceholder="always">
                    <mat-option [value]="'round-robin'">Round-Robin</mat-option>
                    <mat-option [value]="'ip-hash'">IP-Hash</mat-option>
                    <mat-option [value]="'leastconn'">Leastconn</mat-option>
                    <mat-option [value]="'uri'">URI</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="col-12 full-width" floatLabel="always">
                <mat-select class="col-12 full-width margin-top-20" required placeholder="{{globalization.networkSettings.sideNavPool.monitorId[language]}}"
                    name="MonitorId" required id="MonitorId" formControlName="MonitorId" [(ngModel)]="Pool.MonitorId" floatPlaceholder="always">
                    <mat-option *ngFor="let monitor of Monitors" [value]="monitor.MonitorId">{{monitor.Name}}</mat-option>
                </mat-select>
                <div class="error-messages col-12">
                    <div *ngIf="poolForm.get('MonitorId').hasError('required') && poolForm.get('MonitorId').touched">Campo requerido.</div>
                </div>
            </mat-form-field>

            <form [formGroup]="serverForm">
                <div class="" style="vertical-align: middle;">
                    <div class="sidenav-subtitle" style="position: relative; display: inline-block;     margin-top: auto !important;">
                        <h6>{{globalization.networkSettings.sideNavPool.members[language]}}</h6>
                    </div>
                    <div class="" style="display: inline-block !important">
                        <button (click)="addMember()" mat-icon-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
                <div [hidden]="!showMemberDetail">
                    <div class="col-12 full-width">
                        <button (click)="saveMember()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!serverForm.valid">Agregar</button>
                    </div>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <mat-select class="col-12 full-width margin-top-20" required placeholder="{{globalization.networkSettings.sideNavPool.ipAddress[language]}}"
                            name="MemberIpAddress" required id="MemberIpAddress" formControlName="IpAddress" [(ngModel)]="Member.IpAddress"
                            floatPlaceholder="always">
                            <mat-option *ngFor="let machine of getMachines()" [value]="machine.Configuration.PrivateIp">{{machine.Configuration.PrivateIp}}</mat-option>
                        </mat-select>
                        <div class="error-messages col-12">
                            <div *ngIf="serverForm.get('IpAddress').hasError('required') && serverForm.get('IpAddress').touched">Campo requerido.</div>
                        </div>
                    </mat-form-field>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <input Name="MemberWeight" id="MemberWeight" formControlName="Weight" [(ngModel)]="Member.Weight" required matInput placeholder="{{globalization.networkSettings.sideNavPool.weight[language]}}"
                            maxlength="50">
                        <mat-icon matSuffix *ngIf="serverForm.get('Weight').valid && serverForm.get('Weight').touched" class="form-accepted">done</mat-icon>
                        <mat-icon matSuffix *ngIf="!serverForm.get('Weight').valid && serverForm.get('Weight').touched" class="form-rejected">error</mat-icon>
                        <mat-hint align="start">
                            <div class="error-messages">
                                <div *ngIf="serverForm.get('Weight').hasError('required') && serverForm.get('Weight').touched">Campo requerido.</div>
                            </div>
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <input Name="MemberPort" id="MemberPort" formControlName="Port" [(ngModel)]="Member.Port" required matInput placeholder="{{globalization.networkSettings.sideNavPool.port[language]}}"
                            maxlength="50">
                        <mat-icon matSuffix *ngIf="serverForm.get('Port').valid && serverForm.get('Port').touched" class="form-accepted">done</mat-icon>
                        <mat-icon matSuffix *ngIf="!serverForm.get('Port').valid && serverForm.get('Port').touched" class="form-rejected">error</mat-icon>
                        <mat-hint align="start">
                            <div class="error-messages">
                                <div *ngIf="serverForm.get('Port').hasError('required') && serverForm.get('Port').touched">Campo requerido.</div>
                            </div>
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <input Name="MemberMonitorPort" id="MemberMonitorPort" formControlName="MonitorPort" [(ngModel)]="Member.MonitorPort" required
                            matInput placeholder="{{globalization.networkSettings.sideNavPool.monitorPort[language]}}" maxlength="50">
                        <mat-icon matSuffix *ngIf="serverForm.get('MonitorPort').valid && serverForm.get('MonitorPort').touched" class="form-accepted">done</mat-icon>
                        <mat-icon matSuffix *ngIf="!serverForm.get('MonitorPort').valid && serverForm.get('MonitorPort').touched" class="form-rejected">error</mat-icon>
                        <mat-hint align="start">
                            <div class="error-messages">
                                <div *ngIf="serverForm.get('MonitorPort').hasError('required') && serverForm.get('MonitorPort').touched">Campo requerido.</div>
                            </div>
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <input Name="MemberMaxConn" id="MemberMaxConn" formControlName="MaxConn" [(ngModel)]="Member.MaxConn" required matInput placeholder="{{globalization.networkSettings.sideNavPool.maxConn[language]}}"
                            maxlength="50">
                        <mat-icon matSuffix *ngIf="serverForm.get('MaxConn').valid && serverForm.get('MaxConn').touched" class="form-accepted">done</mat-icon>
                        <mat-icon matSuffix *ngIf="!serverForm.get('MaxConn').valid && serverForm.get('MaxConn').touched" class="form-rejected">error</mat-icon>
                        <mat-hint align="start">
                            <div class="error-messages">
                                <div *ngIf="serverForm.get('MaxConn').hasError('required') && serverForm.get('MaxConn').touched">Campo requerido.</div>
                            </div>
                        </mat-hint>
                    </mat-form-field>

                    <mat-form-field class="col-12 full-width" floatLabel="always">
                        <input Name="MemberMinConn" id="MemberMinConn" formControlName="MinConn" [(ngModel)]="Member.MinConn" required matInput placeholder="{{globalization.networkSettings.sideNavPool.minConn[language]}}"
                            maxlength="50">
                        <mat-icon matSuffix *ngIf="serverForm.get('MinConn').valid && serverForm.get('MinConn').touched" class="form-accepted">done</mat-icon>
                        <mat-icon matSuffix *ngIf="!serverForm.get('MinConn').valid && serverForm.get('MinConn').touched" class="form-rejected">error</mat-icon>
                        <mat-hint align="start">
                            <div class="error-messages">
                                <div *ngIf="serverForm.get('MinConn').hasError('required') && serverForm.get('MinConn').touched">Campo requerido.</div>
                            </div>
                        </mat-hint>
                    </mat-form-field>
                </div>

                <mat-list *ngIf="getMembers().length > 0" class="margin-bottom-10">
                    <mat-list-item class="col-12" *ngFor="let item of getMembers()">
                        <div class="row no-default-padding">
                            <div class="col-6 port-title">{{item.IpAddress}}</div>
                            <div class="col-3">
                                <button (click)="editMember(item)" mat-button>
                                    <mat-icon>create</mat-icon>
                                </button>
                            </div>
                            <div class="col-3">
                                <button (click)="removeMember(item)" mat-button>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </form>
        </div>

        <div class="row container-fluid sidenav-form-actionbuttons">
            <div class="col-6 full-width">
                <button color="warn" mat-button (click)="sidenav.toggle()" type="button" class="pull-right">Cancelar</button>
            </div>

            <div class="col-6 full-width">
                <button (click)="saveRule()" class="pull-right" type="button" mat-raised-button color="primary" [disabled]="!poolForm.valid">Guardar</button>
            </div>
        </div>
    </form>
</div>
