import { Component, Input, Output, OnInit, OnChanges, EventEmitter } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { FirewallDTO, FirewallSourceDTO, FirewallApplication, FirewallSaveRequestDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../../../models/constants.component';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
    selector: 'firewall-settings',
    templateUrl: './firewall-configuration.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class FirewallConfiguration implements OnInit, OnChanges {
    @Input("FirewallRule") Rule: FirewallDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("IpSets") ipSets: Array<any>;
    @Input("appGr") appGr: Array<any>;
    @Input("PrivateIps") PrivateIps: Array<string>;
    @Input("sidenav") sidenav: MatSidenav;
    @Input("IsNew") IsNew : boolean = true;
    @Output() NewBindings = new EventEmitter();
    const: Constants = new Constants();
    loading: boolean = false;
    requirePort: boolean = false;
    private validForm: boolean = false;
    source: FirewallSourceDTO = new FirewallSourceDTO();
    Destination: FirewallSourceDTO = new FirewallSourceDTO();
    newApplication: FirewallApplication = new FirewallApplication();
    saveRequest: FirewallSaveRequestDTO = new FirewallSaveRequestDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    firewallForm: FormGroup;
    applicationForm:FormGroup;

    constructor(private wlservice: WlService, public snackbar: MatSnackBar, private formBuilder: FormBuilder, public auth: AuthService) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.firewallForm = this.formBuilder.group({
            'name' : ['',[Validators.required]],
            'ruleType' : ['',[Validators.required]],
            'sourceIp' : ['',''],
            'status' : ['',[Validators.required]],
            'destinationIP' : ['',''],

        });

        this.applicationForm = this.formBuilder.group({
            'specifyPorts' : ['',[Validators.required]],
            'protocol' : ['', [Validators.required]],
            'port' : ['', [Validators.pattern(/(^(\d{1,5}\-\d{1,5})$)|(^\d{1,5}$)/)]],
        });
    }

    resetAll() {
        this.loading = false;
        this.requirePort = false;
        this.source = new FirewallSourceDTO();
        this.Destination = new FirewallSourceDTO();
        this.newApplication = new FirewallApplication();
        this.saveRequest = new FirewallSaveRequestDTO();
    }

    getPorts() {
        if (this.Rule.Port){

            return this.Rule.Port.split(", ").filter(f => f.toUpperCase() != "ANY")
        }
        else{

            return [];
        }
    }

    saveApplication() {
        var temp = this.getPorts();
        temp.push(this.newApplication.protocol + " " + this.newApplication.port);
        this.Rule.Port = temp.join(", ")
        this.newApplication = new FirewallApplication();
    }

    ngOnInit() {
        this.Rule = new FirewallDTO();
    }

    ngOnChanges(changes) {
        if (changes['Rule']) {
            this.requirePort = false;
            if (changes['Rule'].currentValue && changes['Rule'].currentValue.Port.toUpperCase() != "" && changes['Rule'].currentValue.Port.toUpperCase() != "ANY" && changes['Rule'].currentValue.Port.split(", ").length > 0)
            {
                this.requirePort = true;
                console.log(changes['Rule'].currentValue.Port.toUpperCase());
            }
            if (changes['Rule'].currentValue.Source != null && changes['Rule'].currentValue.Source.IpAddress.length > 0)
                this.source.IpAddress = changes['Rule'].currentValue.Source.IpAddress;
            else if (changes['Rule'].currentValue.Source == null)
                this.source.IpAddress = ["any"];

            if (changes['Rule'].currentValue.Destination != null && changes['Rule'].currentValue.Destination.IpAddress.length > 0)
                this.Destination.IpAddress = changes['Rule'].currentValue.Destination.IpAddress;
            else if (changes['Rule'].currentValue.Destination == null)
            {
                this.Destination = new FirewallSourceDTO();
                this.Destination.IpAddress = ["any"];
            }

            if (changes['Rule'].currentValue.Destination) {
                if (changes['Rule'].currentValue.Destination.GroupingObjectId && changes['Rule'].currentValue.Destination.GroupingObjectId.length > 0) {
                    this.Rule.Destination.groupingObjectId = changes['Rule'].currentValue.Destination.GroupingObjectId;
                    this.Destination.groupingObjectId = changes['Rule'].currentValue.Destination.GroupingObjectId;
                    this.Rule.Destination.IpAddress = [];
                    this.Destination.IpAddress = [];
                    for(let detIP of this.Rule.Destination.groupingObjectId)
                    {
                        let tmp = this.ipSets.filter(i => i.ObjectId == detIP)[0];
                        if (tmp) {
                            this.Rule.Destination.IpAddress.push(tmp.Value);
                            this.Destination.IpAddress.push(tmp.Value.toString());
                        }
                    }
                }
                else
                {
                    this.Destination.IpAddress = changes['Rule'].currentValue.Destination.IpAddress;
                    this.Rule.Destination.IpAddress = changes['Rule'].currentValue.Destination.IpAddress;
                }

                if(changes['Rule'].currentValue.Application == null || changes['Rule'].currentValue.Application == [])
                    this.Rule.Application = [];
            }
            else if(changes['Rule'].currentValue.Destination == null)
            {
                this.Destination = new FirewallSourceDTO();
                this.Rule.Destination = new FirewallSourceDTO();
            }
        }
        if(changes["IsNew"]){
            if(changes["IsNew"].currentValue){
                this.resetAll();
                this.IsNew = false;
            }
        }
    }

    removePort(item) {
        this.Rule.Port = this.Rule.Port.split(", ").filter(f => f != item).join(", ");
        var protocol = item.split(" ")[0];
        var port = item.split(" ")[1]
        this.Rule.Application = this.Rule.Application.filter(f => f.port != port && f.protocol.toLowerCase() != protocol.toLowerCase());
    }

    saveRule() {
        this.loading = true;
        this.firewallForm.disable();
        this.applicationForm.disable();

        if (this.source.IpAddress.length == 0)
            this.Rule.Source = null;
        else
        {
            this.Rule.Source = new FirewallSourceDTO();
            this.Rule.Source.groupingObjectId = [];
            this.Rule.Source.IpAddress = [];
            for(let detIP of this.source.IpAddress)
            {
                let tmp = this.ipSets.filter(i => i.Value == detIP)[0];
                if (tmp) {
                    this.Rule.Source.groupingObjectId.push(tmp.ObjectId);
                }
                else {
                    if(detIP != "" && detIP != null)
                        this.Rule.Source.IpAddress.push(detIP);
                }
            }
        }

        if (this.Destination.IpAddress.length == 0)
            this.Rule.Destination = null;
        else
        {
            this.Rule.Destination = new FirewallSourceDTO();
            this.Rule.Destination.groupingObjectId = [];
            this.Rule.Destination.IpAddress = [];
            for(let detIP of this.Destination.IpAddress)
            {
                let tmp = this.ipSets.filter(i => i.Value == detIP)[0];
                if (tmp) {
                    this.Rule.Destination.groupingObjectId.push(tmp.ObjectId);
                }
                else {
                    if(detIP != "" && detIP != null)
                        this.Rule.Destination.IpAddress.push(detIP);
                }
            }
        }

        if(this.Rule.Port.toUpperCase() == "ANY")
            this.Rule.Port = "";

        this.saveRequest.firewallRules.Rule = [];
        this.saveRequest.firewallRules.Rule.push(this.Rule);
        this.saveRequest.DatacenterID = this.DatacenterID;

        this.wlservice.SaveFirewallRule(this.saveRequest).subscribe(
            response => {
                this.firewallForm.enable();
                this.applicationForm.enable();
                this.loading = false;
                this.snackbar.open('Regla de firewall creada satisfactoriamente', null, {
                    duration: 3000,
                });

                this.sidenav.close();
                this.NewBindings.emit(this.Rule);
            },
            error => {
                this.firewallForm.enable();
                this.applicationForm.enable();
                this.loading = false;
                this.snackbar.open('Error agregando regla de firewall ' + error.error, null, {
                    duration: 3000,
                });
            }
        )

    }
}
