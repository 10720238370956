import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { saveAs } from "file-saver";
// import { utils, write, WorkBook } from "xlsx";
const EXCEL_EXTENSION = ".xlsx";
@Injectable()
export class ExcelService {
  constructor(private datePipe: DatePipe) {}
  generateInvoceExcel(
    title: string,
    header: string[],
    data: object[],
    totalServer: any,
    discountType: number
  ) {
    this.saveExcelFile(title);
  }

  saveExcelFile(title: string) {}

  public exportAsJsonExcelFile(json: any[], excelFileName: string): void {
    // let ws_name = "SomeSheet";
    // let wb: WorkBook = { SheetNames: [], Sheets: {} };
    // let ws: any = utils.json_to_sheet(json);
    // wb.SheetNames.push(ws_name);
    // wb.Sheets[ws_name] = ws;
    // const wbout = write(wb, {
    //   bookType: "xlsx",
    //   bookSST: true,
    //   type: "binary",
    // });
    // this.saveAsExcelFile(wbout, excelFileName);
  }

  public s2ab(s: any): any {
    // const buf = new ArrayBuffer(s.length);
    // const view = new Uint8Array(buf);
    // for (let i = 0; i !== s.length; ++i) {
    //   view[i] = s.charCodeAt(i) & 0xff;
    // }
    // return buf;
  }

  private saveAsExcelFile(buffer: any, excelFileName: string): void {
    // saveAs(
    //   new Blob([this.s2ab(buffer)], { type: "application/octet-stream" }),
    //   excelFileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    // );
  }
}
