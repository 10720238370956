import {Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator }  from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WlService } from '../../../services/wlservice.service';
import { BundleQuoteDTO } from '../dto/cloud-server-dto';

class Item {
  isActive: boolean;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-view-quotes',
  templateUrl: './view-quotes.component.html',
  styleUrls: ['./view-quotes.component.scss']
})

/**
 * @title Table with QuoteList
 */

export class ViewQuotesComponent implements OnInit  {
  public items: Array<Item> = [];
  displayedColumns: string[] = ['Cod', 'BusinessOpportunityID','Owner', 'Name', 'Client', 'Date', 'Description', 'Acciones'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSendMail: object = {};
  loading =  false;
  public Quotes =  new Array<BundleQuoteDTO>();
  constructor(private service: WlService,
              private route: ActivatedRoute,
              public snackbar: MatSnackBar,
              private router: Router,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.GetQuotesByUser();
    this.dataSource.sort = this.sort;
    this.items = [
      {
       icon : 'folder',
       name: 'Mis cotizaciones',
       isActive : true,
      },

      {
        icon : 'people_outline',
        name: 'Compartidas Conmigo',
        isActive : false,
      }
    ]
  }

  caculatorRoute() {
    this.router.navigateByUrl('/home/(main:calculator)', { skipLocationChange: true });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  GetQuotesByUser() {
    this.loading = true;
    this.service.GetBundleQuotesByUser(0).subscribe(
        response => {
          this.Quotes = response;
          this.dataSource.data = response;
          this.loading = false;
          this.toggleItem(this.items[0]);
        },
        error => {
          this.loading = false;
          this.snackbar.open('Error consultando servicio' + error.error, null, {
            duration: 3000
        });

        }
    )
  }

  EditQuote(idBundleQuote: number ) {
    this.router.navigate(['home', { outlets: { main: [ 'calculator', idBundleQuote ] }}]);
  }

  public toggleItem(data: Item) {
    this.filteredData(data.name);
    const items = this.items.filter(item => item.isActive === true);
    items.forEach(element => {
      element.isActive = false;
    });
    data.isActive = true;

  }


  public filteredData(name: string) {
    let data = [];
    data = this.Quotes.filter(item => item.Owner);
    if (name === 'Mis cotizaciones') {
        data = this.Quotes.filter(item => item.Owner);
      } else if (name === 'Compartidas Conmigo') {
        data =  this.Quotes.filter(item => !item.Owner);
      }
    this.dataSource.data =  data;
  }


  SendQuoteObject(value: any, item: BundleQuoteDTO ) {

    const dataSendMail = {
      Configuration: item,
      Email: value.email,
      Name: value.name,
      Detail : value.detail
    }

    this.service.SendBundleQuote(dataSendMail)
    .subscribe( response => {
      if (response) {
        this.snackbar.open('Cotización Enviada Correctamente', null, {
          duration: 2000,
        });
      } else {
        this.snackbar.open('Ocurrió un error enviando la Cotización por favor intentelo nuevamente', null, {
          duration: 2000,
        });
      }
      },
      error => {
        this.snackbar.open('Ocurrió un error enviando la Cotización por favor intentelo nuevamente' + error, null, {
          duration: 2000,
        });
      }
    );
  }

  DeleteQuote(index: number) {
   this.service.DeleteBundleQuoteByID(this.Quotes[index].ID)
   .subscribe(
      response => {
        this.Quotes.splice(index, 1);
        this.snackbar.open('Cotización eliminada correctamente' , null, {
          duration: 3000
      });
        this.GetQuotesByUser();
      },
      error => {
        this.snackbar.open('Error eliminando Cotización' , null, {
          duration: 3000
      });
    })
  }
}
