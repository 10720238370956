import { Component, OnChanges, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { WlService } from '../../../../services/wlservice.service';
import { DHCPDTO, ServiceDTO, DHCPSaveRequestDTO } from '../../../../models/dtos.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globalization } from '../../../../globalization/globalization';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth/auth.service';


@Component({
    selector: 'dhcp-settings',
    templateUrl: './dhcp-configuration.component.html',
    styleUrls: ['../networkSettings.scss']
})

export class DhcpConfiguration implements OnInit, OnChanges {
    @Input("DhcpRule") Rule: DHCPDTO;
    @Input("DatacenterID") DatacenterID: string;
    @Input("AvailableMacs") AvailableMacs: Array<ServiceDTO>;
    @Input("sidenav") sidenav: MatSidenav;
    @Output() NewBindings = new EventEmitter();
    loading: boolean = false;
    selectedMac: ServiceDTO = new ServiceDTO();
    globalization: any = Globalization;
    token: string;
    language: string;
    dhcpConfigurationFb : FormGroup;
    saveRequest : DHCPSaveRequestDTO = new DHCPSaveRequestDTO();

    constructor(private wlservice: WlService, public snackbar: MatSnackBar,  public formBuilder : FormBuilder, public auth: AuthService ) {
        this.token = auth.getToken();
        this.language = JSON.parse(atob(this.token.split('.')[1])).Language;
        this.dhcpConfigurationFb = this.formBuilder.group({
            'mac' : [{value: '', disabled:this.loading},[Validators.required]],
            'ipAddress' : [{value: '', disabled:this.loading},[Validators.required,
              Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]],
            'defaultGateway' : [{value: '', disabled:this.loading},[Validators.required,
              Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]],
            'primaryNameServer' : [{value: '', disabled:this.loading},[Validators.required,
              Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]],
            'secondaryNameServer' : [{value: '', disabled:this.loading},[Validators.required,
              Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]],
            'subnetMask' : [{value: '', disabled:this.loading},[Validators.required,
              Validators.pattern(/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/)]]
        });

    }

    selectServiceID(selected) {
        this.Rule.MacAddress = selected.value.Configuration.MacAddress;
        this.Rule.Hostname = "VM" + selected.value.ServiceID;
    }

    ngOnInit() {
        this.Rule = new DHCPDTO();
        this.AvailableMacs = [];
    }

    ngOnChanges(changes) {
        if (changes['Rule'] && changes['Rule'].currentValue.MacAddress) {
            this.selectedMac = this.AvailableMacs.filter(f => f.Configuration.MacAddress == changes['Rule'].currentValue.MacAddress)[0];
        }
    }

    saveRule() {
        this.loading = true;
        this.dhcpConfigurationFb.disable();
        this.saveRequest.staticBinding = this.Rule;
        this.saveRequest.DatacenterID = this.DatacenterID;
        this.wlservice.AddDHCPRule(this.saveRequest).subscribe(
            response => {
                this.dhcpConfigurationFb.enable();
                this.snackbar.open('Regla DHCP guardada satisfactoriamente.', null, {
                    duration: 2000,
                });
                this.loading = false;
                this.sidenav.close();
                this.NewBindings.emit(response);
            },
            error => {
                this.dhcpConfigurationFb.enable();
                this.loading = false;
                this.snackbar.open('Error guardando regla DHCP ' + error.error, null, {
                    duration: 2000,
                });
            }
        )
    }
}
