import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-rules-lan',
  templateUrl: './services-rules-lan.component.html',
  styleUrls: ['./services-rules-lan.component.scss']
})
export class ServicesRulesLanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
