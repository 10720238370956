<mat-card class="event-card-container mt-4">
  <div class="custom-card-decoration-switch" style="justify-content: center">
    <p class="text-light mt-3">Estado de sincronización por disco</p>
  </div>
  <div class="text-center" *ngIf="loadData">
    <small><strong>Última actualización:</strong> {{ maxDate }}</small>
  </div>
  <div class="update-dashboard-container">
    <button
      mat-icon-button
      class="pull-right"
      (click)="refresh()"
      matTooltip="{{ 'CSJ.REFRESH' | translate }}"
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      mat-icon-button
      class="pull-right add-customer-cloud"
      *ngIf="loadData"
      (click)="exportToExcel()"
      matTooltip="{{ 'CSJ.TO_EXPORT' | translate }}"
      matTooltipPosition="below"
    >
      <mat-icon>save</mat-icon>
    </button>
    <button
      *ngIf="loadData"
      class="box-md-20 pull-right wl-grid-refresh-button"
      matTooltip="Exportar Graficas"
      (click)="downloadToJpeg('dashboard')"
      mat-icon-button
    >
      <span class="material-icons md-24 al-top">get_app</span>
    </button>
  </div>
  <mat-card-content>
    <div
      class="display-card-flex"
      style="margin-top: 34px !important"
      id="dashboard"
    >
      <div class="d-center" *ngIf="!loadData">
        <img src="assets/img/loadchart.gif" alt="" width="100px" />
        <p *ngIf="!loadData">Consultado información...</p>
      </div>

      <div
        class="animate__animated animate__pulse m-3"
        style="min-width: 315px; max-width: 315px"
        *ngFor="let disk of dataDisk"
      >
        <div class="port-card">
          <div
            class="port-card-decoration bg-{{ disk.info.colors.class }}"
            (click)="OnDetailClick(disk, disk.info.graphicFiles, true)"
            matTooltip="Ver grafico linea de tiempo de archivos"
          >
            <mat-icon class="text-light">timeline</mat-icon>
          </div>
          <div
            class="port-card-decoration"
            style="left: 50px"
            (click)="OnDetailClick(disk, disk.info.graphicTimeUseDisk, false)"
            [ngClass]="{
              lleno: disk.value > 90,
              libre: disk.value < 80,
              medio: disk.value >= 80 && disk.value <= 90
            }"
            matTooltip="Ver linea de tiempo de uso del disco"
          >
            <mat-icon class="text-light">storage</mat-icon>
          </div>
          <div class="fav-decoration">
            <strong class="title-disk">{{ disk.name }}</strong>
          </div>

          <div
            class="card-body"
            style="background-color: #fff; padding-top: 2.2rem"
          >
            <div class="d-flex d-center flex-row-reverse mt-2">
              <div class="p-2 bd-highlight"></div>
            </div>

            <div class="d-flex d-center flex-row-reverse">
              <div class="pr-2">
                Archivos faltantes ultimo día:
                <span
                  class="text-{{
                    disk.info.colors.class
                  }} fs-11 label-count-files"
                  >{{ disk.info.noDestino }}</span
                >
              </div>
            </div>
            <div class="d-flex d-center flex-row-reverse">
              <ngx-charts-pie-grid
                class="ngx-custom"
                [scheme]="{ domain: [disk.info.colors.hex] }"
                [results]="disk.info.pie"
                [view]="copyView"
                [designatedTotal]="disk.info.total"
                [animations]="true"
              >
              </ngx-charts-pie-grid>
            </div>

            <div class="d-flex d-center flex-row-reverse">
              <div class="pr-2 label-count-files">
                Total archivos origen:
                <span class="text-success fs-11">{{ disk.info.total }}</span>
              </div>
            </div>
            <div class="d-flex d-center flex-row-reverse d-wrap">
              <a class="link p-2">Uso de Disco: {{ disk.value }}%</a>
              <div class="progress">
                <div
                  class="progress-bar"
                  [style.width.%]="disk.value"
                  [ngClass]="{
                    lleno: disk.value > 90,
                    libre: disk.value < 80,
                    medio: disk.value >= 80 && disk.value <= 90
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
