<mat-sidenav-container class="wl-content">
    <mat-sidenav #sidenavdesktop position="end">
        <mat-toolbar>
            <div  class="title-form">
                {{'DESKTOP.MANAGE_USER' | translate }}
            </div>
        </mat-toolbar>
        <form #userForm="ngForm" (ngSubmit)="SubmitForm($event)" class="user-form">
            <mat-form-field class="full-width" floatLabel="always">
                <input matInput placeholder="Nombres" required [(ngModel)]="name" name="name" maxlength="30">
                 <mat-hint align="end">{{name ? name.length : 0}} / 30</mat-hint>
            </mat-form-field>
            <mat-form-field class="full-width" floatLabel="always">
                <input matInput placeholder="Apellidos" required [(ngModel)]="lastName" name="lastName" maxlength="30">
                <mat-hint align="end">{{lastName ? lastName.length : 0}} / 30</mat-hint>
            </mat-form-field>
            <div class="full-width submit-form">
                <button type="submit" [disabled]="!userForm.form.valid" mat-raised-button color="primary">Aceptar</button>
            </div>
        </form>
    </mat-sidenav>
    <app-control-panel></app-control-panel>
</mat-sidenav-container>
