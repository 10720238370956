export class FirewallIPDto {
    public name: string;
    public description: string;
    public values :string;
    public type:string;
    constructor() {
        this.name = null;
        this.description = null;
        this.values = null;
        this.type = null;
    }
}

 export class FirewallIPInDto {
    public name: string;
    public description: string;
    public value :ipObject[];
    public type:string;
    constructor() {
        this.name = null;
        this.description = null;
        this.value = new Array();
        this.type = null;
    }
}
 
export class ipObject {
   public address: string;
   public descr: string;
   public type :string;

   constructor() {
    this.address = '';
    this.descr = '';
    this.type = '';
   }

}

export  class RequestAliasFirewallDto
{
    public customerId: string;
    public datacenterId : string;
    public ccid?: number ;
    public rulePosition? : number;
    public NewAliasFirewallDto:  FirewallIPInDto;
}




