export class Machine {
  public ID: string;
  public Duration: number;
  public Cores: Cores;
  public Ram: Ram;
  public Hypervisor: Hypervisor;
  public Availability: Ha;
  public Network: Networking;
  public MachineStorage: [Storage];
  public OperativeSystem: OperativeSystem;
  public DataBase: any;
  public MachineName: string;
  public RemoteAccess: any;
  public PrivateIp: string;
  public PublicIp: string;
  public MacAddress: string;
  public MachineLicenses: any;
  public MachineServices: any;
  public Cost: BundleCost;
  public Vcenter: string;
  public ServiceID: string;
  public Configuration: any;
  public State?: string;
  public ServiceName?: string;
  constructor() {
    this.Configuration = null;
    this.ID = null;
    this.Duration = 0;
    this.Cores = new Cores();
    this.Ram = new Ram();
    this.Hypervisor = new Hypervisor();
    this.Availability = new Ha();
    this.Network = new Networking();
    this.MachineStorage = [new Storage()];
    this.OperativeSystem = new OperativeSystem();
    this.DataBase = new DataBase();
    this.MachineName = null;
    this.RemoteAccess = "";
    this.MacAddress = null;
    this.MachineLicenses = [];
    this.MachineServices = [];
    this.Cost = new BundleCost();
    this.Vcenter = null;
    this.ServiceID = null;
    this.ServiceName = null;
    this.State = null;
  }
}

class DataBase {
  public Db: string;
  public Cost: BundleCost;
  constructor() {
    this.Db = "";
    this.Cost = new BundleCost();
  }
}

export class BundleCost {
  public _CAPEX?: number;
  public _OPEX?: number;
  public CAPEX?: number;
  public OPEX?: number;
  public MRC?: number;
  public DiscountMRC?: number;
  constructor() {
    this._CAPEX = 0;
    this._OPEX = 0;
    this.CAPEX = 0;
    this.OPEX = 0;
    this.MRC = 0;
    this.DiscountMRC = 0;
  }
}


class Networking {
  public localNetworkType: number;
  public Internet: number;
  public Cost: BundleCost;
  constructor() {
    this.localNetworkType = 0;
    this.Internet = 5;
    this.Cost = new BundleCost();
  }
}

class Hypervisor {
  public plataform: number;
  public Cost: BundleCost;
  constructor() {
    this.plataform = 2;
    this.Cost = new BundleCost();
  }
}


class Ha {
  public availabilityType: number;
  public replicationServer: number;
  public Cost: BundleCost;
  constructor() {
    this.availabilityType = 0;
    this.replicationServer = null;
    this.Cost = new BundleCost();
  }
}

class Cores{
    public Value:number;
    constructor(){
        this.Value=0;
    }
}

class Ram{
    public Value:number;
    constructor(){
        this.Value=0;
    }
}

export class Storage{
    public diskType : number;
    public size:number;
    public Backup:Backup;
    constructor(){
        this.diskType = 0;
        this.size = 0;
        this.Backup = new Backup();
    }
}

class Backup{
    public month:number;
    constructor(){
        this.month = 0;
    }
}

class OperativeSystem {
    public Os: string;
    constructor() {
        this.Os = '';
    }
}

export class MachineOld {
    public ServiceID : number;
    public Cores : number;
    public Ram : number;
    public HardDisk : number;
    public Iops : number;
    public OperativeSystem : string;
    public VirtualNetworkID : number;
    public CloudID : number;

    constructor(){
        this.ServiceID = null;
        this.Cores = null;
        this.Ram = null;
        this.HardDisk = null;
        this.Iops = null;
        this.OperativeSystem = null;
        this.VirtualNetworkID = null;
        this.CloudID = null;
    }
}
