import { GraphDto } from './../DTO/dtos';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataCenterComponent } from '../dataCenter.component';
import { WlService } from 'src/app/services/wlservice.service';
import { MatSnackBar } from '@angular/material/snack-bar';


const graph: GraphDto = {
  CPU: [
    {
      name: 'Usada',
      value: 24.0
    },
    {
      name: 'Libre',
      value: 0.0
    }
  ],
  RAM: [
    {
      name: 'Usada',
      value: 168.0
    },
    {
      name: 'Libre',
      value: 40.0
    }
  ],
  HD: [
    {
      name: 'Usada',
      value: 3826.00
    },
    {
      name: 'Libre',
      value: -26.00
    }
  ]
}

@Component({
  selector: 'app-childrenUsers',
  templateUrl: './childrenUsers.component.html',
  styleUrls: ['./childrenUsers.component.scss']
})
export class ChildrenUsersComponent implements OnInit {
  @ViewChild(DataCenterComponent) dataCenterComponent: DataCenterComponent;
  public resourcePoolChart: any = {
    colorScheme: {
      domain: ['#e83e8c', '#6c757d', '#FD443A', '#AAAAAA']
    },
    customColors: [
      {
        name: 'Success',
        value: '#e83e8c'
      },
      {
        name: 'Failed',
        value: '#6c757d'
      },
      {
        name: 'Error',
        value: '#FD443A'
      },
      {
        name: 'Warning',
        value: '#FF992C'
      },
    ],
    data: [],
    showXAxis: false,
    showYAxis: false,
    gradient: false,
    showLegend: true,
    showXAxisLabel: false,
    showYAxisLabel: true,
    autoScale: true,
    view: [450, 200]
  };


  public selectedCustomer: any;
  public displayedColumns = [
    'HV', 'SQL', 'Estado',
    'SID', 'SistemaOperativo', 'RAM',
    'Cores', 'HDD', 'Options'];
  public indexcloud: number;
  public clouds: any = [];
  public resourcesPool: any = [];
  public customerId: string;
  public services: any = [];
  public isLoading = false;
  public machines: any = [];
  public machinesResources: any = [];
  public allMachinesCloud: any[] = [];
  constructor(
    private service: WlService,
    private route: ActivatedRoute,
    public snackbar: MatSnackBar
  ) {
    this.route.params.subscribe(params => {
      this.customerId = params.id;
      this.getUser(this.customerId);
      this.getCustomerCloud();
    });

  }

  ngOnInit() {
    this.getResourcePoolChart();
  }

  validShowPool() {
    return this.services.some((vm) => vm.category === "RP")
  }

  getResourcePoolChart() {

    this.resourcePoolChart.data = {
      CPU: [{
        name: 'Usada',
        value: 0,
      },
      {
        name: 'Libre',
        value: 0,
      }
      ],
      RAM: [{
        name: 'Usada',
        value: 0,
      },
      {
        name: 'Libre',
        value: 0,
      }],
      HD: [{
        name: 'Usada',
        value: 0,
      },
      {
        name: 'Libre',
        value: 0,
      }]
    };
  }

  getUser(customerId) {
    this.service.GetUserInformation(customerId).subscribe((customer) => {
      this.selectedCustomer = customer;
    });
  }

  SelectedGraphPoolMethod($event) {
    if ($event.serviceID !== 'CS' || $event.serviceID !== null || $event.customerID !== null) {
      this.service.GetGraphPool($event.customerID, $event.serviceID).subscribe(
        response => {
          this.resourcePoolChart.data = response; // Metodo para borrar Equipo
        }, () => {
          this.resourcePoolChart.data = graph;
        });
    }
  }

  getCustomerCloud() {
    this.machines = [];
    this.machinesResources = []
    this.isLoading = true;
    this.service.GetCustomerCloud(this.customerId).subscribe(
      (response) => {
        this.clouds = response;
        this.resourcesPool = response;
        this.service.GetCustomerMachines(this.customerId).subscribe(
          (machines) => {
            this.services = machines;
            this.services.forEach(element => {
              switch (element.category) {
                case 'CS':
                  this.machines = element.machinesList;
                  break;
                case 'RP':
                  this.machinesResources = element.machinesList;
                  break;
                default:
                  break;
              }
            });

            for (let i = 0; i < this.clouds.length; i++) {
              this.clouds[i].machines = this.getFilteredMachines(this.clouds[i]);
              console.log('Despues del filtro las CS', this.clouds)
              this.clouds[i].machinesResources = this.GetMachinesPool(this.clouds[i]);

            }
            this.onCloudChanged({ index: this.indexcloud });
          },
          (error) => {
            this.isLoading = false;
            console.error(error);
          }
        );
      },
      error => {
        this.isLoading = false;
        console.error(error);
      }
    );
    this.isLoading = false;
  }

  openNetworkSettings(datacenterID: string) {
    this.dataCenterComponent.OpenNetworkSettings(datacenterID);
  }

  onSelect(event) {
  }

  onCloudChanged(data) {
    this.indexcloud = data.index;
    this.allMachinesCloud = this.clouds[this.indexcloud] && this.clouds[this.indexcloud].machines ?
      this.clouds[this.indexcloud].machines : [];
  }

  getFilteredMachines(cloud) {
    var _tmp = this.machines.filter(s => s.VcenterID === cloud.DataCenterID);
    return _tmp;
  }

  getFilteredReorucesPool(cloud) {
    var _tmp = this.machinesResources.filter(s => s.VcenterID === cloud.DataCenterID);

    return _tmp;
  }

  getMachineImage(machine: any) {
    if (machine.Configuration.OperativeSystem.Os.indexOf('WIN') !== -1) {
      return '/assets/img/windows.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('DEBIAN') !== -1) {
      return '/assets/img/debianLogoBg.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('CENTOS') !== -1) {
      return '/assets/img/centosLogoBg.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('UBUNTU') !== -1) {
      return '/assets/img/ubuntuLogoBg.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('SUSE') !== -1) {
      return '/assets/img/suseLogoBg.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('REDHAT') !== -1) {
      return '/assets/img/redhatLogoBg.png';
    } else if (machine.Configuration.OperativeSystem.Os.indexOf('Other') !== -1) {
      return '/assets/img/noOS-en.png';
    } else {
      return '../assets/img/linuxM.png';
    }
  }

  GetAlias(machine: any) {
    if (machine.ServiceID == null) {
      return this.ReplaceName(machine.Configuration.OperativeSystem.Os);
    } else if (machine.ServiceName != null) {
      return this.ReplaceName(machine.ServiceName);
    } else {
      return this.ReplaceName(machine.Configuration.OperativeSystem.Os);
    }
  }

  ReplaceName(name) {
    const response = name.replace(/_/g, ' ');
    let capitalizeName = '';
    response.split(' ').forEach(element => {
      if (capitalizeName.length !== 0) {
        capitalizeName += ' ';
      }
      capitalizeName += element.charAt(0).toUpperCase() + element.toLowerCase().slice(1);
    });
    return capitalizeName;
  }

  GetMachinesPool(cloud) {
    const pools = {};
    const machines = this.machinesResources.filter(s => s.VcenterID === cloud.DataCenterID);
    machines.map((element) => {
      const sid = element.ServiceID.split('_')[0];
      if (!pools[sid]) {
        pools[sid] = machines.filter(s => s.ServiceID.includes(sid));
      }
    });
    return pools;
  }

}
